import { ArrowIcon, InvoicesIssuedBigIcon, InvoicesReceivedBigIcon } from "@components/icon";
import { RadioListItem } from "@components/inputs/radioButtonGroup/RadioListItem";
import { saveEntity } from "@odata/Data.utils";
import { IDocumentDraftEntity, IRecurringTaskEntity } from "@odata/GeneratedEntityTypes";
import { DocumentTypeCode, RecurringTaskTypeCode } from "@odata/GeneratedEnums";
import { BatchRequest } from "@odata/OData";
import { ODataError, ODataQueryResult } from "@odata/ODataParser";
import { isObjectEmpty } from "@utils/general";
import { cloneDeep } from "lodash";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { Button } from "../../components/button";
import Dialog, { DialogContext, IDialogContext } from "../../components/dialog/Dialog";
import Field from "../../components/inputs/field";
import RadioButtonGroup from "../../components/inputs/radioButtonGroup";
import { NEW_ITEM_DETAIL } from "../../constants";
import { AppContext } from "../../contexts/appContext/AppContext.types";
import { WithPermissionContext, withPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { IconSize, LabelStatus, RadioButtonGroupLayout } from "../../enums";
import BindingContext, { createBindingContext } from "../../odata/BindingContext";
import { getAlertFromError } from "../../views/formView/Form.utils";
import { FormStorage } from "../../views/formView/FormStorage";
import { FormViewForExtend, IFormViewProps } from "../../views/formView/FormView";
import { DocumentFormViewForExtend } from "../documents/DocumentFormView";
import { getDefinitions } from "./Draft.def";
import DraftForm from "./DraftForm";
import { DialogHeader } from "./RecurringTask.styles";
import { IRecurringTaskCustomData } from "./RecurringTask.utils";

interface IProps extends WithPermissionContext, WithTranslation, IFormViewProps<IRecurringTaskEntity, IRecurringTaskCustomData> {
    onClose: () => void;
}

interface IState {
    page: number,
    draftData: IDocumentDraftEntity
}

const PAGE_COUNT = 3;

class TaskEditDialog extends FormViewForExtend<IRecurringTaskEntity, IProps, IState> {
    static contextType = AppContext;
    formStorage: FormStorage<IRecurringTaskEntity, IRecurringTaskCustomData>;
    formViewRef = React.createRef<DocumentFormViewForExtend>();
    dialogContext: IDialogContext;

    init = () => {
        const draftId = this.props.storage.data.entity?.DocumentDraft?.Id;
        const bindingContext = createBindingContext("DocumentDrafts", this.props.storage.oData.metadata)
            .addKey(draftId ?? NEW_ITEM_DETAIL, !draftId);

        this.formStorage = new FormStorage({
            id: "recurringTaskDraft",
            oData: this.props.storage.oData,
            theme: this.props.storage.theme,
            context: this.context,
            t: this.props.t,
            definition: getDefinitions(this.props.storage.context, this.props.storage.data.entity.TypeCode ?? DocumentTypeCode.InvoiceIssued).form,
            bindingContext,
            refresh: () => {
                this.formViewRef?.current?.forceUpdate();
            }
        });
    };

    state: IState = {
        page: 1,
        draftData: null
    };

    shouldComponentUpdate(nextProps: IProps, nextState: IState): boolean {
        return this.state.page !== nextState.page;
    }

    handleFinalConfirm = async () => {
        this.dialogContext?.setBusy(true);

        const task = this.props.storage.data.entity;
        const draft = await this.formViewRef.current?.prepareDataForSave(this.formStorage.data.entity, true) as IDocumentDraftEntity;
        const draftBc = this.formStorage.data.bindingContext;
        if (draftBc.isNew()) {
            draft[BindingContext.NEW_ENTITY_ID_PROP] = "new";
            draft.Company = { Id: this.context.getCompany().Id };
            draft.GeneratedByRecurringTask = { Id: task.Id };
        } else {
            const batch: BatchRequest = this.props.storage.oData.batch();
            batch.beginAtomicityGroup("draft");

            saveEntity({
                bindingContext: draftBc,
                entity: draft,
                originalEntity: this.formStorage.data.origEntity,
                batch,
                changesOnly: false
            });

            const result = await batch.execute();

            this.dialogContext?.setBusy(false);

            if (result[0].status >= 300) {
                this.formStorage.setFormAlert(getAlertFromError(result[0].body as ODataError));
                this.formStorage.refresh();
                this.dialogContext?.scrollRef?.current?.scroll({ top: 0 });
                return;
            }

            const draftId = (result[0]?.body as ODataQueryResult)?.value?.Id;

            if (draftId) {
                this.formStorage.data.origEntity = this.formStorage.data.entity;
            }
        }
        if (draft.DocumentTypeCode === DocumentTypeCode.InvoiceReceived && draft.BusinessPartner?.BusinessPartner?.Id) {
            draft.BusinessPartner.BusinessPartner.BankAccounts = this.formStorage.getCustomData().bankAccounts;
            this.props.storage.setCustomData({ bankAccounts: this.formStorage.getCustomData().bankAccounts });
        }
        task.DocumentDraft = draft;
        this.props.storage.setCustomData({ copyDraftEntity: true });
        this.props.onClose();
    };

    handleCancel = () => {
        this.props.onClose();
    };

    getDialogHeader = (title: string) => {
        return <DialogHeader>{this.state.page}/{PAGE_COUNT}: {title}</DialogHeader>;
    };

    handleTypeChange = async (id: string) => {
        if (this.props.storage.getValueByPath("TypeCode") !== id) {
            this.props.storage.setValueByPath("TypeCode", id);
            const bindingContext = createBindingContext("DocumentDrafts", this.props.storage.oData.metadata)
                .addKey(NEW_ITEM_DETAIL, true);

            this.formStorage = new FormStorage({
                id: "recurringTaskDraft",
                oData: this.props.storage.oData,
                theme: this.props.storage.theme,
                context: this.context,
                t: this.props.t,
                definition: getDefinitions(this.props.storage.context, id).form,
                bindingContext,
                refresh: () => {
                    this.formViewRef?.current?.forceUpdate();
                }
            });
            this.forceUpdate();
        }
    };

    renderPage = (dialogContext: IDialogContext): React.ReactElement => {
        const type = this.props.storage.getValueByPath("TypeCode");

        this.dialogContext = dialogContext;

        switch (this.state.page) {
            case 1:
                const listDef = [{
                    id: RecurringTaskTypeCode.InvoiceReceived,
                    label: <RadioListItem
                        title={this.props.t("RecurringTasks:TaskDialog.InvoiceReceived")}
                        description={this.props.t("RecurringTasks:TaskDialog.InvoiceReceivedDesc")}
                        icon={InvoicesReceivedBigIcon}/>
                }, {
                    id: RecurringTaskTypeCode.InvoiceIssued,
                    label: <RadioListItem
                        title={this.props.t("RecurringTasks:TaskDialog.InvoiceIssued")}
                        description={this.props.t("RecurringTasks:TaskDialog.InvoiceIssuedDesc")}
                        icon={InvoicesIssuedBigIcon}/>
                }];

                return (<>
                    {this.getDialogHeader(this.props.t("RecurringTasks:ScheduledTask"))}
                    <Field labelStatus={LabelStatus.Removed}>
                        <RadioButtonGroup
                            onChange={this.handleTypeChange}
                            definition={listDef}
                            checkedButton={type}
                            layout={RadioButtonGroupLayout.Column}/>
                    </Field>
                </>);
            case 2:
            case 3:
                const title = this.props.t(`RecurringTasks:TaskDialog.${this.state.page === 2 ? `Selected${type}` : "Confirmation"}`);
                const draftData = this.state.draftData?.DocumentTypeCode === type ? this.state.draftData : null;
                return (<>
                    {this.getDialogHeader(title)}
                    <DraftForm
                        passRef={this.formViewRef}
                        storage={this.formStorage}
                        draftData={draftData}
                        page={this.state.page}
                        typeCode={type}
                    />
                </>);
        }
        return <></>;
    };

    handleNextPage = () => {
        this.setState({ page: this.state.page + 1 }, () => this.forceUpdate());
    };

    handleBack = () => {
        this.setState({ page: this.state.page - 1 }, () => this.forceUpdate());
    };

    get hasDraft() {
        return !isObjectEmpty(this.props.storage.data.entity?.DocumentDraft);
    }

    handleOpen = async () => {
        this.init();
        const draftData = cloneDeep(this.props.storage.data.entity?.DocumentDraft);
        this.setState({ page: this.hasDraft ? 2 : 1, draftData }, () => this.formViewRef?.current?.forceUpdate());
    };

    handleConfirm = (): void => {
        if (this.state.page !== PAGE_COUNT) {
            this.handleNextPage();
        } else {
            this.handleFinalConfirm();
        }
    };

    getFooterBtn = () => {
        return (
            <Button
                onClick={this.handleConfirm}>
                {this.props.t(`Common:General.${this.state.page !== PAGE_COUNT ? "Continue" : "Finish"}`)}
            </Button>
        );
    };

    render = () => {
        if (!this.isReady()) {
            return null;
        }

        return (
            <Dialog onAfterOpen={this.handleOpen}
                    title={this.props.t("RecurringTasks:ScheduledTask")}
                    onClose={this.handleCancel}
                    onConfirm={this.handleConfirm}
                    width={"550px"}
                    height={"100%"}
                    footer={(
                        <>
                            {this.state.page === 1 ? null : <Button isTransparent
                                                                    icon={<ArrowIcon width={IconSize.S}
                                                                                     height={IconSize.S}/>}
                                                                    onClick={this.handleBack}>
                                {this.props.t("Common:General.Back")}
                            </Button>}
                            <Button isTransparent
                                    onClick={this.handleCancel}>
                                {this.props.t("Common:General.Cancel")}
                            </Button>
                            {this.getFooterBtn()}
                        </>
                    )}>
                <DialogContext.Consumer>
                    {(dialogContext: IDialogContext) => {
                        return (
                            <>
                                {this.renderPage(dialogContext)}
                            </>
                        );
                    }}
                </DialogContext.Consumer>
            </Dialog>
        );
    };
}

export default withPermissionContext(withTranslation(["Common", "RecurringTasks", "Document"], { withRef: true })(TaskEditDialog));
