import { WithConfirmationDialog, withConfirmationDialog } from "@components/dialog/withConfirmationDialog";
import { ActionType, ISmartFastEntriesActionEvent } from "@components/smart/smartFastEntryList";
import { ISmartFieldBlur, ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { setNestedValue } from "@odata/Data.utils";
import { BankTransactionEntity, EntityTypeName, IBankTransactionEntity } from "@odata/GeneratedEntityTypes";
import {
    BankTransactionTypeCode,
    CompanyPermissionCode,
    CurrencyCode,
    DocumentTypeCode,
    PaymentDocumentItemTypeCode,
    SelectionCode
} from "@odata/GeneratedEnums";
import { BatchRequest } from "@odata/OData";
import { IDocumentFormViewProps } from "@pages/documents/DocumentInterfaces";
import { handleBankAccountOrIBANBlur } from "@utils/BankUtils";
import { isAccountAssignmentCompany, isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { ICopyEntityArgs } from "@utils/DraftUtils";
import { getRoundedSplitSum } from "@utils/PairTableUtils";
import { checkPermissionWithOwnPermission } from "@utils/permissionUtils";
import i18next from "i18next";
import { cloneDeep } from "lodash";
import React, { ReactElement } from "react";

import BusyIndicator from "../../../components/busyIndicator";
import { Button } from "../../../components/button";
import Dialog from "../../../components/dialog/Dialog";
import { AppContext, ContextEvents } from "../../../contexts/appContext/AppContext.types";
import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";
import { PageViewMode, Status } from "../../../enums";
import { EmptyObject } from "../../../global.types";
import BindingContext, { IEntity } from "../../../odata/BindingContext";
import DraftFormView, { IDraftFormViewProps } from "../../../views/formView/DraftFormView";
import { FormStorage, IGetCorrectErrorBc, ISaveArgs } from "../../../views/formView/FormStorage";
import {
    correctAccountIdAfterChange,
    correctAccountIds,
    handleAccAssignmentChange,
    handleAccAssignmentChildrenChange,
    IAccAssDialogCustomData,
    invalidateAccountAssignments,
    sendAdditionalRequestForAccountAssignment,
    setCorrectSpecialItemsForAccountAssignment
} from "../../accountAssignment/AccountAssignment.utils";
import { AccountAssignmentDialog } from "../../accountAssignment/AccountAssignmentDialog";
import {
    accountPartnerDepFields,
    clearBPIfNotSet,
    handleBusinessPartnerBlur,
    handleBusinessPartnerChange,
    setSavedContactItems
} from "../../businessPartner/BusinessPartner.utils";
import {
    handleCustomCbaCategoryChange,
    loadCategoriesMap,
    setCorrectSpecialItemsForItemCategory
} from "../../cashBasisAccounting/CashBasisAccounting.utils";
import CustomCategoryTemporalDialog from "../../cashBasisAccounting/CustomCategoryTemporalDialog";
import DialogPage from "../../DialogPage";
import { DRAFT_ITEM_ID_PATH } from "../../documents/Document.utils";
import {
    deleteEmptyBankAccount,
    getBankBlurArgs,
    handleBankFieldChange,
    IBankAccountsCustomData,
    loadAllBankAccounts,
    prepareAllSavedAccounts,
    SAVED_ACCOUNTS_PATH
} from "../bankAccounts/BankAccounts.utils";
import {
    addExpenseGainIfMissing,
    correctGainAccountAssignment,
    deleteAdditionalGains,
    handleItemAmountBlur
} from "../Pair.utils";
import { createPaymentEntityCopy } from "../Payments.utils";
import BankTransactionPairTableView from "./BankTransactionPairTableView";
import {
    getBankTransactionType,
    getTranBalance,
    handleBeforeSave,
    handleLineItemsChange,
    IBankCustomData,
    refreshExRateAndRefreshItems,
    renderAssignmentSplitDialog,
    renderPairingDialog,
    setNumberOurs
} from "./BankTransactions.utils";
import { isPairFastEntryDisabled } from "./BankTransactionsDef";
import { getDefinitions as getStatementDefinition } from "./BankTransactionStatementPairDef";
import BankTransactionStatementPairTableView from "./BankTransactionStatementPairTableView";

interface IProps extends IDraftFormViewProps<IBankTransactionEntity, IBankCustomData & IBankAccountsCustomData>, WithConfirmationDialog {
}

class BankTransactionsFormView extends DraftFormView<IBankTransactionEntity, IProps> {
    static contextType = AppContext;

    documentTypeCode = DocumentTypeCode.BankTransaction;

    get _documentItemDomainType(): string {
        return EntityTypeName.PaymentDocumentItem;
    }

    get hasAccountAssignment(): boolean {
        return isAccountAssignmentCompany(this.context);
    }

    get isCashBasisAccountingCompany(): boolean {
        return isCashBasisAccountingCompany(this.context);
    }

    componentDidMount(): void {
        super.componentDidMount();

        this.registerHeader();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    componentDidUpdate(prevProps: Readonly<IDocumentFormViewProps>, prevState: Readonly<EmptyObject>): void {
        super.componentDidUpdate(prevProps, prevState);
        this.registerHeader();
    }

    getAdditionalLoadPromise = (): Promise<void>[] => {
        const promises = [
            loadAllBankAccounts(this.props.storage)
        ];
        return promises;
    };

    registerHeader = (): void => {
        if (this.props.storage.data.bindingContext) {
            const bc = this.props.storage.data.bindingContext.navigate(BankTransactionEntity.BankStatement);
            this.props.storage.addCustomRef(this._refHeader.current, bc);

            const refBc = this.props.storage.data.bindingContext.navigate(BankTransactionEntity.TransactionAmount);
            this.props.storage.addCustomRef(this._refHeader.current, refBc);
        }
    };

    onAfterLoad = async () => {
        const { storage } = this.props;


        const businessPartner = this.entity.BusinessPartner?.BusinessPartner;
        setSavedContactItems(storage, businessPartner?.Contacts);
        setCorrectSpecialItemsForAccountAssignment(storage, BankTransactionEntity.Items);
        await Promise.all([setCorrectSpecialItemsForItemCategory(storage), correctAccountIds(storage, BankTransactionEntity.Items)]);

        const bc = storage.data.bindingContext.navigate(SAVED_ACCOUNTS_PATH);
        const savedAccountsInfo = storage.getInfo(bc);

        this.correctSplitValue();

        if (savedAccountsInfo) {
            prepareAllSavedAccounts({
                storage,
                type: DocumentTypeCode.BankTransaction,
                businessPartner,
                isInit: true
            });
        }

        if (this.isCashBasisAccountingCompany) {
            loadCategoriesMap(storage);
        }
        return super.onAfterLoad();
    };


    handleLineItemsAction = (args: ISmartFastEntriesActionEvent): void => {
        args.actionType !== ActionType.Add && this.saveDraft();
        if (args.actionType === ActionType.Clone) {
            const [item] = args.affectedItems;
            delete item[DRAFT_ITEM_ID_PATH];
        }

        this.props.storage.handleLineItemsAction(args);
        this.props.storage.refresh();
    };

    correctSplitValue = () => {
        // in some cases BE returns split code in assignment but without split items
        // in such case we delete code
        const storage = this.props.storage;

        for (const data of storage.data.bindingContext.iterateNavigation("Items", storage.data.entity.Items)) {
            if (data.entity.PaymentDocumentItemTypeCode === PaymentDocumentItemTypeCode.Payment && data.entity.AccountAssignmentSelection?.Selection?.Code === SelectionCode.Split) {
                if (!data.entity?.SplitAccountAssignments?.length) {
                    setNestedValue(null, "AccountAssignmentSelection/Selection/Code", data.entity);

                    storage.setError(data.bindingContext.navigate("AccountAssignmentSelection/AccountAssignment"), {
                        message: i18next.t("Banks:Transactions.MissingSplit")
                    });
                }
            }
        }
    };

    handleTransactionAmountBlur = (args: ISmartFieldChange) => {
        if (args.bindingContext.getPath() === BankTransactionEntity.TransactionAmount) {
            const val = this.props.storage.getValue(args.bindingContext);
            if (!isNaN(val) && val < 0) {
                this.props.storage.setValueByPath(BankTransactionEntity.BankTransactionType, BankTransactionTypeCode.OutgoingPayment);
            }
        }
    };


    handleBlur = async (args: ISmartFieldBlur) => {
        if (args.wasChanged) {
            handleItemAmountBlur(this.props.storage, args);
        }

        await this.props.storage.handleBlur(args);

        if (args.wasChanged) {
            await handleBusinessPartnerBlur({
                storage: this.props.storage,
                type: DocumentTypeCode.BankTransaction
            }, args, true, false);
            handleBankAccountOrIBANBlur(getBankBlurArgs(this.props.storage, args.bindingContext));
            this.handleTransactionAmountBlur(args);
        }

        this.props.storage.refreshFields();
    };

    handleBankPairChange = (e: ISmartFieldChange) => {
        // needs to be called after change
        if (e.bindingContext.getPath() === BankTransactionEntity.DocumentLinks) {
            const links = this.entity?.DocumentLinks;
            for (const link of links || []) {
                if (!link.TargetDocument._metadata) {
                    const selItem = (e.selectedItems || []).find(item => item.id === link.TargetDocument.Id);
                    if (selItem && selItem.additionalData?._metadata) {
                        link.TargetDocument._metadata = selItem.additionalData._metadata;
                    }
                }
            }
        }
    };

    confirmAssignment = async () => {
        const storage: FormStorage<unknown, IAccAssDialogCustomData> = this.props.storage;
        const bc = storage.getCustomData().AccountAssignmentDialogBc;
        const item = storage.getValue(bc.getParent().getParent());

        const oldType = item?.AccountAssignmentSelection?.Selection?.Code;
        await deleteAdditionalGains(storage, item, oldType);

        correctGainAccountAssignment(storage, item);
        addExpenseGainIfMissing(storage, item);
        this.props.storage.refresh();
    };

    handleLineItemsChange = async (args: ISmartFieldChange) => {
        // if you change something just for this method, keep in mind changes in CashBox !!
        await handleLineItemsChange(this.props.storage, args);


        this.saveDraft();
        this.props.storage.refreshFields();
    };

    handleStatementChange = (e: ISmartFieldChange) => {
        if (e.bindingContext.getPath() === BankTransactionEntity.BankStatement && e.triggerAdditionalTasks) {
            const numberOurs = e.additionalData.NumberOurs;
            setNumberOurs(numberOurs, e.additionalData._metadata.Transactions?.count, this.props.storage);

            const date = this.entity.DateBankTransaction;
            refreshExRateAndRefreshItems(this.props.storage, e.additionalData?.BankAccount?.TransactionCurrencyCode, date);
        }
    };

    handleSavedAccountChange = (e: ISmartFieldChange) => {
        // be aware that this is only part of the code of saved account change
        // rest is in handleBankFieldChange
        if (e.bindingContext.getNavigationPath(true) === SAVED_ACCOUNTS_PATH && e.triggerAdditionalTasks) {
            if (e.value && e.value !== SelectionCode.Own) {
                // load business partner fields based on business partner and change status from creating (if there was such status)
                this.props.storage.processDependentField(accountPartnerDepFields, e.additionalData, e.bindingContext);
                setSavedContactItems(this.props.storage, e.additionalData?.BusinessPartner?.Contacts);
                this.props.storage.setCustomData({ bankAccounts: e.additionalData?.BusinessPartner?.BankAccounts });

                this.props.storage.setGroupStatus({
                    isCreating: false
                }, "partner");

                this.props.storage.refreshGroupByKey("partner");
            }
        }
    };

    handleFieldsForPair = (e: ISmartFieldChange) => {
        const path = e.bindingContext.getPath();
        if ((path === BankTransactionEntity.DateBankTransaction && (e.triggerAdditionalTasks || !e.value)) ||
            (path === BankTransactionEntity.BankStatement && e.triggerAdditionalTasks) ||
            [BankTransactionEntity.BankTransactionType, BankTransactionEntity.TransactionAmount, BankTransactionEntity.ExchangeRatePerUnit].includes(path as BankTransactionEntity)) {
            this.props.storage.addActiveField(this.props.storage.data.bindingContext.navigate(BankTransactionEntity.Items));
        }
    };

    handleBankTransactionTypeChange = (e: ISmartFieldChange) => {
        if (e.bindingContext.getPath() === BankTransactionEntity.BankTransactionType) {
            // reset account assignment select => filter has changed
            invalidateAccountAssignments(this.props.storage);
        }
    };

    handleChange = (e: ISmartFieldChange) => {
        const { storage } = this.props;
        const wasPairDisabled = isPairFastEntryDisabled(storage);

        handleBusinessPartnerChange({
            storage: this.props.storage,
            type: DocumentTypeCode.BankTransaction
        }, e, { showsAllBankAccounts: true, allowCreate: false });
        this.handleStatementChange(e);
        this.handleSavedAccountChange(e);

        this.handleDateBankTransactionChange(e);

        handleAccAssignmentChildrenChange(storage, e);
        handleAccAssignmentChange({
            storage,
            event: e,
            documentTypeCode: DocumentTypeCode.BankTransaction
        });

        storage.handleChange(e);

        this.handleBankTransactionTypeChange(e);
        // must be called after change
        correctAccountIdAfterChange(storage, e);

        const isPairDisabled = isPairFastEntryDisabled(storage);

        this.handleBankPairChange(e);
        if (wasPairDisabled !== isPairDisabled) {
            this.handleFieldsForPair(e);
        }

        handleBankFieldChange({
            storage,
            type: DocumentTypeCode.BankTransaction,
            changePartner: true
        }, e);

        this.saveDraft();
        storage.refreshFields(e.triggerAdditionalTasks);
    };

    handleDateBankTransactionChange = async (args: ISmartFieldChange) => {
        if (args.bindingContext.getPath() === BankTransactionEntity.DateBankTransaction && args.triggerAdditionalTasks) {
            const currency = this.entity.TransactionCurrency?.Code;
            refreshExRateAndRefreshItems(this.props.storage, currency, args.value as Date);
        }
    };

    handleClosePairStatementPairing = () => {
        this.props.storage.setCustomData({ statementPairedDialogOpened: false });
        this.forceUpdate();
    };

    handleStatementPairingDialogAfterSave = (bc: BindingContext) => {
        const info = this.props.storage.getInfo(this.props.storage.data.bindingContext.navigate(BankTransactionEntity.BankStatement));
        if (info) {
            // this forces reload items in selector
            delete info.fieldSettings.items;
            this.forceUpdate();
        }
    };

    renderAssignStatementDialog = () => {
        return (
            <>
                {this.props.storage.getCustomData().statementPairedDialogOpened &&
                    <Dialog
                        isEditableWindow={true}
                        onConfirm={null}
                        onClose={this.handleClosePairStatementPairing}>
                        <DialogPage
                            onAfterSave={this.handleStatementPairingDialogAfterSave}
                            pageViewMode={PageViewMode.FormReadOnly}
                            rootStorage={this.props.storage}
                            tableView={BankTransactionStatementPairTableView}
                            getDef={getStatementDefinition}/>
                    </Dialog>
                }
            </>
        );
    };

    canPostDocument = (): boolean => {
        return checkPermissionWithOwnPermission(this.props.storage, this.props.permissionContext, CompanyPermissionCode.Bank);
    };

    shouldDisableSaveButton = (): boolean => {
        return !this.canPostDocument() || this.props.storage.data.entity[BankTransactionEntity.Locks]?.length > 0 || this.props.storage.isDisabled;
    };

    handlePost = () => {
        this.save({
            queryParams: {
                postInvoice: true
            }
        });
    };

    handleSaveAndPost = () => {
        this.save({
            queryParams: {
                postInvoice: true,
                clearInvoice: true
            }
        });
    };

    handleSave = () => {
        this.save();
    };

    handlePreSave = () => {
        const balance = getTranBalance(this.props.storage);
        const hasItems = this.entity.Items?.length > 0;
        const hadItems = this.props.storage.data.origEntity?.Items?.length > 0;
        if (hasItems || hadItems) {
            if (balance === 0) {
                this.handleSaveAndPost();
            } else {
                this.handlePost();
            }
        } else {
            this.handleSave();
        }
    };

    getReadOnlyNavButtonText = () => {
        return this.props.storage.t("Banks:Form.OpenTranAction");
    };

    renderSaveButtons = (): ReactElement => {
        return <Button
            isDisabled={this.shouldDisableSaveButton()}
            onClick={this.handlePreSave}>
            {this.props.storage.t("Common:General.Save")}
        </Button>;
    };

    validateSplitValues = (data: IBankTransactionEntity) => {
        const { storage } = this.props;

        // there can be mismatch in split values in case row was split and ten amount changed via pair dialog
        const errorBcs = [];
        if (this.hasAccountAssignment) {
            for (const item of data.Items || []) {
                const bc = storage.data.bindingContext.navigate("Items").addKey(item);

                if (item.AccountAssignmentSelection?.Selection?.Code === SelectionCode.Split && item.PaymentDocumentItemTypeCode === PaymentDocumentItemTypeCode.Payment) {
                    const amount = item.TransactionAmount;
                    const sum = getRoundedSplitSum(item, storage);

                    if (sum !== amount) {
                        errorBcs.push(bc);
                    }
                }

                if (item.Amount && !item.AccountAssignmentSelection?.Selection?.Code) {
                    errorBcs.push(bc);
                }
            }
        }

        return errorBcs;
    };

    getCorrectErrorBc = (args: IGetCorrectErrorBc): BindingContext => {
        // match errors from BE that points to DebitAccount/CreditAccount to their parent,
        // because we use AccountAssignment field to show both of them together on FE
        if (args.error.entity.entityType === EntityTypeName.PaymentDocumentItemAccountAssignment) {
            return args.matchedBindingContext.getParent();
        }

        return args.matchedBindingContext;
    };

    save = async (args: ISaveArgs = {}) => {
        const { storage } = this.props;
        this.saveDraftDebounced.cancel();
        const data = await this.prepareDataForSave(this.entity);
        const splitErrors = this.validateSplitValues(data);
        if (splitErrors.length > 0) {
            storage.setFormAlert({
                status: Status.Error,
                title: storage.t("Common:General.Error"),
                subTitle: storage.t("Banks:Transactions.SplitError")
            });

            for (const bcErr of splitErrors) {
                storage.setError(bcErr.navigate("AccountAssignmentSelection").navigate("AccountAssignment"), {
                    message: i18next.t("Banks:Transactions.MissingSplit")
                });
            }

            this.forceUpdate(this.scrollPageUp);
            return null;
        }

        this.props.onBeforeSave?.();

        const isNew = storage.data.bindingContext.isNew();
        let shouldReloadAccountAssignment = false;
        const shouldReloadBankAccounts = false;

        if (isNew) {
            data.CurrencyCode = CurrencyCode.CzechKoruna;
        }

        const subTitle = storage.t(`${storage.data.definition?.translationFiles?.[0]}:Validation.Saved`);
        const result = await storage.save({
            ...args,
            data,
            onBeforeExecute: async (batch: BatchRequest) => {
                // const isNewBusinessPartner = !data.BusinessPartner?.BusinessPartner?.Id;

                // addBusinessPartnerContactRequest(storage, isNewBusinessPartner, batch);
                // shouldReloadBankAccounts = addBusinessPartnerBankAccountRequest(this.props.storage, isNewBusinessPartner, batch);

                shouldReloadAccountAssignment = await sendAdditionalRequestForAccountAssignment(storage, data, batch, DocumentTypeCode.BankTransaction, "Items");
            },
            getCorrectErrorBc: this.getCorrectErrorBc
        });

        if (!result) {
            this.props.onSaveFail?.();
            this.forceUpdate(this.scrollPageUp);
            return result;
        }

        if (isNew && !!data.PaymentDocumentDraft) {
            this.context.eventEmitter.emit(ContextEvents.RecalculateDrafts);
        }

        if (shouldReloadBankAccounts) {
            storage.setCustomData({ allBankAccounts: null });
        }

        // we need to remove all cached items in linked pair account assignments
        // if (shouldReloadAccountAssignment) {
        invalidateAccountAssignments(storage, BankTransactionEntity.Items);
        // }

        await storage.displaySaveOkMessage(subTitle);

        if (!args.skipAfterSave) {
            this.onAfterSave?.(isNew, false);
        }
        this.forceUpdate(isNew ? this.scrollPageDown : undefined);

        // we need to refresh account filter as it shows last update date
        if (args?.queryParams?.postInvoice || this.isCashBasisAccountingCompany) {
            await this.context.updateCompanyBankAccounts();
            storage.refresh(true);
        }

        return result;
    };

    handleCustomCbaCategoryChange = (e: ISmartFieldChange): void => {
        handleCustomCbaCategoryChange(e, this.props.storage);
    };

    handleCloseCbaCategoryDialog = (): void => {
        this.props.storage.setCustomData({
            isCbaCategoryDialogOpen: false
        });
        this.forceUpdate();
    };

    createEntityCopy = (args: ICopyEntityArgs = {}): IBankTransactionEntity => {
        const ALLOWED_NAVIGATIONS = [
            "BankAccount", "BankStatement", "BankTransactionType", "BusinessPartner", "Company",
            "Currency", "Items", "NumberRange", "PaymentMethod", "TransactionCurrency"
        ];

        if (args.includeAttachments) {
            ALLOWED_NAVIGATIONS.push("Attachments");
        }

        return createPaymentEntityCopy<IBankTransactionEntity>(this.props.storage, args, ALLOWED_NAVIGATIONS);
    };

    prepareDataForSave = async (data: IEntity, isDraft?: boolean): Promise<IBankTransactionEntity> => {
        const entity = cloneDeep(data);
        handleBeforeSave(this.props.storage, entity);
        deleteEmptyBankAccount(entity);

        // !isDraft && prepareNewBusinessPartner(this.props.storage, entity, true);
        !isDraft && clearBPIfNotSet(entity);

        return entity;
    };

    render() {
        if (!this.isReady()) {
            return <BusyIndicator isDelayed/>;
        }

        return (
            <>
                {this.renderForm()}
                <AccountAssignmentDialog storage={this.props.storage}
                                         onChange={this.handleChange}
                                         onConfirm={this.confirmAssignment}
                                         onTemporalChange={this.handleTemporalChange}/>
                {renderPairingDialog(this.props.storage, {
                    type: getBankTransactionType(this.props.storage),
                    view: BankTransactionPairTableView,
                    saveDraftFn: this.saveDraft.bind(this)
                })}
                {renderAssignmentSplitDialog(this.props.storage)}
                <CustomCategoryTemporalDialog storage={this.props.storage}
                                              onTemporalChange={this.handleCustomCbaCategoryChange}
                                              onClose={this.handleCloseCbaCategoryDialog}/>
                {this.renderAssignStatementDialog()}
            </>
        );
    }
}

export default withConfirmationDialog(withPermissionContext(BankTransactionsFormView));