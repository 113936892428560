import { SearchAnimationState } from "@components/search/SearchIllustration";
import { composeRefHandlers, isDefined, isNotDefined } from "@utils/general";
import { AnimationItem } from "lottie-web";
import React, { useCallback, useEffect, useRef } from 'react';

import { BikeAnim, QueueAnim } from "../../animations/Animations";
import bikerStaticSvg from "../../svg/login/bikerStatic.svg"; // origName: ostatní/PODVOJ+login+a+registrace/ilustrace_animace/Evala Login.svg
import signSvg from "../../svg/login/sign.svg"; // origName: ostatní/PODVOJ+login+a+registrace/ilustrace_animace/cedule.svg
import { AnimationPlayStatus, AnimationType } from "../Login.utils";
import { AnimationStyled, AnimWrapper, BikerSignImage, StaticBikerImage } from "../LoginPage.styles";
import { useLoginDispatch, useLoginSelector } from "../state/hooks";
import { selectAnimation, setAnimationStatus } from "../state/loginSlice";
import store from "../state/store";

function setAnimationState(anim: AnimationItem, currentStep: number, nextStep: number) {
    if (isDefined(currentStep)) {
        const dir = nextStep > currentStep ? 1 : -1;
        anim.setDirection(dir);
        anim.playSegments([currentStep, nextStep], true);
        store.dispatch(setAnimationStatus(AnimationPlayStatus.Playing));
    } else {
        anim.goToAndStop(nextStep, true);
    }
}

const Animation: React.FC = () => {
    const animRef = useRef<AnimationItem>();

    const config = useLoginSelector(selectAnimation);
    const dispatch = useLoginDispatch();
    const { type, step } = config ?? {};

    const lastStep = useRef<SearchAnimationState>();

    useEffect(() => {
        if (isNotDefined(step) || isNotDefined(animRef.current)) {
            return;
        }
        setAnimationState(animRef.current, lastStep.current, step);
        lastStep.current = step;
    }, [step]);

    // useEffect()

    const handleAnimationRef = useCallback((ref: AnimationItem) => {
        // initial setup
        setAnimationState(ref, lastStep.current, step);
        lastStep.current = step;

        const event = "complete";
        const onAnimationComplete = () => {
            dispatch(setAnimationStatus(AnimationPlayStatus.Stopped));
        };
        ref.addEventListener(event, onAnimationComplete);

        return function unsubscribe() {
            ref.removeEventListener(event, onAnimationComplete);
        };
    }, [step, dispatch]);

    let content;

    switch (type) {
        case AnimationType.Queue:
            content = (
                    <QueueAnim autoplay={false} loop={false}
                               animationRef={composeRefHandlers(animRef, handleAnimationRef)}/>
            );
            break;

        case AnimationType.BikerWithSign:
            // minWidth = 1600;
            content = (<>
                <BikerSignImage src={signSvg} alt=""/>
                <BikeAnim autoplay={false}
                          animationRef={composeRefHandlers(animRef, handleAnimationRef)}
                          loop={false}/>
            </>);
            break;

        case AnimationType.StaticBiker:
        default:
            content = (
                    <StaticBikerImage src={bikerStaticSvg}
                                      alt={"Biker static"}/>
            );
            break;
    }
    return (
            <AnimationStyled>
                <AnimWrapper>
                    {content}
                </AnimWrapper>
            </AnimationStyled>
    );
};

export default Animation;
