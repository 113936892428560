import { AlertPosition, IAlertProps } from "@components/alert/Alert";
import { IWithAlertSettings, WithAlert, withAlert } from "@components/alert/withAlert";
import {
    Condition,
    ConditionType,
    createFilterRow,
    IValueInterval
} from "@components/conditionalFilterDialog/ConditionalFilterDialog.utils";
import { WithConfirmationDialog, withConfirmationDialog } from "@components/dialog/withConfirmationDialog";
import { WithPromisedComponent, withPromisedComponent } from "@components/dialog/withPromisedComponent";
import { getDrillDownLink, getTableDrillDownLink } from "@components/drillDown/DrillDown.utils";
import { HotspotViewIds } from "@components/hotspots/HotspotViewIds";
import {
    CaretIcon,
    CloseIcon,
    DownloadIcon,
    EditSimpleIcon,
    ExpandIcon,
    LockClosedIcon,
    LockOpenedIcon,
    MenuIcon,
    PlusIcon,
    StatusWarnIcon
} from "@components/icon";
import { formatDateToDateString } from "@components/inputs/date/utils";
import { IInputOnBlurEvent, IInputOnChangeEvent } from "@components/inputs/input";
import { NumericInput } from "@components/inputs/numericInput/NumericInput";
import { ISelectionChangeArgs } from "@components/inputs/select/Select.types";
import Switch, { SwitchType } from "@components/inputs/switch/Switch";
import { IReadOnlyListItem } from "@components/readOnlyList/ReadOnlyListItem";
import { IFieldDef, isVisible } from "@components/smart/FieldInfo";
import { TFormTable } from "@components/smart/smartFormGroup/SmartFormGroup";
import SmartTableManager from "@components/smart/smartTable/SmartTableManager";
import Summary from "@components/summary";
import SummaryItem from "@components/summary/SummaryItem";
import { TooltipIconInfo } from "@components/tooltipIcon";
import BindingContext, { createBindingContext } from "@odata/BindingContext";
import { ODataError } from "@odata/Data.types";
import {
    ElectronicSubmissionEntity,
    EntitySetName,
    EntityTypeName,
    ICompanyVatReducedCoefficientEntity,
    IDocumentEntity,
    IElectronicSubmissionEntity
} from "@odata/GeneratedEntityTypes";
import {
    CurrencyCode,
    DocumentTypeCode,
    ElectronicSubmissionSubmissionTypeCode,
    ElectronicSubmissionTypeCode,
    VatStatementFrequencyCode
} from "@odata/GeneratedEnums";
import { getEnumDisplayValue } from "@odata/GeneratedEnums.utils";
import { IFormatOptions, transformToODataString } from "@odata/OData.utils";
import { parseError } from "@odata/ODataParser";
import { WithOData, withOData } from "@odata/withOData";
import AdditionalSubmissionDialog, {
    IAdditionalSubmissionDialogResult
} from "@pages/electronicSubmission/AdditionalSubmissionDialog";
import VatLockOverrideConfirmDialog from "@pages/electronicSubmission/VatLockOverrideConfirmDialog";
import { IOnVatLockOverrideConfirmDialogFinish } from "@pages/electronicSubmission/VatLockOverrideConfirmDialog.utils";
import { CommonReportProps } from "@pages/reports/CommonDefs";
import { CUSTOM_DATE_RANGE_ID } from "@pages/reports/customFilterComponents/ComposedDateRange.utils";
import { DocumentJournalProps } from "@pages/reports/documentJournal/DocumentJournalDef";
import { getCompanyCurrency, isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { isDefined, isNotDefined, isObjectEmpty } from "@utils/general";
import { getOneFetch, isAbortException } from "@utils/oneFetch";
import { capitalize } from "@utils/string";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { BreadCrumbProvider } from "../../components/breadCrumb";
import BusyIndicator from "../../components/busyIndicator";
import { Button, ButtonGroup } from "../../components/button";
import Header from "../../components/header";
import Field from "../../components/inputs/field";
import FieldsWrapper from "../../components/inputs/field/FieldsWrapper";
import { BasicSelect } from "../../components/inputs/select/BasicSelect";
import ReadOnlyList from "../../components/readOnlyList/ReadOnlyList";
import { SmartTable } from "../../components/smart/smartTable";
import Tabs, { ITabData } from "../../components/tabs";
import { DASH_CHARACTER, EMPTY_VALUE } from "../../constants";
import { AppContext, IAppContext } from "../../contexts/appContext/AppContext.types";
import { BasicInputSizes, IconSize, Sort, Status, TextAlign, ValueType } from "../../enums";
import { TRecordAny, TValue } from "../../global.types";
import { IFilterQuery } from "../../model/TableStorage";
import { ValidationMessage } from "../../model/Validator.types";
import { ROUTE_DOCUMENT_JOURNAL } from "../../routes";
import TestIds from "../../testIds";
import CurrencyType, { formatCurrency } from "../../types/Currency";
import DateType, { DATE_MAX, DATE_MIN, getUtcDate, getUtcDateBy, getUtcDayjs } from "../../types/Date";
import customFetch from "../../utils/customFetch";
import memoize from "../../utils/memoize";
import { getAlertFromError } from "../../views/formView/Form.utils";
import { StyledErrorWrapper } from "../../views/formView/FormView.styles";
import View from "../../views/View";
import { getCompanyCurrencyEntity } from "../companies/Company.utils";
import { VatReportProps } from "../reports/VatReport.utils";
import ClearingRatioDialog from "./ClearingRatioDialog";
import {
    createLockedVatStatementSubmission,
    createVatSubmissionDocuments,
    downloadSubmissionXmlFile,
    ElectronicSubmissionDateRangeType,
    ElectronicSubmissionNamespaces,
    getPossibleSubmission,
    getSubmissionEpoFileUrl,
    getVatStatementFrequencyForSubmissionType,
    getVatStatementFrequencyFromDateRange,
    getVatStatementPeriod,
    IPossibleSubmissionRange,
    isSubmissionLocked,
    REGULAR_SUBMISSION_THRESHOLD_DATE,
    removeVatSubmissionDocuments,
    SubmissionStatus,
    TPossibleSubmissions,
    unlockSubmission
} from "./ElectronicSubmission.utils";
import {
    getDocumentJournalDocumentsData,
    getDocumentJournalReportArgs,
    getLiabilityDocumentTableDef,
    getVatSubmissionClearingRatio,
    getVatSubmissionDocumentsData,
    getVatSubmissionPeriodName,
    getVatSubmissionReportArgs,
    isSubmissionLastPeriod,
    IVatSubmissionCountArgs,
    PREVIOUS_SUBMISSIONS_TABLE_ID,
    TVatSubmissionDocumentsData
} from "./VatSubmission.utils";
import {
    ClickableStyled,
    ClickableWrapper,
    CustomFormWrapper,
    DocumentCountWrapper,
    StyledHeading,
    StyledParagraph,
    StyledVatSubmissionDetail,
    TableWrapperStyled
} from "./VatSubmissionDetail.styles";

interface IProps extends WithTranslation, WithOData, WithAlert, WithPromisedComponent, WithConfirmationDialog {
    type: ElectronicSubmissionTypeCode;
    year: number;
    monthIndex: number;
    // current submission for this period
    submission: IElectronicSubmissionEntity;
    // there can be multiple (corrective) submissions for the same period,
    // this is the list of all of them
    allRelatedSubmissions: IElectronicSubmissionEntity[];
    // list of all submissions
    allSubmissionsRows: IElectronicSubmissionEntity[];
    possibleSubmissions: TPossibleSubmissions;
    status?: SubmissionStatus;
    shouldAllowAdditionalSubmission?: boolean;
    onSubmissionChange?: (submission: IElectronicSubmissionEntity, isRemoved?: boolean) => Promise<boolean>;
}

enum VatPaymentType {
    VatLiability = "VatLiability",
    VatReceivable = "VatReceivable"
}

interface IState {
    busy: boolean;
    documents?: TVatSubmissionDocumentsData;
    modifiedDocuments?: TVatSubmissionDocumentsData;
    amount?: number;
    amountError?: string;
    type?: VatPaymentType;
    coefficient?: ICompanyVatReducedCoefficientEntity;
}

class VatSubmissionDetail extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;
    oneFetch = getOneFetch();

    state: IState = {
        busy: true,
        coefficient: null
    };

    getVatFrequency = memoize((): VatStatementFrequencyCode => {
        return getVatStatementFrequencyForSubmissionType({
            type: this.props.type,
            month: this.props.monthIndex,
            submission: this.props.submission,
            possibleSubmissions: this.props.possibleSubmissions
        });
    }, () => [this.props.type, this.props.monthIndex, this.props.submission, this.props.possibleSubmissions]);

    get subtitle() {
        const { year, monthIndex } = this.props;
        const d = getUtcDateBy(year, monthIndex);
        return capitalize(getVatSubmissionPeriodName(d, this.getVatFrequency()));
    }

    get vatSubmissionCountArgs() {
        const args: IVatSubmissionCountArgs = {};
        if (this.props.submission) {
            args.submission = this.props.submission;
        } else {
            const dateInPeriod = getUtcDateBy(this.props.year, this.props.monthIndex, 1);
            const { from, to } = getVatStatementPeriod(dateInPeriod, this.getVatFrequency());

            args.from = from.toDate();
            args.to = to.toDate();
        }
        return args;
    }

    get showClearingRatio(): boolean {
        return this.props.type === ElectronicSubmissionTypeCode.VatStatement && isSubmissionLastPeriod(this.getVatFrequency(), this.props.monthIndex);
    }

    get useVatReducedDeduction(): boolean {
        return this.showClearingRatio && isDefined(this.state.coefficient);
    }

    get isLocked(): boolean {
        return isSubmissionLocked(this.props.submission) || this.props.status === SubmissionStatus.Locked;
    }

    get isLockedVatViesQuarterlyStatement(): boolean {
        return this.isLocked && this.props.type === ElectronicSubmissionTypeCode.VatVIESStatement && this.getVatFrequency() === VatStatementFrequencyCode.Quarterly;
    }

    componentDidMount() {
        this.setBreadcrumbs();
        this.loadDocumentCounts();

        if (this.props.submission?.Id) {
            this.setDefaultStateFromSubmission();

            if (isSubmissionLocked(this.props.submission) && this.showClearingRatio) {
                this.loadClearingRatio();
            }
        }

        this.initAdditionalDocumentsWarning();
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        this.setBreadcrumbs();

        if (this.props.year !== prevProps.year || this.props.monthIndex !== prevProps.monthIndex
            || this.props.submission !== prevProps.submission || this.props.type !== prevProps.type) {
            this.setAlert(null);
            this.loadDocumentCounts();
            this.initAdditionalDocumentsWarning();
        }
        if (this.props.submission?.Id !== prevProps.submission?.Id || isSubmissionLocked(this.props.submission) !== isSubmissionLocked(prevProps.submission)) {
            this.setAlert(null);
            this.setDefaultStateFromSubmission();
            this.initAdditionalDocumentsWarning();
        }
        if (this.props.monthIndex !== prevProps.monthIndex) {
            if (this.props.submission?.Id && isSubmissionLocked(this.props.submission) && this.showClearingRatio) {
                this.loadClearingRatio();
            } else {
                this.setState({ coefficient: null });
            }
        }
    }

    getPossibleSubmission = (): IPossibleSubmissionRange => {
        let possibleSubmission = getPossibleSubmission(this.props.possibleSubmissions, this.props.type, this.props.monthIndex);

        if (!possibleSubmission && this.isLockedVatViesQuarterlyStatement) {
            // quarterly VatVies submission can have monthly possible submissions,
            // in that case, BE is telling us that next time submission is unlocked, it will split from the quarterly frequency into monthly
            // => we need to try to look for the possible submission in all months of the quarter
            const quarter = Math.floor(this.props.monthIndex / 3);
            const possibleMonths = [quarter * 3, quarter * 3 + 1, quarter * 3 + 2].filter(month => month !== this.props.monthIndex);

            for (const month of possibleMonths) {
                possibleSubmission = getPossibleSubmission(this.props.possibleSubmissions, this.props.type, month);

                if (possibleSubmission) {
                    break;
                }
            }
        }

        return possibleSubmission;
    };

    isSplittableQuarterlyVatVies = (): boolean => {
        if (!this.isLockedVatViesQuarterlyStatement) {
            return false;
        }

        const possibleSubmission = this.getPossibleSubmission();

        if (!possibleSubmission) {
            return false;
        }

        return getVatStatementFrequencyFromDateRange({
            DateStart: possibleSubmission.DateStart,
            DateEnd: possibleSubmission.DateEnd
        }) === VatStatementFrequencyCode.Monthly;
    };

    isAdditionalSubmissionAvailable = (): boolean => {
        const possibleSubmission = this.getPossibleSubmission();

        return possibleSubmission?.Type?.includes(ElectronicSubmissionDateRangeType.Corrective);
    };

    isAdditionalSubmissionThresholdCrossed = (): boolean => {
        if (!this.props.submission) {
            return false;
        }

        const today = getUtcDayjs();
        const thresholdDate = getUtcDateBy(this.props.year, this.props.submission.DatePeriodEnd.getMonth() + 1, REGULAR_SUBMISSION_THRESHOLD_DATE);

        return today.isAfter(thresholdDate, "date");
    };

    isAmendedSubmission = (): boolean => {
        return this.props.submission?.ElectronicSubmissionSubmissionTypeCode === ElectronicSubmissionSubmissionTypeCode.Amended;
    };

    getAdditionalSubmissionTypeName = (): string => {
        const isAmended = this.isAdditionalSubmissionThresholdCrossed();
        let name = "";

        if (!isAmended) {
            name += this.props.t(`ElectronicSubmission:VatSubmission.CorrectiveSubmission`);
        } else {
            if (this.props.type === ElectronicSubmissionTypeCode.VatStatement) {
                name += this.props.t(`ElectronicSubmission:VatSubmission.AmendedSubmission`);
            } else {
                name += this.props.t(`ElectronicSubmission:VatSubmission.AdditionalSubmission`);
            }
        }

        return name;
    };

    initAdditionalDocumentsWarning = () => {
        if (!this.state.modifiedDocuments) {
            return;
        }

        if (this.isAdditionalSubmissionAvailable()) {
            if (this.isSplittableQuarterlyVatVies()) {
                this.props.setAlert({
                    status: Status.Error,
                    title: this.props.t("ElectronicSubmission:VatSubmission.SplittableQuarterlyWarningTitle"),
                    subTitle: this.props.t("ElectronicSubmission:VatSubmission.SplittableQuarterlyWarningDescription"),
                    isFullWidth: true,
                    useFade: false
                });

                return;
            }

            const type = this.props.t(`ElectronicSubmission:VatSubmission.${this.props.type}`);
            let subtitle: React.ReactNode;

            if (!this.isLocked) {
                subtitle = this.props.t(`ElectronicSubmission:VatSubmission.DeadlineMissedAlertWarning`, { type });
            } else {
                let message = this.props.t(`ElectronicSubmission:VatSubmission.AdditionalDocumentsWarningIntro`);

                message += this.getAdditionalSubmissionTypeName();
                message += type;

                if (!this.isAdditionalSubmissionThresholdCrossed()) {
                    message += this.props.t(`ElectronicSubmission:VatSubmission.LockUnlockPeriod`);
                }

                message += ".";

                const drilldownLink = this.getDocumentJournalDrilldown(this.props.type, false, true);

                subtitle = (
                    <>
                        {`${this.props.t("ElectronicSubmission:VatSubmission.AdditionalDocumentsWarning")} `}
                        {drilldownLink}.
                        {message}
                    </>
                );
            }

            this.props.setAlert({
                status: Status.Warning,
                title: this.props.t("Common:Warnings.Warning"),
                subTitle: subtitle,
                isFullWidth: true,
                useFade: false
            });
        }
    };

    async loadDocumentCounts() {
        this.setState({ busy: true });

        try {
            const promises = [
                await getVatSubmissionDocumentsData({
                    subArgs: this.vatSubmissionCountArgs,
                    type: this.props.type,
                    isLocked: this.isLocked,
                    fetchFn: this.oneFetch.fetch
                })
            ];

            if (this.isAdditionalSubmissionAvailable()) {
                promises.push(await getDocumentJournalDocumentsData({
                    subArgs: this.vatSubmissionCountArgs,
                    type: this.props.type,
                    isLocked: false,
                    isCorrective: true
                }));
            }

            const [documents, modifiedDocuments] = await Promise.all(promises);

            this.setState({
                busy: false,
                documents,
                modifiedDocuments: isObjectEmpty(modifiedDocuments) ? null : modifiedDocuments
            }, this.initAdditionalDocumentsWarning);
        } catch (e) {
            if (isAbortException(e)) {
                // ignore, another request is already being handled
                return;
            } else {
                this.setAlertFromODataError(e);
                this.setState({ busy: false });
            }
        }
    }

    async loadClearingRatio(): Promise<void> {
        const { year } = this.props;
        const coefficient = await getVatSubmissionClearingRatio(this.props.oData, this.context, year + 1);
        this.setState({ coefficient });
    }

    getSubmissionTypeLabel = (type: ElectronicSubmissionTypeCode) => {
        return this.props.t(`ElectronicSubmission:VatSubmission.${type}`);
    };

    getSubmissionTypeLabelWithPeriod(type: ElectronicSubmissionTypeCode) {
        return `${this.getSubmissionTypeLabel(type)} (${this.subtitle})`;
    }

    // sets default state for vat submission field
    setDefaultStateFromSubmission() {
        const { VatStatementDocument, VatClearingDocument } = this.props.submission ?? {};
        const hasDocument = !!(VatClearingDocument?.Id || VatStatementDocument?.Id);
        const [type, amount] = hasDocument ? this.getStatementVatDocumentData()
            : (isSubmissionLocked(this.props.submission) ? this.getVatData() : [null, 0]);
        this.setState({ type, amount });
    }

    validateAmount(amount: number): string {
        let validation = null;
        if (isNotDefined(amount)) {
            validation = ValidationMessage.Required;
        }
        if (isNaN(amount)) {
            validation = ValidationMessage.NotANumber;
        }
        const isCashBasisCompany = isCashBasisAccountingCompany(this.context);
        if (isCashBasisCompany ? amount <= 0 : amount < 0) {
            validation = isCashBasisCompany ? ValidationMessage.PositiveNumber : ValidationMessage.PositiveNumberOrZero;
        }
        return validation ? this.props.t(`Common:${validation}`) : null;
    }

    setBreadcrumbs = () => {
        if (this.props.tReady) {
            this.context.setViewBreadcrumbs({
                items: [{
                    key: "detail",
                    title: this.getSubmissionTypeLabelWithPeriod(this.props.type)
                }],
                lockable: false
            });
        }
    };

    setAlert(key: string, status = Status.Success, detailData?: TRecordAny) {
        let alertProps: IAlertProps = null;
        if (key) {
            alertProps = {
                status,
                title: this.props.t(`Common:${status === Status.Success ? "Validation.SuccessTitle" : Status.Error ? "Errors.ErrorHappened" : "Warnings.Warning"}`),
                subTitle: this.props.t(key),
                isFullWidth: true,
                detailData
            };
            if (status === Status.Success) {
                alertProps.position = AlertPosition.CenteredBottom;
                alertProps.isFullWidth = false;
            }
        }
        this.props.setAlert(alertProps);
    }

    setAlertFromODataError(e: ODataError) {
        this.props.setAlert({
            ...getAlertFromError(e),
            action: null
        });
    }

    handleRemoveDocuments = async () => {
        const { submission, onSubmissionChange } = this.props;

        this.setState({ busy: true });

        try {
            const res = await removeVatSubmissionDocuments(submission);

            if (res.ok) {
                await onSubmissionChange?.({
                    ...submission,
                    VatStatementDocument: null,
                    VatClearingDocument: null
                });
            } else {
                const error = parseError(await res.json());

                this.props.setAlert({
                    ...getAlertFromError(error),
                    title: this.props.t("ElectronicSubmission:Tax.ErrorRemoveDocument")
                });

            }
        } catch (e) {
            this.setAlertFromODataError(e);
        }

        this.setState({ busy: false });
    };

    handleGenerateDocument = async () => {
        const { submission, onSubmissionChange } = this.props;
        const { amount, type } = this.state;

        const error = this.validateAmount(amount);

        if (error) {
            this.setState({ amountError: error });
            return;
        }

        // find last submission with last created VatStatementDocument
        // used to prefill the account number in the dialog
        const sortedSubmissionRows = [...this.props.allSubmissionsRows]
            .filter(sub => sub.VatStatementDocument?.Id)
            .sort((a, b) => b.VatStatementDocument.DateCreated.getTime() - a.VatStatementDocument.DateCreated.getTime());

        const isCashBasis = isCashBasisAccountingCompany(this.context);
        const result = await this.props.promisedComponent.openComponent<IOnVatLockOverrideConfirmDialogFinish>((onFinish) => {
            return (
                <VatLockOverrideConfirmDialog onFinish={onFinish}
                                              title={this.props.t(`ElectronicSubmission:VatLockOverrideDialog.${isCashBasis ? "TitleCashBasis" : "Title"}`)}
                                              dateFieldLabel={this.props.t(`ElectronicSubmission:VatLockOverrideDialog.${isCashBasis ? "DateCbaEntry" : "DateAccountingTransaction"}`)}
                                              showAccountField={!isCashBasis}
                                              previousSubmission={sortedSubmissionRows[0]}
                />
            );
        });

        if (!result) {
            return;
        }

        this.setState({ busy: true });

        try {
            const liability = (type === VatPaymentType.VatReceivable ? -1 : 1) * amount;
            const documents = await createVatSubmissionDocuments(submission, liability, result.date, result.accountNumber);
            let VatClearingDocument: IDocumentEntity = null;
            let VatStatementDocument: IDocumentEntity = null;

            if (documents?.length) {
                VatClearingDocument = documents.find(doc => doc.DocumentTypeCode === DocumentTypeCode.InternalDocument);
                VatStatementDocument = documents.find(doc => doc.Id !== VatClearingDocument?.Id);
            } else {
                // no document is allowed state, comment out error
                // this.setAlert("ElectronicSubmission:Tax.ErrorGenerateDocument", Status.Error);
            }

            await onSubmissionChange?.({
                ...submission,
                VatStatementDocument,
                VatClearingDocument
            });
            this.setAlert(null);
            this.setDefaultStateFromSubmission();
        } catch (e) {
            this.setAlertFromODataError(e);
        }

        this.setState({ busy: false });
    };

    updateSubmissionLock = async (shouldLock: boolean,
                                  args: {
                                      vatSubmissionSubmissionType: ElectronicSubmissionSubmissionTypeCode,
                                      dateCorrectionDiscovered?: Date,
                                      correctionReason?: string
                                      referenceNumber?: string;
                                  }): Promise<void> => {
        const { vatSubmissionSubmissionType, dateCorrectionDiscovered, correctionReason, referenceNumber } = args;
        const { submission, year, monthIndex } = this.props;

        if (this.isLocked && !submission) {
            // fake lock, set correct error
            this.props.setAlert({
                status: Status.Error,
                title: this.props.t("Common:General.FormValidationErrorTitle"),
                subTitle: this.props.t("Error:ElectronicSubmissionCannotBeUnlockedWhenFollowedByLockedSubmission")
            });
            return;
        }

        this.setState({ busy: true });

        try {
            const updatedSubmission = await (!shouldLock
                ? unlockSubmission(this.props.oData, submission)
                : createLockedVatStatementSubmission({
                    context: this.context,
                    oData: this.props.oData,
                    date: getUtcDateBy(year, monthIndex),
                    vatSubmissionType: this.props.type,
                    vatSubmissionSubmissionType,
                    freq: this.getVatFrequency(),
                    dateCorrectionDiscovered,
                    correctionReason,
                    referenceNumber
                }));

            if (updatedSubmission) {
                const willCloseDetail = await this.props.onSubmissionChange?.(updatedSubmission, !updatedSubmission?.Id);

                if (willCloseDetail) {
                    // no further action, component will be unmounted
                    return;
                }
                if (this.showClearingRatio) {
                    this.loadClearingRatio();
                }
            }

            this.setAlert(null);
            this.initAdditionalDocumentsWarning();
        } catch (e) {
            this.setAlertFromODataError(e);
        }

        this.setState({ busy: false });
    };

    handleLockSubmission = async (isLocked: boolean): Promise<void> => {
        if (this.isLocked) {
            if (this.isSplittableQuarterlyVatVies()) {
                // request user confirmation, non reversible action
                const isConfirmed = await this.props.confirmationDialog.open({
                    content: this.props.t("ElectronicSubmission:VatSubmission.SplitPeriodWarning")
                });

                if (!isConfirmed) {
                    return;
                }
            } else if (this.isAdditionalSubmissionAvailable() && this.isAdditionalSubmissionThresholdCrossed()) {
                // request user confirmation, non reversible action
                const isConfirmed = await this.props.confirmationDialog.open({
                    content: this.props.t("ElectronicSubmission:VatSubmission.UnlockSubmissionWhenAdditionalAvailableWarning")
                });

                if (!isConfirmed) {
                    return;
                }
            }
        }

        await this.updateSubmissionLock(isLocked, {
            vatSubmissionSubmissionType: ElectronicSubmissionSubmissionTypeCode.Regular
        });
    };

    handleFileDownload = async (submission: IElectronicSubmissionEntity) => {
        const type = submission.ElectronicSubmissionTypeCode as ElectronicSubmissionTypeCode;
        try {
            await downloadSubmissionXmlFile({
                context: this.context,
                type: type,
                submission: submission
            });
        } catch (e) {
            this.setAlert("ElectronicSubmission:VatSubmission.DownloadError", Status.Error, { errorMessage: e?.message });
        }
    };

    handleTaxPortalRedirect = async (type: ElectronicSubmissionTypeCode) => {
        const url = getSubmissionEpoFileUrl({
            context: this.context,
            type,
            submission: this.props.submission
        });
        const res = await customFetch(url);
        const value = await res.text();

        if (res.ok) {
            const redirectUrl = value;
            // open in new tab
            window.open(redirectUrl, "_blank");
        } else {
            this.setAlert("ElectronicSubmission:VatSubmission.DownloadError", Status.Error);
        }
    };

    getDocumentsCount(type: ElectronicSubmissionTypeCode, isLocked: boolean, isCorrective?: boolean): number {
        return (isCorrective ? this.state.modifiedDocuments : this.state.documents)?.[type] ?? 0;
    }

    // todo try to refactor with getDocumentJournalDocumentsData so that the redundant values are only taken from one place,
    // now lots of values are created again in both places
    getDocumentJournalDrilldown = (type: ElectronicSubmissionTypeCode, isLocked: boolean, isCorrective?: boolean): React.ReactNode => {
        const { modifiedDocuments } = this.state;

        if (!modifiedDocuments) {
            return null;
        }

        const args = this.vatSubmissionCountArgs;
        const { path, value, columnName } = getDocumentJournalReportArgs({
            type, isLocked, isCorrective, isDrilldown: true
        });

        const count = this.getDocumentsCount(type, isLocked, isCorrective);
        const dateRange: IValueInterval = {
            from: formatDateToDateString(DATE_MIN),
            to: formatDateToDateString(DATE_MAX)
        };
        // todo bylo by hezky kdyz bych mohl vzit tohle a neco by mi z toho sestavilo automaticky strukturu
        // kterou muzu volat fetchReportTableData, tak jako interne funguje reportview
        // ale to asi nejde snadno, protoze bych musel mit definici toho reportu, patrne rozjet report storage a tak
        const filters = {
            [CommonReportProps.dateRange]: CUSTOM_DATE_RANGE_ID,
            [CommonReportProps.dateRangeCustomValue]: dateRange,
            [DocumentJournalProps.dateClearingStatusTo]: formatDateToDateString(getUtcDate()),
            [BindingContext.localContext("Document_DateDocumentVatStatement")]: [createFilterRow({
                id: "Document_DateDocumentVatStatement",
                type: ConditionType.Included,
                condition: Condition.Between,
                value: {
                    from: args.submission?.DatePeriodStart ?? args?.from,
                    to: args.submission?.DatePeriodEnd ?? args?.to

                }
            }, ValueType.Date)],
            [BindingContext.localContext(columnName)]: [createFilterRow({
                id: columnName,
                type: ConditionType.Included,
                condition: Condition.Equals,
                value: value.map(val => getEnumDisplayValue(EntityTypeName.VatStatementStatus, val))
            })]
        };

        return !count ? DASH_CHARACTER :
            getDrillDownLink(`${count} ${this.props.t(`Common:General.${isCorrective ? "DocumentGenitiv" : "Document"}`, { count })}`, {
                route: path,
                context: this.context,
                filters
            });
    };

    getVatReportDrilldown(type: ElectronicSubmissionTypeCode, isLocked: boolean, isCorrective?: boolean): React.ReactNode {
        const { documents, modifiedDocuments } = this.state;

        if (!documents) {
            return null;
        }

        const args = this.vatSubmissionCountArgs;
        const wholeDateRangeFilter = {
            DateStart: args.submission?.DatePeriodStart ?? args?.from,
            DateEnd: args.submission?.DatePeriodEnd ?? args?.to
        };
        const { path, value } = getVatSubmissionReportArgs({
            type, isLocked, isCorrective, isDrilldown: true
        });

        const count = this.getDocumentsCount(type, isLocked, isCorrective);
        const filters = {
            [VatReportProps.vatStatementPeriod]: wholeDateRangeFilter,
            [VatReportProps.vatStatementStatus]: value
        };

        return !count ? DASH_CHARACTER :
            getDrillDownLink(`${count} ${this.props.t(`Common:General.${isCorrective ? "DocumentGenitiv" : "Document"}`, { count })}`, {
                route: path,
                context: this.context,
                filters
            });
    }

    getVatData(): [VatPaymentType, number] {
        const { VatLiability } = this.props.submission;
        const { abs } = Math;
        const isLiability = VatLiability > 0;
        const type = isLiability ? VatPaymentType.VatLiability : VatPaymentType.VatReceivable;
        return [type, abs(VatLiability ?? 0)];
    }

    getStatementVatDocumentData(): [VatPaymentType, number, CurrencyCode] {
        const { VatStatementDocument } = this.props.submission;
        const { Amount, CurrencyCode: currency } = VatStatementDocument ?? {};
        const isLiability = VatStatementDocument.DocumentTypeCode === DocumentTypeCode.OtherLiability;
        const type = isLiability ? VatPaymentType.VatLiability : VatPaymentType.VatReceivable;
        return [type, (Amount ?? 0), (currency ?? CurrencyCode.CzechKoruna) as CurrencyCode];
    }

    reloadPreviousSubmissionsTable = async (): Promise<void> => {
        const table = SmartTableManager.getTable(PREVIOUS_SUBMISSIONS_TABLE_ID);

        if (table) {
            await table.reloadTable();
        }
    };

    renderLinks(type: ElectronicSubmissionTypeCode): React.ReactElement {
        return (
            <ClickableWrapper data-testid={TestIds.ElSubDetailLinks}>
                <ClickableStyled onClick={() => this.handleTaxPortalRedirect(type)} isLink>
                    {this.props.t("ElectronicSubmission:VatSubmission.MyTaxesLink")}
                    <ExpandIcon width={IconSize.XS} height={IconSize.XS}/>
                </ClickableStyled>
                <ClickableStyled onClick={() => this.handleFileDownload(this.props.submission)} isLink>
                    {this.props.t("ElectronicSubmission:VatSubmission.DownloadXml")}
                    <DownloadIcon width={IconSize.XS} height={IconSize.XS}/>
                </ClickableStyled>
            </ClickableWrapper>
        );
    }

    getLiabilityDocumentTableDef = memoize((): TFormTable => {
        return getLiabilityDocumentTableDef(this.context as IAppContext);
    });

    getElectronicSubmissionsTableDef = memoize((submission: IElectronicSubmissionEntity): TFormTable => {
        let filter = `Id ne ${submission.Id}`;

        filter += ` AND ${ElectronicSubmissionEntity.DatePeriodStart} eq ${formatDateToDateString(submission.DatePeriodStart)}`;
        filter += ` AND ${ElectronicSubmissionEntity.DatePeriodEnd} eq ${formatDateToDateString(submission.DatePeriodEnd)}`;
        filter += ` AND ${ElectronicSubmissionEntity.ElectronicSubmissionTypeCode} eq '${submission.ElectronicSubmissionTypeCode}'`;

        return {
            id: PREVIOUS_SUBMISSIONS_TABLE_ID,
            entitySet: EntitySetName.ElectronicSubmissions,
            initialSortBy: [
                { id: ElectronicSubmissionEntity.DateSubmission, sort: Sort.Desc }
            ],
            columns: [
                {
                    id: ElectronicSubmissionEntity.DateSubmission,
                    additionalProperties: [
                        { id: ElectronicSubmissionEntity.FileMetadata }
                    ]
                },
                {
                    id: ElectronicSubmissionEntity.ElectronicSubmissionSubmissionType,
                    fieldSettings: {
                        displayName: "Name"
                    },
                    formatter: (val: TValue, args?: IFormatOptions) => {
                        const dateRange: IValueInterval = {
                            from: formatDateToDateString(DATE_MIN),
                            to: formatDateToDateString(DATE_MAX)
                        };

                        const filters = {
                            [CommonReportProps.dateRange]: CUSTOM_DATE_RANGE_ID,
                            [CommonReportProps.dateRangeCustomValue]: dateRange,
                            [DocumentJournalProps.electronicSubmissionId]: args.entity.Id
                        };


                        return getTableDrillDownLink(val.toString(), {
                            route: ROUTE_DOCUMENT_JOURNAL,
                            context: this.context,
                            filters
                        });
                    }
                },
                {
                    id: ElectronicSubmissionEntity.ElectronicSubmissionTypeCode,
                    label: this.props.t("ElectronicSubmission:VatSubmission.XmlColumn"),
                    formatter: (val: TValue, args?: IFormatOptions) => {
                        const submission = args.entity as IElectronicSubmissionEntity;
                        return {
                            tooltip: this.props.t("ElectronicSubmission:VatSubmission.DownloadXml"),
                            value: (
                                <ClickableStyled onClick={() => this.handleFileDownload(submission)} isLink>
                                    {this.props.t("ElectronicSubmission:VatSubmission.DownloadXml")}
                                    <DownloadIcon width={IconSize.XS} height={IconSize.XS}/>
                                </ClickableStyled>
                            )
                        };
                    }
                },
                {
                    id: ElectronicSubmissionEntity.CorrectionReason
                }
            ],
            filter: {
                query: filter
            }
        };
    });

    getDocumentsTableColumns = memoize((columns: IFieldDef[]) => {
        return columns.filter(col => isVisible({
            info: col,
            context: this.context as IAppContext
        }));
    });

    renderTable = memoize((Ids: number[]) => {
        if (!Ids.length && !this.isAmendedSubmission()) {
            return null;
        }

        const tabData: ITabData[] = [];

        if (Ids.length) {
            tabData.push({
                id: "Documents",
                title: this.props.t("ElectronicSubmission:Tax.Documents"),
                content: () => {
                    const tableDef = this.getLiabilityDocumentTableDef();
                    const bindingContext = createBindingContext(EntitySetName.Documents, this.props.oData.metadata);
                    const filterQuery = {
                        query: `Id in (${transformToODataString(Ids, ValueType.Number)})`
                    };

                    return (
                        <SmartTable bindingContext={bindingContext}
                                    initialSortBy={tableDef.initialSortBy}
                            // call memoized function to prevent infinite re-rendering
                                    columns={this.getDocumentsTableColumns(tableDef.columns)}
                                    filter={filterQuery}
                                    tableId={tableDef.id}
                                    disableVirtualization
                                    preventAutoReset={tableDef.preventAutoReset}
                                    hideDrilldown
                                    drilldown={null}/>
                    );
                }
            });
        }

        if (this.isAmendedSubmission()) {
            tabData.push({
                id: "PreviousSubmissions",
                title: this.props.t("ElectronicSubmission:VatSubmission.PreviousSubmissions"),
                content: () => {
                    const tableDef = this.getElectronicSubmissionsTableDef(this.props.submission);
                    const bindingContext = createBindingContext(EntitySetName.ElectronicSubmissions, this.props.oData.metadata);

                    return (
                        <SmartTable bindingContext={bindingContext}
                                    initialSortBy={tableDef.initialSortBy}
                                    columns={tableDef.columns}
                                    filter={tableDef.filter as IFilterQuery}
                                    tableId={tableDef.id}
                                    disableVirtualization
                                    preventAutoReset={tableDef.preventAutoReset}
                                    hideDrilldown
                                    drilldown={null}/>
                    );
                }
            });
        }


        return (
            <TableWrapperStyled>
                <Tabs data={tabData}/>

            </TableWrapperStyled>
        );
    }, (ids: number[]) => ids.join("-") + this.isAmendedSubmission());

    handleAmountBlur = (args: IInputOnBlurEvent) => {
        this.setState({ amountError: this.validateAmount(this.state.amount) });
    };

    handleAmountChange = (args: IInputOnChangeEvent<number>) => {
        this.setState({
            amount: args.value,
            amountError: null
        });
    };

    handleTypeChange = (args: ISelectionChangeArgs) => {
        if (args.triggerAdditionalTasks) {
            this.setState({ type: args.value as VatPaymentType });
        }
    };

    handleChangeRatio = async () => {
        const coefficient = await this.props.promisedComponent.openComponent<ICompanyVatReducedCoefficientEntity>((onFinish) => {
            return (
                <ClearingRatioDialog
                    initialCoefficient={this.state.coefficient}
                    context={this.context}
                    oData={this.props.oData}
                    onConfirm={onFinish}
                    onCancel={() => onFinish(null)}/>
            );
        });

        if (coefficient) {
            this.setState({ coefficient });

            if (!coefficient?.Id) {
                this.loadClearingRatio();
            }
        }
    };

    vatPaymentTypeItems = memoize(() => {
        return [VatPaymentType.VatLiability, VatPaymentType.VatReceivable].map(id => ({
            id, label: this.props.t(`ElectronicSubmission:VatSubmission.${id}`)
        }));
    });

    renderFields(isDisabled: boolean) {
        const { amountError, amount, type } = this.state;

        const [calcType, calcAmount] = this.getVatData();
        const currency = getCompanyCurrencyEntity(this.context);
        const calcOptions = {
            amount: formatCurrency(calcAmount, currency.Code)
        };

        return (
            <CustomFormWrapper data-testid={TestIds.FormView}>
                <StyledParagraph
                    data-testid={TestIds.CalculatedTax}>{this.props.t(`ElectronicSubmission:Tax.CalculatedTax_${calcType}`, calcOptions)}</StyledParagraph>
                <FieldsWrapper style={{ width: "100%", maxWidth: "100%" }}>
                    <Field width={BasicInputSizes.S}
                           name="custom-type"
                           label={this.props.t("ElectronicSubmission:Tax.TaxType")}>
                        <BasicSelect
                            inputIcon={<CaretIcon width={IconSize.M}/>}
                            isDisabled={isDisabled}
                            openOnClick
                            value={type}
                            onChange={this.handleTypeChange}
                            items={this.vatPaymentTypeItems()}
                        />
                    </Field>
                    <Field
                        width={BasicInputSizes.M}
                        name="custom-amount"
                        label={this.props.t("ElectronicSubmission:Tax.Tax")}
                        isRequired>
                        <NumericInput width={BasicInputSizes.M}
                                      isDisabled={isDisabled}
                                      error={amountError ? { message: amountError } : null}
                                      value={amount}
                                      textAlign={TextAlign.Right}
                                      unit={CurrencyType.getCurrencyUnit(getCompanyCurrency(this.context))}
                                      onBlur={this.handleAmountBlur}
                                      parserArgs={{ maximumFractionDigits: currency.MinorUnit }}
                                      onChange={this.handleAmountChange}/>
                    </Field>
                </FieldsWrapper>
            </CustomFormWrapper>
        );
    }

    renderClearingRatio() {
        const { coefficient } = this.state;

        return (
            <>
                <StyledHeading>{this.props.t("ElectronicSubmission:VatSubmission.ClearingRatio")}</StyledHeading>
                <StyledParagraph
                    data-testid={TestIds.CalculatedCoefficient}>{this.props.t("ElectronicSubmission:VatSubmission.ClearingInfo", { value: coefficient?.VatReducedCoefficient ?? EMPTY_VALUE })}</StyledParagraph>
                <ButtonGroup align={TextAlign.Right}>
                    <Button isTransparent onClick={this.handleChangeRatio} icon={<EditSimpleIcon/>}>
                        {this.props.t("ElectronicSubmission:VatSubmission.Change")}
                    </Button>
                </ButtonGroup>
            </>
        );
    }

    renderVatStatementSubmissionDetails(): React.ReactElement {
        const isCashBasisCompany = isCashBasisAccountingCompany(this.context);

        const isPeriodEmpty = !this.state.documents?.[ElectronicSubmissionTypeCode.VatStatement];
        const hasDocument = !!this.props.submission?.AreVatDocumentsCreated;
        const canGenerateDocument = !isPeriodEmpty && !hasDocument && this.validateAmount(this.state.amount) === null;
        const documentIds = this.props.allRelatedSubmissions
            .flatMap(submission => [submission.VatStatementDocument?.Id, submission.VatClearingDocument?.Id])
            .filter(id => !!id);

        return (
            <>
                {this.renderLinks(ElectronicSubmissionTypeCode.VatStatement)}
                <StyledHeading>{this.props.t("ElectronicSubmission:Tax.Title")}</StyledHeading>
                {this.renderFields(hasDocument || isPeriodEmpty)}
                <ButtonGroup align={TextAlign.Right} style={{ marginBottom: "60px" }}>
                    <Button onClick={this.handleRemoveDocuments}
                            isTransparent
                            isDisabled={!hasDocument}
                            hotspotId={"ElectronicSubmissionRemoveDocuments"}>
                        {this.props.t(`ElectronicSubmission:Tax.${isCashBasisCompany ? "RemoveDocument" : "CancelPosting"}`)}
                    </Button>
                    <Button onClick={this.handleGenerateDocument}
                            isDisabled={!canGenerateDocument}
                            hotspotId={"ElectronicSubmissionGenerateDocuments"}>
                        {this.props.t(`ElectronicSubmission:Tax.${isCashBasisCompany ? "CreateDocument" : "Post"}`)}
                    </Button>
                </ButtonGroup>
                {this.renderTable(documentIds)}
            </>
        );
    }

    renderOtherSubmissionDetails(type: ElectronicSubmissionTypeCode): React.ReactElement {
        return (
            <>
                {this.renderLinks(type)}
                {this.renderTable([])}
            </>
        );
    }

    renderLockedSubmissionDetails() {
        if (!this.props.submission) {
            return null;
        }

        return (<>
            {this.useVatReducedDeduction && this.renderClearingRatio()}
            <StyledHeading>{this.props.t("ElectronicSubmission:VatSubmission.TaxPortalDocuments")}</StyledHeading>
            <StyledParagraph>{this.props.t("ElectronicSubmission:VatSubmission.TaxPortalDocumentsDescription")}</StyledParagraph>
            {this.props.type === ElectronicSubmissionTypeCode.VatStatement
                ? this.renderVatStatementSubmissionDetails()
                : this.renderOtherSubmissionDetails(this.props.type)
            }
        </>);
    }

    getAdditionalSubmissionDialogTitle = (): string => {
        return this.props.t("ElectronicSubmission:VatSubmission.CreateAdditionalSubmission", { type: this.getAdditionalSubmissionTypeName() });
    };

    handleAdditionalSubmissionClicked = async (): Promise<void> => {
        if (this.props.type === ElectronicSubmissionTypeCode.VatVIESStatement) {
            await this.updateSubmissionLock(true, {
                vatSubmissionSubmissionType: ElectronicSubmissionSubmissionTypeCode.Amended
            });
            this.reloadPreviousSubmissionsTable();

            return;
        }

        const result = await this.props.promisedComponent.openComponent<IAdditionalSubmissionDialogResult>((onFinish) => {
            return (
                <AdditionalSubmissionDialog
                    title={this.getAdditionalSubmissionDialogTitle()}
                    showReason={this.props.type === ElectronicSubmissionTypeCode.VatStatement}
                    showReferenceNumber={this.props.type === ElectronicSubmissionTypeCode.VatControlStatement}
                    onConfirm={(result: IAdditionalSubmissionDialogResult) => onFinish(result)}
                    onCancel={() => onFinish(null)}/>
            );
        });

        if (result) {
            await this.updateSubmissionLock(true, {
                vatSubmissionSubmissionType: ElectronicSubmissionSubmissionTypeCode.Amended,
                dateCorrectionDiscovered: result.dateCorrectionDiscovered,
                correctionReason: result.correctionReason,
                referenceNumber: result.referenceNumber
            });
            this.reloadPreviousSubmissionsTable();
        }
    };

    handleAdditionalSubmissionCancelClicked = async (): Promise<void> => {
        await this.updateSubmissionLock(false, {
            vatSubmissionSubmissionType: ElectronicSubmissionSubmissionTypeCode.Amended
        });
        this.reloadPreviousSubmissionsTable();
    };

    renderAdditionalSubmissionButton = () => {
        if (!this.props.submission || !this.isAdditionalSubmissionAvailable() || !this.isAdditionalSubmissionThresholdCrossed() || this.isSplittableQuarterlyVatVies()) {
            return null;
        }

        return (
            <ButtonGroup align={TextAlign.Right}
                         style={{ marginBottom: "30px" }}>
                <Button icon={<PlusIcon/>}
                        isTransparent
                        onClick={this.handleAdditionalSubmissionClicked}>
                    {this.getAdditionalSubmissionDialogTitle()}
                </Button>
            </ButtonGroup>
        );
    };

    renderLastSubmission = (): React.ReactElement => {
        if (!this.isAmendedSubmission()) {
            return null;
        }

        const listData: IReadOnlyListItem[] = [
            {
                label: this.props.t(`ElectronicSubmission:VatSubmission.LastSubmissionType`),
                value: capitalize(this.getAdditionalSubmissionTypeName().trim())
            }
        ];

        if (this.props.type !== ElectronicSubmissionTypeCode.VatVIESStatement) {
            listData.push({
                label: this.props.t(`ElectronicSubmission:AdditionalSubmissionDialog.Date`),
                value: DateType.format(this.props.submission.DateCorrectionDiscovered)
            });
        }

        if (this.props.type === ElectronicSubmissionTypeCode.VatStatement) {
            listData.push({
                label: this.props.t(`ElectronicSubmission:AdditionalSubmissionDialog.Reason`),
                value: this.props.submission.CorrectionReason
            });
        }

        if (this.props.type === ElectronicSubmissionTypeCode.VatControlStatement) {
            listData.push({
                label: this.props.t(`ElectronicSubmission:AdditionalSubmissionDialog.ReferenceNumberOfNotice`),
                value: this.props.submission.ReferenceNumberOfNotice
            });
        }

        return (
            <>
                <StyledHeading>
                    {this.props.t(`ElectronicSubmission:VatSubmission.LastSubmission`)}
                </StyledHeading>
                <ReadOnlyList data={listData} withoutPadding/>
                <ButtonGroup align={TextAlign.Right}
                             style={{ marginBottom: "30px" }}>
                    <Button icon={<CloseIcon/>}
                            isTransparent
                            onClick={this.handleAdditionalSubmissionCancelClicked}>
                        {this.props.t("ElectronicSubmission:VatSubmission.CancelLastSubmission")}
                    </Button>
                </ButtonGroup>
            </>
        );
    };

    renderSubmissionDetail() {
        const isLocked = this.isLocked;
        const isRegular = !this.props.submission || this.props.submission.ElectronicSubmissionSubmissionTypeCode === ElectronicSubmissionSubmissionTypeCode.Regular;
        const style: React.CSSProperties = {};

        const iconSet = {
            on: LockClosedIcon,
            off: LockOpenedIcon
        };

        if (!isRegular) {
            style.marginBottom = "30px";
        }

        return (
            <>
                {this.renderLastSubmission()}
                <StyledHeading>
                    {this.props.t(`ElectronicSubmission:VatSubmission.LabelVatPeriod`)}
                    <TooltipIconInfo style={{ marginLeft: "6px", top: "1px" }}>
                        {this.props.t("ElectronicSubmission:VatSubmission.Description")}
                    </TooltipIconInfo>
                </StyledHeading>

                <DocumentCountWrapper data-testid={TestIds.ElSubDetailIncludedDocuments} style={style}>
                    {this.isAdditionalSubmissionAvailable() && this.getDocumentsCount(this.props.type, this.isLocked) ?
                        <StatusWarnIcon width={IconSize.S}/> : null}
                    {this.getVatReportDrilldown(this.props.type, this.isLocked)}
                </DocumentCountWrapper>

                {isRegular &&
                    <FieldsWrapper style={{ justifyContent: "flex-end" }}>
                        {!this.isSplittableQuarterlyVatVies() &&
                            <Switch checked={isLocked}
                                    label={this.props.t("ElectronicSubmission:VatSubmission.LockSwitchLabel")}
                                    hotspotId={"ElectronicSubmissionVatSubmission"}
                                    onChange={this.handleLockSubmission}
                                    type={SwitchType.Icons}
                                    iconSet={iconSet}
                                    style={{ margin: "27px 0" }}/>
                        }
                        {this.isSplittableQuarterlyVatVies() &&
                            <Button onClick={() => {
                                this.handleLockSubmission(false);
                            }}
                                    icon={<MenuIcon/>}
                                    isTransparent
                                    style={{ margin: "27px 0" }}>
                                {this.props.t("ElectronicSubmission:VatSubmission.SplitPeriod")}
                            </Button>
                        }
                    </FieldsWrapper>
                }

                {isLocked && this.renderLockedSubmissionDetails()}
            </>
        );
    }

    render() {
        if (!this.props.tReady) {
            return (<BusyIndicator/>);
        }

        return (<>
            <BreadCrumbProvider/>
            <View hotspotContextId={HotspotViewIds.VatSubmissionsDetail}>
                <StyledVatSubmissionDetail>
                    <Header title={this.getSubmissionTypeLabel(this.props.type)}
                            afterIconsContent={(
                                <Summary>
                                    <SummaryItem id={"subtitle"} label={""}
                                                 value={this.subtitle}/>
                                </Summary>
                            )}/>
                    {[Status.Error, Status.Warning].includes(this.props.alertProps?.status) &&
                        (
                            <StyledErrorWrapper>
                                {this.props.alert}
                            </StyledErrorWrapper>
                        )
                    }
                    {this.renderAdditionalSubmissionButton()}
                    {this.renderSubmissionDetail()}
                </StyledVatSubmissionDetail>
            </View>
            {this.props.alertProps?.status === Status.Success && this.props.alert}
            {this.state.busy && <BusyIndicator/>}
        </>);
    }
}

const alertProps: IWithAlertSettings = {
    autoHide: true,
    position: AlertPosition.Default,
    isFullWidth: true
};

export default withAlert(alertProps)(withConfirmationDialog(withPromisedComponent(withTranslation(ElectronicSubmissionNamespaces)(withOData(VatSubmissionDetail)))));