import { IconSelectClean } from "@components/inputs/select/IconSelect";
import { IGetValueArgs } from "@components/smart/FieldInfo";
import BindingContext from "@odata/BindingContext";
import { EntityTypeName } from "@odata/GeneratedEntityTypes";
import { PrChildSupportDeductionTypeCode, PrDeductionTypeCode } from "@odata/GeneratedEnums";
import { getEnumSelectItems } from "@odata/GeneratedEnums.utils";
import i18next from "i18next";
import React from "react";

import { ToolbarItemType } from "../../../enums";
import { TableStorage } from "../../../model/TableStorage";
import { ICustomButtonDefArgs, TableActionOrder, TTableToolbarItem } from "../../../views/table/TableToolbar.utils";

export enum DeductionType {
    GroupDeduction = "GroupDeduction",
    IndividualDeduction = "IndividualDeduction"
}

export const DEDUCTION_ADD_BUTTON = "deductionAddButton";
export const ADD_EMPLOYEE_ITEM = "addEmployeeItem";

export const DEFAULT_EMPLOYER_PERCENTAGE = 55;
export const DEFAULT_EMPLOYEE_PERCENTAGE = 100 - DEFAULT_EMPLOYER_PERCENTAGE;

export const isAmongTypes = (types: PrDeductionTypeCode[]) => (args: IGetValueArgs): boolean => {
    return types.includes(args.storage.data.entity.TypeCode);
};

export const isDebtTypeOfChildSupport = (args: IGetValueArgs): boolean => {
    return args.storage.data.entity.TypeCode !== PrDeductionTypeCode.ChildSupport || args.storage.data.entity.ChildSupportType?.Code !== PrChildSupportDeductionTypeCode.Regular;
};

export const DEBT_BALANCE_PATH = BindingContext.localContext("PrIndividualDeductionBalance");

export function getToolbarAddButtonConfig(storage: TableStorage, ref: React.RefObject<IconSelectClean>, type: DeductionType, args?: ICustomButtonDefArgs): TTableToolbarItem {
    let items = getEnumSelectItems(EntityTypeName.PrDeductionType);
    if (type === DeductionType.GroupDeduction) {
        items = items.filter(item => [PrDeductionTypeCode.Custom, PrDeductionTypeCode.MealVoucher].includes(item.id as PrDeductionTypeCode));
    }

    return {
        id: DEDUCTION_ADD_BUTTON,
        itemType: ToolbarItemType.IconSelect,
        iconName: "Add",
        label: i18next.t("IndividualDeduction:NewDeduction"),
        order: TableActionOrder.Add,
        itemProps: {
            isTransparent: true,
            selectProps: {
                isActive: storage.data.addingRow || args?.isActive,
                isDisabled: !!storage.tableAction || args?.isDisabled,
                ref
            },
            items
        }
    };
}