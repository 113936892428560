import { DateRangeSpecialValue } from "@components/inputs/date/popup/Calendar.utils";
import { SwitchType } from "@components/inputs/switch/Switch";
import { IGetValueArgs } from "@components/smart/FieldInfo";
import { IFormGroupDef } from "@components/smart/smartFormGroup/SmartFormGroup";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import { TSmartODataTableStorage } from "@components/smart/smartTable/SmartODataTableBase";
import { TCellValue } from "@components/table";
import BindingContext, { createPath } from "@odata/BindingContext";
import {
    EntitySetName,
    EntityTypeName,
    PrTaxpayerDeclarationChildEntity,
    PrTaxpayerDeclarationDiscountEntity,
    PrTaxpayerDeclarationEntity
} from "@odata/GeneratedEntityTypes";
import { PrTaxDiscountTypeCode } from "@odata/GeneratedEnums";
import { genericStatusFormatter } from "@pages/chartOfAccounts/ChartOfAccountsDef";
import {
    getPathsForDeductionType,
    getTogglePropPath,
    showChildrenPath
} from "@pages/payroll/taxpayerDeclaration/TaxpayerDeclaration.utils";
import TaxpayerDeclarationFormView from "@pages/payroll/taxpayerDeclaration/TaxpayerDeclarationFormView";
import i18next from "i18next";

import { FieldType, Sort, TextAlign } from "../../../enums";
import { TValue } from "../../../global.types";
import { IFormDef } from "../../../views/formView/Form";
import { ISplitPageTableDef } from "../../../views/table/TableView.utils";
import { setDefByEntityType } from "../../getDefByEntityType";
import { IDefinition, IGetDefinition, TFieldsDefinition } from "../../PageUtils";

const switchDef = {
    type: FieldType.Switch,
    fieldSettings: {
        type: SwitchType.YesNo,
    },
    customizationData: {
        useForCustomization: false
    }
};

const getGroupForDeductionType = (type: PrTaxDiscountTypeCode): IFormGroupDef => {
    return {
        id: type,
        isVisible: true, // todo check validitity (deduction types are in customData) - check against year of declaration
        title: i18next.t(`TaxpayerDeclaration:Types.${type}`),
        togglePropPath: getTogglePropPath(type),
        showGroupDivider: false,
        isSmallBottomMargin: true,
        isLightText: true,
        customizationData: {
            isLocked: true
        },
        rows: [[{
            id: BindingContext.localContext(createPath(type, PrTaxpayerDeclarationDiscountEntity.DateValidFrom))
        }, {
            id: BindingContext.localContext(createPath(type, PrTaxpayerDeclarationDiscountEntity.DateValidTo)),
        }]]
    };
};

const getFieldDefinitions = () => {
    return Object.values(PrTaxDiscountTypeCode).reduce((defs: TFieldsDefinition, type) => {
        const { switchPath, dateValidTo, dateValidFrom } = getPathsForDeductionType(type);
        const commonDateFieldProps = {
            type: FieldType.Date,
            isVisible: (args: IGetValueArgs) => {
                return !!args.storage.getValueByPath(switchPath);
            }
        };

        defs[switchPath] = {
            ...switchDef
        };
        defs[dateValidFrom] = {
            ...commonDateFieldProps,
            label: i18next.t("TaxpayerDeclaration:Form.DateValidFrom"),
            fieldSettings: {
                showSpecialValue: DateRangeSpecialValue.WithoutStart
            }
        };
        defs[dateValidTo] = {
            ...commonDateFieldProps,
            label: i18next.t("TaxpayerDeclaration:Form.DateValidTo"),
            fieldSettings: {
                showSpecialValue: DateRangeSpecialValue.WithoutEnd
            }
        };
        return defs;
    }, {});
};

export const getDefinitions: IGetDefinition = (): IDefinition => {
    const table: ISplitPageTableDef = {
        id: `${EntityTypeName.PrTaxpayerDeclaration}Table`,
        filter: (args: IGetValueArgs) => {
            const employeeId = (args.storage as TSmartODataTableStorage).getCustomData().rootStorage.data.bindingContext.getKey();
            return `${PrTaxpayerDeclarationEntity.Employee}/Id eq ${employeeId}`;
        },
        filterBarDef: [],
        columns: [
            PrTaxpayerDeclarationEntity.Year,
            PrTaxpayerDeclarationEntity.IsChecked
        ],
        columnDefinition: {
            [PrTaxpayerDeclarationEntity.Year]: {
                formatter: val => val?.toString()
            },
            [PrTaxpayerDeclarationEntity.IsChecked]: {
                textAlign: TextAlign.Center,
                formatter: (val: TValue): TCellValue => {
                    return genericStatusFormatter(val as boolean);
                }
            }
        },
        preventStoreVariant: true,
        title: i18next.t("TaxpayerDeclaration:Title"),
        initialSortBy: [{ id: PrTaxpayerDeclarationEntity.Year, sort: Sort.Asc }]
    };

    const summary: ISummaryItem[] = [{
        id: PrTaxpayerDeclarationEntity.Year
    }];

    const form: IFormDef = {
        id: `${EntityTypeName.PrTaxpayerDeclaration}Form`,
        translationFiles: getDefinitions.translationFiles,
        formControl: TaxpayerDeclarationFormView,
        isDeletable: true,
        summary,
        title: i18next.t("TaxpayerDeclaration:FormTitle"),
        additionalProperties: [{
            id: PrTaxpayerDeclarationEntity.IsChecked
        }, {
            id: PrTaxpayerDeclarationEntity.Children
        }, {
            id: PrTaxpayerDeclarationEntity.Discounts,
            additionalProperties: [{
                id: PrTaxpayerDeclarationDiscountEntity.DiscountType
            }, {
                id: PrTaxpayerDeclarationDiscountEntity.DateValidFrom
            }, {
                id: PrTaxpayerDeclarationDiscountEntity.DateValidTo
            }]
        }],
        isReadOnly: (args: IGetValueArgs) => {
            return args.data?.IsChecked;
        },
        fieldDefinition: {
            ...getFieldDefinitions(),
            [showChildrenPath]: {
                ...switchDef
            },
            [createPath(PrTaxpayerDeclarationEntity.Children, PrTaxpayerDeclarationChildEntity.FirstName)]: {},
            [createPath(PrTaxpayerDeclarationEntity.Children, PrTaxpayerDeclarationChildEntity.LastName)]: {},
            [createPath(PrTaxpayerDeclarationEntity.Children, PrTaxpayerDeclarationChildEntity.PersonalIdentificationNumber)]: {},
        },
        groups: [
            getGroupForDeductionType(PrTaxDiscountTypeCode.TaxPayerDiscount),
            {
                id: PrTaxpayerDeclarationEntity.Children,
                title: i18next.t("TaxpayerDeclaration:Types.Children"),
                togglePropPath: showChildrenPath,
                showGroupDivider: false,
                isSmallBottomMargin: true,
                isLightText: true,
                lineItems: {
                    additionalFields: [],
                    collection: PrTaxpayerDeclarationEntity.Children,
                    order: "Id",
                    canAdd: args => args.storage.getValueByPath(showChildrenPath),
                    isItemVisible: args => args.storage.getValueByPath(showChildrenPath),
                    columns: [
                        { id: PrTaxpayerDeclarationChildEntity.FirstName },
                        { id: PrTaxpayerDeclarationChildEntity.LastName },
                        { id: PrTaxpayerDeclarationChildEntity.PersonalIdentificationNumber }
                    ]
                }
            },
            getGroupForDeductionType(PrTaxDiscountTypeCode.BasicDisabilityDiscount),
            getGroupForDeductionType(PrTaxDiscountTypeCode.ExtendedDisabilityDiscount),
            getGroupForDeductionType(PrTaxDiscountTypeCode.DiscountForZTP_PHolder)
        ]
    };

    return {
        entitySet: EntitySetName.PrTaxpayerDeclarations,
        table,
        form
    };
};

getDefinitions.translationFiles = ["TaxpayerDeclaration"];

setDefByEntityType(EntityTypeName.PrTaxpayerDeclaration, getDefinitions);