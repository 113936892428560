import { SwitchType } from "@components/inputs/switch/Switch";
import { CashBoxEntity, EntitySetName, EntityTypeName } from "@odata/GeneratedEntityTypes";
import { getEnumNameSpaceName } from "@odata/GeneratedEnums.utils";
import { isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { arrayInsert } from "@utils/general";
import i18next from "i18next";

import { CASH_BOXES_BALANCE_SHEET_ACCOUNT_PREFIX } from "../../constants";
import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { BasicInputSizes, FieldType } from "../../enums";
import { IFormDef } from "../../views/formView/Form";
import {
    getAccountRelatedAccountSettingsFieldsDef,
    getAccountSettingsFormGroup,
    getInitialBalanceFieldsDef,
    getInitialBalanceFormGroup,
    getIsDefaultSwitchDef
} from "../banks/bankAccounts/BankAccounts.utils";
import { setDefByEntityType } from "../getDefByEntityType";
import { getDefaultRangeDefinition } from "../numberRange/NumberRangeFields.utils";
import { IDefinition, IGetDefinition, TFieldsDefinition } from "../PageUtils";

export const entitySet = EntitySetName.CashBoxes;


function addAnalyticAccountDefs(context: IAppContext): TFieldsDefinition {
    if (!isCashBasisAccountingCompany(context)) {
        return getAccountRelatedAccountSettingsFieldsDef(CASH_BOXES_BALANCE_SHEET_ACCOUNT_PREFIX);
    }
    return null;
}

export const getDefinitions: IGetDefinition = (context: IAppContext): IDefinition => {
    const form: IFormDef = {
        id: `${EntityTypeName.CashBox}Form`,
        translationFiles: getDefinitions.translationFiles,
        additionalProperties: [{ id: CashBoxEntity.Currency }, { id: CashBoxEntity.IsActive }],
        fieldDefinition: {
            Note: {
                width: BasicInputSizes.XL
            },
            CashierName: {
                width: BasicInputSizes.L
            },
            HasMaterialLiability: {
                type: FieldType.Switch,
                fieldSettings: {
                    type: SwitchType.YesNo
                }
            },
            Name: {
                width: BasicInputSizes.XL,
                label: i18next.t("Cash:CashBoxes.Name")
            },
            ReceiptIssuedNumberRangeDefinition: {
                ...getDefaultRangeDefinition(EntityTypeName.CashReceiptIssued),
                label: i18next.t("Cash:CashBoxes.NumberRangeIssuedDocs")
            },
            ReceiptReceivedNumberRangeDefinition: {
                ...getDefaultRangeDefinition(EntityTypeName.CashReceiptReceived),
                label: i18next.t("Cash:CashBoxes.NumberRangeReceivedDocs")
            },
            [CashBoxEntity.IsDefault]: getIsDefaultSwitchDef(true),
            ...addAnalyticAccountDefs(context),
            ...getInitialBalanceFieldsDef()
        },
        groups: [
            {
                id: "Title",
                rows: [[
                    { id: CashBoxEntity.Name },
                    { id: CashBoxEntity.IsDefault }
                ]]
            },
            {
                id: "general",
                rows: [[
                    { id: CashBoxEntity.ReceiptReceivedNumberRangeDefinition },
                    { id: CashBoxEntity.ReceiptIssuedNumberRangeDefinition }
                ], [
                    { id: CashBoxEntity.CashierName },
                    { id: CashBoxEntity.HasMaterialLiability }
                ]]
            },
            getInitialBalanceFormGroup()
        ]
    };


    if (!isCashBasisAccountingCompany(context)) {
        form.groups = arrayInsert(form.groups, getAccountSettingsFormGroup(), 2);
    }

    return {
        entitySet,
        form
    };
};

getDefinitions.translationFiles = ["Cash", "Banks", getEnumNameSpaceName(EntityTypeName.Currency)];
setDefByEntityType(EntityTypeName.CashBox, getDefinitions);