import { FieldsWithOwnReadOnlyMaxWidth } from "@components/inputs/field/Field";
import { isNumericType } from "@evala/odata-metadata/src";
import { getBoundValue, getNewItemsMaxId, getSharedContextPrefix } from "@odata/Data.utils";
import {
    arrayMove,
    composeRefHandlers,
    getElementOuterHeight,
    getValue,
    isDefined,
    isNotDefined,
    isObjectEmpty,
    sortCompareFn
} from "@utils/general";
import { KeyboardShortcut } from "@utils/keyboardShortcutsManager/KeyboardShorcutsManager.utils";
import { startsWithAccentsInsensitive } from "@utils/string";
import { cloneDeep, debounce } from "lodash";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { VariableSizeList } from "react-window";

import { DASH_CHARACTER, INPUT_DEBOUNCE_TIME } from "../../../constants";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { WithDomManipulator, withDomManipulator } from "../../../contexts/domManipulator/withDomManipulator";
import {
    FastEntryInputSizes,
    FormMode,
    GroupedField,
    IconSize,
    LabelStatus,
    Sort,
    TextAlign,
    ToggleState
} from "../../../enums";
import { TRecordAny, TRecordString } from "../../../global.types";
import { AuditTrailLineComparison, FieldAdditionalData, ModelEvent } from "../../../model/Model";
import BindingContext, { areBindingContextsDifferent, IEntity } from "../../../odata/BindingContext";
import TestIds from "../../../testIds";
import DateType from "../../../types/Date";
import NumberType from "../../../types/Number";
import KeyboardShortcutsManager from "../../../utils/keyboardShortcutsManager/KeyboardShortcutsManager";
import memoizeOne from "../../../utils/memoizeOne";
import { FormStorage } from "../../../views/formView/FormStorage";
import ConfirmationButtons from "../../../views/table/ConfirmationButtons";
import { getConfirmationActionText } from "../../../views/table/TableView.render.utils";
import { TableWrapper } from "../../../views/table/TableView.styles";
import { VIEW_PADDING_VALUE } from "../../../views/View.styles";
import { Button, IconButton, IProps as IButtonProps } from "../../button";
import { FastEntry, FastEntryActionStatus, IFastEntryChild } from "../../fastEntry/FastEntry";
import { REORDER_ICON_MARGIN } from "../../fastEntry/FastEntry.styles";
import FastEntryList, { IFastEntryListChild, IFastEntryListChildRender } from "../../fastEntry/FastEntryList";
import { ButtonGroupStyled } from "../../formGroup";
import { CloseIcon } from "../../icon";
import { ICheckboxChange } from "../../inputs/checkbox";
import { ScrollBar } from "../../scrollBar";
import SimpleTable from "../../simpleTable";
import {
    ItemIndex,
    ORDER_BACKGROUND_RIGHT_MARGIN,
    ORDER_BACKGROUND_WIDTH,
    OrderBackground
} from "../../sortableList/SortableList.styles";
import { IRow } from "../../table";
import {
    getInfoValue,
    IFieldInfoProperties,
    isRequired,
    isTGetValueFn,
    isVisible,
    TInfoValue,
    TInfoValueProp
} from "../FieldInfo";
import SmartField from "../smartField";
import {
    ISmartFieldBlur,
    ISmartFieldChange,
    ISmartFieldHeightChange,
    ISmartFieldProps,
    ISmartFieldWidthChange
} from "../smartField/SmartField";
import { isFieldValueSame } from "../smartField/SmartFieldUtils";
import { IFormLineItemsDef } from "../smartFormGroup/SmartFormGroup";
import { getFormattedValueFromValues } from "../smartTable/SmartTable.utils";
import ErrorStepper from "./ErrorStepper";
import SearchField, { ISearchFieldProps, ISearchFieldState } from "./SearchField";
import {
    ButtonsFooter,
    CheckboxStyledMainToggle,
    ConfirmationButtonsWrapper,
    FastEntryLabelWrapper,
    SmartActionButtonStyled,
    StyledHeaderLabelAbs,
    StyledSmartFastEntryList
} from "./SmartFastEntryList.styles";
import { ActionType, addEmptyLineItem, ISmartFastEntriesActionEvent } from "./SmartFastEntryList.utils";

const VIRTUALIZATION_THRESHOLD = 20;

interface ISmartFastEntryColumnsExtension {
    [id: string]: {
        width: string;
        widths: TRecordString;
        textAlign: TextAlign;

        // at least on of the field in the column IS read only
        hasReadOnly?: boolean;
        // at least one of the field in the column is NOT read only
        hasEditable?: boolean;

        isFirstReadOnly?: boolean;
        columnIndex?: number;
    };
}

interface ISmartFastEntryLineExtension {
    [id: string]: {
        heights: TRecordString;
    };
}

export enum ActionButtonPosition {
    Before = "Before",
    After = "After"
}

type DefinitionProps =
    "columns"
    | "order"
    | "orderDirection"
    | "canReorder"
    | "useLabelWrapping"
    | "isCollapsible"
    | "containsPrimary"
    | "isItemCloneable"
    | "isItemRemovable"
    | "isItemVisible"
    | "isItemSelectable"
    | "canAdd"
    | "isAddDisabled"
    | "isItemDisabled"
    | "isTheOnlyItemRemovable"
    | "isGroupToggleable"
    | "customActionButtons"
    | "disableFieldsOnActiveAction"
    | "showLineNumbers";

export interface ICustomPreContentArgs {
    itemBc: BindingContext;
    index: number;
    item: IEntity;
    items: IEntity[];
    labelStatus?: LabelStatus;
}

interface IProps extends WithTranslation, WithDomManipulator, Pick<IFormLineItemsDef, DefinitionProps> {
    storage?: FormStorage;
    groupId?: string;
    isReadOnly?: boolean;
    isDisabled?: TInfoValue<boolean>;
    isLight?: boolean;
    /** Never virtualize items */
    disableVirtualization?: boolean;
    // adds checkboxes and confirm buttons
    customAction?: { id: string; confirmLabel: string; ignoreTheme?: boolean; };
    /** Sometimes, e.g. when customPreContent is used for FastEntries, we move the items to the right by some amount.
     * This is ok when each item has its own labels. In case we use useLabelWrapping and items are not wrapped,
     * we need to move the main labels to the right by the same amount. labelsLeftMargin lets you pass that amount.*/
    labelsLeftMargin?: number;
    onCustomActionFinish?: () => void;
    /** List of properties from given entity (set) that are supposed to be shown */
    bindingContext: BindingContext;
    onBlur?: (args: ISmartFieldBlur) => Promise<void>;
    onChange?: (args: ISmartFieldChange) => void;
    onTemporalChange?: (args: ISmartFieldChange) => void;
    onAction?: (args: ISmartFastEntriesActionEvent) => void;
    isAddButtonTransparent?: boolean;

    renderAsFastEntryForReadOnly?: boolean;

    // should display draft changes for this list
    showChanges?: boolean;

    /** Renders custom content custom next to the "Add" button. */
    customFooterContent?: React.ReactElement;
    customPreContent?: (args: ICustomPreContentArgs) => React.ReactNode;
    customAddButtonText?: string;
    addDisabledAlert?: IButtonProps["alert"];

    filterItems?: (items: IEntity[]) => IEntity[];
    style?: React.CSSProperties;
    searchInChildProps?: string[];
    deletedItemsTranslationKey?: string;
    /** Pass isConfirmable to every field */
    isConfirmable?: boolean;
}

interface IState {
    columnsExtensions: ISmartFastEntryColumnsExtension;
    lineExtensions: ISmartFastEntryLineExtension;
    selectedItems: Record<number, boolean>;
    searchValue: string;
    isWrapped?: boolean;
    contentWidth?: number;
}

function getScrollParent(node: HTMLElement): HTMLElement {
    if (node == null) {
        return null;
    }

    if (node.scrollHeight > node.clientHeight) {
        return node;
    } else {
        return getScrollParent(node.parentElement);
    }
}


const ITEM_PROPS_DEFAULTS: Partial<Record<TInfoValueProp, { readOnly: boolean, editable: boolean }>> = {
    isItemDisabled: { readOnly: false, editable: false },
    isItemReadOnly: { readOnly: true, editable: false },
    isItemVisible: { readOnly: true, editable: true },
    isItemRemovable: { readOnly: false, editable: true },
    isItemCloneable: { readOnly: false, editable: true }
};

enum CalculationStateType {
    None,
    Items,
    Labels,
    Finished
}

class SmartFastEntryList extends React.Component<IProps, IState> {
    static contextType = AppContext;
    //sadly, breaks typescript type checking
    //context: React.ContextType<typeof AppContext>;
    _fastEntryListRef = React.createRef<HTMLDivElement>();
    _fastEntryRef = React.createRef<HTMLDivElement>();
    _buttonRef = React.createRef<HTMLButtonElement>();
    _errorStepperRef = React.createRef<ErrorStepper>();
    _virtualizedListRef = React.createRef<VariableSizeList>();
    _searchFieldRef = React.createRef<React.Component<ISearchFieldProps, ISearchFieldState>>();

    _calculationState = CalculationStateType.None;

    _updateAddPos = false;
    _focusItemN: number = null;

    _items: IEntity[];

    _calculatedBc: BindingContext;

    _lastVisibleColCount: number;


    // use debounce to prevent multiple slow re-renders
    handleSearchChange = debounce((searchValue: string) => {
        this.setState({
            searchValue
        });
    }, INPUT_DEBOUNCE_TIME);

    constructor(props: IProps) {
        super(props);

        // automatically register on model
        this.props.storage.addRef(this, this.props.bindingContext);
        this.props.storage.emitter.on(ModelEvent.AfterLoad, this.handleFormAfterLoad);
        this.props.storage.emitter.on(ModelEvent.RecalculateFastEntryList, this.updateColumnExtensions);

        this.state = {
            // Set all possible (editable) column extensions before first render,
            // so that the field widths are immediately correct - needed when virtualization is used.
            columnsExtensions: this.getColumnExtensions(),
            lineExtensions: {},
            selectedItems: {},
            searchValue: ""
        };
    }

    componentDidMount() {
        if (this._calculationState === CalculationStateType.Labels) {
            this.updateLabelsPosition();
        }

        if (this._calculationState === CalculationStateType.Items && this.getItems().length > 0) {
            this.updateColumnExtensions();
        }

        this._items = getBoundValue({
            bindingContext: this.props.bindingContext,
            data: this.props.storage.data.entity,
            dataBindingContext: this.props.storage.data.bindingContext
        });

        this.getListWidth();
    }

    componentWillUnmount() {
        this.props.storage.removeRef(this, this.props.bindingContext);
        this.props.storage.emitter.off(ModelEvent.AfterLoad, this.handleFormAfterLoad);
        this.props.storage.emitter.off(ModelEvent.RecalculateFastEntryList, this.updateColumnExtensions);
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        const bindingContextHasChanged = areBindingContextsDifferent(prevProps.bindingContext, this.props.bindingContext);
        if ((!prevProps.customAction && this.props.customAction) || bindingContextHasChanged) {
            this.setState({
                selectedItems: {}
            });
        }

        const _currentItems = this.getItems();

        if ((this._calculationState === CalculationStateType.Items && _currentItems.length > 0) || bindingContextHasChanged) {
            this.updateColumnExtensions();
            return;
        }

        if (this._calculationState === CalculationStateType.Finished) {
            const visibleColCount = this.getVisibleColumnCount();
            if (this._lastVisibleColCount !== visibleColCount) {
                this.updateLabelsPosition();
                this._lastVisibleColCount = visibleColCount;
                return;
            }
        }

        // this is magic check when list if fully rendered and some EXTERNAL situation makes its items changed
        if (this._calculationState === CalculationStateType.Finished && this._items && this._items !== _currentItems) {
            this.updateColumnExtensions();
        } else if (this._calculationState === CalculationStateType.Labels) {
            this.updateLabelsPosition();
        }

        if (!this.useVirtualization() && this.state.searchValue) {
            // when number of items gets under VIRTUALIZATION_THRESHOLD
            // remove filter value so that all items are rendered
            this.setState({
                searchValue: null
            });
        }

        this._items = _currentItems;

        // when add button is clicked, keep it in the same position for user by scrolling down the page , kinda hacky
        if (this._updateAddPos) {
            if (!this.useVirtualization() && this._fastEntryRef.current) {
                const diff = getElementOuterHeight(this._fastEntryRef.current?.parentElement?.parentElement);
                let scrollParent = getScrollParent(this._buttonRef.current);

                if (scrollParent) {
                    scrollParent = scrollParent.parentElement;
                }

                const scroll = getScrollParent(scrollParent);

                if (scroll && scroll.parentElement) {
                    scroll.parentElement.scrollTop += diff;
                }
            } else if (this.useVirtualization() && this._virtualizedListRef.current) {
                this._virtualizedListRef.current.scrollToItem(this.getItems().length, "start");
            }

            this._updateAddPos = false;
        }

        if (isDefined(this._focusItemN)) {
            const focusN = this._focusItemN;
            this._focusItemN = null;
            setTimeout(() => {
                this.focusFirstInputOfNItem(focusN);
            }, 20);
        }

        this.getListWidth();
    }

    getListWidth() {
        if (this._fastEntryListRef.current) {
            const width = this._fastEntryListRef.current.offsetWidth;
            if (this.state.contentWidth !== width) {
                this.setState({ contentWidth: width });
            }
        }
    }

    getVisibleColumnCount = () => {
        return this.props.columns?.filter(column => {
            const columnBindingContext = this.props.bindingContext.navigate(column.id);
            const columnMetadata = this.props.storage.getInfo(columnBindingContext);
            if (!columnMetadata) {
                console.error(`Unable to load info for field ${column.id}`);
            }

            return getInfoValue(columnMetadata, "isVisible", {
                storage: this.props.storage,
                bindingContext: columnBindingContext,
                context: this.props.storage.context
            }) !== false;
        })?.length || 0;
    };

    /** Negative index can be used to select last item */
    focusFirstInputOfNItem = (index: number) => {
        if (index >= this.getItems().length || index < 0) {
            return;
        }

        // items can be virtualized - we cannot query all items, we need to query only the specific item which should be rendered
        const item = this._fastEntryListRef.current?.querySelector(`[data-fasteentry-id="${index}"]`);

        if (item) {
            const firstInput = item.querySelector("input,textarea");

            if (firstInput) {
                (firstInput as HTMLInputElement).focus();
            }
        }
    };


    handleFormAfterLoad = () => {
        this._calculationState = CalculationStateType.Items;
    };

    handleKeyDown = (event: React.KeyboardEvent): void => {
        if (KeyboardShortcutsManager.isEventShortcut(event, KeyboardShortcut.ALT_R)) {
            if (this.canAdd && !this.isAddDisabled) {
                this.handleAdd();
            }
        }
    };

    getCorrectIndexFromAttr = (item: HTMLDivElement) => {
        return parseFloat(item.dataset.fasteentryId);
    };

    getColumnExtensions = () => {
        const columnsExtensions: ISmartFastEntryColumnsExtension = {};
        let needRecalc = false;
        let invisibleCount = 0;

        const { storage, bindingContext, columns } = this.props;

        this._calculatedBc = bindingContext;

        for (let i = 0; i < columns.length; i++) {
            const column = columns[i];
            const columnBindingContext = bindingContext.navigate(column.id);
            const columnMetadata = storage.getInfo(columnBindingContext);
            if (!columnMetadata) {
                console.error(`Unable to load info for field ${column.id}`);
            }
            // read only columns should have width based on their widest row value
            const isVisible = getInfoValue(columnMetadata, "isVisible", {
                storage,
                bindingContext: columnBindingContext,
                context: this.props.storage.context
            });

            if (isVisible === false) {
                invisibleCount++;
                continue;
            }
            const columnIndex = i - invisibleCount;

            const fieldWidth = parseFloat(getInfoValue(columnMetadata, "width", {
                bindingContext: columnBindingContext,
                info: columnMetadata,
                storage
            }) || FastEntryInputSizes.M);

            let width = 0;
            let hasReadOnly, hasEditable, isFirstReadOnly;

            // if we are sure no field can be readonly (either there is no callback or "isReadOnly" is straight NO
            // we don't need to do any crazy width calculation  from DOM and just set concrete value
            const canBeReadOnly = isTGetValueFn(columnMetadata.isReadOnly) || columnMetadata.isReadOnly === true;

            if (canBeReadOnly) {
                // top label, if the lines are not wrapped
                const mainLabel = this._fastEntryListRef.current?.querySelector(`[data-testid="${TestIds.FastEntryListHeader}"]`)?.querySelectorAll(`[data-testid="${TestIds.FieldLabel}"]`)?.[columnIndex] as HTMLDivElement;
                const htmlItems = this._fastEntryListRef.current?.querySelectorAll(`[data-testid="${TestIds.FastEntryItem}"]`);

                const hasOwnReadOnlyMaxWidth = FieldsWithOwnReadOnlyMaxWidth[columnMetadata.type];

                if (!htmlItems || htmlItems.length === 0) {
                    needRecalc = true;
                    width = 0;
                } else {

                    const items = this.getItems();

                    for (let i = 0; i < (htmlItems ?? []).length; i++) {
                        const htmlItem = htmlItems[i];

                        // for virtualization HTML div index doesn't match index of items
                        const correctIndex = this.getCorrectIndexFromAttr(htmlItem as HTMLDivElement);
                        const item = items[correctIndex];
                        const itemBc = bindingContext.addKey(item).navigate(column.id);
                        const isReadOnly = getInfoValue(columnMetadata, "isReadOnly", {
                            storage,
                            bindingContext: itemBc,
                            context: this.props.storage.context
                        }) || this.props.isReadOnly;

                        const row = htmlItem.querySelector(`[data-testid="${TestIds.FormGroupLine}"]`);
                        const columnElement = row?.children[columnIndex] as HTMLDivElement;

                        isFirstReadOnly = isDefined(isFirstReadOnly) ? isFirstReadOnly : isReadOnly;

                        // todo: width for read only columns doesn't work in audit trail mode
                        if (isReadOnly && this.props.storage.formMode !== FormMode.AuditTrail) {
                            // for maximum size, subtract paddings
                            const maxWidth = Math.min(fieldWidth, parseInt(FastEntryInputSizes.L) - 36);

                            const labelElement = mainLabel ?? columnElement.querySelector(`[data-testid="${TestIds.FieldLabel}"]`) as HTMLDivElement;
                            const labelWidth = labelElement?.children[0] ? (labelElement.children[0] as HTMLDivElement).offsetWidth + 2 : 0;
                            const textElement = columnElement.querySelector(`[data-testid="${TestIds.Text}"]`) as HTMLDivElement;
                            const fieldContentElement = columnElement.querySelector(`[data-testid="${TestIds.FieldContent}"]`) as HTMLDivElement;
                            const finalElement = (textElement?.children[0] ?? fieldContentElement?.children[0] ?? columnElement) as HTMLDivElement;
                            let elementWidth = finalElement.offsetWidth;

                            if (finalElement === columnElement) {
                                console.warn("No text or field content element found, this might be probably mistake and width is not correctly calculated to the content size");
                            } else {
                                // increase only in case we calculate width of children element.
                                // It would increase the width of the whole column on each calculation otherwise
                                elementWidth += 2;
                            }

                            width = Math.max(width, elementWidth, labelWidth);
                            if (!hasOwnReadOnlyMaxWidth) {
                                width = Math.min(maxWidth, width);
                            }
                            hasReadOnly = true;
                        } else {
                            width = Math.max(width, fieldWidth);
                            hasEditable = true;
                        }
                    }
                }
            } else {
                width = fieldWidth;
                hasEditable = true;
            }

            columnsExtensions[column.id] = {
                width: `${width}px`,
                widths: {},
                columnIndex,
                isFirstReadOnly,
                hasReadOnly, hasEditable,
                textAlign: columnMetadata.textAlign ?? (isNumericType(
                    columnBindingContext.getNavigationBindingContext(columnMetadata.fieldSettings?.displayName).getProperty()
                ) ? TextAlign.Right : TextAlign.Left)
            };
        }

        this._calculationState = needRecalc && this.props.useLabelWrapping ? CalculationStateType.Items : CalculationStateType.Labels;
        this._lastVisibleColCount = (this.props.columns?.length || 0) - invisibleCount;

        return columnsExtensions;
    };

    correctLabelPosition = (data: IEntity, left: number, columnId: string) => {
        const bc = this.props.bindingContext.navigate(columnId);
        const info = this.props.storage.getInfo(bc);
        const textAlign = data.textAlign;

        const _isRequired = isRequired({
            bindingContext: bc,
            info,
            storage: this.props.storage
        }) && data?.hasEditable;

        // UX shifts
        if ((_isRequired && textAlign === TextAlign.Left) ||
            (!_isRequired && textAlign === TextAlign.Left && !data.isFirstReadOnly)) {
            left += 12;
        }

        if (textAlign === TextAlign.Right && !data?.isFirstReadOnly) {
            left -= 12;
        }

        if (textAlign === TextAlign.Right && data?.isFirstReadOnly && data?.hasEditable) {
            left -= 8;
        }

        return left;
    };

    updateColumnExtensions = () => {
        if (!this.renderAsTable) {
            const columnsExtensions = this.getColumnExtensions();
            this.setState({
                columnsExtensions
            });
        }
    };

    updateLabelsPosition = () => {
        if (this.renderAsTable || !this.props.useLabelWrapping) {
            return;
        }

        const htmlItems = this._fastEntryListRef.current?.querySelectorAll(`[data-testid="${TestIds.FastEntryItem}"]`);
        if (!htmlItems || htmlItems.length === 0) {
            return;
        }

        const htmlLabelsWrapper = this._fastEntryListRef.current?.querySelector(`[data-testid="${TestIds.FastEntryListHeader}"]`);
        const htmlLabels = htmlLabelsWrapper?.children;

        if (!htmlLabelsWrapper || !htmlLabels || htmlLabels.length === 0) {
            return;
        }

        let i = 0;

        const htmlItem = htmlItems[0] as HTMLDivElement;
        // in children collection there can be a lot of different trash nodes so try to grab only direct field items or some of their wrappers
        const firstRow = [...htmlItem.querySelector(`[data-testid="${TestIds.FormGroupLine}"]`)?.children || []].filter((item: Element) => {
            const htmlElement = item as HTMLElement;
            return htmlElement.dataset.testid === TestIds.Field || htmlElement.dataset.elementtype === "FastEntryCol" || htmlElement.dataset.testid === TestIds.FastEntryListLastField;
        });

        for (const columnElement of firstRow || []) {
            const htmlLabel = htmlLabels[i++] as HTMLDivElement;

            const columnId = htmlLabel.dataset.columnId;

            const data = this.state.columnsExtensions[columnId];
            // todo: should we check for data and if not defined, should we call 'updateColumnExtensions' ?

            const parent = (columnElement.parentNode as HTMLElement);
            const parentLeft = parent.getBoundingClientRect().left;
            const _fieldElement = columnElement.querySelector(`[data-testid="${TestIds.Field}"]`) as HTMLDivElement;
            const fieldElement = _fieldElement ?? columnElement;

            const paddingLeft = parseFloat(window.getComputedStyle(fieldElement).getPropertyValue("padding-left"));
            const hasBoth = data.hasEditable && data.hasReadOnly;
            const left = this.correctLabelPosition(data, columnElement.getBoundingClientRect().left - parentLeft + (!hasBoth ? paddingLeft : 0), columnId);

            htmlLabel.style.left = `${left}px`;
        }

        this._calculationState = CalculationStateType.Finished;
    };

    getLineHeight = (lineBindingContext: BindingContext) => {
        let height;

        const lineExtension = this.state.lineExtensions[lineBindingContext.toString()];

        if (lineExtension) {
            height = Math.max(...Object.values(lineExtension.heights).map(colHeight => parseInt(colHeight)));
        }

        return height;
    };

    handleReorder = (dragIndex: number, hoverIndex: number) => {
        const allItems = this.getItems(false);

        if (hoverIndex - 1 >= allItems.length) {
            // sadly, it is now possible to drag items over/behind "removed" items indicators
            // ignore it, but ideally, prevent it completely somehow
            return;
        }

        const { visible, invisible } = allItems.reduce((ret, item) => {
            const type = this.isItem("isItemVisible", item, this.props.bindingContext.addKey(item)) ? "visible" : "invisible";
            ret[type].push(item);
            return ret;
        }, { visible: [], invisible: [] });

        let newItems = [...arrayMove(visible, dragIndex, hoverIndex), ...invisible];
        if (this.props.order) {
            newItems = newItems.map((item: IEntity, i: number) => {
                return { ...item, [this.props.order]: i + 1 }; // order property is expected from 1 not from 0
            });
        }

        this.props.onAction?.({
            bindingContext: this.props.bindingContext,
            actionType: ActionType.Reorder,
            groupId: this.props.groupId,
            items: newItems
        });
    };

    handleBlur = async (args: ISmartFieldBlur) => {
        // optimization - only recompute all errors if error state changed on blurred field
        const hadError = this.props.storage.getError(args.bindingContext);

        await this.props.onBlur?.(args);

        const hasError = this.props.storage.getError(args.bindingContext);

        if (hadError !== hasError) {
            this._errorStepperRef.current?.forceUpdate();
        }
    };

    _debounceForceUpdate = debounce(() => {
        this.forceUpdate();
    }, 300);

    handleChange = (e: ISmartFieldChange) => {
        e.groupId = this.props.groupId;
        this.showChanges && this._debounceForceUpdate();
        this.props.onChange?.(e);
    };

    handleTemporalChange = (e: ISmartFieldChange) => {
        e.groupId = this.props.groupId;
        this.props.onTemporalChange?.(e);
    };

    handleWidthChange = ({ width, bindingContext }: ISmartFieldWidthChange) => {
        const path = bindingContext.getPath();

        const currentWidth = this.state.columnsExtensions[path].width;
        const columnsExtensions = { ...this.state.columnsExtensions };

        columnsExtensions[path] = { ...columnsExtensions[path] };
        columnsExtensions[path].widths[bindingContext.toString()] = width;

        const maxWidth = Object.values(columnsExtensions[path].widths).reduce((maxWidth, currentWidth) => {
            return `${Math.max(parseInt(maxWidth), parseInt(currentWidth))}px`;
        }, "0");

        if (maxWidth !== currentWidth) {
            columnsExtensions[path].width = maxWidth;
            this.setState({
                columnsExtensions
            });
        }
    };

    handleHeightChange = ({ height, bindingContext }: ISmartFieldHeightChange) => {
        const linePath = getSharedContextPrefix(this.props.bindingContext, bindingContext).toString();
        const lineExtensions = {
            ...this.state.lineExtensions,
            [linePath]: {
                ...this.state.lineExtensions[linePath],
                heights: {
                    ...this.state.lineExtensions[linePath]?.heights,
                    [bindingContext.toString()]: height
                }
            }
        };

        this.setState({
            lineExtensions
        });
    };

    handleAdd = () => {
        if (this.props.isCollapsible) {
            const isExpanded = this.props.storage.getGroupStatus(this.props.groupId)?.isExpanded;
            if (!isExpanded) {
                this.props.storage.expandGroup(true, this.props.groupId);
                this.forceUpdate(this.addItem);
                return;
            }
        }

        if (this.state.searchValue) {
            // remove filter, otherwise new item will not be visible
            this.setState({
                searchValue: null
            });
            this._searchFieldRef.current?.setState({
                searchValue: null
            });
        }

        this._calculationState = CalculationStateType.Items;

        this.addItem();
    };

    getNewItemIndex = (): number => {
        return this.getItems(false).length;
    };

    addItem = (preventScrolling?: boolean) => {
        if (!preventScrolling) {
            this._updateAddPos = true;
            this._focusItemN = this.getNewItemIndex();
        }

        const items = this.getItems(false);
        const newItemsMax = getNewItemsMaxId(items);
        const newItem = addEmptyLineItem({
            storage: this.props.storage,
            bindingContext: this.props.bindingContext,
            newItemsCount: newItemsMax + 1,
            index: items.length + 1,
            order: this.props.order,
            columns: this.props.columns,
            context: this.props.storage.context
        });
        this.props.onAction({
            bindingContext: this.props.bindingContext,
            actionType: ActionType.Add,
            groupId: this.props.groupId,
            items: [...items, newItem],
            affectedItems: [newItem]
        });
    };

    handleClone = (clonedIndex: number) => {
        this._updateAddPos = true;
        this._focusItemN = this.getNewItemIndex();

        let newItems = [...this.getItems(false)];
        const visibleItems = [...this.getItems(true)];
        const originalItem = visibleItems[clonedIndex];
        const newItemsMax = getNewItemsMaxId(newItems);
        let newItem = BindingContext.createNewEntity(newItemsMax + 1);
        const keyName = this.props.bindingContext.getKeyPropertyName();

        for (const [key, value] of Object.entries(originalItem)) {
            if (key !== keyName && !(key in newItem)) {
                newItem[key] = cloneDeep(value);
            }
        }

        newItems.push(newItem);

        if (this.props.order) {
            newItems = newItems.map((item, index) => {
                return {
                    ...item,
                    [this.props.order]: index + 1
                };
            });
            // replace newItem, so it's the same object as in the list
            newItem = newItems.find(i => i[BindingContext.NEW_ENTITY_ID_PROP] === newItem[BindingContext.NEW_ENTITY_ID_PROP]);
        }

        // cloning should set dirty flag as we are cloning non-empty row and the new row is not saved on BE
        const itemBindingContext = this.props.bindingContext.addKey(newItem);
        this.props.storage.setDirty(itemBindingContext);

        this.props.onAction?.({
            bindingContext: this.props.bindingContext,
            items: newItems,
            groupId: this.props.groupId,
            actionType: ActionType.Clone,
            affectedItems: [newItem]
        });
    };

    handleRemove = (removedIndex: number) => {
        // Note: there may be difference between visible items and all items. We need to work with both
        const visibleItems = this.getItems();
        let items = this.getItems(false);

        this._focusItemN = removedIndex >= items.length - 1 ? removedIndex - 1 : removedIndex;

        const removedItem = visibleItems[removedIndex];
        const itemBindingContext = this.props.bindingContext.addKey(removedItem);

        // remove width from columnsExtensions for removed bindingContext
        for (const column of Object.keys(this.state.columnsExtensions)) {
            const colBc = itemBindingContext.navigate(column);
            if (this.state.columnsExtensions[column].widths[colBc.getFullPath()]) {
                this.handleWidthChange({ width: "0px", bindingContext: colBc });
            }
        }

        // remove extension for the line
        const lineExtensions = { ...this.state.lineExtensions };
        delete lineExtensions[itemBindingContext.getFullPath()];

        this.setState({
            lineExtensions
        });

        items = items.filter(item => item !== removedItem);

        if (this.props.order) {
            // order property is expected from 1 not from 0
            const _getNextOrder = (idx: number) => this.props.orderDirection === Sort.Desc ? items.length - idx : idx + 1;

            items = items.map((item, i) => { // update Order
                return { ...item, [this.props.order]: _getNextOrder(i) };
            });
        }

        this._calculationState = CalculationStateType.Items;

        this.props.onAction?.({
            bindingContext: this.props.bindingContext,
            actionType: ActionType.Remove,
            groupId: this.props.groupId,
            items,
            affectedItems: [removedItem]
        });
    };

    handleFocusPreviousItem = (index: number): void => {
        this.focusFirstInputOfNItem(index - 1);
    };

    handleFocusNextItem = (index: number): void => {
        this.focusFirstInputOfNItem(index + 1);
    };

    handleReorderUp = (index: number): void => {
        if (index > 0) {
            const newIndex = index - 1;

            this._focusItemN = newIndex;
            this.handleReorder(index, newIndex);
        }
    };

    handleReorderDown = (index: number): void => {
        if (index < this.getItems().length - 1) {
            const newIndex = index + 1;

            this._focusItemN = newIndex;
            this.handleReorder(index, newIndex);
        }
    };

    sortByOrder = (a: IEntity, b: IEntity) => {
        const dir = this.props.orderDirection === Sort.Desc ? Sort.Desc : Sort.Asc;

        return sortCompareFn(a[this.props.order], b[this.props.order], dir);
    };

    useVirtualization = (applySearchValue = false): boolean => {
        return !this.props.disableVirtualization && this.getItems(true, applySearchValue).length > VIRTUALIZATION_THRESHOLD;
    };

    handleWrapChange = (isWrapped: boolean) => {
        if (!isWrapped) {
            this._calculationState = CalculationStateType.Labels;
        }
        this.setState((state: IState) => ({ ...state, isWrapped }));
    };

    getLabels = () => {
        return this.props.columns
            .map((column) => {
                const bc = this.props.bindingContext.navigate(column.id);
                const data = this.state.columnsExtensions?.[column.id];
                const width = !data?.width || data.width === "0px" ? "auto" : data.width;

                const info = this.props.storage.getInfo(bc);
                const textAlign = data?.textAlign;

                const _isVisible = isVisible({
                    bindingContext: bc,
                    info,
                    storage: this.props.storage
                });

                const _isLabelRemoved = info.labelStatus === LabelStatus.Removed;

                if (!_isVisible || _isLabelRemoved) {
                    return null;
                }

                const _isRequired = isRequired({
                    bindingContext: bc,
                    info,
                    storage: this.props.storage
                }) && data?.hasEditable;

                const key = bc.toString();

                return <FastEntryLabelWrapper data-column-id={column.id} key={key}>
                    <StyledHeaderLabelAbs
                        data-column-id={column.id}
                        width={width}
                        textAlign={textAlign}
                        isRequired={_isRequired}
                        isLight={this.props.isLight}
                        isHidden={info.labelStatus === LabelStatus.Hidden}
                        hasPadding={false}
                        key={key}
                        _opacity={this._calculationState === CalculationStateType.Items ? 0 : 1}
                    >{info.label}
                    </StyledHeaderLabelAbs>;
                </FastEntryLabelWrapper>;
            })
            .filter(label => !!label); // filter out invisible fields' labels
    };

    getAuditTrailData = (bc: BindingContext) => {
        if ((this.props.storage as FormStorage)?.formMode === FormMode.AuditTrail) {
            return this.props.storage.getAdditionalFieldData(bc, FieldAdditionalData.AuditTrailData);
        }

        return undefined;
    };

    isFormDisabled = () => {
        return this.props.storage?.isDisabled;
    };

    isComparison = () => {
        return (this.props.storage as FormStorage)?.formMode === FormMode.AuditTrail;
    };

    isReadOnlyFormMode = () => {
        return (this.props.storage as FormStorage)?.isReadOnly;
    };

    /**
     * returns if whole line item is removable or cloneable - when in readOnly mode,
     * item is by default not cloneable or removable, however in default form, all line items
     * are by default removable and cloneable, so we handle here different default value.
     */
    isItem = (name: TInfoValueProp, item: IEntity, bindingContext: BindingContext) => {
        const propVal = getInfoValue(this.props, name, {
            storage: this.props.storage,
            bindingContext, item,
            context: this.props.storage.context,
            fastEntryListId: this.props.groupId
        });
        const isReadOnlyMode = this.isReadOnlyFormMode();
        const defaults = ITEM_PROPS_DEFAULTS[name];
        const defaultValue = (isReadOnlyMode ? defaults?.readOnly : defaults?.editable) ?? false;

        return isDefined(propVal) ? propVal : defaultValue;
    };

    handleCollapsed = () => {
        const isExpanded = this.props.storage.getGroupStatus(this.props.groupId)?.isExpanded;
        this.props.storage.expandGroup(!isExpanded, this.props.groupId);
        this.forceUpdate();
    };

    handleCollapsingEnd = () => {
        this.props.storage.emitter.emit(ModelEvent.RecalculateScrollbar);
    };

    getMainToggleState = (): ToggleState => {
        let allToggled = true;
        let allUntoggled = true;

        const items = this.getItems();

        for (let i = 0; i < items.length; i++) {
            const isSelectable = this.isSelectable(items[i]);

            allToggled = allToggled && (this.state.selectedItems[i] || !isSelectable);
            allUntoggled = allUntoggled && (!this.state.selectedItems[i] || !isSelectable);
        }

        if (allToggled) {
            return ToggleState.AllChecked;
        } else if (allUntoggled) {
            return ToggleState.AllUnchecked;
        } else {
            return ToggleState.Other;
        }
    };

    isSelectable(item: IEntity, lineBindingContext?: BindingContext) {
        if (!lineBindingContext) {
            lineBindingContext = this.props.bindingContext.addKey(item);
        }
        return lineBindingContext.isNew() ? false : this.isItem("isItemSelectable", item, lineBindingContext);
    }

    handleSelect = (id: number, selected: boolean) => {
        this.setState({
            selectedItems: {
                ...this.state.selectedItems,
                [id]: selected
            }
        });
    };

    handleMainToggleChange = (e: ICheckboxChange) => {
        const items = this.getItems();
        const selectedItems: Record<number, boolean> = {};

        for (let i = 0; i < items.length; i++) {
            const isSelectable = this.isSelectable(items[i]);

            if (isSelectable) {
                selectedItems[i] = e.value;
            }
        }

        this.setState({
            selectedItems
        });
    };

    getSelectedItems = () => {
        return this.getItems()
            .filter((item, index) => !!this.state.selectedItems[index]);
    };

    handleCustomActionConfirm = () => {
        const items = this.getSelectedItems();

        // custom action sends only selected items in 'items'
        this.props.onAction({
            bindingContext: this.props.bindingContext,
            actionType: ActionType.Custom,
            customActionType: this.props.customAction.id,
            groupId: this.props.groupId,
            items, // todo: refactor customActions, use affectedItems instead of items
            affectedItems: items
        });

        this.props.onCustomActionFinish?.();
    };

    getItems = (onlyVisible = true, applySearchValue?: boolean): IEntity[] => {
        if (applySearchValue === undefined) {
            applySearchValue = onlyVisible;
        }

        // clone items to prevent side effect mutation on the original object (like sort)
        let _items: IEntity[] = [...getBoundValue({
            bindingContext: this.props.bindingContext,
            data: this.props.storage.data.entity,
            dataBindingContext: this.props.storage.data.bindingContext
        }) || []];

        if (onlyVisible) {
            _items = _items.filter((item: IEntity) => this.isItem("isItemVisible", item, this.props.bindingContext.addKey(item)));
        }

        if (this.props.filterItems) {
            _items = this.props.filterItems(_items);
        }

        if (this.props.order) {
            _items.sort(this.sortByOrder);
        }

        // todo how exactly should filtering work?
        if (this.state.searchValue && applySearchValue) {
            _items = _items.filter(item => {
                let values = Object.values(item);
                if (this.props.searchInChildProps) {
                    for (const prop of this.props.searchInChildProps) {
                        values = [
                            ...values,
                            ...Object.values(item[prop])
                        ];
                    }
                }
                const searchValue = this.state.searchValue;

                return values.some(val => {
                    let correctedSearchVal = searchValue;
                    let correctedVal;

                    if (typeof val === "number") {
                        const parsedSearchVal = NumberType.parse(searchValue);

                        if (isNaN(val) || isNaN(parsedSearchVal)) {
                            return false;
                        }

                        correctedVal = Math.abs(val).toString();
                        correctedSearchVal = parsedSearchVal.toString();
                    } else if (val && val instanceof Date) {
                        correctedVal = DateType.format(val);
                    } else {
                        correctedVal = val;
                    }

                    return startsWithAccentsInsensitive(correctedVal?.toString(), correctedSearchVal);
                });
            });
        }

        return _items;
    };

    get showChanges() {
        return isDefined(this.props.showChanges) ? this.props.showChanges : this.props.storage.data.definition.showChanges;
    }

    getRemovedItems = (): IEntity[] => {
        if (!this.showChanges) {
            return [];
        }

        const items = this.getItems(true, false);
        let origItems: IEntity[] = getBoundValue({
            bindingContext: this.props.bindingContext,
            data: this.props.storage.data.origEntity,
            dataBindingContext: this.props.storage.data.bindingContext
        }) || [];

        const keyName = this.props.bindingContext.getKeyPropertyName();

        if (this.props.filterItems) {
            origItems = this.props.filterItems(origItems);
        }

        return origItems.filter(origItem => !items.find(item => item[keyName] === origItem[keyName]));
    };

    renderMainToggle = () => {
        return (
            <CheckboxStyledMainToggle checked={this.getMainToggleState() === ToggleState.AllChecked}
                                      onChange={this.handleMainToggleChange}/>
        );
    };

    handleCustomButtonAction = (id: string): void => {
        const items = this.getSelectedItems();

        this.props.onAction({
            bindingContext: this.props.bindingContext,
            actionType: ActionType.Custom,
            customActionType: id,
            groupId: this.props.groupId,
            items,
            affectedItems: items
        });
    };

    renderCustomActionButtons = (position: ActionButtonPosition) => {
        const visibleActions = this.props.customActionButtons.filter(action => {
            const _isVisible = isVisible({ info: action, storage: this.props.storage });
            return _isVisible && (action.position === position || (!action.position && position === ActionButtonPosition.After));
        });

        if (visibleActions.length === 0) {
            return null;
        }

        return (
            <ButtonGroupStyled align={TextAlign.Left}>
                {visibleActions.map(action => (
                    <SmartActionButtonStyled isBefore={position === ActionButtonPosition.Before} key={action.id}
                                             storage={this.props.storage} action={action}
                                             onClick={this.handleCustomButtonAction}/>))}
            </ButtonGroupStyled>
        );
    };

    renderConfirmationButtons = () => {
        const selectedCount = Object.values(this.state.selectedItems).reduce((count, isSelected) => {
            return count + (isSelected ? 1 : 0);
        }, 0);

        return (
            <ConfirmationButtonsWrapper>
                <ConfirmationButtons isLight
                                     isTransparent
                                     ignoreTheme={this.props.customAction.ignoreTheme}
                                     onCancel={this.props.onCustomActionFinish}
                                     onConfirm={this.handleCustomActionConfirm}
                                     isDisabled={selectedCount === 0}
                                     confirmText={getConfirmationActionText(this.props.customAction.confirmLabel, selectedCount)}/>
            </ConfirmationButtonsWrapper>
        );
    };

    renderReadOnly = (sortedItems: IEntity[]) => {
        const columns = this.props.columns.filter(col => {
            const colBc = this.props.bindingContext.navigate(col.id);
            const isVisible = getInfoValue(this.props.storage.getInfo(colBc), "isVisible", {
                storage: this.props.storage,
                bindingContext: colBc,
                context: this.props.storage.context
            });
            return isVisible !== false;
        }).map(col => {
            const colBc = this.props.bindingContext.navigate(col.id);
            const colId = colBc.getNavigationPath();
            const colDef: IFieldInfoProperties = this.props.storage.data.mergedDefinition?.[colId]?.fieldDef ?? {};
            const colInfo = this.props.storage.getInfo(colBc);
            const colExt = this.state.columnsExtensions[col.id];
            const path = this.props.bindingContext.getPath(true);

            // width and columns in line item definition is different from table columns width and has to be removed
            // additionalProperties use root notation like 'id: "/Items/UnitPrice"'
            // we use different root here than in FormView load => remove the /Items prefix, if present
            const additionalProperties = colDef.additionalProperties?.map(def => {
                const prefix = `/${path}`;
                if (def.id.startsWith(prefix)) {
                    return {
                        ...def,
                        id: def.id.slice(prefix.length)
                    };
                }

                return def;
            });

            return {
                ...colDef, ...col,
                bindingContext: colBc,
                additionalProperties,
                label: colInfo?.label || "",
                textAlign: colDef?.textAlign ?? colExt?.textAlign,
                width: null, columns: null
            };
        });

        const removableRows: { id: string, index: number }[] = [];
        const isReadOnlyForm = this.props.storage.isReadOnly;
        const rows: IRow[] = sortedItems.map((item: IEntity, idx: number) => {
            const lineBindingContext = this.props.bindingContext.addKey(item);
            const values: IEntity = {};
            for (const column of columns) {
                // Formatters are usually made to work with regular fields.
                // Unfortunately, we try to use them from multiple places, with multiple different bc/item/entity values.
                // => To make them work from here, if the column has a formatter, we have to call it the same way SmartField would.
                // For other columns, use getFormattedValueFromValues to format values automatically, like in SmartTable.
                const colHasFormatter = !!column.formatter;

                if (colHasFormatter) {
                    const value = item[column.id];
                    const formattedValue = column.formatter(value, {
                        entity: this.props.storage.data.entity,
                        item: item,
                        bindingContext: lineBindingContext.navigate(column.id),
                        readonly: true,
                        storage: this.props.storage
                    });

                    if (column.render) {
                        values[column.id] = column.render({
                            storage: this.props.storage,
                            props: {
                                formattedValue,
                                info: {
                                    ...column,
                                    bindingContext: lineBindingContext.navigate(column.id)
                                }
                            }
                        });
                    } else {
                        values[column.id] = formattedValue;
                    }
                }

                // it seems like some values are not necessarily handled by the formatter
                // as it is expected for Selects that the value is already stored in their (additional) items
                // => call getFormattedValueFromValues not only if colHasFormatter is false, but even if "formatter" didn't return any data
                if (!colHasFormatter || isNotDefined(values[column.id])) {
                    values[column.id] = getFormattedValueFromValues({
                        column,
                        values: item,
                        valuesBindingContext: this.props.bindingContext,
                        storage: this.props.storage
                    });
                }

                if (isNotDefined(values[column.id]) || values[column.id] === "") {
                    values[column.id] = DASH_CHARACTER;
                }
            }

            const rowId = `row-${idx}`;

            if (!isReadOnlyForm && this.isItem("isItemRemovable", item, lineBindingContext)) {
                removableRows.push({
                    id: rowId,
                    index: idx
                });
            }

            return {
                id: rowId,
                values,
                isDisabled: this.isItem("isItemDisabled", item, lineBindingContext)
            };
        });
        const getContentAfter = (row: IRow, index: number) => {
            const removableRow = removableRows.find(r => r.id === row.id);

            if (!removableRow) {
                return null;
            }

            return (
                <IconButton title={this.props.t("Common:General.Remove")}
                            onClick={() => this.handleRemove(index)}
                            isDecorative
                            isTransparent>
                    <CloseIcon width={IconSize.S} height={IconSize.S}/>
                </IconButton>
            );
        };


        const rowHeight = 30;
        if (!rows.length) {
            return null;
        }
        return (
            <>
                <TableWrapper hasMinHeight={false}>
                    {this.props.showLineNumbers &&
                        <OrderBackground
                            style={{
                                top: `${rowHeight}px`,
                                height: `${rows.length * rowHeight}px`,
                                left: `-${VIEW_PADDING_VALUE}px`
                            }}
                        >
                            {rows.map((row, index) => {
                                return (
                                    <ItemIndex key={index}
                                               $height={`${rowHeight}px`}
                                               $top={"8px"}>
                                        {index + 1}
                                    </ItemIndex>
                                );
                            })}
                        </OrderBackground>}
                    <ScrollBar
                        style={{
                            overflowX: "visible",
                            width: "100%",
                            height: "auto"
                        }}
                    >
                        <SimpleTable columns={columns}
                                     rows={rows}
                                     contentAfter={getContentAfter}
                                     hasBigFont
                                     showHeaderBorder={false}/>
                    </ScrollBar>
                </TableWrapper>
                {this.props.customFooterContent ?
                    <div style={{ marginTop: "20px" }}>{this.props.customFooterContent}</div> : null}
            </>
        );
    };

    handleErrorFocusChange = (errorBc: BindingContext, itemIndex: number) => {
        this._virtualizedListRef.current.scrollToItem(itemIndex, "start");
    };

    renderErrorStepper = () => {
        return <ErrorStepper storage={this.props.storage}
                             bindingContext={this.props.bindingContext}
                             groupId={this.props.groupId}
                             isLight={this.props.isLight}
                             ref={this._errorStepperRef}
                             onErrorFocusChange={this.handleErrorFocusChange}/>;
    };

    getCustomPreContent = (labelStatus: LabelStatus, index: number): React.ReactNode => {
        const items = this.getItems();
        const item = items[index];
        const lineBindingContext = this.props.bindingContext.addKey(item);

        return this.props.customPreContent({
            itemBc: lineBindingContext,
            index,
            item,
            items,
            labelStatus
        });
    };

    getCustomPreContentWithLabelStatus = memoizeOne((labelStatus: LabelStatus) => {
        return this.getCustomPreContent.bind(this, labelStatus);
    });

    getFastEntryListChildren = (sortedItems: IEntity[], isFormDisabled: boolean): IFastEntryListChild[] => {
        const children: IFastEntryListChild[] = [];
        const removedItems = this.getRemovedItems();

        const canReorder = this.canReorder;

        const originalOrder = this.props.storage.data.origEntity?.Items?.reduce((origOrderMap: TRecordAny, item: IEntity) => {
            origOrderMap[item[this.props.bindingContext.getKeyPropertyName()]] = item[this.props.order];
            return origOrderMap;
        }, {}) ?? {};

        // use getItems to get all items, not just filtered ones (when virtualized)
        // so that only LAST item can not be removed
        const disableRemoveAction = !this.canRemoveLastItem && this.getItems(false, false).length === 1;

        sortedItems.forEach((row, i) => {
            const lineBindingContext = this.props.bindingContext.addKey(row);
            const height = this.getLineHeight(lineBindingContext);
            const key = lineBindingContext.toString();
            let heightPx: string;

            if (height) {
                heightPx = `${height}px`;
            }

            children.push({
                id: i.toString(),
                showIndicator: () => {
                    const isAnyFieldChanged = this.props.columns.some(column => {
                        const bc = lineBindingContext.navigate(column.id);
                        const info = this.props.storage.getInfo(bc);
                        const isVisible = getInfoValue(info, "isVisible", {
                            storage: this.props.storage,
                            bindingContext: bc,
                            context: this.props.storage.context
                        });

                        return isVisible !== false && !isFieldValueSame(this.props.storage, bc);
                    });
                    const showNewLineIndicator = !this.props.bindingContext.getRootParent()?.isNew() &&
                        !this.props.storage.loading && (lineBindingContext.isNew() || isAnyFieldChanged || row[this.props.order] !== originalOrder[lineBindingContext.getKey()])
                        && this.showChanges && !this.getAuditTrailData(lineBindingContext);

                    return showNewLineIndicator;
                },
                render: ({
                             dragHandleProps,
                             connectEntryRef,
                             labelStatus
                         }: IFastEntryListChildRender) => {
                    const auditTrailData = this.getAuditTrailData(lineBindingContext);
                    const isDisabled = this.isItem("isItemDisabled", row, lineBindingContext);
                    const itemCannotBeDeleted = !!this.props.storage.getBackendDisabledFieldMetadata(lineBindingContext)?.cannotBeDeleted;
                    const hasCustomAction = !!this.props.customAction;
                    const isSelectable = this.isSelectable(row, lineBindingContext);
                    return (
                        <FastEntry key={key} id={i}
                                   auditTrailData={auditTrailData}
                                   hideCloneAction={hasCustomAction || !this.isItem("isItemCloneable", row, lineBindingContext) || !this.canAdd}
                                   hideRemoveAction={hasCustomAction || !this.isItem("isItemRemovable", row, lineBindingContext)}
                                   disableCloneAction={this.isDisabled || !this.canAdd}
                                   disableRemoveAction={this.isDisabled || itemCannotBeDeleted || disableRemoveAction}
                                   actionStatus={this.useVirtualization() ? FastEntryActionStatus.Hidden : FastEntryActionStatus.Default}
                                   onClone={this.handleClone}
                                   onRemove={this.handleRemove}
                                   onFocusPreviousItem={this.handleFocusPreviousItem}
                                   onFocusNextItem={this.handleFocusNextItem}
                                   onReorderUp={!hasCustomAction && canReorder ? this.handleReorderUp : null}
                                   onReorderDown={!hasCustomAction && canReorder ? this.handleReorderDown : null}
                                   canReorder={!hasCustomAction && canReorder}
                                   isSelectable={hasCustomAction && isSelectable}
                                   isSelected={this.state.selectedItems[i]}
                                   onSelect={this.handleSelect}
                                   isDisabled={isFormDisabled || isDisabled}
                                   dragHandleProps={dragHandleProps}
                                   customPreContent={this.props.customPreContent ? this.getCustomPreContentWithLabelStatus(labelStatus) : null}
                            // emulate the size of the checkbox for unselectable item
                                   style={{ marginLeft: hasCustomAction && !isSelectable ? "38px" : null }}
                            /* we need some ref to be set, but when virtualized, we don't know which one is rendered, so last rendered ref wins and is assigned to the refObject */
                                   passRef={composeRefHandlers(this._fastEntryRef, connectEntryRef)}>
                            {this.props.columns.filter(column => {
                                const bc = lineBindingContext.navigate(column.id);
                                const info = this.props.storage.getInfo(bc);
                                const isVisible = getInfoValue(info, "isVisible", {
                                    storage: this.props.storage,
                                    bindingContext: bc,
                                    context: this.props.storage.context
                                });
                                return isVisible !== false;
                            }).map((column) => {
                                return ({ isSharpLeft, isSharpRight }: IFastEntryChild) => {
                                    const bc = lineBindingContext.navigate(column.id);

                                    const colExtension = this._calculatedBc.getFullPath() === this.props.bindingContext.getFullPath() && this.state.columnsExtensions[column.id];
                                    if (!colExtension && this.props.useLabelWrapping) {
                                        // sometimes DOM manipulator doesn't trigger calculation, so we have to do it manually
                                        this._calculationState = CalculationStateType.Items;
                                    }

                                    const auditTrailLineType = auditTrailData?.lineData?.type;
                                    if (auditTrailLineType === AuditTrailLineComparison.AdditionalRow || auditTrailLineType === AuditTrailLineComparison.MissingRow) {
                                        // this renders fields without tabindex
                                        this.props.storage.setAdditionalFieldData(bc, FieldAdditionalData.AuditTrailData, {}, false);
                                    }

                                    let width = colExtension?.width;
                                    if (colExtension?.hasEditable && colExtension?.hasReadOnly) {
                                        const columnBindingContext = this.props.bindingContext.navigate(column.id);
                                        const columnInfo = this.props.storage.getInfo(columnBindingContext);
                                        const isFieldReadOnly = getInfoValue(columnInfo, "isReadOnly", {
                                            storage: this.props.storage,
                                            bindingContext: bc,
                                            context: this.props.storage.context
                                        });

                                        // https://solitea-cz.atlassian.net/browse/DEV-18871
                                        // when different readonly/editable states are used in one column
                                        // we need to subtract width of the paddings (20+10) from the readonly fields
                                        // to make them the same size as the editable ones

                                        if (isFieldReadOnly) {
                                            width = `${parseInt(colExtension?.width) - 27}px`;
                                        }
                                    }

                                    isSharpRight = isSharpRight && column.groupedField !== GroupedField.MultiEnd;
                                    isSharpLeft = isSharpLeft && column.groupedField !== GroupedField.MultiStart;

                                    const fieldDef: IFieldInfoProperties = {};
                                    if (hasCustomAction && (!isSelectable || this.props.disableFieldsOnActiveAction)) {
                                        fieldDef.isDisabled = true;
                                    }
                                    if (this.props.isConfirmable) {
                                        fieldDef.isConfirmable = true;
                                    }
                                    const props = {
                                        storage: this.props.storage,
                                        fieldDef: isObjectEmpty(fieldDef) ? null : fieldDef,
                                        fastEntryListId: this.props.groupId,
                                        fieldProps: {
                                            width,
                                            textAlign: colExtension?.textAlign,
                                            isSharpRight: isSharpRight,
                                            isSharpLeft: isSharpLeft,
                                            isLight: this.props.isLight,
                                            height: heightPx,
                                            isMultiLine: false,
                                            showChange: false,
                                            maxRows: 3,
                                            // Removed should be always passed down, otherwise let SmartField take its LabelStatus from fieldinfo
                                            labelStatus: labelStatus === LabelStatus.Removed ? labelStatus : undefined
                                        },

                                        useWidthWhenReadOnly: true,
                                        key: column.id,
                                        bindingContext: bc,
                                        onBlur: this.handleBlur,
                                        onChange: this.handleChange,
                                        onTemporalChange: this.handleTemporalChange,
                                        onWidthChange: this.handleWidthChange,
                                        onHeightChange: this.handleHeightChange
                                    };

                                    if (this.props.isReadOnly) {
                                        (props as ISmartFieldProps).fieldProps.isReadOnly = true;
                                    }

                                    return (
                                        // todo should we disable all fields when isFormDisabled || isDisabled is true?
                                        <SmartField {...props} />
                                    );
                                };
                            })}
                        </FastEntry>
                    );
                }
            });
        });

        if (removedItems.length > 0) {
            const tKey = this.props.deletedItemsTranslationKey ?? "Components:SmartFastEntryList.ItemsDeleted";
            children.push({
                id: (sortedItems.length + 1).toString(),
                render: null,
                showIndicator: () => {
                    return true;
                },
                isEmptyItem: true,
                emptyItemText: this.props.t(tKey, { count: removedItems.length })
            });
        }

        return children;
    };

    get canReorder() {
        return getValue(this.props.canReorder, {
            storage: this.props.storage,
            context: this.props.storage.context
        }) && !this.isDisabled;
    }

    get canRemoveLastItem() {
        return this.props.isTheOnlyItemRemovable;
    }

    get showOrderIcon() {
        return this.canReorder && !this.isComparison();
    }

    get canAdd(): boolean {
        if (this.props.isReadOnly) {
            return false;
        }

        if (this.props.isGroupToggleable) {
            const items = this.getItems(false, false);
            if (!items.length) {
                // toggleable group without items should not show add button
                return false;
            }
        }

        const args = {
            storage: this.props.storage,
            context: this.props.storage.context
        };

        return getValue(this.props.canAdd, args);
    }

    get isAddDisabled(): boolean {
        const args = {
            storage: this.props.storage,
            context: this.props.storage.context
        };

        return getValue(this.props.isAddDisabled, args) || this.isDisabled;
    }

    /** In the context of SmartFastEntryList, isDisabled means that the items of the collection cannot be add or removed.
     * We don't care about disabled state of the fields, that is handled by SmartField for each field */
    get isDisabled(): boolean {
        return getInfoValue(this.props, "isDisabled", {
            storage: this.props.storage,
            bindingContext: this.props.bindingContext,
            context: this.props.storage.context
        }) || !!this.props.storage.getBackendDisabledFieldMetadata(this.props.bindingContext);
    }

    get renderAsTable(): boolean {
        return this.props.isReadOnly && !this.props.renderAsFastEntryForReadOnly;
    }

    render = () => {
        const sortedItems = this.getItems();

        if (this.renderAsTable) {
            return this.renderReadOnly(sortedItems);
        }

        const isFormDisabled = this.isFormDisabled();
        const canAdd = this.canAdd;
        const useVirtualization = this.useVirtualization();

        let labelsLeftPad = 0;

        if (!!this.props.customAction) {
            // todo this is suspicious, maybe the value should be passed from place it is used in
            labelsLeftPad = 42;
        } else {
            if (this.showOrderIcon) {
                // icon + its margin
                labelsLeftPad += IconSize.asNumber("M") + REORDER_ICON_MARGIN;
            }
            if (this.props.showLineNumbers) {
                labelsLeftPad += ORDER_BACKGROUND_WIDTH + ORDER_BACKGROUND_RIGHT_MARGIN;
            }
        }

        if (this.props.labelsLeftMargin) {
            labelsLeftPad += this.props.labelsLeftMargin;
        }

        /* Currently only VAT table is using customFooterContent. The VAT table should have same
            width as FastEntryList content in case it is not wrapped, but the other content (buttons)
            should be on the most right edge -> set the width only to the CustomFooterContent*/
        const contentWidth = !useVirtualization && this.props.customFooterContent && !this.state.isWrapped
            ? this.state.contentWidth : null;

        // when virtualized, we need to recalculate item size when columns change,
        // => remount here by using columns as key instead of finding a way how to propagate the columns into SortableList
        const columnsKey = useVirtualization ? this.props.columns.map(col => col.id).join("-") : "";

        return (
            <StyledSmartFastEntryList
                showLineNumbers={this.props.showLineNumbers}
                onKeyDown={this.handleKeyDown}
                style={this.props.style}
                data-testid={TestIds.FastEntryListVirtualization}>
                {useVirtualization &&
                    <SearchField
                        ref={this._searchFieldRef}
                        onChange={this.handleSearchChange}
                        isLight={this.props.isLight}/>}
                {useVirtualization && this.renderErrorStepper()}
                <FastEntryList
                    id={this.props.bindingContext.toString()}
                    recalculate={columnsKey}
                    useLabelWrapping={this.props.useLabelWrapping}
                    onWrapChange={this.handleWrapChange}
                    isInAuditTrail={this.props.storage.formMode === FormMode.AuditTrail}
                    showLineNumbers={this.props.showLineNumbers}
                    isCollapsed={!this.props.storage.getGroupStatus(this.props.groupId)?.isExpanded}
                    onCollapsingEnd={this.handleCollapsingEnd}
                    onCollapsed={this.handleCollapsed}
                    canReorder={!!this.canReorder}
                    onReorder={this.handleReorder}
                    isCollapsible={this.props.isCollapsible}
                    // when filter is used, we still want to show SearchField here, even if there are less items than the threshold,
                    // but FastEntryList should not be virtualized so that its height isn't always 70vh,
                    // even if there are only few items
                    // => call useVirtualization with applySearchValue true
                    useVirtualization={this.useVirtualization(true)}
                    height={"70vh"}
                    containsPrimary={this.props.containsPrimary}
                    customPreContent={!!this.props.customAction ? this.renderMainToggle() : null}
                    labels={this.getLabels()}
                    labelsLeftPad={labelsLeftPad}
                    passRef={this._fastEntryListRef}
                    virtualizedListRef={this._virtualizedListRef}
                >

                    {this.getFastEntryListChildren(sortedItems, isFormDisabled)}

                </FastEntryList>
                {!this.props.customAction && !this.isComparison() && (canAdd || this.props.customActionButtons || this.props.customFooterContent) && (
                    <ButtonsFooter width={contentWidth}
                                   hasMargin={!!this.canReorder}>
                        {this.props.customActionButtons && this.renderCustomActionButtons(ActionButtonPosition.Before)}
                        {canAdd &&
                            <Button
                                hotspotId={"fastEntryAdd"}
                                isTransparent={this.props.isAddButtonTransparent}
                                onClick={this.handleAdd}
                                passRef={this._buttonRef}
                                isDisabled={isFormDisabled || this.isAddDisabled}
                                hoverAlert={this.props.addDisabledAlert}>
                                {this.props.customAddButtonText ?? this.props.t("Common:General.Add")}
                            </Button>
                        }
                        {this.props.customActionButtons && this.renderCustomActionButtons(ActionButtonPosition.After)}
                        {this.props.customFooterContent}
                    </ButtonsFooter>
                )}
                {this.props.customAction && this.renderConfirmationButtons()}

            </StyledSmartFastEntryList>
        );
    };
}

export default withDomManipulator(withTranslation("Common")(SmartFastEntryList));