import React from "react";

import TestIds from "../../testIds";
import { StyledBusyIndicator, StyledBusyIndicatorWrapper } from "./BusyIndicator.styles";
import { BUSY_INDICATOR_DELAY, BusyIndicatorSize, BusyIndicatorType } from "./BusyIndicator.utils";
import { ReactComponent as BusyLSvg } from "./loaders/busyL.svg"; // origName: animace/loaders/svg/vcela_L_Kreslicí plátno 1_Kreslicí plátno 1.svg
import { ReactComponent as BusyMSvg } from "./loaders/busyM.svg"; // origName: animace/loaders/svg/vcela_M_Kreslicí plátno 1.svg
import { ReactComponent as BusyXSSvg } from "./loaders/busyXS.svg"; // origName: animace/loaders/svg/vcela_XS_Kreslicí plátno 1_Kreslicí plátno 1.svg

export interface IProps {
    size?: BusyIndicatorSize;
    type?: BusyIndicatorType;
    /** Inverse color scheme for usage on dark backgrounds */
    isInverse?: boolean;
    /** Busy indicator is not shown immediately, but after BUSY_INDICATOR_DELAY.
     * It is better for user experience to not show busy state when the action is fast enough.
     * Use in cases when we don't have to prevent user from being able to do any other action. */
    isDelayed?: boolean;
    /** BusyIndicator is supposed to overflow few pixels outside, when overlaying one component */
    isOverComponent?: boolean;
    /** Use if you don't want to show any content under the BusyIndicator
     * e.g. if there would be several BusyIndicators at once */
    isOpaque?: boolean;
    className?: string;

    customContent?: React.ReactNode;
}

interface IState {
    isVisible?: boolean;
}

export default class BusyIndicator extends React.PureComponent<IProps, IState> {
    static defaultProps: Partial<IProps> = {
        size: BusyIndicatorSize.M,
        type: BusyIndicatorType.Normal
    };

    _isMounted: boolean;
    _busyRef = React.createRef<HTMLDivElement>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            isVisible: !this.props.isDelayed
        };
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.isDelayed) {
            setTimeout(() => {
                if (this._isMounted) {
                    this.setState({
                        isVisible: true
                    });
                }
            }, BUSY_INDICATOR_DELAY);
        }

        this._busyRef.current?.dispatchEvent(
                new CustomEvent("isBusy", { bubbles: true })
        );
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    get busyAnimation(): React.ReactNode {
        if (this.props.type === BusyIndicatorType.BackgroundOnly) {
            return null;
        }

        const { size, customContent } = this.props;

        if (customContent) {
            return customContent;
        }
        switch (size) {
            case BusyIndicatorSize.L:
                return <BusyLSvg/>;
            case BusyIndicatorSize.M:
                return <BusyMSvg/>;
            case BusyIndicatorSize.XS:
                return <BusyXSSvg/>;
        }
    }

    render() {
        return (
                <StyledBusyIndicatorWrapper data-testid={TestIds.BusyIndicator}
                                            ref={this._busyRef}
                                            aria-hidden={this.state.isVisible}
                                            $isOverComponent={this.props.isOverComponent}>
                    {this.state.isVisible && (
                            <StyledBusyIndicator className={this.props.className}
                                                 $size={this.props.size}
                                                 $isInverse={this.props.isInverse}
                                                 $isWithoutBackground={this.props.type === BusyIndicatorType.WithoutBackground}
                                                 $isOpaque={this.props.isOpaque}>
                                {this.busyAnimation}
                            </StyledBusyIndicator>
                    )}
                </StyledBusyIndicatorWrapper>
        );
    }
}

