import { AssetStatusCode, AssetTypeCode } from "@odata/GeneratedEnums";
import React from "react";

import Dialog from "../../../../components/dialog";
import { PageViewMode } from "../../../../enums";
import { TableStorage } from "../../../../model/TableStorage";
import PairingFixedAssetTableView from "../../../asset/fixedAsset/PairingFixedAssetTableView";
import DialogPage from "../../../DialogPage";
import { getDefinitionsFactory } from "../../fixedAsset/FixedAssetDef";

interface IProps {
    storage: TableStorage;
    renderedTable: React.ReactElement;
    type: AssetTypeCode;
    entryIds: number[];
    onClose: () => void;
}

export default class CompleteFixedAsset extends React.Component<IProps> {

    handleClose = (): void => {
        this.props.onClose?.();
    };

    render() {
        return (
            <Dialog isEditableWindow={true}
                    onConfirm={null}
                    onClose={this.props.onClose}>

                <DialogPage
                    pageViewMode={PageViewMode.FormReadOnly}
                    rootStorage={this.props.storage}
                    tableView={PairingFixedAssetTableView}
                    tableViewProps={{
                        renderedTable: this.props.renderedTable,
                        entryIds: this.props.entryIds,
                        onClose: this.handleClose
                    }}
                    formProps={{
                        shouldHideVariant: true
                    }}
                    getDef={getDefinitionsFactory(AssetStatusCode.Created, this.props.type, true)}
                />
            </Dialog>
        );
    }
}
