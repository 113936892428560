import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { fetchItemsByInfo } from "@components/smart/smartSelect/SmartSelectAPI";
import { getNestedValue } from "@odata/Data.utils";
import { AccountEntity, EntitySetName, IAccountEntity } from "@odata/GeneratedEntityTypes";
import { AccountTypeCode, TaxApplicabilityCode } from "@odata/GeneratedEnums";
import { BatchRequest } from "@odata/OData";
import { prepareQuery } from "@odata/OData.utils";
import { isDefined, isObjectEmpty } from "@utils/general";
import { cloneDeep } from "lodash";

import BindingContext, { IEntity } from "../../odata/BindingContext";
import { IContextInitArgs, IFormStorageSaveResult } from "../../views/formView/FormStorage";
import { FormViewForExtend, IFormViewProps } from "../../views/formView/FormView";
import {
    CATEGORY_SELECT_PATH,
    CategoryItems,
    CategoryType,
    composeCategory,
    decomposeCategory,
    getMandatoryAccountsPromise,
    IChartOfAccountsFormCustomData,
    IS_INVERTIBLE_PATH,
    isBalance,
    isStatement
} from "./ChartOfAccounts.utils";
import { accountCannotBeParent } from "./ChartOfAccountsDef";

interface IProps extends IFormViewProps<IAccountEntity, IChartOfAccountsFormCustomData> {
}

/**
 * Note: This is common base class for ChartOfAccountsFormView and ChartOfAccountsTemplatesFormView,
 * keep in mind during modifications
 */
export default abstract class ChartOfAccountsBaseFormView extends FormViewForExtend<IAccountEntity, IProps> {

    protected balanceLayoutName: AccountEntity.BalanceSheetLayout = AccountEntity.BalanceSheetLayout;
    protected incomeStatementLayoutName: AccountEntity.IncomeStatementLayout = AccountEntity.IncomeStatementLayout;

    protected defaultType: AccountTypeCode;

    constructor(props: IProps) {
        super(props);

        this.onAfterLoad = this.onAfterLoad.bind(this);
    }

    isTemplateForm = (): boolean => {
        return this.props.storage.data.bindingContext.getParent().getPath(true) === EntitySetName.ChartOfAccountsTemplates;
    };

    getAdditionalLoadPromise = (args: IContextInitArgs): Promise<unknown>[] => {
        return [getMandatoryAccountsPromise(this.props.storage)];
    };

    async onAfterLoad() {
        this.splitAccountNumber();
        this.defaultType = this.props.storage.getValueByPath("Type")?.Code;
        this.setupCategory();
        this.setCorrectCategoryLabels();
        await this.setDefaultParent();
        return super.onAfterLoad();
    }

    // used here and in Labels to preselect parent based on the row that were selected when user clicked on "Add" button
    setDefaultParent = async (): Promise<void> => {
        if (!this.props.storage.data.bindingContext.isNew()) {
            return null;
        }

        const defaultParent: BindingContext = this.props.storage.getCustomData().Parent;

        if (!defaultParent) {
            return null;
        }

        const parentId = defaultParent.getKey();
        // parent key is not enough to set default value to the Parent account field,
        // we need the whole object, so that the formatter can work with it
        // => fetch accounts, find the matching one and set it as default value
        const items = await fetchItemsByInfo(this.props.storage, this.props.storage.getInfo(this.props.storage.data.bindingContext.navigate(AccountEntity.Parent)), true);
        const parentAccount = items.find((item) => item.id === parentId)?.additionalData as IAccountEntity;

        if (accountCannotBeParent(parentAccount)) {
            return null;
        }

        this.props.storage.setValueByPath(AccountEntity.Parent, parentAccount);
        await this.applyParentValues(parentId as number);

        this.props.storage.refresh();
    };

    splitAccountNumber(): void {
        const entity = this.props.storage.data.entity;
        const parent = entity.Parent;
        if (!isObjectEmpty(parent)) {
            if (entity.Number?.startsWith(parent.Number)) {
                entity.Number = entity.Number?.slice(parent.Number?.length);
            }
        }
    }

    setupCategory = (): void => {
        let categoryId = composeCategory(this.entity);

        if (!categoryId) {
            return;
        }

        const isInvertible = this.entity.IsInvertible;

        if (isInvertible) {
            categoryId = CategoryItems.BalanceLiabilityAssets;
            this.props.storage.setValueByPath(IS_INVERTIBLE_PATH, this.entity.Type?.Code);
            this.props.storage.setValueByPath(AccountEntity.TaxApplicabilityCode, null);
        }

        this.props.storage.setValueByPath(CATEGORY_SELECT_PATH, categoryId);
    };

    setCorrectCategoryLabels = (category?: string): void => {
        const _setLabel = (path: string, label: string) => {
            const bc = this.props.storage.data.bindingContext.navigate(path),
                info = this.props.storage.getInfo(bc);

            info.label = label;
        };
        category = category || this.props.storage.getValueByPath(CATEGORY_SELECT_PATH);

        if (!category) {
            return;
        }

        if (category === CategoryItems.BalanceLiabilityAssets) {
            _setLabel(`${this.balanceLayoutName}/LiabilitiesReportSection`, this.props.storage.t("ChartsOfAccounts:BalanceLayout.Liability"));
            _setLabel(`${this.balanceLayoutName}/AssetsReportSection`, this.props.storage.t("ChartsOfAccounts:BalanceLayout.Active"));
        } else if (category?.startsWith(CategoryType.Balance)) {
            _setLabel(`${this.balanceLayoutName}/LiabilitiesReportSection`, this.props.storage.t("ChartsOfAccounts:BalanceLayout.Balance"));
            _setLabel(`${this.balanceLayoutName}/AssetsReportSection`, this.props.storage.t("ChartsOfAccounts:BalanceLayout.Balance"));

        }
        // TODO: missing BE
        /*else if (category?.startsWith(CategoryType.Statement)) {
            _setLabel("BalanceSheetLayout/LiabilitiesReportSection", this.props.storage.t("ChartsOfAccounts:BalanceLayout.Statement"));
        }*/
    };

    handleCategoryChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath(true) === CATEGORY_SELECT_PATH) {
            this.decompositeCategory();
            this.setCorrectCategoryLabels(e.value as string);
        }
    };


    handleActiveChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath(true) === "IsActive") {
            if (this.props.storage.data.alert) {
                this.props.storage.refresh();
            }
        }
    };

    handleIsInvertibleChange = (e: ISmartFieldChange): void => {
        if (e.bindingContext.getPath(true) === IS_INVERTIBLE_PATH) {
            const isInvertible = this.props.storage.getValueByPath(IS_INVERTIBLE_PATH);
            this.props.storage.setValueByPath("Type", isInvertible);
        }
    };

    applyParentValues = async (parentAccountId: number): Promise<void> => {
        const { storage } = this.props;
        const { entity, bindingContext } = storage.data;

        const isNotTemplateForm = !this.isTemplateForm();
        const columns = [{ id: "Note" }, { id: "Type" }, { id: "TaxApplicabilityCode" },
            { id: "Currency" }, { id: "Category" }, { id: "Number" },
            { id: `${this.balanceLayoutName}/AssetsReportSection/Code` },
            { id: `${this.balanceLayoutName}/IsAssetsCorrection` },
            { id: `${this.balanceLayoutName}/LiabilitiesReportSection/Code` },
            { id: `${this.incomeStatementLayoutName}/ByNatureReportSection/Code` },
            { id: `${this.incomeStatementLayoutName}/ByFunctionReportSection/Code` },
            { id: "IsInvertible" }, { id: "IsActive" }];

        if (isNotTemplateForm) {
            columns.push({ id: "IsClosed" });
        }

        let parent: IAccountEntity = null;
        if (parentAccountId) {
            const parentBc = bindingContext.removeKey().addKey(parentAccountId);
            const query = await prepareQuery({
                oData: storage.oData,
                bindingContext: parentBc,
                fieldDefs: columns
            }).fetchData<IAccountEntity>();

            parent = query?.value;
        }

        storage.setCustomData({
            isParentInvertible: parent?.IsInvertible
        });

        if (parent) {
            columns.forEach(col => {
                // Number is set as Parent/Number, don't propagate it to child
                if (col.id === AccountEntity.Number) {
                    return;
                }
                // IsActive changes only if current active moves under inactive parent
                if (parent && col.id === AccountEntity.IsActive && !parent.IsActive && entity.IsActive) {
                    return;
                }
                // IsClosed changes only if current open moved under closed parent
                if (parent && col.id === AccountEntity.IsClosed && parent.IsClosed && !entity.IsClosed) {
                    return;
                }

                let bc = bindingContext.navigate(col.id);
                // for correct error and current value clearing we need proper bc to navigation property not code itself (value is stored in both values to code,
                // but current value is stored in additional data directly which is not working for code BC
                if (bc.getPath(true) === "Code") {
                    bc = bc.getParent();
                }
                this.props.storage.clearAndSetValue(bc, getNestedValue(col.id, parent));
            });
        }

        this.setupCategory();
        const category = storage.getValueByPath(CATEGORY_SELECT_PATH);
        this.setCorrectCategoryLabels(category);
        storage.setValueByPath("Parent/Number", parent?.Number);
    }

    handleParentChange = async (e: ISmartFieldChange): Promise<void> => {
        if (e.bindingContext.getPath(true) === "Parent" && e.triggerAdditionalTasks) {
            const { storage } = this.props;
            let parentBc: BindingContext = null;
            if (isDefined(e.value)) {
                parentBc = storage.data.bindingContext.removeKey().addKey(e.value as number);
            } else {
                storage.clearValueByPath("Parent");
            }
            await this.applyParentValues(e.value as number);
            // change Parent and rerender table to change position of the new row
            storage.setCustomData({
                Parent: parentBc
            });
            this.props.onTableRefreshNeeded({
                modelRefreshOnly: true
            });
            this.props.storage.refresh();
        }

    };

    handleChange = async (e: ISmartFieldChange): Promise<void> => {
        this.props.storage.handleChange(e);
        this.props.storage.refreshFields(e.triggerAdditionalTasks);

        this.handleActiveChange(e);
        this.handleCategoryChange(e);
        this.handleIsInvertibleChange(e);
        this.handleParentChange(e);
    };

    decompositeCategory = (): void => {
        const { storage } = this.props;
        const category = storage.getValueByPath(CATEGORY_SELECT_PATH);

        if (category) {
            const items = decomposeCategory(category);

            storage.setValueByPath("Category", items.categoryCode);
            if (category === CategoryItems.BalanceLiabilityAssets) {
                let assetValue = storage.getValueByPath(BindingContext.localContext("IsInvertible"));
                if (!assetValue) {
                    assetValue = AccountTypeCode.Assets;
                    storage.setValueByPath(BindingContext.localContext("IsInvertible"), AccountTypeCode.Assets);
                }
                storage.setValueByPath("Type", assetValue);
            } else {
                const newTypeCode = items.typeCode;

                if (newTypeCode !== this.entity.Type?.Code) {
                    // select items are different between Income/Expense
                    // => reset currently selected values
                    storage.clearAndSetValueByPath(`${this.incomeStatementLayoutName}/ByNatureReportSection`, null);
                    storage.clearAndSetValueByPath(`${this.incomeStatementLayoutName}/ByNatureReportSectionCode`, null);
                    storage.clearAndSetValueByPath(`${this.incomeStatementLayoutName}/ByFunctionReportSection`, null);
                    storage.clearAndSetValueByPath(`${this.incomeStatementLayoutName}/ByFunctionReportSectionCode`, null);
                }

                storage.setValueByPath("Type", newTypeCode);
            }

            storage.setValueByPath("IsInvertible", category === CategoryItems.BalanceLiabilityAssets);
            storage.setValueByPath(AccountEntity.TaxApplicabilityCode, items.isTaxApplicable ? TaxApplicabilityCode.TaxApplicable : TaxApplicabilityCode.NotTaxApplicable);
        }
    };

    getLayoutEntityType = (): AccountEntity.IncomeStatementLayout | AccountEntity.BalanceSheetLayout => {
        const category = this.props.storage.getValueByPath("Category", { useDirectValue: false });
        if (category === CategoryType.Statement) {
            return this.incomeStatementLayoutName;
        }
        if (category === CategoryType.Balance) {
            return this.balanceLayoutName;
        }
        return null;
    };

    clearUnusedLayouts = (entity: IEntity): void => {
        const category = this.props.storage.getValueByPath("Category", { useDirectValue: false });
        const { storage } = this.props;
        const type = storage.getValueByPath("Type/Code");

        if (category !== CategoryType.Balance) {
            entity[this.balanceLayoutName] = {};
        }
        if (category !== CategoryType.Statement) {
            entity[this.incomeStatementLayoutName] = {};
        }

        if (isBalance(storage)) {
            const isInvertible = this.props.storage.getValueByPath("IsInvertible");
            if (!isInvertible && entity[this.balanceLayoutName]) {
                if (type === AccountTypeCode.Assets) {
                    delete entity[this.balanceLayoutName].LiabilitiesReportSection;
                    entity[this.balanceLayoutName].LiabilitiesReportSectionCode = null;
                }

                if (type === AccountTypeCode.Liabilities) {
                    delete entity[this.balanceLayoutName].AssetsReportSection;
                    entity[this.balanceLayoutName].AssetsReportSectionCode = null;
                }
            }
        }
    };

    prepareDataForSave = (data: IAccountEntity): IAccountEntity => {
        const saveData = cloneDeep(this.props.storage.data.entity);
        const layoutEntityType = this.getLayoutEntityType();

        if (saveData.Parent?.Number) {
            saveData.Number = saveData.Parent?.Number + saveData.Number;
        }

        if (!isStatement(this.props.storage)) {
            saveData.TaxApplicabilityCode = null;
        }

        this.clearUnusedLayouts(saveData);

        // ChartOfAccounts is required to create new layout entity
        if (layoutEntityType && !saveData[layoutEntityType]?.Id) {
            if (!saveData[layoutEntityType]) {
                saveData[layoutEntityType] = {};
            }

            saveData[layoutEntityType]["ChartOfAccounts"] = { Id: this.props.storage.data.bindingContext.getParent().getKey() as number };
        }
        return saveData;
    };

    removeOldType = async (batch: BatchRequest): Promise<void> => {
        const type = this.props.storage.getValueByPath("Type/Code");
        const statementTypes = [AccountTypeCode.Expense, AccountTypeCode.Income];
        const oldType = statementTypes.includes(this.defaultType) ? CategoryType.Statement : CategoryType.Balance;
        const currentType = statementTypes.includes(type) ? CategoryType.Statement : CategoryType.Balance;

        if (oldType && oldType !== currentType) {
            const propName = oldType === CategoryType.Balance ? this.balanceLayoutName : this.incomeStatementLayoutName;
            const id = this.props.storage.getValueByPath(propName)?.Id;
            const entitySet = `${propName}${this.isTemplateForm() ? "Template" : ""}s`;
            if (id) {
                // delete has to precede PATCH request for backend to accept the changes => index 0
                batch.getEntitySetWrapper(entitySet as EntitySetName).delete(id, { index: 0 });

            }
        }
    };

    _save = async (subTitle: string): Promise<IFormStorageSaveResult> => {
        this.props.onBeforeSave();

        const isNew = this.props.storage.data.bindingContext.isNew();
        const preparedData = this.prepareDataForSave(this.props.storage.data.entity);

        const result = await this.props.storage.save({
            data: preparedData,
            successSubtitle: subTitle,
            updateEnabledFor: [`Accounts/${this.balanceLayoutName}`, `Accounts/${this.incomeStatementLayoutName}`],
            onBeforeExecute: this.removeOldType
        });

        if (!this._isMounted) {
            return null;
        }

        if (!result) {
            this.props.onSaveFail?.();
            this.forceUpdate(this.scrollPageUp);
            return result;
        }

        this.props.storage.displaySaveOkMessage(subTitle);
        this.onAfterSave(isNew, false);
        this.forceUpdate(isNew ? this.scrollPageDown : undefined);

        return result;
    };
}
