import { mix } from "polished";
import styled, { css } from "styled-components/macro";

import { Status } from "../../enums";
import {
    fadeInAnimation,
    getSemanticElColor,
    getSemanticTextColor,
    LayoutStackOrder,
    T_TITLE_5
} from "../../global.style";

export const TooltipHorizontalPadding = 10;
export const TooltipPaddingFromPage = 19;

interface ITooltip {
    noBackground?: boolean;
    $status?: Status;
}

export const StyledTooltip = styled.div<ITooltip>`
    ${fadeInAnimation};
    font-size: 10px;
    width: fit-content;
    min-width: min-content;
    max-width: 400px;
    z-index: ${LayoutStackOrder.Tooltips};
    white-space: pre-wrap;
    ${props => !props.noBackground && css`
        background-color: ${props.theme.C_BG_tooltip};
        color: ${props.theme.C_TEXT_primary};
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.24);
        border-radius: 3px;
        padding: 3px ${TooltipHorizontalPadding}px;
    `};

    ${props => props.$status && css<ITooltip>`
        ${T_TITLE_5};
        color: ${props => getSemanticTextColor(props.theme, props.$status)};
        background-color: ${props => mix(0.2, getSemanticElColor(props.theme, props.$status), props.theme.C_BG_menu)};
    `};
`;