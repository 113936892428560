import { mix, transparentize } from "polished";
import { DefaultTheme, ThemeProps } from "styled-components/macro";

import waveMountain from "../src/static/images/waveMountain.svg"; // origName: vlnky/vlnky_jednoduché/SVG/menu_illustration_vlnka_jednoducha-hory.svg
import waveMountainComplex from "../src/static/images/waveMountainComplex.svg"; // origName: vlnky/vlnky_jednoduché/SVG/menu_illustration_vlnka_jednoducha-hory-snih.svg
import waveMountainPanorama from "../src/static/images/waveMountainPanorama.svg"; // origName: vlnky/vlnky_jednoduché/SVG/menu_illustration_vlnka_jednoducha_Prepojenie s iDoklad.svg
import waveSimple from "../src/static/images/waveSimple.svg"; // origName: vlnky/vlnky_jednoduché/SVG/menu_illustration_vlnka_jednoducha-zaklad.svg

declare module "styled-components" {
    // extend styled-components DefaultTheme to receive correct auto suggest in props.theme
    export interface DefaultTheme {
        C_MAIN_brand_gradient_start: string;
        C_MAIN_brand_gradient_end: string;
        C_MAIN_brand_gradient: string;
        C_MAIN_brand_gradient_inactive_company: string;

        C_BG_shellbar_grad_start: string;
        C_BG_shellbar_grad_end: string;
        C_BG_shellbar: string;
        C_BG_shellbar_inactive_company: string;
        C_BG_shellbar_notification_alert: string;
        C_BG_progressbar: string;
        C_BG_progressbar_semantic: string;
        C_BG_progresswheel: string;
        C_BG_progresswheel_semantic: string;
        C_BG_progresswheeel_gray: string;
        C_BG_card: string;
        C_BG_menu: string;
        C_BG_selected_color: string,
        C_BG_floorplan: string;
        C_BG_floorplan_inactive_company: string;
        C_BG_stripes: string;
        C_BG_dialogue: string;
        C_BG_popover: string;
        C_BG_active_hotspot: string;
        C_BG_tooltip: string;
        C_BG_info: string;
        C_BG_info_arrow: string;
        C_BG_filterbar: string;
        C_BG_row_field: string;
        C_BG_filestrip: string;
        C_BG_row_field_hover: string;
        C_TEXT_bg_DnD: string;
        C_ACT_main: string;
        C_ACT_main_dark: string;
        C_ACT_der: string;
        C_ACT_hover: string;
        C_ACT_hover_without_opacity: string;
        C_ACT_thick_line: string;
        C_ACT_thick_line_hover: string;
        C_ACT_light_line: string;

        C_BTN_4L_emph_bg: string;
        C_BTN_4L_emph_line_color: string;
        C_BTN_4L_emph_line_size: string;
        C_BTN_4L_emph_text: string;
        C_BTN_4L_emph_bg_hover: string;
        C_BTN_4L_emph_line_color_hover: string;
        C_BTN_4L_emph_line_size_hover: string;
        C_BTN_4L_emph_text_hover: string;
        C_BTN_4L_emph_bg_selected: string;
        C_BTN_4L_emph_line_color_selected: string;
        C_BTN_4L_emph_line_size_selected: string;
        C_BTN_4L_emph_text_selected: string;

        C_BTN_4D_emph_bg: string;
        C_BTN_4D_emph_line_color: string;
        C_BTN_4D_emph_line_size: string;
        C_BTN_4D_emph_text: string;
        C_BTN_4D_emph_bg_hover: string;
        C_BTN_4D_emph_line_color_hover: string;
        C_BTN_4D_emph_line_size_hover: string;
        C_BTN_4D_emph_text_hover: string;
        C_BTN_4D_emph_bg_selected: string;
        C_BTN_4D_emph_line_color_selected: string;
        C_BTN_4D_emph_line_size_selected: string;
        C_BTN_4D_emph_text_selected: string;

        C_BTN_4L_trans_bg: string;
        C_BTN_4L_trans_line_color: string;
        C_BTN_4L_trans_line_size: string;
        C_BTN_4L_trans_text: string;
        C_BTN_4L_trans_bg_hover: string;
        C_BTN_4L_trans_line_color_hover: string;
        C_BTN_4L_trans_line_size_hover: string;
        C_BTN_4L_trans_text_hover: string;
        C_BTN_4L_trans_bg_selected: string;
        C_BTN_4L_trans_line_color_selected: string;
        C_BTN_4L_trans_line_size_selected: string;
        C_BTN_4L_trans_text_selected: string;

        C_BTN_4D_trans_bg: string;
        C_BTN_4D_trans_line_color: string;
        C_BTN_4D_trans_line_size: string;
        C_BTN_4D_trans_text: string;
        C_BTN_4D_trans_bg_hover: string;
        C_BTN_4D_trans_line_color_hover: string;
        C_BTN_4D_trans_line_size_hover: string;
        C_BTN_4D_trans_text_hover: string;
        C_BTN_4D_trans_bg_selected: string;
        C_BTN_4D_trans_line_color_selected: string;
        C_BTN_4D_trans_line_size_selected: string;
        C_BTN_4D_trans_text_selected: string;

        C_BTN_hover_dark: string;
        C_BTN_hover_light: string;

        C_LAB_grey: string;
        C_LAB_yellow: string;
        C_LAB_green: string;
        C_LAB_blue: string;
        C_LAB_purple: string;
        C_LAB_orange: string;

        C_SEM_el_good: string;
        C_SEM_el_warning: string;
        C_SEM_el_bad: string;
        C_SEM_el_good_inv: string;
        C_SEM_el_warning_inv: string;
        C_SEM_el_bad_inv: string;
        C_SEM_el_neutral: string;
        C_SEM_text_good: string;
        C_SEM_text_warning: string;
        C_SEM_text_bad: string;
        C_SEM_el_good_hover: string;
        C_SEM_el_bad_hover: string;
        C_SEM_text_good_hover: string;
        C_SEM_text_bad_hover: string;
        C_SEM_el_highlighted: string;

        // Semantic colors bg opacity - based on C_SEM_el_good, C_SEM_el_warning, C_SEM_el_bad
        C_SEM_opacity_bg_color: string;
        C_SEM_opacity_validation: string;

        // todo: define universal grey shades
        C_GREY_x: string;

        C_DSH_border: string;

        // todo: rename to defined color by UX (black line in logo, white in dark mode)
        C_EVALA_LOGO: string;

        C_TEXT_primary: string;
        C_TEXT_dialogue_title: string;
        C_TEXT_dialogue: string;
        C_TEXT_purple_bg: string;
        C_TEXT_selection: string;
        C_TEXT_link: string;
        C_TEXT_link_hover: string;
        C_TEXT_link_selected: string;
        C_TEXT_link_light: string;
        C_TEXT_link_hover_light: string;
        C_TEXT_link_selected_light: string;

        C_ICON_primary_dark: string;
        C_ICON_secondary_dark: string;
        C_ICON_tertiary_dark: string;
        C_ICON_primary_light: string;
        C_ICON_primary_light_hover: string;
        C_ICON_secondary_light: string;
        C_ICON_tertiary_light: string;
        C_ICON_done: string;

        C_ICON_document: string;

        C_ICON_MENU_main: string;
        C_ICON_MENU_stroke: string;
        C_ICON_MENU_fill: string;

        C_ICON_MENU_grad_start?: string,
        C_ICON_MENU_grad_end?: string,

        C_ICON_OBJECT_PAGE_main: string;

        C_ICON_STATUS_INDICATOR_stroke: string;

        C_BUSY_INVERSE: string;

        C_FIELD_fill: string;
        C_FIELD_line: string;
        C_FIELD_line_der: string;
        C_FIELD_sync: string;
        C_FIELD_section_line: string,

        C_GRD_blue: string;
        C_GRD_red: string;
        C_GRD_pink: string;
        C_GRD_purple: string;

        /* Chart colors */
        C_CHART_purple: string;
        C_CHART_blue: string;
        C_CHART_green: string;
        C_CHART_yellow: string;
        C_CHART_red: string;
        C_CHART_orange: string;
        C_CHART_grey: string;

        /* not in definition file*/
        C_DEF_tableBorder: string;
        C_LINE_variants: string;
        C_BTN_border_hover: string;
        C_SPLIT_LAYOUT_BUBBLE: string;
        C_FORM_GROUP_HEADER_LINE: string;
        C_SPLIT_LAYOUT_COLLAPSED_HOVER: string;
        C_SPLIT_LAYOUT_COLLAPSED_GRADIENT_TOP: string;
        C_SPLIT_LAYOUT_COLLAPSED_GRADIENT_BOTTOM: string;

        C_SELECT_SEARCH_INPUT_FOCUS: string;
        C_SELECT_SEARCH_INPUT_BACKGROUND: string;

        C_HIERARCHICAL_SELECT_LINE: string;

        C_AVATAR_BG_LIGHT: string;
        C_AVATAR_BG_DARK: string;
        C_BG_INACTIVE_COMPANY: string;
        INACTIVE_COMPANY_transparentize: string;

        C_AUDIT_TRAIL_DIFF: string;
        C_AUDIT_TRAIL_NO_DIFF: string;
        C_AUDIT_TRAIL_DIFF_VISIBLE_OPACITY: string;

        C_BG_ACCOUNT_PROGRESS_BG: string;

        borderRadius: string;
        borderLargeRadius: string;
        transition_transform_time: string;
        disabled_opacity: string;
        dashboard_tile_shadow_opacity: string;
        shadow_opacity: string;
        lightFieldGroupOpacity: string;

        C_CUST_DASHBOARD_TILE_BG: string;
        C_CUST_DASHBOARD_TILE_BG_OPACITY: string;
        C_CUST_DASHBOARD_TILE_BORDER: string;
        C_CUST_DASHBOARD_TILE_BORDER_OPACITY: string;

        C_DASHBOARD_TILE_PLACEHOLDER_BORDER: string;
        C_DASHBOARD_TILE_PLACEHOLDER_BG: string;

        F_wave: string;
        F_wave_mountain: string;
        F_wave_mountain_complex: string;
        F_wave_mountain_panorama: string;

        DEV_TOOLS_link: string;
    }
}

const defaultConfig: DefaultTheme = {
    C_MAIN_brand_gradient_start: "#4701bc",
    C_MAIN_brand_gradient_end: "#df0552",
    get C_MAIN_brand_gradient(): string {
        return `linear-gradient(to right, ${this.C_MAIN_brand_gradient_start}, ${this.C_MAIN_brand_gradient_end})`;
    },
    C_MAIN_brand_gradient_inactive_company: "linear-gradient(to right,#4701bc8c,#df05528c)",

    C_BG_shellbar_grad_start: "#7a0399",
    C_BG_shellbar_grad_end: "#b10372",
    get C_BG_shellbar() {
        return `linear-gradient(to top, ${this.C_BG_shellbar_grad_start}, ${this.C_BG_shellbar_grad_end})`;
    },
    C_BG_shellbar_inactive_company: "linear-gradient(to top,#7a0399cc,#b10372d4)",
    C_BG_shellbar_notification_alert: "linear-gradient(to top, #790399, #7b0398 95%, #9a0382, #b10372)",
    C_BG_progressbar: "linear-gradient(to right, #b10372, #7a0399)",
    C_BG_progressbar_semantic: "linear-gradient(to right, #d42e3c, #6db392)",
    C_BG_progresswheel: "conic-gradient(from 0.25turn, #b10372, #7a0399)",
    C_BG_progresswheel_semantic: "conic-gradient(from 0.25turn, #d32e3c, #6db292)",
    C_BG_progresswheeel_gray: "#dbdada",
    C_BG_card: "linear-gradient(to right, #7a0399 0%, #b10372 100%)",

    C_BG_menu: "#fff",
    C_BG_selected_color: "#fff",
    C_BG_floorplan: "#fafafa",
    C_BG_floorplan_inactive_company: "#fafafa",
    C_BG_stripes: "#ebe9e9",
    C_BG_dialogue: "linear-gradient(11deg, #92296f 10%, #9e037f 90%)",
    C_BG_popover: "linear-gradient(to top, #92296f, #9e037f)",
    C_BG_active_hotspot: "#202737",
    C_BG_tooltip: "#f2e1eb",
    C_BG_info: "#202737, #2a344a",
    C_BG_info_arrow: "#2a344a",
    C_BG_filterbar: "#690048",
    C_BG_row_field: "#fff",
    C_BG_filestrip: "rgba(168, 69, 140, 0.22)",
    C_TEXT_bg_DnD: "#c83179",

    C_ACT_main: "#690048",
    C_ACT_main_dark: "#b776a3",
    C_ACT_der: "#a8458c",
    C_ACT_hover: "rgba(242, 225, 235, 0.2)",
    C_ACT_hover_without_opacity: "#f2e1eb",
    C_ACT_thick_line: "#a7a6a7",
    C_ACT_thick_line_hover: "rgba(167, 166, 167, 0.5)",
    C_ACT_light_line: "#fff",

    // combination of row background with hover color that has opacity
    get C_BG_row_field_hover() {
        return mix(0.2, this.C_ACT_hover_without_opacity, this.C_BG_row_field) as string;
    },

    C_BTN_4L_emph_bg: "#690048",
    C_BTN_4L_emph_line_color: "transparent",
    C_BTN_4L_emph_line_size: "0",
    C_BTN_4L_emph_text: "#fff",
    C_BTN_4L_emph_bg_hover: "#690048",
    C_BTN_4L_emph_line_color_hover: "transparent",
    C_BTN_4L_emph_line_size_hover: "0",
    C_BTN_4L_emph_text_hover: "#f1add5",
    C_BTN_4L_emph_bg_selected: "#54063b",
    C_BTN_4L_emph_line_color_selected: "transparent",
    C_BTN_4L_emph_line_size_selected: "0",
    C_BTN_4L_emph_text_selected: "#fff",

    C_BTN_4D_emph_bg: "transparent",
    C_BTN_4D_emph_line_color: "#fff",
    C_BTN_4D_emph_line_size: "1",
    C_BTN_4D_emph_text: "#fff",
    C_BTN_4D_emph_bg_hover: "transparent",
    C_BTN_4D_emph_line_color_hover: "#f1add5",
    C_BTN_4D_emph_line_size_hover: "1",
    C_BTN_4D_emph_text_hover: "#f1add5",
    C_BTN_4D_emph_bg_selected: "rgba(0, 0, 0, 0.3)",
    C_BTN_4D_emph_line_color_selected: "#fff",
    C_BTN_4D_emph_line_size_selected: "1",
    C_BTN_4D_emph_text_selected: "#fff",

    C_BTN_4L_trans_bg: "transparent",
    C_BTN_4L_trans_line_color: "transparent",
    C_BTN_4L_trans_line_size: "0",
    C_BTN_4L_trans_text: "#690048",
    C_BTN_4L_trans_bg_hover: "transparent",
    C_BTN_4L_trans_line_color_hover: "#f2e1eb",
    C_BTN_4L_trans_line_size_hover: "2",
    C_BTN_4L_trans_text_hover: "#690048",
    C_BTN_4L_trans_bg_selected: "#f2e1eb",
    C_BTN_4L_trans_line_color_selected: "transparent",
    C_BTN_4L_trans_line_size_selected: "0",
    C_BTN_4L_trans_text_selected: "#690048",

    C_BTN_4D_trans_bg: "transparent",
    C_BTN_4D_trans_line_color: "transparent",
    C_BTN_4D_trans_line_size: "0",
    C_BTN_4D_trans_text: "#fff",
    C_BTN_4D_trans_bg_hover: "transparent",
    C_BTN_4D_trans_line_color_hover: "transparent",
    C_BTN_4D_trans_line_size_hover: "0",
    C_BTN_4D_trans_text_hover: "#f1add5",
    C_BTN_4D_trans_bg_selected: "rgba(0, 0, 0, 0.3)",
    C_BTN_4D_trans_line_color_selected: "transparent",
    C_BTN_4D_trans_line_size_selected: "0",
    C_BTN_4D_trans_text_selected: "#fff",

    C_BTN_hover_dark: "#fff",
    C_BTN_hover_light: "#000",

    C_LAB_grey: "#dfdede",
    C_LAB_yellow: "#faefb5",
    C_LAB_green: "#daeccb",
    C_LAB_blue: "#d4edf8",
    C_LAB_purple: "#dccbe8",
    C_LAB_orange: "#ecd0cb",

    C_SEM_el_good: "#53b68f",
    C_SEM_el_warning: "#f58920",
    C_SEM_el_bad: "#e60434",
    C_SEM_el_good_inv: "#b1e928",
    C_SEM_el_warning_inv: "#ffa55f",
    C_SEM_el_bad_inv: "#e5396b",
    C_SEM_el_neutral: "#202737",
    C_SEM_text_good: "#4a9b7b",
    C_SEM_text_warning: "#da7513",
    C_SEM_text_bad: "#c5173c",
    C_SEM_el_good_hover: "#84caae",
    C_SEM_el_bad_hover: "#eb4d6e",
    C_SEM_text_good_hover: "#7eb7a0",
    C_SEM_text_bad_hover: "#d45a74",
    C_SEM_el_highlighted: "#a8458c",

    C_SEM_opacity_bg_color: "0.35",
    C_SEM_opacity_validation: "0.15",

    C_GREY_x: "#cecece",

    C_DSH_border: mix(0.5, "#4b4a4b", "#fff"),

    C_EVALA_LOGO: "#1e1e1e",

    C_TEXT_primary: "#4b4a4b",
    C_TEXT_dialogue_title: "#f2e1eb",
    C_TEXT_dialogue: "#fff",
    C_TEXT_purple_bg: "#fff",
    C_TEXT_selection: "#f2e1eb",
    C_TEXT_link: "#683fac",
    C_TEXT_link_hover: "#711AFF",
    C_TEXT_link_selected: "#4511A6",
    C_TEXT_link_light: "#9082a7",
    C_TEXT_link_hover_light: "#C6B3E6",
    C_TEXT_link_selected_light: "#585066",

    C_ICON_primary_dark: "#771849",
    C_ICON_secondary_dark: "#98436D",
    C_ICON_tertiary_dark: "#E50041",
    C_ICON_primary_light: "#fff",
    C_ICON_primary_light_hover: "#f1add5",
    C_ICON_secondary_light: "#ddcad1",
    C_ICON_tertiary_light: "#e50041",
    C_ICON_done: "#48484c",

    C_ICON_document: "#67154A",

    C_ICON_MENU_main: "#4c4a4b",
    C_ICON_MENU_stroke: "#b21373",
    C_ICON_MENU_fill: "#b21373",
    get C_ICON_MENU_grad_start() {
        return this.C_BG_shellbar_grad_start;
    },
    get C_ICON_MENU_grad_end() {
        return this.C_BG_shellbar_grad_end;
    },

    C_ICON_OBJECT_PAGE_main: "#67154a",

    C_ICON_STATUS_INDICATOR_stroke: "#4b4a4b",
    C_BUSY_INVERSE: "#A0628B",

    C_FIELD_fill: "#fff",
    C_FIELD_line: "#dbdada",
    C_FIELD_line_der: "#eeeded",
    C_FIELD_sync: "#f2f2f2",
    C_FIELD_section_line: "#ebe9e9",

    C_DEF_tableBorder: "#f5f5f5",
    C_LINE_variants: "#fafafa",
    C_BTN_border_hover: "#9e037f",
    C_SPLIT_LAYOUT_BUBBLE: "#f2f2f2",
    C_FORM_GROUP_HEADER_LINE: "#eae9e9",
    C_SPLIT_LAYOUT_COLLAPSED_HOVER: transparentize(0.97, "#000"),
    C_SPLIT_LAYOUT_COLLAPSED_GRADIENT_TOP: "#a8458c",
    C_SPLIT_LAYOUT_COLLAPSED_GRADIENT_BOTTOM: "#ededed",

    C_AUDIT_TRAIL_DIFF: "#b1e928",
    C_AUDIT_TRAIL_NO_DIFF: "#eeeded",
    C_AUDIT_TRAIL_DIFF_VISIBLE_OPACITY: "0.6",

    C_SELECT_SEARCH_INPUT_FOCUS: "#a23086",
    C_SELECT_SEARCH_INPUT_BACKGROUND: "#ad4592",

    C_GRD_blue: "#4901bb",
    C_GRD_red: "#de0553",
    C_GRD_pink: "#a1037d",
    C_GRD_purple: "#850391",

    C_CHART_purple: "#b495c9",
    C_CHART_blue: "#9fcfe3",
    C_CHART_green: "#aacc8f",
    C_CHART_yellow: "#f2df7e",
    C_CHART_red: "#d97184",
    C_CHART_orange: "#edac80",
    C_CHART_grey: "#c0c0c0",

    C_HIERARCHICAL_SELECT_LINE: "#f2e1eb",
    C_AVATAR_BG_LIGHT: "#DDDDDD",
    C_AVATAR_BG_DARK: "#232323",
    C_BG_INACTIVE_COMPANY: "#f3e9ee",
    INACTIVE_COMPANY_transparentize: "0.5",

    C_BG_ACCOUNT_PROGRESS_BG: "#918998",

    borderRadius: "3px",
    borderLargeRadius: "17px",
    transition_transform_time: "0.3s",
    disabled_opacity: "0.5",
    dashboard_tile_shadow_opacity: "0.18",
    shadow_opacity: "0.18",
    lightFieldGroupOpacity: "0.05",

    C_CUST_DASHBOARD_TILE_BG: "#fff",
    C_CUST_DASHBOARD_TILE_BG_OPACITY: "0.15",
    C_CUST_DASHBOARD_TILE_BORDER: "#000",
    C_CUST_DASHBOARD_TILE_BORDER_OPACITY: "0.5",

    C_DASHBOARD_TILE_PLACEHOLDER_BG: "#7D737A",
    C_DASHBOARD_TILE_PLACEHOLDER_BORDER: "#723A62",

    F_wave: waveSimple,
    F_wave_mountain: waveMountain,
    F_wave_mountain_complex: waveMountainComplex,
    F_wave_mountain_panorama: waveMountainPanorama,

    DEV_TOOLS_link: "#0000EE"
};

const darkConfig: DefaultTheme = {
    C_MAIN_brand_gradient_start: "#9155f1",
    C_MAIN_brand_gradient_end: "#df0552",
    get C_MAIN_brand_gradient(): string {
        return `linear-gradient(to right, ${this.C_MAIN_brand_gradient_start}, ${this.C_MAIN_brand_gradient_end})`;
    },
    C_MAIN_brand_gradient_inactive_company: "linear-gradient(to right, #9155f1, #df0552)",

    C_BG_shellbar_grad_start: "#7a0399",
    C_BG_shellbar_grad_end: "#b10372",
    get C_BG_shellbar() {
        return `linear-gradient(to top, ${this.C_BG_shellbar_grad_start}, ${this.C_BG_shellbar_grad_end})`;
    },
    C_BG_shellbar_inactive_company: "linear-gradient(to top, #7a0399, #b10372)",
    C_BG_shellbar_notification_alert: "linear-gradient(to top, #790399, #7b0398 95%, #9a0382, #b10372)",
    C_BG_progressbar: "linear-gradient(to right, #b10372, #7a0399)",
    C_BG_progressbar_semantic: "linear-gradient(to right, #6db392, #d42e3c)",
    C_BG_progresswheel: "conic-gradient(from 0.25turn, #b10372, #7a0399)",
    C_BG_progresswheel_semantic: "conic-gradient(from 0.25turn, #d32e3c, #6db292)",
    C_BG_progresswheeel_gray: "#dbdada",
    C_BG_card: "linear-gradient(to right, #7a0399 0%, #b10372 100%)",

    C_BG_menu: "#282f3f",
    C_BG_selected_color: "#fff",
    C_BG_floorplan: "#202737",
    C_BG_floorplan_inactive_company: "#000",
    C_BG_stripes: "#1a202d",
    C_BG_dialogue: "linear-gradient(349deg, #92296f 10%, #9e037f 90%)",
    C_BG_popover: "linear-gradient(to top, #92296f, #9e037f)",
    C_BG_active_hotspot: "#dddddd",
    C_BG_tooltip: "#41475a",
    C_BG_info: "#dddddd, #ffffff",
    C_BG_info_arrow: "#ffffff",
    C_BG_filterbar: "#9D6F8F",
    C_BG_row_field: "#282f3f",
    C_BG_filestrip: "rgba(204, 149, 194, 0.22)",
    C_TEXT_bg_DnD: "#da3684",

    get C_BG_row_field_hover() {
        return mix(0.5, this.C_ACT_hover_without_opacity, this.C_BG_row_field);
    },

    C_ACT_main: "#b776a3",
    C_ACT_main_dark: "#690048",
    C_ACT_der: "#CC95C2",
    C_ACT_hover: "rgba(65, 71, 90, 0.5)",
    C_ACT_hover_without_opacity: "#41475A",
    C_ACT_thick_line: "#a7a6a7",
    C_ACT_thick_line_hover: "#a7a6a7",
    C_ACT_light_line: "#fff",

    C_BTN_4L_emph_bg: "#966888",
    C_BTN_4L_emph_line_color: "transparent",
    C_BTN_4L_emph_line_size: "0",
    C_BTN_4L_emph_text: "#000",
    C_BTN_4L_emph_bg_hover: "#966888",
    C_BTN_4L_emph_line_color_hover: "transparent",
    C_BTN_4L_emph_line_size_hover: "0",
    C_BTN_4L_emph_text_hover: "#3e4455",
    C_BTN_4L_emph_bg_selected: "#b776a3",
    C_BTN_4L_emph_line_color_selected: "transparent",
    C_BTN_4L_emph_line_size_selected: "0",
    C_BTN_4L_emph_text_selected: "#000",

    C_BTN_4D_emph_bg: "transparent",
    C_BTN_4D_emph_line_color: "#000",
    C_BTN_4D_emph_line_size: "1",
    C_BTN_4D_emph_text: "#000",
    C_BTN_4D_emph_bg_hover: "transparent",
    C_BTN_4D_emph_line_color_hover: "#3e4455",
    C_BTN_4D_emph_line_size_hover: "1",
    C_BTN_4D_emph_text_hover: "#3e4455",
    C_BTN_4D_emph_bg_selected: "rgba(255, 255, 255, 0.15)",
    C_BTN_4D_emph_line_color_selected: "#000",
    C_BTN_4D_emph_line_size_selected: "1",
    C_BTN_4D_emph_text_selected: "#000",

    C_BTN_4L_trans_bg: "transparent",
    C_BTN_4L_trans_line_color: "transparent",
    C_BTN_4L_trans_line_size: "0",
    C_BTN_4L_trans_text: "#b776a3",
    C_BTN_4L_trans_bg_hover: "transparent",
    C_BTN_4L_trans_line_color_hover: "#41475A",
    C_BTN_4L_trans_line_size_hover: "2",
    C_BTN_4L_trans_text_hover: "#b776a3",
    C_BTN_4L_trans_bg_selected: "#41475A",
    C_BTN_4L_trans_line_color_selected: "transparent",
    C_BTN_4L_trans_line_size_selected: "0",
    C_BTN_4L_trans_text_selected: "#b776a3",

    C_BTN_4D_trans_bg: "transparent",
    C_BTN_4D_trans_line_color: "transparent",
    C_BTN_4D_trans_line_size: "0",
    C_BTN_4D_trans_text: "#000",
    C_BTN_4D_trans_bg_hover: "transparent",
    C_BTN_4D_trans_line_color_hover: "transparent",
    C_BTN_4D_trans_line_size_hover: "0",
    C_BTN_4D_trans_text_hover: "#3e4455",
    C_BTN_4D_trans_bg_selected: "rgba(255, 255, 255, 0.15)",
    C_BTN_4D_trans_line_color_selected: "transparent",
    C_BTN_4D_trans_line_size_selected: "0",
    C_BTN_4D_trans_text_selected: "#000",

    C_BTN_hover_dark: "#000",
    C_BTN_hover_light: "#fff",

    C_LAB_grey: "#5c5959",
    C_LAB_yellow: "#7e6e12",
    C_LAB_green: "#436428",
    C_LAB_blue: "#2f5465",
    C_LAB_purple: "#61377f",
    C_LAB_orange: "#6a491e",

    C_SEM_el_good: "#b1e928",
    C_SEM_el_warning: "#ffa55f",
    C_SEM_el_bad: "#e5396b",
    C_SEM_el_good_inv: "#53b68f",
    C_SEM_el_warning_inv: "#f58920",
    C_SEM_el_bad_inv: "#e60434",
    C_SEM_el_neutral: "#ebe9e9",
    C_SEM_text_good: "#79b426",
    C_SEM_text_warning: "#fab883",
    C_SEM_text_bad: "#f84386",
    C_SEM_el_good_hover: "#85af2c",
    C_SEM_el_bad_hover: "#aa335b",
    C_SEM_text_good_hover: "#a0ca66",
    C_SEM_text_bad_hover: "#b73a6e",
    C_SEM_el_highlighted: "#cc95c2",

    C_SEM_opacity_bg_color: "0.35",
    C_SEM_opacity_validation: "0.2",

    C_GREY_x: "#cecece",

    C_DSH_border: mix(0.5, "#dedbde", "#000"),

    C_EVALA_LOGO: "#fff",

    C_TEXT_primary: "#dedbde",
    C_TEXT_dialogue_title: "#f2e1eb",
    C_TEXT_dialogue: "#fff",
    C_TEXT_purple_bg: "#000",
    C_TEXT_selection: "#41475A",
    C_TEXT_link: "#9082a7",
    C_TEXT_link_hover: "#C6B3E6",
    C_TEXT_link_selected: "#585066",
    C_TEXT_link_light: "#683fac",
    C_TEXT_link_hover_light: "#711AFF",
    C_TEXT_link_selected_light: "#4511A6",

    C_ICON_primary_dark: "#b776a3",
    C_ICON_secondary_dark: "#8f6286",
    C_ICON_tertiary_dark: "#E50041",
    C_ICON_primary_light: "#000",
    C_ICON_primary_light_hover: "#3e4455",
    C_ICON_secondary_light: "#463e55",
    C_ICON_tertiary_light: "#e50041",
    C_ICON_done: "#dedbde",

    C_ICON_document: "#AD74AA",

    C_ICON_MENU_main: "#dedbde",
    C_ICON_MENU_stroke: "#b21373",
    C_ICON_MENU_fill: "#b21373",
    get C_ICON_MENU_grad_start() {
        return this.C_BG_shellbar_grad_start;
    },
    get C_ICON_MENU_grad_end() {
        return this.C_BG_shellbar_grad_end;
    },

    C_ICON_OBJECT_PAGE_main: "#b776a3",

    C_ICON_STATUS_INDICATOR_stroke: "#dedbde",
    C_BUSY_INVERSE: "#644D6A",

    C_FIELD_fill: "#282f3f",
    C_FIELD_line: "#495062",
    C_FIELD_line_der: "#303748",
    C_FIELD_sync: "#272e3d",
    C_FIELD_section_line: "#343e56",

    C_DEF_tableBorder: "#202737",
    C_LINE_variants: "#343e56",
    C_BTN_border_hover: "#b776a3",

    C_SPLIT_LAYOUT_BUBBLE: "",
    C_FORM_GROUP_HEADER_LINE: "#495062",
    C_SPLIT_LAYOUT_COLLAPSED_HOVER: "",
    C_SPLIT_LAYOUT_COLLAPSED_GRADIENT_TOP: "",
    C_SPLIT_LAYOUT_COLLAPSED_GRADIENT_BOTTOM: "",

    C_HIERARCHICAL_SELECT_LINE: "#f2e1eb",
    C_AVATAR_BG_LIGHT: "#DDDDDD",
    C_AVATAR_BG_DARK: "#232323",
    C_BG_INACTIVE_COMPANY: "#2a3140",
    INACTIVE_COMPANY_transparentize: "0",

    C_GRD_blue: "#9255f2",
    C_GRD_red: "#de0553",
    C_GRD_pink: "#bd1489",
    C_GRD_purple: "#ad4592",

    C_CHART_purple: "#9771b0",
    C_CHART_blue: "#7bb2C9",
    C_CHART_green: "#95b879",
    C_CHART_yellow: "#edd86f",
    C_CHART_orange: "#db9465",
    C_CHART_red: "#c7586c",
    C_CHART_grey: "#a7a6a7",

    C_SELECT_SEARCH_INPUT_FOCUS: "#a23086",
    C_SELECT_SEARCH_INPUT_BACKGROUND: "#ad4592",

    C_AUDIT_TRAIL_DIFF: "#53b68f",
    C_AUDIT_TRAIL_NO_DIFF: "#303748",
    C_AUDIT_TRAIL_DIFF_VISIBLE_OPACITY: "0.6",

    C_BG_ACCOUNT_PROGRESS_BG: "#282f3f",

    borderRadius: "3px",
    borderLargeRadius: "17px",
    transition_transform_time: "0.3s",
    disabled_opacity: "0.5",
    dashboard_tile_shadow_opacity: "0.3",
    shadow_opacity: "0.6",
    lightFieldGroupOpacity: "0.2",

    C_CUST_DASHBOARD_TILE_BG: "#fff",
    C_CUST_DASHBOARD_TILE_BG_OPACITY: "0.1",
    C_CUST_DASHBOARD_TILE_BORDER: "#fff",
    C_CUST_DASHBOARD_TILE_BORDER_OPACITY: "0.2",

    C_DASHBOARD_TILE_PLACEHOLDER_BG: "#23202C",
    C_DASHBOARD_TILE_PLACEHOLDER_BORDER: "#60475E",

    F_wave: waveSimple,
    F_wave_mountain: waveMountain,
    F_wave_mountain_complex: waveMountainComplex,
    F_wave_mountain_panorama: waveMountainPanorama,

    DEV_TOOLS_link: "#00c2ee"
};

const customConfig = {
    ...defaultConfig,
    C_ACT_main: "gray",

    C_ICON_primary_dark: "#ede046",
    C_ICON_secondary_dark: "#2a386c",
    C_ICON_tertiary_dark: "#288d1c",
    C_ICON_primary_light: "#fff",
    C_ICON_secondary_light: "#ddcad1",
    C_ICON_tertiary_light: "#e50041"
};

export type TTheme = "light" | "dark" | "custom";

export const themes: Record<TTheme, DefaultTheme> = {
    light: defaultConfig,
    dark: darkConfig,
    custom: customConfig,
};

export type Theme = DefaultTheme;
export type PropsWithTheme = ThemeProps<Theme>;