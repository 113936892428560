import BindingContext, { createPath } from "@odata/BindingContext";
import { PrTaxpayerDeclarationDiscountEntity, PrTaxpayerDeclarationEntity } from "@odata/GeneratedEntityTypes";
import { PrTaxDiscountTypeCode } from "@odata/GeneratedEnums";

import { IDeductionTypePaths } from "./TaxpayerDeclaration.types";

export const getTogglePropPath = (type: PrTaxDiscountTypeCode): string => {
    return `##Show${type}##`;
};

export const showChildrenPath = BindingContext.localContext(`Show${PrTaxpayerDeclarationEntity.Children}`);

export const getPathsForDeductionType = (type: PrTaxDiscountTypeCode): IDeductionTypePaths => {
    return {
        switchPath: getTogglePropPath(type),
        dateValidFrom: BindingContext.localContext(createPath(type, PrTaxpayerDeclarationDiscountEntity.DateValidFrom)),
        dateValidTo: BindingContext.localContext(createPath(type, PrTaxpayerDeclarationDiscountEntity.DateValidTo))
    };
};