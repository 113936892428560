import { ISelectItem } from "@components/inputs/select/Select.types";
import {
    IFieldDef,
    IFieldInfoProperties,
    IGetValueArgs,
    isFieldDisabled,
    TValidatorFnResult
} from "@components/smart/FieldInfo";
import { ISummaryItem, ISummaryItemEditProps } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import { getDocumentTypeCodeFromEntityType } from "@odata/EntityTypes";
import {
    EntitySetName,
    EntityTypeName,
    FiscalYearEntity,
    IBankStatementEntity,
    ICashReceiptIssuedEntity,
    IDocumentEntity,
    IFiscalPeriodEntity,
    IFiscalYearEntity,
    INumberRangeDefinitionEntity,
    INumberRangeEntity,
    INumberRangeWildcardEntity,
    IProformaInvoiceIssuedEntity,
    NumberRangeDefinitionEntity,
    NumberRangeEntity,
    NumberRangeTypeEntity
} from "@odata/GeneratedEntityTypes";
import {
    DocumentTypeCode,
    FiscalYearStatusCode,
    NumberRangeTypeCode,
    NumberRangeWildcardCode
} from "@odata/GeneratedEnums";
import { IFormatOptions } from "@odata/OData.utils";
import { memoizedWithCacheStrategy } from "@utils/CacheCleaner";
import { isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { isDefined, isNotDefined, isObjectEmpty } from "@utils/general";
import { logger } from "@utils/log";
import i18next from "i18next";
import React from "react";
import { ValidationError } from "yup";

import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { BasicInputSizes, CacheStrategy, FieldType, NavigationSource, ValidatorType } from "../../enums";
import { TValue } from "../../global.types";
import { Model } from "../../model/Model";
import BindingContext, { createPath, IEntity } from "../../odata/BindingContext";
import { getUtcDayjs } from "../../types/Date";
import { FormStorage, IFormStorageDefaultCustomData } from "../../views/formView/FormStorage";
import { isDocumentType, isIssued, isReceived } from "../documents/Document.utils";
import NumberRangeTemporalFormDialog from "../documents/NumberRangeTemporalFormDialog";
import { TFieldsDefinition } from "../PageUtils";

export const WILDCARDS = ["%Y%", "%YY%", "%YYYY%", "%MM%", "%F%", "%FF%", "%FFFF%", "%PPP%"];
export const MAX_NUMERIC_SIGNS = 10;
export const CARRY_FORWARD_PREFIX = "%FF%";
export const CARRY_FORWARD_PREFIX_CBA = "%YY%";

export const FiscalYearForNumberRangePath = BindingContext.localContext("FiscalYearForNumberRange");
export const FiscalPeriodForNumberRangePath = BindingContext.localContext("FiscalPeriodForNumberRange");

interface ILoadNumberRangeArgs {
    type: NumberRangeTypeCode;
    storage: Model;
    context: IAppContext;
}

export interface INumberRangeFormCustomData extends IFormStorageDefaultCustomData {
    allWildcards?: INumberRangeWildcardEntity[];
    NumberRanges?: INumberRangeEntity[];
}

export async function loadNumberRanges({
                                           type,
                                           storage
                                       }: ILoadNumberRangeArgs): Promise<INumberRangeEntity[]> {
    const result = await storage.oData
        .getEntitySetWrapper(EntitySetName.NumberRanges)
        .query()
        .expand(NumberRangeEntity.Definition, (query) => {
            query.expand(NumberRangeDefinitionEntity.NumberRangeType, nestedQuery => nestedQuery.select(NumberRangeTypeEntity.DocumentTypeCode));
        })
        .expand(NumberRangeEntity.FiscalYear, query => query.select(FiscalYearEntity.Id))
        .select(NumberRangeEntity.Id, NumberRangeEntity.NextNumber)
        .filter(`${createPath(NumberRangeEntity.Definition, NumberRangeDefinitionEntity.NumberRangeTypeCode)} eq '${type}' and ${createPath(NumberRangeEntity.Definition, NumberRangeDefinitionEntity.IsActive)} eq true`)
        .fetchData<INumberRangeEntity[]>();

    return result?.value ?? [];
}

const loadNumberRangesMemoizedWithCacheStrategy = memoizedWithCacheStrategy<ILoadNumberRangeArgs, INumberRangeEntity[]>(loadNumberRanges, ({ type }: ILoadNumberRangeArgs) => type);

export function loadNumberRangesMemoized(args: ILoadNumberRangeArgs): Promise<INumberRangeEntity[]> {
    return loadNumberRangesMemoizedWithCacheStrategy(args, CacheStrategy.None);
}

// todo: once localContext supports additionalProperties, add this to getNumberOursSummaryDef
export const NumberRangeAdditionalProperties: IFieldDef[] = [
    { id: "NumberOurs" },
    {
        id: "NumberRange",
        additionalProperties: [{ id: "NextNumber" }, { id: "Definition/Name" }]
    }
];

function getNumberRangeItems(items: ISelectItem[], args: IGetValueArgs): ISelectItem[] {
    const numberRanges = getFilteredRanges(args.storage);

    return numberRanges
        .map((range: IEntity) => ({
            label: range.NextNumber,
            id: range.Id,
            tabularData: [range.NextNumber, range.Definition?.Name],
            additionalData: range
        }));
}

export const getNumberRangeFieldDef = (): IFieldInfoProperties => ({
    type: FieldType.ComboBox,
    useForValidation: true,
    fieldSettings: {
        displayName: NumberRangeEntity.NextNumber,
        noRecordText: i18next.t("NumberRange:Dialog.OutsideRange"),
        shouldDisplayAdditionalColumns: true,
        showTabularHeader: true,
        itemsForRender: getNumberRangeItems,
        localDependentFields: [{
            from: { id: "NextNumber" },
            to: { id: "NextNumber" },
            navigateFrom: NavigationSource.Itself
        }]
    },
    columns: [
        { id: "NextNumber", label: i18next.t("NumberRange:Dialog.NumberRange") },
        { id: "Definition/Name", label: i18next.t("NumberRange:Dialog.Name") }
    ],
    width: BasicInputSizes.XL,
    customizationData: {
        useForCustomization: false
    }
});

export const getNumberOursFieldDef = (translationFile: string): IFieldInfoProperties => ({
    type: FieldType.Autocomplete,
    fieldSettings: {
        itemsFactory: async (args: IGetValueArgs): Promise<ISelectItem[]> => {
            return getFilteredRanges(args.storage)?.map(range => {
                const nextNumber = replaceWildcards(range.NextNumber, args.storage.data.entity);
                return {
                    id: nextNumber,
                    label: nextNumber,
                    additionalData: {
                        Id: range.Id
                    }
                };
            });
        }
    },
    label: i18next.t(`${translationFile}:Summary.NumberOursLabel`),
    width: BasicInputSizes.XL,
    isRequired: true,
    useForValidation: true,
    customizationData: {
        useForCustomization: false
    },
    validator: {
        type: ValidatorType.Custom,
        settings: {
            customValidator: numberOursValidator
        }
    }
});

export const getNumberRangeFieldDefs = (translationFile: string): TFieldsDefinition => ({
    NumberRange: getNumberRangeFieldDef(),
    NumberOurs: getNumberOursFieldDef(translationFile)
});

export const NumberOursSummaryBindingContext = BindingContext.localContext("NumberOurs");
export const NumberRangePreviewPath = BindingContext.localContext("numRangePreview");
export const VarSymbolPreviewPath = BindingContext.localContext("varSymbolPreview");
export const NumRangeWithProgressPath = BindingContext.localContext("numRangeWithProgress");
export const PreviewsPath = BindingContext.localContext("previews");
export const ChildrenPath = BindingContext.localContext("children");


export function getFiscalDataForNumberRange(entity: IEntity): { FiscalYear: IFiscalYearEntity, FiscalPeriod: IFiscalPeriodEntity } {
    const FiscalYear = entity[FiscalYearForNumberRangePath] ?? entity.FiscalYear;
    const FiscalPeriod = entity[FiscalPeriodForNumberRangePath] ?? entity.FiscalPeriod;
    return { FiscalYear, FiscalPeriod };
}

function isNumberRangeFiscalYearClosed(args: IGetValueArgs) {
    const { FiscalYear } = getFiscalDataForNumberRange(args.storage.data.entity);
    return FiscalYear?.StatusCode === FiscalYearStatusCode.Closed;
}

export const getNumberOursSummaryDef = (transFile: string): ISummaryItem => {
    return {
        id: NumberOursSummaryBindingContext,
        containedFields: ["NumberRange", "NumberOurs"],
        renderEdit: (args: ISummaryItemEditProps) => (
            <NumberRangeTemporalFormDialog
                storage={args.storage}
                fieldPaths={[["NumberRange"], ["NumberOurs"]]}
                title={args.item.additionalData?.dialogTitle}
                onChange={args.onChange}
                onClose={args.onClose}
            />
        ),
        isReadOnly: (args: IGetValueArgs) => {
            return !args.storage.data.bindingContext.isNew() && isNumberRangeFiscalYearClosed(args);
        },
        isDisabled: (args) => {
            const numberOursBc = args.storage.data.bindingContext.navigate("NumberOurs");

            return isFieldDisabled(args.storage.getInfo(numberOursBc), args.storage, numberOursBc);
        },
        disabledText: (args) => {
            const storage = args.storage as FormStorage;
            const numberRangeBc = args.storage.data.bindingContext.navigate("NumberRange");
            const numberOursBc = args.storage.data.bindingContext.navigate("NumberOurs");
            const disabledFieldMetadata = storage.getBackendDisabledFieldMetadata?.(numberRangeBc) || storage.getBackendDisabledFieldMetadata?.(numberOursBc);

            return disabledFieldMetadata?.message;
        },
        additionalData: {
            dialogTitle: i18next.t(`${transFile}:Summary.ChangeNumberOursDialogTitle`)
        },
        formatter: (val: TValue, data?: IFormatOptions) => {
            // we need to work with entity directly as the summary item has local context
            return (data?.entity?.NumberOurs ?? i18next.t(`Common:Form.WithoutNumber`))?.toString();
        },
        colorFormatter: (val: TValue, data?: IFormatOptions) => {
            return data?.entity?.NumberOurs ? "C_TEXT_primary" : "C_ACT_thick_line";
        }
    };
};

export function getNumberRangeType(bc: BindingContext): NumberRangeTypeCode {
    const type = bc.getEntityType();
    const typeName = type?.getName() as EntityTypeName;

    return getNumberRangeTypeCodeFromEntityTypeName(typeName);
}

export const getNumberRangeTypeCodeFromEntityTypeName = (type: EntityTypeName): NumberRangeTypeCode => {
    switch (type) {
        case EntityTypeName.CompanyBankAccount:
        case EntityTypeName.BankStatement:
            return NumberRangeTypeCode.BankStatement;
        case EntityTypeName.MinorAsset:
            return NumberRangeTypeCode.MinorAsset;
        case EntityTypeName.Asset:
            return NumberRangeTypeCode.Asset;
        case EntityTypeName.CorrectiveInvoiceIssued:
            return NumberRangeTypeCode.CorrectiveInvoiceIssued;
        case EntityTypeName.CorrectiveInvoiceReceived:
            return NumberRangeTypeCode.CorrectiveInvoiceReceived;
        case EntityTypeName.InternalDocument:
            return NumberRangeTypeCode.InternalDocument;
        case EntityTypeName.InvoiceIssued:
            return NumberRangeTypeCode.InvoiceIssued;
        case EntityTypeName.InvoiceReceived:
            return NumberRangeTypeCode.InvoiceReceived;
        case EntityTypeName.OtherLiability:
            return NumberRangeTypeCode.OtherLiability;
        case EntityTypeName.OtherReceivable:
            return NumberRangeTypeCode.OtherReceivable;
        case EntityTypeName.ProformaInvoiceIssued:
            return NumberRangeTypeCode.ProformaInvoiceIssued;
        case EntityTypeName.ProformaInvoiceReceived:
            return NumberRangeTypeCode.ProformaInvoiceReceived;
        case EntityTypeName.CashReceiptIssued:
            return NumberRangeTypeCode.CashReceiptIssued;
        case EntityTypeName.CashReceiptReceived:
            return NumberRangeTypeCode.CashReceiptReceived;
        case EntityTypeName.PrEmployee:
            return NumberRangeTypeCode.Employee;
        case EntityTypeName.PrEmploymentTemplate:
            return NumberRangeTypeCode.EmploymentTemplate;
        case EntityTypeName.PrEmployment:
            return NumberRangeTypeCode.Employment;
        case EntityTypeName.PrSalaryAdvance:
            return NumberRangeTypeCode.SalaryAdvance;
        case EntityTypeName.PrIndividualDeduction:
            return NumberRangeTypeCode.IndividualDeduction;
        case EntityTypeName.PrGroupDeduction:
            return NumberRangeTypeCode.GroupDeduction;
        default:
            return null;
    }
};

async function duplicateNumberOursValidator(value: TValue, {
    storage,
    bindingContext
}: IGetValueArgs): Promise<boolean> {
    const _validationQuery = async (NumberOurs: string) => {
        const entitySet = storage.data.bindingContext.getEntityType().hasParentWithBaseType(EntityTypeName.Document) ? "Documents" : storage.data.bindingContext.getEntitySet().getName();
        return await storage.oData.getEntitySetWrapper(entitySet as EntitySetName)
            .query()
            .select("NumberOurs")
            .filter(`NumberOurs eq '${NumberOurs}'`)
            .top(0)
            .count()
            .fetchData<IEntity>();
    };

    const NumberRangeBc = bindingContext.navigateWithSiblingFallback("NumberRange");
    const NumberRange = storage.getValue(NumberRangeBc);

    const isSavedNumberOurs = (storage as FormStorage).data.origEntity.NumberOurs === value;
    if (NumberRange || isSavedNumberOurs) {
        return true;
    }

    const docs = await _validationQuery(value as string);
    return !docs?._metadata.count;
}

async function numberOursValidator(value: TValue, args: IGetValueArgs): Promise<TValidatorFnResult> {
    const maxLength = 25;

    if (!value) {
        return new ValidationError(i18next.t("Common:Validation.Required"), false, args.bindingContext.getPath(true));
    } else if ((value as string).length > maxLength) {
        return new ValidationError(i18next.t("Common:Validation.Max", { max: maxLength.toString() }), false, args.bindingContext.getPath(true));
    }

    // DEV-4853, DEV-11180 - must have 1 to 10 digits (due to variable symbol)
    if (isDocumentType(args.bindingContext)) {
        const docType = getDocumentTypeCodeFromEntityType(args.bindingContext.getEntityType().getName() as EntityTypeName);

        if (isReceived(docType) || isIssued(docType)) {
            const numberOfIntegers = (value as string).replace(/[^0-9]/g, "").length;
            const hasCorrectNumberOfDigits = numberOfIntegers && numberOfIntegers > 0 && numberOfIntegers < 11;
            if (!hasCorrectNumberOfDigits) {
                return new ValidationError(i18next.t("Error:DocumentNumberOursOneToTenDigits"), false, args.bindingContext.getPath(true));
            }
        }
    }

    const isNotDuplicate = await duplicateNumberOursValidator(value, args);
    if (!isNotDuplicate) {
        return new ValidationError(i18next.t("Error:DocumentNumberOursCannotMatchNumberRangeWhenSelectedManually"), false, args.bindingContext.getPath(true));
    }

    return true;
}

/**
 * ToDo: we may need to customize / configure this differently per NumberRangeType...
 * @param range
 * @param entity
 * @param type
 */
export function isRangeMatchingEntityByType(range: INumberRangeEntity, entity: IEntity, type: NumberRangeTypeCode): boolean {
    let isFiscalYearMatching = true;
    if (range?.FiscalYear?.Id) {
        const { FiscalYear } = getFiscalDataForNumberRange(entity);
        isFiscalYearMatching = range.FiscalYear.Id === FiscalYear?.Id;
    }
    return range.Definition.NumberRangeTypeCode === type && isFiscalYearMatching;
}

export function getFilteredRanges(storage: Model): INumberRangeEntity[] {
    const ranges = getStoredRanges(storage);
    const { bindingContext, entity } = storage.data;
    const type = getNumberRangeType(bindingContext);

    return ranges.filter(range => isRangeMatchingEntityByType(range, entity, type)) ?? [];
}

export const canChangeNumberRange = (storage: FormStorage): boolean => {
    const currentNr = storage.data.entity.NumberRange;
    return !isObjectEmpty(currentNr);
};

export function getRangeByDefinitionId(storage: Model, id: number, fiscalYearId?: number): INumberRangeEntity {
    const ranges = getStoredRanges(storage);
    const _isMatchingFY = (range: INumberRangeEntity) => (isNotDefined(fiscalYearId) || isNotDefined(range.FiscalYear) || isObjectEmpty(range.FiscalYear) || range.FiscalYear?.Id === fiscalYearId);
    const range = ranges.find(range => range?.Definition?.Id === id && _isMatchingFY(range));

    if (!range) {
        // return default range if none is found (typically there is configured non-active range at the bank account
        return ranges.find(range => range?.Definition?.IsDefault && _isMatchingFY(range));
    }

    return range;
}


export function getStoredRanges(storage: Model): INumberRangeEntity[] {
    return (storage.getCustomData() as INumberRangeFormCustomData).NumberRanges;
}

export async function loadAndStoreRanges(storage: Model, force = false): Promise<boolean> {
    if (!force && getStoredRanges(storage)) {
        return false;
    }
    const { context, data: { bindingContext } } = storage;
    const type = getNumberRangeType(bindingContext);
    const ranges = await loadNumberRangesMemoized({ type, storage, context });
    (storage as FormStorage<unknown, INumberRangeFormCustomData>).setCustomData({ NumberRanges: ranges });
    return true;
}

/**
 * Function checks if ranges are related -> ranges which depends on FY has different ID per FY and are connected
 * with the original NumberRange by parent (Definition) relation. We consider NumberRange with same Definition as related.
 * @param range1
 * @param range2
 */
function isRelatedNumberRange(range1: INumberRangeEntity, range2: INumberRangeEntity): boolean {
    if (isNotDefined(range1) || isNotDefined(range2) ||
        (isNotDefined(range1?.Definition?.Id) && isNotDefined(range2?.Definition?.Id))) {
        // both number ranges has to be defined and at least one of them
        // must have defined parent, unless there is nothing to compare.
        return false;
    }
    // 2 NumberRanges with same NumberRangeDefinition are related.
    return range1.Definition?.Id === range2?.Definition?.Id;
}

/**
 * Sets best matching number range. If the number range is the original one,
 * reverts to original number (not the NextNumber of the row)
 *
 * ONLY CHANGE NUMBER OURS for new document
 * for existing document, only change the list of available NumberRanges,
 * but NumberOurs have to be changed manually
 */
export async function setMatchingNumberRange(storage: FormStorage, forceReload?: boolean, decisiveDateProp?: string): Promise<boolean> {
    // loads data on first call
    await loadAndStoreRanges(storage, forceReload);

    const { entity, origEntity } = storage.data;
    const filteredRanges = getFilteredRanges(storage) || [];
    const currentRangeId = entity.NumberRange?.Id;
    // values for update
    let { NumberOurs } = entity,
        NumberRange;

    const hasCustomNumberOurs = !!NumberOurs && !currentRangeId;
    const isCurrentRangeMatching = filteredRanges.find(range => range.Id === currentRangeId);

    // todo: current range might be matching, but there could be different property value for wildcard replacement
    //  - how we identify this case? Should we replace numberOurs with new one or keep the original saved one?

    // Case 1: entity has custom number ours (outside range) or number range is matching -> no action
    if (hasCustomNumberOurs || (!!NumberOurs && isCurrentRangeMatching)) {
        return false;
    }

    // otherwise, numberRange should be updated...
    if (filteredRanges.find(range => range.Id === origEntity.NumberRange?.Id)
        && origEntity.NumberOurs) {
        // If originalNumberRange is set and it's in filteredRanges -> reset to originalValues
        NumberRange = { ...origEntity.NumberRange };
        NumberOurs = origEntity.NumberOurs;
    } else {
        // pick first matching, default for the current type or just first number range (in that order)
        // matching means that the NumberRanges are the "same" - both are result of one NumberRange with CarryForward == true
        NumberRange = filteredRanges.find(range => isRelatedNumberRange(range, entity.NumberRange))
            || filteredRanges.find(range => range.Definition.IsDefault)
            || filteredRanges[0];
        if (NumberRange) {
            NumberOurs = replaceWildcards(NumberRange.NextNumber, entity, {
                previewString: "",
                useNow: false,
                decisiveDateProp
            });
        } else {
            NumberOurs = null;
        }
    }

    storage.clearAndSetValueByPath("NumberRange", storage.data.bindingContext.isNew() ? NumberRange : null, true);

    if (storage.data.bindingContext.isNew()) {
        storage.clearAndSetValueByPath("NumberOurs", NumberOurs, true);
    }

    return true;
}

// @param previewString - if data are not defined in entity and preview string is set, it's used instead on them
// note: preview string is just one for all wildcards, use something which could be valid for
//         all of them. I would suggest "2001", which means "001" for %PPP%, "2001" for %YYYY%, etc...
// @param useNow - if true, Date.now() instead of transaction date is used
// @param decisiveDateProp - date property of document, which is decisive for wildcards
interface IReplaceWildcardsOpts {
    previewString?: string;
    useNow?: boolean;
    decisiveDateProp?: string;
}

/**
 * Replace wildcards in numberRange with actual strings
 *   todo: we may want to be able to define from which bindingContext path we take the data for particular wildcards
 * @param s
 * @param entity
 * @param opts
 */
// TODO refactor to accept date instead of entity to provide fiscal ear and period
// and change getFiscalDataForNumberRange to retrieve it based on that date
export function replaceWildcards(s: string, entity: IEntity, opts: IReplaceWildcardsOpts = {}): string {
    const { previewString, useNow, decisiveDateProp } = { ...opts };
    const _trim = (r: string, length: number) => r.slice(-1 * length);

    const date = decisiveDateProp ? entity[decisiveDateProp] : useNow ? Date.now()
        : ((entity as IProformaInvoiceIssuedEntity)?.DateProcessed
            ?? (entity as IDocumentEntity)?.DateAccountingTransaction
            ?? (entity as IBankStatementEntity)?.DateFrom
            ?? (entity as ICashReceiptIssuedEntity)?.DateIssued);

    const { FiscalYear, FiscalPeriod } = getFiscalDataForNumberRange(entity);

    for (const wildcard of WILDCARDS) {
        if (s?.includes(wildcard)) {
            let replacement = "", trimmedReplacement: string;

            // Possibilities to replace patterns, always with fallbacks as it might not be defined in some cases
            switch (wildcard) {
                case "%F%":
                case "%FF%":
                case "%FFFF%":
                    const numericLen = wildcard.length - 2;
                    const re = new RegExp(`([0-9]{${numericLen}}(/Z))?$`);
                    replacement = FiscalYear?.Number ?? previewString;
                    const matches = replacement?.match(re);
                    trimmedReplacement = matches?.[1];
                    break;

                case "%Y%":
                case "%YY%":
                case "%YYYY%":
                    replacement = getUtcDayjs(date).format("YYYY");
                    break;

                case "%MM%":
                    replacement = getUtcDayjs(date).format("MM");
                    break;

                case "%PPP%":
                    replacement = FiscalPeriod?.Number;
                    break;
            }

            if (!isDefined(replacement)) {
                if (previewString) {
                    replacement = previewString;
                } else {
                    logger.error(`Insufficient data provided for wildcard '${wildcard}' replacement.`);
                    return "";
                }
            }

            // trim to max length of wildcard
            trimmedReplacement = trimmedReplacement ?? _trim(replacement, wildcard.length - 2);
            // replace all occurrences
            s = s.replace(new RegExp(wildcard, "g"), trimmedReplacement);
        }
    }
    return s;
}

export const getMaxNumericCount = (storage: Model): number => {
    // return isNestedNumberRange({
    //     storage
    // }) ? MAX_NUMERIC_SIGNS - CARRY_FORWARD_PREFIX.replaceAll("%", "").length : MAX_NUMERIC_SIGNS;
    return MAX_NUMERIC_SIGNS;
};

const _getLength = (s: string, entity: INumberRangeDefinitionEntity) => {
    if (!s) {
        return 0;
    }
    return replaceWildcards(s, entity, { previewString: "9999" }).replace(/\D/g, "").length;
};

export const numericSignsLeft = (storage: Model): number => {
    const entity: INumberRangeDefinitionEntity = storage.data.entity;
    return getMaxNumericCount(storage) - (_getLength(entity.NumberRangeTypePrefix, entity) + _getLength(entity.CarryForwardPrefix, entity) + _getLength(entity.Prefix, entity) + (parseInt(entity.NumberOfDigits?.toString()) || 0) + _getLength(entity.Suffix, entity));
};

const commonValidator = (value: TValue, args: IGetValueArgs): ValidationError | boolean => {
    const signsLeft = numericSignsLeft(args.storage);
    if (_getLength(value?.toString(), args.storage.data.entity) && (signsLeft < 0)) {
        return new ValidationError(i18next.t("NumberRange:Validation.NumSignsExceeded"), false, args.bindingContext.getPath(true));
    }
    return true;
};

const containsInvalidWildcard = (value: string, entity: INumberRangeDefinitionEntity, context: IAppContext) => {
    if (!value || !entity.NumberRangeType) {
        return false;
    }
    let inWildcard = false;
    let token = "";
    const escapeSymbol = "%";

    let wildCards = entity.NumberRangeType.Wildcards;

    if (isCashBasisAccountingCompany(context)) {
        wildCards = wildCards.filter(wc => !wc.IsFiscalYearDependent);
    }

    const wildCardCodes = wildCards.map(card => card.Code);

    for (const char of value) {
        if (!inWildcard && char === escapeSymbol) {
            token += char;
            inWildcard = true;
        } else if (inWildcard && char !== escapeSymbol) {
            token += char;
        } else if (inWildcard && char === escapeSymbol) {
            token += char;
            inWildcard = false;
            if (!wildCardCodes.includes(token as NumberRangeWildcardCode)) {
                return true;
            }
            token = "";
        }
    }

    return inWildcard;
};

export const prefixSuffixValidator = (value: TValue, args: IGetValueArgs): TValidatorFnResult => {
    const numberRangeDefinition = args.storage.data.entity as INumberRangeDefinitionEntity;

    if (containsInvalidWildcard(value?.toString(), numberRangeDefinition, args.storage.context)) {
        return new ValidationError(i18next.t("NumberRange:Validation.InvalidWildcard"), false, args.bindingContext.getPath(true));
    }
    return commonValidator(value, args);
};

export const numberOfDigitsValidator = (value: TValue, args: IGetValueArgs): TValidatorFnResult => {
    if (!Number.isInteger(value as number)) {
        return new ValidationError(i18next.t("Common:Validation.Integer"), false, args.bindingContext.getPath(true));
    } else if ((value as number) <= 0) {
        return new ValidationError(i18next.t("Common:Validation.SmallNumber", { min: 1 }), false, args.bindingContext.getPath(true));
    }
    return commonValidator(value, args);
};

export const numberRangeTypePrefixValidator = (value: TValue, args: IGetValueArgs): TValidatorFnResult => {
    return commonValidator(value, args);
};


export const isDocumentNumberRange = (storage: Model): boolean => {
    const numberRangeDefinition = storage.data.entity as INumberRangeDefinitionEntity;

    return !numberRangeDefinition.NumberRangeType || !!numberRangeDefinition?.NumberRangeType?.DocumentTypeCode;
};

export function isIssuedDocumentNumberRange(storage: Model): boolean {
    const numberRangeDefinition = storage.data.entity as INumberRangeDefinitionEntity;
    const { DocumentTypeCode } = numberRangeDefinition?.NumberRangeType ?? {};

    return isIssued(DocumentTypeCode as DocumentTypeCode);
}

export function replaceWildcardsAfterDateChange(storage: FormStorage, decisiveDateProp?: string): void {
    // If FY has changed, NumberRange is updated by setMatchingNumberRangeForDocument method called
    // from refreshFormAfterDecisiveDateChange method. Here we need only to update NumberOurs
    // to match wildcards with actual DateAccountingTransaction, e.g. to update %MM% with current months
    // todo: BE doesn't support to update wildcards in existing NumberOurs without changing NumberRange.
    //  so we keep original NumberOurs in this case, even if the wildcards don't match.
    const { entity, origEntity, bindingContext } = storage.data;
    if (!isObjectEmpty(entity.NumberRange) && origEntity.NumberOurs !== entity.NumberOurs) {
        const newValue = replaceWildcards(entity.NumberRange.NextNumber, entity, { decisiveDateProp });
        storage.setValueByPath("NumberOurs", newValue, true);
        storage.addActiveField(bindingContext.navigate(NumberOursSummaryBindingContext));
    }
}
