import { sortCompareFn } from "@utils/general";
import React from "react";

import { TValue } from "../../global.types";
import { ClickableStyled } from "./FilterBar.styles";

interface IFilterItemReadOnlyProps {
    id: string;
    label: string;
    description?: string;
    value: TValue;
    isFirst?: boolean;
    onExpand?: (id: string) => void;
    hiddenItems?: number;
}

const FilterBarItemReadOnly: React.FC<IFilterItemReadOnlyProps> = (props) => {
    const { hiddenItems = 0 } = props;

    const handleExpand = () => {
        props.onExpand(props.id);
    };

    return (
        <>
            {!props.isFirst &&
                " / "
            }
            <b>{props.label}</b>{props.description && <em> ({props.description})</em>}:
            {` ${Array.isArray(props.value) ? props.value.sort(sortCompareFn).join(", ") : props.value}`}
            {hiddenItems ? <ClickableStyled onClick={handleExpand}>+{props.hiddenItems}</ClickableStyled>
                : ""}
        </>
    );
};

export default FilterBarItemReadOnly;