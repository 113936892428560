// opt-out approach, add enums that are long, and we don't use them, to prevent GeneratedEnums.ts from getting too big
module.exports = {
    IGNORE_ENUMS: [
        "EntityType", "CzNace", "GovernmentInstitutionBankAccountType",
        "GovernmentInstitutionBankAccount", "GovernmentInstitutionBankAccount",
        "GovernmentInstitutionBankAccountType", "GovernmentInstitutionBankAccountType",
        "TaxDepreciationCoefficient", "CBATransactionType",

        "CurrencyWithExchangeRateInfo",
        "FinancialReportLayoutSection"
    ]
};
