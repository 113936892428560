import * as InterfacesGen from "./InterfacesGen";
import * as Metadata from "./Metadata";
import * as MetadataUtils from "./Metadata.utils";
import * as MetadataParser from "./MetadataParser";
// named export to be used as 'import {something} from metadataLibrary
export * from "./Metadata";
export * from "./MetadataParser";
export * from "./Metadata.utils";
export * from "./InterfacesGen";

// default export to be used as 'import metadataLibrary; metadataLibrary.something()
// is this the right way to achieve this convenience?
export default { ...Metadata, ...MetadataParser, ...MetadataUtils, ...InterfacesGen };
