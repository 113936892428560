import { ellipsis, transparentize } from "polished";
import styled, { css } from "styled-components/macro";

import { LocalStackOrder, T_BOLD_tiny, T_PLAIN_tiny, T_TITLE_4_NORMAL } from "../../global.style";
import { Button, IconButton } from "../button";

const BackgroundJobHeight = 100;
const MaxBackgroundJobsCount = 5;
const Padding = 12;
const BorderOffset = 3;

export const StyledBackgroundJobs = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 277px;
    height: fit-content;
    max-height: ${BackgroundJobHeight * MaxBackgroundJobsCount}px;
    background-color: ${props => props.theme.C_BG_menu};
    border-radius: ${props => props.theme.borderRadius};
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
`;

export const BackgroundJobDeleteAll = styled.div`
    padding: 12px 12px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const DeleteAllText = styled.span`
    ${T_PLAIN_tiny};
    margin-left: 5px;
`;

const OverlayStyles = css`
    position: absolute;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: ${props => transparentize(0.25, props.theme.C_BG_menu)};
`;


interface IStyledBackgroundJob {
    isClosing: boolean;
    hasAction: boolean;
}

export const IconButtonBackgroundJobClose = styled(IconButton)`
    position: absolute;
    top: 7px;
    right: 7px;

    visibility: hidden;
    z-index: ${LocalStackOrder.AboveOverlay};
`;

export const ButtonBackgroundJobAction = styled(Button)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    z-index: ${LocalStackOrder.AboveOverlay};
    visibility: hidden;
`;

export const BackgroundJobActionOverlay = styled.div`
    ${OverlayStyles};
    display: none;
`;

export const BackgroundJobSeparator = styled.div`
    width: 100%;
    height: 1px;
    border: 1px solid ${props => props.theme.C_FORM_GROUP_HEADER_LINE};
`;

export const StyledBackgroundJob = styled.div<IStyledBackgroundJob>`
    position: relative;
    width: 100%;
    height: ${props => props.isClosing ? 0 : BackgroundJobHeight}px;
    padding: ${props => props.isClosing ? 0 : Padding}px ${Padding}px;
    opacity: ${props => props.isClosing ? 0 : 1};
    z-index: ${LocalStackOrder.Base};
    transition: padding 0.3s;
    display: flex;
    flex-direction: column;

    &:last-child {
        ${BackgroundJobSeparator} {
            display: none;
        }
    }

    &:hover, &:focus-visible, &:focus-within:not(:focus) {
        outline: none;

        ${IconButtonBackgroundJobClose}, ${ButtonBackgroundJobAction} {
            visibility: visible;
        }

        ${BackgroundJobActionOverlay} {
            display: block;
            z-index: ${LocalStackOrder.Overlay};
        }

        ::before {
            content: "";
            position: absolute;
            width: calc(100% - ${BorderOffset * 2}px);
            height: calc(100% - ${BorderOffset * 2}px);
            top: ${BorderOffset}px;
            left: ${BorderOffset}px;
            border-radius: ${props => props.theme.borderRadius};
            border: 2px solid ${props => props.theme.C_ACT_hover_without_opacity};
            z-index: ${LocalStackOrder.BelowContent};
        }
    }
`;

export const BackgroundJobHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 8px;
`;

export const BackgroundJobHeaderText = styled.div`
    margin-left: 5px;
    color: ${props => props.theme.C_ACT_thick_line};
    text-transform: uppercase;
    ${T_BOLD_tiny};
`;

export const BackgroundJobTitle = styled.div`
    ${T_TITLE_4_NORMAL};
    margin-bottom: 8px;
    ${ellipsis("100%")}
`;
