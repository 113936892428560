// adds support for pdf annotations (like links) which are otherwise rendered wrong
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import { arrayInsert, clamp, isDefined, isNotDefined, roundToDecimalPlaces } from "@utils/general";
import { saveAs } from "file-saver";
import { debounce } from "lodash";
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { PDFPageProxy } from "react-pdf/dist/Page";

import { TextAlign, ToolbarItemType } from "../../../enums";
import { TRecordType, TValue } from "../../../global.types";
import { KeyName } from "../../../keyName";
import TestIds from "../../../testIds";
import animationFrameThrottle from "../../../utils/animationFrameThrottle";
import BusyIndicator from "../../busyIndicator/BusyIndicator";
import CustomResizeObserver from "../../customResizeObserver/CustomResizeObserver";
import { Toolbar } from "../../toolbar/index";
import { isToolbarItemDefinition } from "../../toolbar/Toolbar.utils";
import { PDF_PAGE_MARGIN, StyledFileViewer } from "../FileViewers.styles";
import {
    FileToolbarItem,
    getFileNameFromSrc,
    getSharedToolbarDefinition,
    IFileViewerProps
} from "../FileViewers.utils";
import NoPreviewSquirrel from "../NoPreviewSquirrel";
import { Header, PdfWrapper, ResizerWrapper, Scroller } from "./PDFViewer.style";

const zoomStates = [0.25, 0.33, 0.5, 0.67, 0.75, 0.8, 0.9, 1, 1.1, 1.25, 1.5, 1.75, 2, 2.5, 3, 4, 5];
const startingZoomIndex = 7;

export enum PdfZoomType {
    FitToWidth = "FitToWidth",
    FitToPage = "FitToPage",
    ZoomOnly = "ZoomOnly"
}

enum PdfControlType {
    MouseSelect = "MoueSelect",
    MouseGrab = "MoueGrab"
}

interface IProps extends IFileViewerProps, WithTranslation {
    // url for print version of the pdf
    // if given, print button is shown
    printSrc?: string;
    defaultZoomType?: PdfZoomType;
}

interface IState {
    loaded: boolean;
    pageCount: number;
    zoom: number;
    currentPage: number;
    width: number;
    height: number;
    error: string;
    zoomType: PdfZoomType;
    controlType: PdfControlType;
    isToolbarResponsive: boolean;
}

interface IPdfPage {
    pageNumber: number;
    width: number;
    height: number;
    originalWidth: number;
    originalHeight: number;
}

class PDFViewer extends React.PureComponent<IProps, IState> {
    scrollerRef = React.createRef<HTMLDivElement>();
    pdfWrapperRef = React.createRef<HTMLDivElement>();
    preventScroll = false;
    preservedScroll: number = null;
    passwordTries = 0;
    pageMinWidth = Number.MAX_SAFE_INTEGER;
    pages: TRecordType<IPdfPage> = {};

    dragPos = { scrollTop: 0, scrollLeft: 0, x: 0, y: 0 };

    static defaultProps: Partial<IProps> = {
        hiddenItems: []
    };

    state: IState = {
        loaded: false,
        pageCount: null,
        zoom: zoomStates[startingZoomIndex],
        currentPage: 1,
        width: null,
        height: null,
        error: null,
        zoomType: this.props.defaultZoomType ?? PdfZoomType.FitToWidth,
        controlType: PdfControlType.MouseSelect,
        isToolbarResponsive: true
    };

    componentDidMount() {
        this.setState({
            width: this.scrollerRef.current.clientWidth,
            height: this.scrollerRef.current.clientHeight,
            loaded: !this.props.src
        });
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (isDefined(this.preservedScroll)) {
            // wait for the pages to render
            setTimeout(() => {
                this.scrollerRef.current.scrollTop = this.preservedScroll;
                this.preservedScroll = null;
            });
        }

        if (this.props.src !== prevProps.src) {
            this.reset();
        }
    }

    reset = () => {
        this.pages = {};
        this.passwordTries = 0;
        this.preventScroll = false;
        this.preservedScroll = null;

        this.setState({
            loaded: !this.props.src,
            error: "",
            pageCount: null,
            currentPage: 1,
            zoom: zoomStates[startingZoomIndex],
            zoomType: PdfZoomType.FitToWidth,
            controlType: PdfControlType.MouseSelect
        });
    };

    handleLoadSuccess = (arg: PDFDocumentProxy) => {
        this.setState({
            pageCount: arg.numPages
        });
    };

    handleLoadError = (error: Error) => {
        this.setState({
            loaded: true,
            error: this.props.t("Components:FileViewers.LoadError")
        });
    };

    handlePassword = (callback: any) => {
        if (this.passwordTries >= 3) {
            this.setState({
                loaded: true,
                error: this.props.t("Components:Pdf.WrongPassword")
            });
            return;
        }

        const password = prompt(this.props.t("Components:Pdf.EnterPassword"));

        if (password) {
            this.passwordTries += 1;
            callback(password);
        } else {
            this.setState({
                loaded: true,
                error: this.props.t("Components:Pdf.WrongPassword")
            });
        }
    };

    handlePageLoadSuccess = (args: PDFPageProxy) => {
        if (this.pages[args.pageNumber]) {
            return;
        }

        if (args.originalWidth < this.pageMinWidth) {
            this.pageMinWidth = args.originalWidth;
        }

        this.pages[args.pageNumber] = {
            pageNumber: args.pageNumber,
            width: args.width,
            height: args.height,
            originalWidth: args.originalWidth,
            originalHeight: args.originalHeight
        };

        if (isDefined(this.state.pageCount) && Object.keys(this.pages).length === this.state.pageCount) {
            this.updateZoom();
            this.setState({
                loaded: true
            });
        }
    };

    handleRenderSuccess = () => {
        this.pdfWrapperRef.current?.dispatchEvent(
            new CustomEvent("pdfCanvasRendered", { bubbles: true })
        );
    };

    getZoomIndex = (zoom: number) => {
        const index = zoomStates.indexOf(zoom);

        if (index > -1) {
            return index;
        }

        const closestZoom = zoomStates.reduce((closestZoom, current) => {
            return Math.abs(closestZoom - zoom) < Math.abs(current - zoom) ? closestZoom : current;
        }, Number.MAX_SAFE_INTEGER);

        return zoomStates.indexOf(closestZoom);
    };

    zoomIn = () => {
        this.zoom(1);
    };

    zoomOut = () => {
        this.zoom(-1);
    };

    zoom = (diff: number) => {
        const newZoomIndex = clamp(this.getZoomIndex(this.getZoom()) + diff, 0, zoomStates.length - 1);

        this.setZoom(zoomStates[newZoomIndex]);
    };

    setZoom = (zoom: number) => {
        this.preserveScroll();
        this.setState({
            zoom: clamp(zoom, zoomStates[0], zoomStates[zoomStates.length - 1]),
            zoomType: PdfZoomType.ZoomOnly
        });
    };

    updateZoom = (args: {
        zoomType?: PdfZoomType;
        pageNumber?: number;
        divWidth?: number;
        divHeight?: number;
    } = {}) => {
        const zoomType = args.zoomType ?? this.state.zoomType;
        const currentPage = args.pageNumber ?? this.state.currentPage;

        if (zoomType === PdfZoomType.FitToPage) {
            const page = this.pages[currentPage];

            if (!page) {
                return;
            }

            const zoom = this.getFitToPageZoom(page.originalWidth, page.originalHeight, args.divWidth, args.divHeight);

            this.setState({
                zoom
            });
        }
    };

    clampCurrentPage = (currentPage: number) => {
        return clamp(currentPage, 1, this.state.pageCount ?? 1);
    };

    setCurrentPage = (pageNumber: number) => {
        if (isNaN(pageNumber)) {
            return;
        }

        const newPage = this.clampCurrentPage(pageNumber);

        this.updateZoom({
            pageNumber: newPage
        });
        this.setState({
            currentPage: newPage
        });

        this.scrollerRef.current.scrollTop = this.getPageTopPos(newPage);
        // prevent next stroll event, which may cause troubles with calculation of last pages and change selected currentPage
        this.preventScroll = true;
    };

    getPageTopPos = (pageNumber: number) => {
        const zoom = this.getZoom();
        let scrollTop = 0;

        for (let i = 1; i < pageNumber; i++) {
            const page = this.pages[i];
            scrollTop += zoom * page.originalHeight + PDF_PAGE_MARGIN;
        }

        return scrollTop;
    };

    getPageMiddlePos = (pageNumber: number) => {
        return this.getPageTopPos(pageNumber) + (this.pages[pageNumber]?.originalHeight * this.getZoom()) / 2;
    };

    preserveScroll = () => {
        if (this.state.zoomType === PdfZoomType.FitToPage) {
            this.preservedScroll = this.getPageTopPos(this.state.currentPage);
        }
    };

    handleScroll = animationFrameThrottle((e: React.UIEvent) => {
        this.updateCurrentPage();
    });

    updateCurrentPage = () => {
        if (this.state.zoomType === PdfZoomType.FitToPage) {
            return;
        }

        if (!this.preventScroll) {
            const page = this.scrollerRef.current;
            if (this.scrollerRef.current && page) {
                const top = page.scrollTop;
                const height = page.clientHeight;
                let newPage = 0;


                while (top + height > this.getPageMiddlePos(newPage + 1)) {
                    newPage += 1;
                }

                newPage = this.clampCurrentPage(newPage);

                if (newPage !== this.state.currentPage) {
                    // todo for some reason this one rerender causes lag (caused by Toolbar), how to get rid of it?
                    this.setState({
                        currentPage: newPage,
                        // using isToolbarResponsive !partially! mitigates the problem
                        isToolbarResponsive: false
                    });
                    this.onAfterScroll();
                }
            }
        }
        this.preventScroll = false;
    };

    onAfterScroll = debounce(() => {
        this.setState({
            isToolbarResponsive: true
        });
    }, 150);

    handleResize = debounce((entries: readonly ResizeObserverEntry[]) => {
        const target = entries[0].target as HTMLDivElement;

        this.setState({
            width: target.offsetWidth,
            height: target.offsetHeight
        }, () => {
            this.updateZoom({
                divWidth: target.offsetWidth,
                divHeight: target.offsetHeight
            });

            this.updateCurrentPage();
        });
    }, 150);

    setCurrentPageThrottled = animationFrameThrottle((currentPage: number) => {
        this.setCurrentPage(currentPage);
    });

    handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (this.state.zoomType !== PdfZoomType.FitToPage) {
            return;
        }

        switch (event.key) {
            case KeyName.ArrowUp:
                this.setCurrentPageThrottled(this.state.currentPage - 1);
                event.preventDefault();
                event.stopPropagation();
                break;
            case KeyName.ArrowDown:
                this.setCurrentPageThrottled(this.state.currentPage + 1);
                event.preventDefault();
                event.stopPropagation();
                break;
            default:
                break;
        }
    };

    // handler cannot be throttled directly to prevent React SyntheticEvent reuse
    handleWheel = (event: React.WheelEvent<HTMLDivElement>) => {
        if (this.state.zoomType !== PdfZoomType.FitToPage) {
            return;
        }

        event.stopPropagation();

        const delta = event.deltaY > 0 ? 1 : -1;

        this.setCurrentPageThrottled(this.state.currentPage + delta);
    };

    handleMouseDown = (e: React.MouseEvent) => {
        if (this.state.controlType !== PdfControlType.MouseGrab) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        this.dragPos = {
            scrollTop: this.scrollerRef.current.scrollTop,
            scrollLeft: this.scrollerRef.current.scrollLeft,
            x: e.clientX,
            y: e.clientY
        };

        this.pdfWrapperRef.current.style.cursor = "grabbing";

        document.addEventListener("mousemove", this.handleMouseMove);
        document.addEventListener("mouseup", this.handleMouseUp);
    };

    handleMouseMove = animationFrameThrottle((e: MouseEvent): void => {
        // how far the mouse has been moved
        const dx = e.clientX - this.dragPos.x;
        const dy = e.clientY - this.dragPos.y;

        this.scrollerRef.current.scrollTop = this.dragPos.scrollTop - dy;
        this.scrollerRef.current.scrollLeft = this.dragPos.scrollLeft - dx;
    });

    handleMouseUp = (e: Event) => {
        this.pdfWrapperRef.current.style.cursor = null;

        document.removeEventListener("mousemove", this.handleMouseMove);
        document.removeEventListener("mouseup", this.handleMouseUp);
    };

    getFitToPageZoom = (pageWidth: number, pageHeight: number, divWidth?: number, divHeight?: number) => {
        const width = divWidth ?? this.state.width;
        const height = divHeight ?? this.state.height;
        let ratio = height / pageHeight;
        const newWidth = pageWidth * ratio;

        if (newWidth > width) {
            ratio = width / pageWidth;
        }

        return ratio;
    };

    getWidth = (): number => {
        if (isNotDefined(this.state.width)) {
            return null;
        }

        const cleanWidth = this.state.width - 15;

        if (this.state.zoomType === PdfZoomType.FitToWidth) {
            return cleanWidth;
        } else if (this.state.zoomType === PdfZoomType.FitToPage) {
            return this.pages[this.state.currentPage]?.originalWidth * this.state.zoom;
        }

        return this.pageMinWidth * this.state.zoom;
    };

    getZoom = () => {
        if (isNotDefined(this.state.zoom) || !this.pages[this.state.currentPage]) {
            return 0;
        }

        if (this.state.zoomType === PdfZoomType.FitToWidth) {
            return this.getWidth() / this.pageMinWidth;
        }

        return this.state.zoom;
    };

    getToolbarDefinition = () => {
        const definition = getSharedToolbarDefinition(this.props.isReadOnly, this.props.hiddenItems, this.props.isRemoveDisabled, this.props.t);

        if (!this.state.error) {
            definition.items = [
                {
                    id: FileToolbarItem.PreviousPage,
                    label: this.props.t("Components:Pdf.PreviousPage"),
                    itemType: ToolbarItemType.SmallIcon,
                    iconName: "Up",
                    itemProps: {
                        isDisabled: this.state.currentPage <= 1
                    },
                    order: null
                },
                {
                    id: FileToolbarItem.NextPage,
                    label: this.props.t("Components:Pdf.NextPage"),
                    itemType: ToolbarItemType.SmallIcon,
                    iconName: "Down",
                    itemProps: {
                        isDisabled: this.state.currentPage >= this.state.pageCount
                    },
                    order: null
                },
                {
                    id: FileToolbarItem.PageCount,
                    itemType: ToolbarItemType.NumericWriteLine,
                    itemProps: {
                        secondaryLabel: `/${this.state.pageCount ?? 0}`,
                        value: this.state.currentPage,
                        min: 1,
                        max: this.state.pageCount,
                        showSteppers: false,
                        width: "27px",
                        name: "PageNumber"
                    },
                    order: null
                }, {
                    id: FileToolbarItem.PointerType,
                    itemType: ToolbarItemType.SegmentedButton,
                    itemProps: {
                        def: [{
                            id: PdfControlType.MouseSelect,
                            title: this.props.t("Components:Pdf.MouseSelect"),
                            iconName: "Cursor"
                        }, {
                            id: PdfControlType.MouseGrab,
                            title: this.props.t("Components:Pdf.MouseGrab"),
                            iconName: "Hand"
                        }],
                        selectedButtonId: this.state.controlType
                    },
                    order: null
                }, {
                    id: FileToolbarItem.Zoom,
                    itemType: ToolbarItemType.NumericWriteLine,
                    itemProps: {
                        value: roundToDecimalPlaces(2, this.getZoom()) * 100,
                        width: "47px",
                        description: "%",
                        textAlign: TextAlign.Center,
                        name: "Zoom"
                    },
                    order: null
                },
                {
                    id: FileToolbarItem.ZoomIn,
                    label: this.props.t("Components:Pdf.ZoomIn"),
                    itemType: ToolbarItemType.SmallIcon,
                    iconName: "ZoomIn",
                    order: null
                },
                {
                    id: FileToolbarItem.ZoomOut,
                    label: this.props.t("Components:Pdf.ZoomOut"),
                    itemType: ToolbarItemType.SmallIcon,
                    iconName: "ZoomOut",
                    order: null
                },
                {
                    id: FileToolbarItem.Fit,
                    itemType: ToolbarItemType.SegmentedButton,
                    itemProps: {
                        def: [{
                            id: PdfZoomType.FitToWidth,
                            title: this.props.t("Components:Pdf.FitToWidth"),
                            iconName: "FitToWidth"
                        }, {
                            id: PdfZoomType.FitToPage,
                            title: this.props.t("Components:Pdf.FitToPage"),
                            iconName: "FitToPage"
                        }],
                        selectedButtonId: this.state.zoomType
                    },
                    order: null
                },
                ...(this.props.customFileActions ?? []),
                ...definition.items
            ];
        }

        if (this.props.printSrc) {
            const downloadIndex = definition.items.findIndex((item) => isToolbarItemDefinition(item) && item.id === FileToolbarItem.Download);

            if (downloadIndex >= 0) {
                definition.items = arrayInsert(definition.items,
                    {
                        id: FileToolbarItem.Print,
                        label: "Print",
                        itemType: ToolbarItemType.Icon,
                        iconName: "Print",
                        order: null
                    }, downloadIndex);

            }
        }

        return definition;
    };

    handleItemChange = (id: string, value?: TValue) => {
        switch (id) {
            case FileToolbarItem.Dashboard:
                this.props.onGoToList?.();
                break;
            case FileToolbarItem.PreviousPage:
                this.setCurrentPage(this.state.currentPage - 1);
                break;
            case FileToolbarItem.NextPage:
                this.setCurrentPage(this.state.currentPage + 1);
                break;
            case FileToolbarItem.PageCount:
                this.setCurrentPage(parseInt(value as string));
                break;
            case FileToolbarItem.ZoomIn:
                this.zoomIn();
                break;
            case FileToolbarItem.ZoomOut:
                this.zoomOut();
                break;
            case FileToolbarItem.Zoom:
                this.setZoom(parseInt(value as string) / 100);
                break;
            case FileToolbarItem.PointerType:
                this.setState({
                    controlType: value as PdfControlType
                });
                break;
            case FileToolbarItem.Fit:
                const zoomType = value as PdfZoomType;

                if (zoomType === PdfZoomType.FitToWidth) {
                    this.preserveScroll();
                }

                this.updateZoom({
                    zoomType
                });
                this.setState({
                    zoomType
                });

                break;
            case FileToolbarItem.Download:
                saveAs(this.props.src, this.props.fileName ?? getFileNameFromSrc(this.props.src));
                break;
            case FileToolbarItem.Print:
                window.open(this.props.printSrc, "_blank");
                break;
            case FileToolbarItem.Remove:
                this.props.onFileRemove?.(this.props.src);
                break;
            default:
                this.props.onCustomFileAction?.(id);
        }

    };

    renderPages = () => {
        return (
            new Array(this.state.pageCount).fill(null).map(
                (el, i) => {
                    const pageNumber = i + 1;

                    if (this.state.zoomType === PdfZoomType.FitToPage && pageNumber !== this.state.currentPage) {
                        return null;
                    }

                    return (
                        <Page
                            renderAnnotationLayer={false}
                            key={`page_${pageNumber}`}
                            loading={""}
                            pageNumber={pageNumber}
                            // renderMode="svg"
                            onLoadSuccess={this.handlePageLoadSuccess}
                            onRenderSuccess={this.handleRenderSuccess}
                            renderTextLayer={true}
                            width={this.getWidth()}
                        />
                    );
                }
            )
        );
    };

    renderPdf = () => {
        return (
            // wrap CustomResizeObserver outside of scroller, to prevent infinite resizing caused by appearing/disappearing scrollbar
            <ResizerWrapper>
                <CustomResizeObserver onResize={this.handleResize}/>
                <Scroller ref={this.scrollerRef} onScroll={this.handleScroll}>
                    <PdfWrapper tabIndex={0}
                                data-testid={TestIds.PdfWrapper}
                                ref={this.pdfWrapperRef}
                                isDraggable={this.state.controlType === PdfControlType.MouseGrab}
                                onMouseDown={this.handleMouseDown}
                                onWheel={this.handleWheel}
                                onKeyDown={this.handleKeyDown}>
                        <Document
                            file={this.props.src}
                            loading={""}
                            onLoadError={this.handleLoadError}
                            onLoadSuccess={this.handleLoadSuccess}
                            onPassword={this.handlePassword}>
                            {this.renderPages()}
                        </Document>
                    </PdfWrapper>
                </Scroller>
            </ResizerWrapper>
        );
    };

    renderError = () => {
        return (
            <NoPreviewSquirrel text={this.state.error}/>
        );
    };

    render() {
        const def = this.getToolbarDefinition();

        return (
            <StyledFileViewer data-testid={TestIds.FileViewer}>
                <Header>
                    <Toolbar
                        onItemChange={this.handleItemChange}
                        staticItems={def.staticItems}
                        disableResponsiveness={!this.state.isToolbarResponsive}>
                        {def.items}
                    </Toolbar>
                </Header>
                {!this.state.loaded && <BusyIndicator/>}
                {!this.state.error && this.renderPdf()}
                {this.state.error && this.renderError()}
            </StyledFileViewer>
        );
    }
}

export default withTranslation(["Components"])(PDFViewer);