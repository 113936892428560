import { AlertPosition } from "@components/alert/Alert";
import { WithAlert, withAlert } from "@components/alert/withAlert";
import { IValueInterval } from "@components/conditionalFilterDialog/ConditionalFilterDialog.utils";
import { IGroupListItemDef } from "@components/configurationList";
import { getTableDrillDownLink } from "@components/drillDown/DrillDown.utils";
import { SendIcon } from "@components/icon";
import { formatDateToDateString } from "@components/inputs/date/utils";
import { ISelectionChangeArgs } from "@components/inputs/select/Select.types";
import { IFieldInfoProperties } from "@components/smart/FieldInfo";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import {
    IMetaColumnFormatterArgs,
    IReportColumnDef,
    IReportColumnFormatterArgs,
    IReportColumnGroupDef,
    IReportRowDef,
    TMetaColumnFormatter
} from "@components/smart/smartTable";
import { updateRows } from "@components/smart/smartTable/SmartTable.utils";
import { ISort } from "@components/table";
import { getNestedValue } from "@odata/Data.utils";
import { ActionTypeCode } from "@odata/GeneratedEnums";
import { IFormatOptions, logAction } from "@odata/OData.utils";
import { CUSTOM_DATE_RANGE_ID } from "@pages/reports/customFilterComponents/ComposedDateRange.utils";
import { saveAs } from "file-saver";
import i18next from "i18next";
import { isEqual } from "lodash";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { SegmentedButton } from "../../../components/button/SegmentedButton";
import IconSelect from "../../../components/inputs/select/IconSelect";
import { REST_API_URL } from "../../../constants";
import {
    BasicInputSizes,
    BorderSize,
    FieldType,
    QueryParam,
    Sort,
    Status,
    ToolbarItemType,
    ValueType
} from "../../../enums";
import { TRecordAny, TValue } from "../../../global.types";
import { ModelEvent } from "../../../model/Model";
import { TableStorage } from "../../../model/TableStorage";
import BindingContext, { IEntity } from "../../../odata/BindingContext";
import { ROUTE_ACCOUNT_ANALYSIS } from "../../../routes";
import { getQueryParameters } from "../../../routes/Routes.utils";
import CurrencyType from "../../../types/Currency";
import DateType, { DATE_MIN, DateFormat, DisplayFormat, getUtcDate } from "../../../types/Date";
import LocalSettings from "../../../utils/LocalSettings";
import memoizeOne from "../../../utils/memoizeOne";
import { TableActionOrder, TableButtonsAction, TTableToolbarItem } from "../../../views/table/TableToolbar.utils";
import { IProps as IPageProps } from "../../Page";
import { AccountAnalysisProps } from "../accountAnalysis/AccountAnalysisDef";
import {
    CommonReportProps,
    getByDateDef,
    getComparisonDef,
    getSplitIntoPeriodsDef,
    getUnitsDef,
    ReportDisplayType,
    ReportPeriods,
    WITH_DELTA_SUFFIX
} from "../CommonDefs";
import FiscalYearDependentReport, { FiscalYearParameterType } from "../FiscalYearDepenedentReport";
import { IncomeStatementDelta } from "../incomeStatement/IncomeStatement";
import {
    commonReportTranslations,
    getLabelDrilldownFilters,
    getReportDefinition,
    IGetReportDefVal,
    IReportTableDefinition,
    labelColumnNamePrefix,
    NumberAggregationFunction,
    ReportColumnType,
    ReportConfigGroup,
    TGetReportConfigListItemOverride,
    TReportColumnOverrides,
    TReportConfigListItemOverride
} from "../Report.utils";
import { ReportId } from "../ReportIds";
import { ReportStorage } from "../ReportStorage";
import { IReportFilterChangeEvent, IReportViewBaseProps } from "../ReportView";
import {
    enhanceRowsWithIsAsset,
    getAllAccounts,
    getColumnGroupForColumn,
    IBalanceSheetCustomData
} from "./BalanceSheet.utils";
import SendBalanceSheetDialog from "./sendBalanceSheetDialog";

const SHORT_SHEET_OPEN_LINES = ["1", "3", "37", "46", "82", "83", "111"];

export const BalanceSheetProps = {
    showOnlyNettoColumn: BindingContext.localContext("ShowOnlyNettoColumn")
};

const tableId = ReportId.BalanceSheet;
const initialSortBy = [{ id: "Line", sort: Sort.Asc }];

export const getBalanceSheetExportUrl = (storage: TableStorage, isShortReport: boolean) => {
    const company = storage.context.getCompany().Id;
    const byDate = formatDateToDateString(storage.data.entity[CommonReportProps.byDate]);

    return `${REST_API_URL}/BalanceSheetPdfExport/${company}/${byDate}/${isShortReport}?CompanyId=${company}`;
};

export const toggleGroupsByReportDisplayType = (storage: ReportStorage, key: ReportDisplayType, shortValues: string[]) => {
    // todo we can't allow to call this before the table is loaded
    const state = storage.tableAPI.getState();
    const rows = updateRows({
        rows: state.rows,
        rowsOrder: state.rowsOrder,
        updateFn: (row, level) => {
            return {
                ...row,
                open: key === ReportDisplayType.Full ? !!row.rows : shortValues.includes(row.values?.Line as string)
            };
        }
    });

    storage.tableAPI.setState({
        rows
    });
    storage.refresh();
};

export const getReportDisplayType = (storage: ReportStorage): ReportDisplayType => {
    return LocalSettings.get(storage.data.definition.id)?.customData?.reportDisplayType as ReportDisplayType;
};

export const setReportDisplayType = (storage: ReportStorage, reportDisplayType: ReportDisplayType): void => {
    LocalSettings.set(storage.data.definition.id, { customData: { reportDisplayType: reportDisplayType } });
};

export const handleReportDisplayTypeChange = (key: ReportDisplayType, storage: ReportStorage, initialSortBy: ISort[], shortValues: string[]) => {
    const tableState = storage.tableAPI.getState();

    if (!tableState.sort || isEqual(tableState.sort, initialSortBy)) {
        toggleGroupsByReportDisplayType(storage, key as ReportDisplayType, shortValues);
    } else {
        storage.tableAPI.setState({
            sort: initialSortBy
        });
        storage.tableAPI.reloadTable();
    }

    let settings = storage.settings;

    // only trigger data reload if filters changed
    if (settings.Filter && Object.keys(settings.Filter).length > 0) {
        storage.clearFilters();
        settings = {
            ...settings,
            Filter: null
        };
    }

    setReportDisplayType(storage, key);
    LocalSettings.remove(storage.data.definition.id, "openedRowsIds");

    storage.settings = settings;
};

export const getColumnBorder = (args: IReportColumnFormatterArgs) => {
    const isLast = args.index === args.allColumns.length - 1;
    let border = BorderSize.None;

    if (isLast) {
        return border;
    }

    if (!args.settings[CommonReportProps.comparison]) {
        if (args.isInMetaColumn) {
            border = BorderSize.Thick;
        }
    } else if (args.isInMetaColumn && args.metaColumnIndex % 2 === 0) {
        border = BorderSize.Thin;
    } else if (!args.settings[CommonReportProps.showDelta] || args.settings[CommonReportProps.showDelta] === IncomeStatementDelta.No) {
        if (args.isInMetaColumn && args.metaColumnIndex % 2 === 1) {
            border = BorderSize.Thick;
        }
    } else if (args.column.type === ReportColumnType.Delta) {
        border = BorderSize.Thick;
    }

    return border;
};

export const getPeriodsSplitValues = (column: IReportColumnDef | IReportColumnGroupDef, settings: IEntity) => {
    const additionalInfoDate = column.AdditionalInfo?.LastDayOfPeriod && getUtcDate(column.AdditionalInfo?.LastDayOfPeriod);
    const selectedPeriods = settings[CommonReportProps.splitIntoPeriods];

    let label = column.Label;

    if (!column.AdditionalInfo || !column.AdditionalInfo.hasOwnProperty("LastDayOfPeriod")) {
        return { label, info: null };
    }

    const info = column.AdditionalInfo?.LastDayOfPeriod
        ? i18next.t("Reporting:BalanceSheet.StateForDay",
            { day: DateType.format(additionalInfoDate, DisplayFormat.DateMedium) })
        : i18next.t("Reporting:BalanceSheet.OutsideOfRange");

    if (additionalInfoDate) {
        if (selectedPeriods === ReportPeriods.Months) {
            label += ` \n${DateType.format(additionalInfoDate, DateFormat.month)}`.toUpperCase();
        } else if (selectedPeriods === ReportPeriods.Periods) {
            label += ` \n${column.AdditionalInfo.PeriodNumber}. ${i18next.t("Reporting:BalanceSheet.Period")}`;
        }
    }

    return { label, info };
};

export const metaColumnFormatter: TMetaColumnFormatter = (args: IMetaColumnFormatterArgs) => {
    const { label, info } = getPeriodsSplitValues(args.metaColumn, args.settings);
    const border = getColumnBorder({
        column: args.column, index: args.columnIndex,
        isInMetaColumn: true,
        metaColumnIndex: args.index,
        allColumns: args.allColumns,
        settings: args.settings
    });

    return { label, info, border };
};

export const columnBorderFormatter = (args: IReportColumnFormatterArgs) => {
    const column = { ...args.column };

    column.border = getColumnBorder(args);

    return column;
};

export const resetReportDisplayType = (storage: ReportStorage) => {
    setReportDisplayType(storage, ReportDisplayType.None);
    storage.refresh();
};

export const getReportDisplayTypeToolbarButtons = (onChange: (key: string) => void, displayType: ReportDisplayType): TTableToolbarItem => {
    return {
        id: "reportType",
        itemType: ToolbarItemType.Custom,
        order: TableActionOrder.Add - 1,
        render: () => {
            return <SegmentedButton
                key={"reportType"}
                hotspotId={"reportType"}
                onChange={onChange}
                style={{ marginRight: "10px" }}
                selectedButtonId={displayType}
                def={[
                    { id: ReportDisplayType.Short, label: i18next.t("Reporting:BalanceSheet.Short") },
                    { id: ReportDisplayType.Full, label: i18next.t("Reporting:BalanceSheet.Full") }
                ]}/>;
        }
    };
};

const getDefinition = (): IReportTableDefinition => {
    const title = i18next.t("Reporting:BalanceSheet.Title");
    const path = "BalanceSheet";
    const disableAggregateButton = true;
    const parameters: string[] = [
        CommonReportProps.byDate,
        BalanceSheetProps.showOnlyNettoColumn,
        CommonReportProps.comparison,
        CommonReportProps.showDelta,
        CommonReportProps.splitIntoPeriods
    ];
    const updateFiltersFromResponse = true;

    const configListItemsOverride: TGetReportConfigListItemOverride = (item: IGroupListItemDef): TReportConfigListItemOverride => {
        switch (true) {
            case item.id === "Section":
                return {
                    ...item,
                    isRequired: true,
                    isDisabled: true,
                    isCopyOnly: false,
                    group: ReportConfigGroup.Groups
                };
            case item.id === "Line":
                return {
                    ...item,
                    isRequired: true,
                    isDisabled: true,
                    isCopyOnly: false,
                    group: ReportConfigGroup.Columns

                };
            case item.id === "Number":
                return {
                    ...item,
                    isRequired: true,
                    isDisabled: true,
                    isCopyOnly: false,
                    group: ReportConfigGroup.Columns
                };
            case item.id === "Values":
                return {
                    ...item,
                    isRequired: true,
                    isDisabled: true,
                    isCopyOnly: false,
                    areItemsReadOnly: true,
                    group: ReportConfigGroup.Aggregations
                };
            case item.id.startsWith(labelColumnNamePrefix):
                return {
                    ...item,
                    allowedGroups: [ReportConfigGroup.Groups, ReportConfigGroup.Columns, ReportConfigGroup.AvailableColumns]
                };
        }

        return item;
    };

    const defaultReportHierarchy = {
        "Aggregate": true,
        "Groups": [
            {
                "ColumnAlias": "Section"
            }
        ],
        "Columns": [
            {
                "ColumnAlias": "Line"
            },
            {
                "ColumnAlias": "Number"
            }
        ],
        "Aggregations": [
            {
                "ColumnAlias": "Values",
                "AggregationFunction": NumberAggregationFunction.Sum
            }
        ]

    };


    /** Backend sends account ids that are needed for drilldown only for the leaves
     * => we need to collect those data and propagate them to all levels */
    const rowsDataFactory = (rowsData: IReportRowDef[]): IReportRowDef[] => {
        if (rowsData[0]) {
            enhanceRowsWithIsAsset(rowsData[0], true);
        }
        if (rowsData[1]) {
            enhanceRowsWithIsAsset(rowsData[1], false);
        }

        // clear cache for getColumnGroupForColumn, in case column ids has changed
        getColumnGroupForColumn.cache.clear();
        return rowsData;
    };


    // TODO add handling for the special row with custom rules
    const getColumnOverrideFormatter = (columnId: string) => {
        return {
            formatter: (val: TValue, args: IFormatOptions) => {
                const usedColumnId = columnId.startsWith("Delta") ? "Netto" : columnId;
                const formattedVal = CurrencyType.format(val, {
                    currency: args.entity["JournalEntry_CurrencyCode"] ?? "CZK",
                    scale: currencyUnit({ storage: args.storage as ReportStorage, settings: args.storage.data.entity })
                });

                // special rows (lines 82, 83, 102) shouldn't have a drilldown
                const isRowWithoutDrilldown = [82, 83, 102].includes(args.entity.Line);

                if (!val || val === 0 || isRowWithoutDrilldown) {
                    return formattedVal;
                }

                const accounts: string[] = [];

                if (usedColumnId.startsWith("Netto") && !args.entity["NettoAccounts"]) {
                    // combine Netto accounts from Brutto and Correction accounts
                    accounts.push(...getAllAccounts(usedColumnId.replace("Netto", "Brutto"), args.entity));
                    accounts.push(...getAllAccounts(usedColumnId.replace("Netto", "Correction"), args.entity));
                } else {
                    accounts.push(...getAllAccounts(usedColumnId, args.entity));
                }

                const filters: TRecordAny = {
                    [CommonReportProps.dateRange]: CUSTOM_DATE_RANGE_ID,
                    [BindingContext.localContext("Account_Number")]: accounts,
                    [AccountAnalysisProps.drilldown]: args.entity.IsAsset ? "BsAssets" : "BsLiabilies",
                    [AccountAnalysisProps.closingAccounts]: "DoNotShow",
                    [AccountAnalysisProps.sumAmounts]: true,
                    ...getLabelDrilldownFilters(args.entity)
                };

                let dateStart: Date | string;
                let dateEnd: Date | string;

                if (columnId.startsWith("Delta")) {
                    const firstNettoId = Object.keys(args.entity).find(key => key.startsWith("Netto_") && !key.includes("Comp"));
                    const firstColGroup = getColumnGroupForColumn(firstNettoId, args.storage as ReportStorage);
                    const compColGroup = getColumnGroupForColumn(columnId.replace("Delta", "Netto") + "_Comp", args.storage as ReportStorage);

                    dateStart = compColGroup.AdditionalInfo.LastDayOfPeriod;
                    dateEnd = firstColGroup.AdditionalInfo.LastDayOfPeriod;
                } else {
                    const columnGroup = getColumnGroupForColumn(usedColumnId, args.storage as ReportStorage);

                    dateStart = DATE_MIN;
                    dateEnd = columnGroup.AdditionalInfo.LastDayOfPeriod;
                }

                const dateRangeParam: IValueInterval = {
                    from: formatDateToDateString(dateStart),
                    to: formatDateToDateString(dateEnd)
                };

                filters[CommonReportProps.dateRangeCustomValue] = dateRangeParam;

                return getTableDrillDownLink(formattedVal, {
                    route: ROUTE_ACCOUNT_ANALYSIS,
                    context: args.storage.context,
                    storage: args.storage,
                    filters
                });
            }
        };
    };

    const columnOverrides: TReportColumnOverrides = (columnId: string): IFieldInfoProperties => {
        if (["Brutto", "Correction", "Netto", "Delta"].some(key => columnId.startsWith(key))) {
            return getColumnOverrideFormatter(columnId);
        }

        return null;
    };

    const currencyUnit = (args: IGetReportDefVal) => {
        return getNestedValue(CommonReportProps.units, args.storage.data.entity);
    };

    const onAfterTableLoad = (storage: ReportStorage) => {
        const reportDisplayType = getReportDisplayType(storage);

        if (reportDisplayType !== ReportDisplayType.None) {
            toggleGroupsByReportDisplayType(storage, reportDisplayType, SHORT_SHEET_OPEN_LINES);
        }
    };

    const onGroupToggle = (storage: ReportStorage) => {
        resetReportDisplayType(storage);
    };

    const filterBarDef: IFilterGroupDef[] = [
        {
            ...getDefaultFilterGroupDef(FilterBarGroup.Parameters),
            defaultFilters: [
                CommonReportProps.byDate,
                BalanceSheetProps.showOnlyNettoColumn,
                CommonReportProps.comparison,
                CommonReportProps.splitIntoPeriods,
                CommonReportProps.units
            ],
            filterDefinition: {
                [CommonReportProps.byDate]: getByDateDef(),
                [BalanceSheetProps.showOnlyNettoColumn]: {
                    type: FieldType.ComboBox,
                    width: BasicInputSizes.L,
                    defaultValue: true,
                    label: i18next.t("Reporting:BalanceSheet.ShowOnlyNettoColumn"),
                    fieldSettings: {
                        items: [
                            {
                                label: i18next.t("Reporting:BalanceSheet.Netto"),
                                id: true
                            },
                            {
                                label: i18next.t("Reporting:BalanceSheet.ShowAll"),
                                id: false
                            }
                        ]
                    }
                },
                [CommonReportProps.comparison]: {
                    ...getComparisonDef(true)
                },
                [CommonReportProps.splitIntoPeriods]: getSplitIntoPeriodsDef(),
                [CommonReportProps.units]: getUnitsDef()
            }
        },
        {
            ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
            allowCustomFilters: false,
            // filters that are not dynamic and can be specified before hand
            // rest are created AFTER the data are loaded from BE
            defaultFilters: [
                BindingContext.localContext("Section"),
                BindingContext.localContext("Number"),
                BindingContext.localContext("Line")
            ],
            filterDefinition: {
                [BindingContext.localContext("Section")]: {
                    label: i18next.t("Reporting:Columns.Section"),
                    valueType: ValueType.String,
                    isValueHelp: true
                },
                [BindingContext.localContext("Number")]: {
                    label: i18next.t("Reporting:Columns.Number"),
                    valueType: ValueType.String,
                    isValueHelp: true
                },
                [BindingContext.localContext("Line")]: {
                    label: i18next.t("Reporting:Columns.Line"),
                    valueType: ValueType.String,
                    isValueHelp: true
                }
            }
        }
    ];

    return {
        title, path, id: tableId,
        onAfterTableLoad, currencyUnit, rowsDataFactory,
        columnOverrides,
        columnFormatter: columnBorderFormatter, metaColumnFormatter,
        initialSortBy, onGroupToggle, disableAggregateButton,
        filterBarDef, parameters, defaultReportHierarchy,
        configListItemsOverride, updateFiltersFromResponse
    };
};

interface IProps extends IPageProps, WithAlert {

}

class BalanceSheet extends React.PureComponent<IProps> {
    storage: ReportStorage<IBalanceSheetCustomData>;

    onAfterLoad = (storage: ReportStorage) => {
        this.storage = storage;

        setReportDisplayType(this.storage, getQueryParameters()?.[QueryParam.ReportDisplayType] as ReportDisplayType ?? getReportDisplayType(storage) ?? ReportDisplayType.Short);

        this.storage.emitter.on(ModelEvent.VariantChanged, this.handleVariantChange);
        this.handleVariantChange();
    };

    componentWillUnmount() {
        this.storage?.emitter.off(ModelEvent.VariantChanged, this.handleVariantChange);
    }

    handleVariantChange = (): void => {
        const showDelta = this.storage.getValueByPath(CommonReportProps.comparison)?.includes(WITH_DELTA_SUFFIX);
        this.storage.settings = {
            ...(this.storage.settings ?? {}),
            [CommonReportProps.showDelta]: showDelta
        };
    };

    handleReportDisplayTypeChange = (key: string) => {
        handleReportDisplayTypeChange(key as ReportDisplayType, this.storage, initialSortBy, SHORT_SHEET_OPEN_LINES);

        this.forceUpdate();
    };

    handleOpenSendDialog = (args: ISelectionChangeArgs) => {
        this.storage.setCustomData({ sendingMessageDialogOpen: true, messageReportType: args.value as string });
        this.forceUpdate();
    };

    get sendMessageToolbarMenu(): TTableToolbarItem {
        return {
            id: "sendMessage",
            itemType: ToolbarItemType.Custom,
            order: TableActionOrder.Fullscreen + 1,
            render: () => {
                const { rowCount, loaded } = this.storage.tableAPI?.getState() ?? {};
                return <IconSelect
                    key={"sendMessage"}
                    hotspotId={"sendMessage"}
                    title={this.props.t("DataBox:SendDialog.SendBalanceSheet")}
                    onChange={this.handleOpenSendDialog}
                    icon={<SendIcon/>}
                    headerText={this.props.t("DataBox:SendDialog.Send")}
                    isDisabled={!rowCount || !loaded}
                    items={[{
                        id: "short",
                        iconName: "ExportReportShort",
                        label: this.props.t("Components:Table.PdfExportReportShort")
                    }, {
                        id: "full",
                        iconName: "ExportReportFull",
                        label: this.props.t("Components:Table.PdfExportReportFull")
                    }]}/>;
            }
        };
    }

    getCustomToolbarDefinitions = (): TTableToolbarItem[] => {
        return [
            getReportDisplayTypeToolbarButtons(this.handleReportDisplayTypeChange, getReportDisplayType(this.storage)),
            this.sendMessageToolbarMenu
        ];
    };

    handleExpand = () => {
        resetReportDisplayType(this.storage);
    };

    handleSendMessageSuccess = () => {
        this.props.setAlert({
            status: Status.Success,
            title: this.storage.t("Common:Validation.SuccessTitle"),
            subTitle: this.storage.t("DataBox:SendDialog.MessageHasBeenSent")
        });
    };

    handleFilterChange = (args: IReportFilterChangeEvent) => {
        if (args.filterChange.bindingContext.getPath() === CommonReportProps.comparison) {
            args.settings[CommonReportProps.showDelta] = !!args.filterChange.additionalData?.showDelta;
            args.settings[CommonReportProps.comparison] = (args.filterChange.value as string)?.replace(WITH_DELTA_SUFFIX, "");
        }

        return args.settings;
    };

    handlePrintReportButtonClick = (buttonAction: TableButtonsAction) => {
        const isShortReport = buttonAction === TableButtonsAction.PdfExportReportShort;
        const url = getBalanceSheetExportUrl(this.storage, isShortReport);

        logAction({
            actionId: this.storage.id,
            actionType: ActionTypeCode.PDFExport,
            detail: JSON.stringify({
                CompanyId: this.storage.settings.CompanyId,
                IsShortReport: isShortReport
            })
        });

        saveAs(url);
    };

    getReportViewProps = memoizeOne((): Partial<IReportViewBaseProps> => {
        return {
            customToolbarButtons: this.getCustomToolbarDefinitions,
            onFilterChange: this.handleFilterChange,
            onExpandAll: this.handleExpand,
            onCollapseAll: this.handleExpand,
            onPrintReportButtonClick: this.handlePrintReportButtonClick,
            showPrintReportButtons: true,
            expandable: true
        };
    });

    render = () => {
        return (
            <>
                <FiscalYearDependentReport
                    getDef={getReportDefinition.bind(null, getDefinition)}
                    reportViewProps={this.getReportViewProps()}
                    onTableStorageLoad={this.onAfterLoad}
                    fiscalYearParameterType={FiscalYearParameterType.DateRanges}
                />
                {this.storage?.getCustomData().sendingMessageDialogOpen &&
                    <SendBalanceSheetDialog
                        onAfterSend={this.handleSendMessageSuccess}
                        storage={this.storage}/>}
                {this.props.alert}
            </>
        );
    };
}

export default withRouter(withTranslation([...commonReportTranslations, "DataBox"])(withAlert({
    autoHide: true,
    position: AlertPosition.CenteredBottom,
    style: { width: "400px" }
})(BalanceSheet)));