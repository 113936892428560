import Tooltip from "@components/tooltip";
import { composeRefHandlers } from "@utils/general";
import React, { AnchorHTMLAttributes, PropsWithChildren } from "react";
import { Link, LinkProps } from "react-router-dom";

import { AppContext } from "../../contexts/appContext/AppContext.types";
import { addCompanyIdToUrl } from "../../contexts/appContext/AppContext.utils";
import { KeyName } from "../../keyName";
import TestIds from "../../testIds";
import { THistoryLocation } from "../drillDown/DrillDown.utils";
import { ClickableText } from "./Clickable.styles";
import { isExternalLink } from "./Clickable.utils";

interface IProps {
    link?: THistoryLocation;
    onClick?: (event: React.SyntheticEvent) => void;
    // if true, text will get truncated with ellipsis when it overflows
    // and hover tooltip will be added automatically
    useEllipsis?: boolean;
    tabIndex?: number;
    passRef?: React.RefObject<HTMLAnchorElement>;
    isLink?: boolean;
    isLight?: boolean;
    isDisabled?: boolean;
    // styled by parent component, that only wants the functionality
    isWithoutStyle?: boolean;
    // by default, it is rendered as inline element (text)
    // rendering as flex will make the element the size of the text, without the default space around it (line height)
    renderAsFlex?: boolean;

    onMouseEnter?: (event: React.MouseEvent) => void;
    onMouseLeave?: (event: React.MouseEvent) => void;
    onFocus?: (e: React.FocusEvent) => void;
    onBlur?: (e: React.FocusEvent) => void;

    testId?: string;
    className?: string;
    style?: React.CSSProperties;
    companyId?: number;
}

/** Main navigation component.
 * If you need (blue/purple) text that can be clicked on, that either navigates or triggers some action,
 * USE THIS!
 * */
class Clickable extends React.PureComponent<PropsWithChildren<IProps>> {
    static contextType = AppContext;

    handleKeyDown = (event: React.KeyboardEvent): void => {
        if (event.key === KeyName.Enter) {
            this.handleClick(event);
        }
    };

    handleClick = (event: React.SyntheticEvent): void => {
        if (this.props.isDisabled) {
            return;
        }
        if (this.props.link) {
            event.stopPropagation();
            // click is handled by browser with the href attribute
        }
        if (!event.defaultPrevented) {
            this.props.onClick?.(event);
        }
    };

    handleContextMenu = (event: React.SyntheticEvent): void => {
        event.stopPropagation();
    };

    render() {
        const optProps: AnchorHTMLAttributes<HTMLAnchorElement> | LinkProps = {};

        const { isDisabled, link, companyId } = this.props;
        const isExternal = typeof link === "string" && isExternalLink(link);
        const isLink = this.props.isLink || !!link;
        let clickableElement: React.ElementType = "a";
        const fullLink = addCompanyIdToUrl(link, companyId ?? this.context);

        if (isLink && fullLink && !this.props.isDisabled) {
            if (isExternal) {
                optProps.href = fullLink as string;
                optProps.target = "_blank";
            } else {
                (optProps as LinkProps).to = fullLink;
                clickableElement = Link;
            }
        }

        return (
            <Tooltip content={this.props.children}
                     onlyShowWhenChildrenOverflowing
                     isHidden={!this.props.useEllipsis}>
                {(ref) => {
                    return <ClickableText className={this.props.className}
                                          style={this.props.style}
                                          ref={composeRefHandlers(ref, this.props.passRef)}
                                          tabIndex={this.props.tabIndex}
                                          as={clickableElement}
                                          $useEllipsis={this.props.useEllipsis}
                                          $renderAsFlex={this.props.renderAsFlex}
                                          aria-disabled={this.props.isDisabled}
                                          onClick={this.handleClick}
                                          onContextMenu={this.handleContextMenu}
                                          onKeyDown={this.handleKeyDown}
                                          onMouseEnter={this.props.onMouseEnter}
                                          onMouseLeave={this.props.onMouseLeave}
                                          onFocus={this.props.onFocus}
                                          onBlur={this.props.onBlur}
                                          $hasLinkStyle={isLink}
                                          $disabled={isDisabled}
                                          $isLight={this.props.isLight}
                                          $isWithoutStyle={this.props.isWithoutStyle}
                                          data-testid={this.props.testId ?? TestIds.ClickableText}
                                          {...optProps}>
                        {this.props.children}
                    </ClickableText>;
                }}
            </Tooltip>
        );
    }
}

export default Clickable;