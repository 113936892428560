import { IAlertProps } from "@components/alert/Alert";
import { isComplexFilterArr } from "@components/conditionalFilterDialog/ConditionalFilterDialog.utils";
import { getDrillDownFilters } from "@components/drillDown/DrillDown.utils";
import { isSelectBasedComponent } from "@components/inputs/select/SelectAPI";
import { getInfoValue, IFieldDef, isVisible } from "@components/smart/FieldInfo";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import { FilterBarGroup, IFilterDef, IFilterGroupDef } from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { IAffectedRow, TSmartODataTableStorage } from "@components/smart/smartTable/SmartODataTableBase";
import { addIndexToDuplicateColumnId } from "@components/smart/smartTable/SmartReportTable.utils";
import {
    getValueHelpFilterGroupByProps,
    parseCompositeFilterId
} from "@components/smart/smartValueHelper/ValueHelper.utils";
import { IRow, ISort } from "@components/table";
import { ITableVariant, IVariantColumn, Variant } from "@components/variantSelector/VariantOdata";
import { getBoundValue, getUniqueContextsSuffix, setBoundValue } from "@odata/Data.utils";
import { getFieldTypeFromProperty, getValueTypeFromProperty, IFieldInfo } from "@odata/FieldInfo.utils";
import { VariantTypeCode } from "@odata/GeneratedEnums";
import { createFilterString, removeKeyFromPath } from "@odata/OData.utils";
import { setTableViewMode } from "@utils/DraftUtils";
import { isDefined, isNotDefined } from "@utils/general";
import { logger } from "@utils/log";
import { cloneDeep } from "lodash";

import SmartTableManager from "../components/smart/smartTable/SmartTableManager";
import { EMPTY_VALUE } from "../constants";
import { LogicOperator, TableAddingRowType, TableViewMode } from "../enums";
import { TRecordType, TRecordValue, TValue } from "../global.types";
import BindingContext, { createBindingContext, IEntity } from "../odata/BindingContext";
import LocalSettings from "../utils/LocalSettings";
import memoizeOne from "../utils/memoizeOne";
import { FormStorage } from "../views/formView/FormStorage";
import { TableButtonsAction } from "../views/table/TableToolbar.utils";
import { IChangedFilter, isDraftView, ISplitPageTableDef } from "../views/table/TableView.utils";
import { IMergedFieldDef, ModelEvent, ModelType } from "./Model";
import { IStorageModelData, StorageModel } from "./StorageModel";

interface ITableStorageInitArgs {
    definition: ISplitPageTableDef;
    rowBindingContext?: BindingContext;
    bindingContext?: BindingContext;
    preserveInfos?: boolean;
    predefinedFilters?: TRecordValue;
    useDrilldownFilters?: boolean;
    isInDialog?: boolean;
}

// name of interface and its keys prone to change
export interface IFilterQuery {
    // filter built from changed filters and draft filter if present
    query: string;
    // filter built from changed filters WITHOUT draft filter if present,
    // count values in secondary tabs are not related to draft and have to be filtered without it
    secondaryTabsValuesQuery?: string;
    // filter passed from definition
    // at the moment seems to be only used in SmartHierarchyTable for CoA
    defaultFilter?: string;
    isInvalid?: boolean;
    /** Filter queries for nested collection
     * e.g. main query is filtering on LabelHierarchies, collectionQueries contains filter for LabelHierarchies/Labels */
    collectionQueries?: TRecordType<IFilterQuery>;
}

export interface ITableStorageDefaultCustomData {
    customOnAfterLoadFn?: () => void;
    rootStorage?: FormStorage;
    isTableReady?: boolean;
    showPdfExport?: boolean;
    isSortingDialogOpen?: boolean;
    isCustomizationDialogOpen?: boolean;
    groupedRows?: IRow[];
    affectedRows?: IAffectedRow[];
}

export interface ITableStorageData<C extends ITableStorageDefaultCustomData = ITableStorageDefaultCustomData> extends IStorageModelData<IEntity, C> {
    expandedGroupKey?: FilterBarGroup;
    visibleFilters?: Record<FilterBarGroup, string[]>;
    alert?: IAlertProps;
    definition?: ISplitPageTableDef;
    // current columns from "columns" definition merged with data from "columnDefinition"
    mergedColumns?: IFieldDef[];
    addingRow?: TableAddingRowType;
    filterQuery?: IFilterQuery;
    customSecondaryFilter?: string;
    predefinedFilters?: TRecordValue;
    rowBindingContext?: BindingContext;
    variant?: ITableVariant;
    tableAction?: TableButtonsAction | string;
    tableViewMode?: TableViewMode;
}

export class TableStorage<TableAPI = {}, C extends ITableStorageDefaultCustomData = ITableStorageDefaultCustomData> extends StorageModel<IEntity, ITableStorageData<C>, C> {
    data: ITableStorageData = {};
    loaded = false;
    type = ModelType.Table;

    LOCAL_STORAGE_VARIANT_NAME = "tableVariant";

    init = async (args: ITableStorageInitArgs): Promise<void> => {
        this.loading = true;

        // those values need to be stored before loadFilters is called
        this.store({
            rowBindingContext: args.rowBindingContext,
            bindingContext: args.bindingContext,
            definition: args.definition,
            additionalFieldData: {},
            fieldsInfo: args.preserveInfos ? { ...this.data.fieldsInfo } : {},
            predefinedFilters: args.predefinedFilters ?? (args.useDrilldownFilters ? getDrillDownFilters() : null)
        });

        if (!args.isInDialog) {
            setTableViewMode(this.getThis() as TSmartODataTableStorage, true);
        }

        if (!args.definition.preventStoreVariant) {
            await this.loadVariants(VariantTypeCode.TableVariant, args.bindingContext);
        }

        try {
            await this.loadStoredFilters();
        } catch (e) {
            /**
             * Binding context might not be defined, e.g. when company has no chartOfAccounts, then we should show
             * the page in empty state, however there is also no bindingContext in AuditTrailView where we want to
             * load filters, because they are all with just localContext...
             * -> if filterGroups are defined (filters were loaded correctly), fall silently
             **/
            if (!this.data.visibleFilters) {
                throw e;
            }
        }

        await this.updateCurrentTableColumns();
        this.onAfterLoad?.();
        this.createTableValidationSchema();

        this.loaded = true;
        this.loading = false;
    };

    /** Extend the returned interface with IFilterDef,
     * which is the actual interface used for table filters */
    getInfo(bc: BindingContext): (IFieldInfo & IFilterDef) {
        return super.getInfo(bc) as (IFieldInfo & IFilterDef);
    }

    getThis = (): TableStorage => {
        return this as unknown as TableStorage;
    };

    storeSingleValue = (name: keyof ITableStorageData, value: any): void => {
        this.storeSingleValueWithoutCheck(name, value);
    };

    async loadInfo(column: IMergedFieldDef, bindingContext: BindingContext): Promise<IFieldInfo> {
        const info = await super.loadInfo(column, bindingContext);

        return {
            ...info,
            // by default, all filters are not required
            isRequired: column.fieldDef.isRequired ?? false
        };
    }

    get tableId(): string {
        return this.data.definition.id;
    }

    get tableAPI(): TableAPI {
        return SmartTableManager.getTable(this.tableId) as unknown as TableAPI;
    }

    get tableAction(): TableButtonsAction | string {
        return this.data.tableAction;
    }

    get isDisabled(): boolean {
        return false;
    }

    get predefinedFilters(): TRecordValue {
        return this.data.predefinedFilters;
    }

    /** For given group id, returns visibleFilters fieldInfos
     * Basically, this replaces IFilterGroupStatus which we used before to store each fieldInfo */
    getFilterGroupFieldInfos = (filterGroupId: FilterBarGroup): IFieldInfo[] => {
        return this.data.visibleFilters[filterGroupId].map((fullFilterName) => {
            return this.data.fieldsInfo[fullFilterName];
        });
    };

    loadStoredFilters = async (): Promise<void> => {
        let customFieldsStored: string[];
        if (this.predefinedFilters) {
            const entitySetPath = this.data.bindingContext?.getFullPath(true);
            customFieldsStored = [...this.getDefaultFilters(), ...Object.keys(this.predefinedFilters).map((path: string) => entitySetPath ? `${entitySetPath}/${path}` : path)];
            customFieldsStored = customFieldsStored.filter((field, index, array) => array.indexOf(field) === index);
        } else {
            customFieldsStored = this.getVariant()?.visibleFilters ?? this.getDefaultFilters();

            // inject always present filters, that doesn't have to be stored in the variant (e.g. BankAccount filter in BankTransactions)
            // otherwise, it won't be rendered
            const nonCustomizableDefs = this.data.definition.filterBarDef.filter(def => !def.allowCustomFilters);

            for (const def of nonCustomizableDefs) {
                const prefix = this.data.bindingContext.getRootParent().getPath(true);

                for (const field of def.defaultFilters) {
                    const fullPath = `${prefix}/${field}`;

                    if (!customFieldsStored.includes(fullPath)) {
                        customFieldsStored.push(fullPath);
                    }
                }
            }
        }

        return this.loadFilters(customFieldsStored);
    };

    setTableAlert = (alert: IAlertProps, withoutRefresh = false): void => {
        if (alert === null) {
            this.clearTableAlert();
        } else {
            this.store({
                alert: {
                    ...alert,
                    onClose: () => {
                        // clear alert after it is closed (e.g. fade out of success alert)
                        this.clearTableAlert();
                        this.refresh(true);
                    }
                }
            });
            if (!withoutRefresh) {
                this.refresh(true);
            }
        }
    };

    clearTableAlert = (): void => {
        this.store({
            alert: null
        });
    };

    getDefaultFilters = (skipGroupId?: string): string[] => {
        const entitySetPath = this.data.bindingContext?.getFullPath(true);
        const filters: string[] = [];

        this.data.definition.filterBarDef.forEach(def => {
            if (def.id !== skipGroupId) {
                def.defaultFilters.forEach(path => {
                    const resPath = entitySetPath ? `${entitySetPath}/${path}` : path;
                    filters.push(resPath);
                });
            }
        });

        return filters;
    };

    loadFilters = async (allFilters: string[] = [], groupId?: string): Promise<void> => {
        const { definition, bindingContext, entity } = this.data;
        const fieldsInfo: Record<string, IFieldInfo> = this.data.fieldsInfo || {};
        const visibleFilters: Record<string, string[]> = this.data.visibleFilters || {};
        let data = entity ?? {};
        // store all fields used in filters to update defaultValues with setCurrentValueFromEntity
        const allPaths: string[] = [];

        const _add = async (field: IFieldDef, filterBindingContext?: BindingContext) => {
            if (!isVisible({ info: field, storage: this.getThis() })) {
                return;
            }

            let fieldBindingContext = filterBindingContext ?? (bindingContext ? bindingContext.navigate(field.id) : createBindingContext(field.id, this.oData.metadata));
            const withoutKey = !field.factory;
            const path = fieldBindingContext.getFullPath(withoutKey);

            if (allPaths.indexOf(field.id) < 0) {
                allPaths.push(field.id);
            }

            const info = await this.loadInfo({
                path: field.id,
                fieldDef: {
                    ...field
                }
            }, fieldBindingContext);

            // ALL navigation filter has to have specified displayName in their field info to work properly
            // uncomment when pushTo isn't field and it is verified that the check make sense
            if (fieldBindingContext.isNavigation() && !info.fieldSettings?.displayName) {
                logger.warn(`Error for '${fieldBindingContext.getPath()}' filter. Can't add navigation filter without specifying 'displayName' in its field info.`);
                return;
            }
            if (info.fieldSettings?.displayName) {
                // load info also for displayName property, so we can validate it in ConditionalFilterDialog
                // field/filter is prepared for the navigation property, e.g. BusinessPartner (mainly because of the field label)
                // but validations need to be run against particular property, which is filtered, e.g. BusinessPartner/Name
                const displayName = info.fieldSettings?.displayName,
                    displayNameBindingContext = fieldBindingContext.navigate(displayName),
                    displayNamePath = displayNameBindingContext.getFullPath(true);

                if (!fieldsInfo[displayNamePath]) {
                    fieldsInfo[displayNamePath] = await this.loadInfo({
                        path: `${field.id}/${displayName}`,
                        fieldDef: {
                            ...field
                        }
                    }, displayNameBindingContext);
                }
            }

            let keyProp: string = null;
            if (fieldBindingContext.isNavigation() && !fieldBindingContext.isCollection()) {
                keyProp = fieldBindingContext.getKeyPropertyName();
                fieldBindingContext = fieldBindingContext.navigate(keyProp);
            }

            const filterValue = getBoundValue({
                bindingContext: fieldBindingContext,
                data,
                dataBindingContext: bindingContext
            });

            if (!isDefined(filterValue)) {
                // Filter has no current value -> add defaultValue if defined
                // ignore stored values in draft view
                // don't use filters from LocalSettings when drillDownFilters passed via history state
                let defVal = (
                    this.isDraftView() ? null
                        : this.predefinedFilters ? this.predefinedFilters[info.bindingContext.getNavigationPath()]
                            // pass info manually, it is not stored into fieldsInfo just yet
                            : this.getFilterValueFromVariant(info.bindingContext, info as (IFieldInfo & IFilterDef))
                ) ?? getInfoValue(info, "defaultValue", {
                    data: this.data.entity,
                    storage: this.getThis(),
                    info: info,
                    context: this.context
                });

                // issue DEV-27488 filters stored array of values including key property name
                //  -> keep just values to be stored as parsedValue
                if (defVal && typeof defVal === "object" && defVal[keyProp]) {
                    defVal = defVal[keyProp];
                }

                if (isDefined(defVal)) {
                    if (!isComplexFilterArr(defVal)) {
                        // just like in getValueHelperChangeParams,
                        // we don't want to store complex value in data.entity to prevent this complex object from getting into filter SmartFields
                        data = setBoundValue({
                            bindingContext: fieldBindingContext,
                            data,
                            newValue: defVal,
                            dataBindingContext: bindingContext
                        });
                    }
                    this.setFilterValue(info.bindingContext, defVal);
                }
            } else {
                // when we loading filters and filter value is already set, we need to keep parsedValue in sync because of value helper fields.
                // e.g. in ChartOfAccounts when switching between tabs, parent entity id is changed, therefore
                this.setFilterValue(info.bindingContext, filterValue);
            }

            if (!fieldsInfo[path]) {
                // don't override already defined fields from defaultFilters by their counterpart from entityProperties
                fieldsInfo[path] = info;
            }
        };

        const infoPromises = [];

        for (const group of definition.filterBarDef || []) {
            if (isDefined(groupId) && groupId !== group.id) {
                continue;
            }

            const filterDefinition = group.filterDefinition;

            for (const filterId of allFilters) {
                const bc = createBindingContext(filterId, this.oData.metadata);
                // isLocal is here to support AuditTrailView and SmartAuditTable
                const navigationPath = bc.isLocal() ? bc.getPath() : bc.getNavigationPath(true, this.data.bindingContext);
                const baseFilterDef = filterDefinition[navigationPath];

                if (baseFilterDef) {
                    if (typeof baseFilterDef.factory === "function") {
                        const [filter] = await baseFilterDef.factory(this.getThis(), filterId);
                        if (filter) {
                            infoPromises.push(_add(filter, filter.bindingContext));
                        }
                    } else {
                        const filter = {
                            id: navigationPath,
                            ...baseFilterDef
                        };
                        infoPromises.push(_add(filter));
                    }
                }
            }

            await Promise.all(infoPromises);

            // fieldInfo must exists, or we skip such configured filter
            visibleFilters[group.id] = allFilters.filter(id => {
                return !!fieldsInfo[id] && (filterDefinition[fieldsInfo[id].id]
                    // for labels
                    ?? filterDefinition[this.data.bindingContext.navigate(fieldsInfo[id].id).getNavigationPath(true)]);
            });
        }

        this.store({
            entity: data,
            fieldsInfo,
            visibleFilters
        });


        // first time page is loaded, there is no expand group key so we want to expand first tab (which won't be shown but rendered)
        // so items in hidden selects will be loaded
        // !! so far this trick works only for the first group !! so default select values in other groups won't show properly
        if (isNotDefined(this.data.expandedGroupKey)) {
            this.store({ expandedGroupKey: definition.filterBarDef[0].id });
        }
    };

    createTableValidationSchema = (): void => {
        const colData: IFieldInfo[] = Object.values(this.data.fieldsInfo);
        const columnNames = colData
            // don't validate selects => they should already contain only valid values (e.g. LabelsHierarchyDef 'Labels/IsActive' filter)
            .filter(column => !isSelectBasedComponent(column.type) && column.useForValidation !== false)
            .map(column => (
                !column.filterName && column.fieldSettings?.displayName ? `${column.id}/${column.fieldSettings?.displayName}` : column.id
            ));

        this.createValidationSchema(columnNames, false);
    };

    updateVisibleFilters = async (id: string, visibleFilters: string[]): Promise<void> => {
        // if this is first time customization, add all default filters (but from cust. group)
        const variant = this.getVariant();

        const storedFilters: string[] = variant.visibleFilters;
        if (!storedFilters) {
            visibleFilters = [...visibleFilters, ...this.getDefaultFilters(id)];
        }

        this.setLocalStorageVariant({
            ...variant,
            visibleFilters
        });

        // TODO: we combine visible with default filters which does not cover case when there are two
        // customizable tabs
        await this.loadFilters(visibleFilters, id);

        // we need to refresh schema with the new filters
        this.createTableValidationSchema();
    };

    returnVariantIfItIsNotEmpty = (variant: ITableVariant): ITableVariant => {
        // system variant doesn't have groups specified as it is in DEF file
        return variant?.columns?.length > 0 ? variant : undefined;
    };

    getInitialSortOrder = memoizeOne(
        (): ISort[] => {
            const variant = this.getVariant();

            if (!variant) {
                return this.data.definition.initialSortBy;
            }

            const sortColumns: IVariantColumn[] = [];

            for (let index = 0; index < variant.columns.length; index++) {
                const col = variant.columns[index];

                let duplicateIndex: number = null;
                const fullColId = col.aggregationFunction ? `${col.id}_${col.aggregationFunction}` : col.id;

                for (let i = 0; i < index; i++) {
                    const fullVarColId = variant.columns[i].aggregationFunction ? `${variant.columns[i].id}_${variant.columns[i].aggregationFunction}` : variant.columns[i].id;

                    if (fullVarColId === fullColId) {
                        duplicateIndex += 1;
                    }
                }

                const id = addIndexToDuplicateColumnId(fullColId, duplicateIndex);

                if (isDefined(col.sortOrder) && !sortColumns.find(c => c.id === id)) {
                    sortColumns.push({
                        ...col,
                        id
                    });
                }
            }

            const sortColumnsOrdered = sortColumns.sort((col1, col2) => col1.sortOrder - col2.sortOrder);

            return sortColumnsOrdered.map(col => ({
                id: col.id,
                sort: col.sortType
            }));
        }
        , () => [this.getVariant()?.columns]);

    getVariantColumnSortProps = (columnId: string, sort: ISort, sortIndex: number): Pick<IVariantColumn, "sortOrder" | "sortType"> => {
        const isSorted = sort && isDefined(sortIndex) && sortIndex >= 0;

        return {
            sortOrder: isSorted ? sortIndex : null,
            sortType: isSorted ? sort?.sort : null
        };
    };

    /** Returns variant if it is already stared either in local storage or in variants */
    getStoredVariant = (canReturnEmptyVariant = false): ITableVariant => {
        const variant = this.getLocalStorageVariant() || this.data.variants?.currentVariant?.table;

        return canReturnEmptyVariant ? variant : this.returnVariantIfItIsNotEmpty(variant);
    };

    /** If no variant is already stored, returns variant created based on default columns */
    getVariant = (): ITableVariant => {
        return this.getStoredVariant() ?? {
            columns: this.createVariantColumns(this.data.definition.columns, this.data.definition.initialSortBy),
            filters: {},
            visibleFilters: this.getDefaultFilters()
        };
    };

    createVariantColumns = memoizeOne((columns: string[] = [], sort?: ISort[]): IVariantColumn[] => {
        // todo: this is temporary solution to support DEV-13833 as we can't sort by invisible columns right now.
        //  to fully support sorting by invisible columns, we need to implement DEV-3652 first. Once the issue is done,
        //  remove this.
        const additionalSortColumns = sort?.filter(val => !columns.includes(val.id.toString()))
            .map(item => item.id.toString()) ?? [];
        // end:todo removal

        return [...columns, ...additionalSortColumns].map(columnId => {
            const sortIndex = sort?.findIndex(sort => sort.id === columnId);

            return {
                id: columnId,
                ...this.getVariantColumnSortProps(columnId, sort?.[sortIndex], sortIndex)
            };
        });
    });

    updateCurrentTableColumns = async (): Promise<void> => {
        const variant = this.getVariant();
        if (!variant.columns) {
            this.data.mergedColumns = [];
            return;
        }

        const mergedColumns: IFieldDef[] = [];
        const extractedDefs: IFieldDef[] = [];

        const _addExtractedColumn = (col: IVariantColumn, defs: IFieldDef[]): boolean => {
            const def = defs.find((def: IFieldDef) => col.id === def.id);
            if (def) {
                mergedColumns.push(def);
                return true;
            }
            return false;
        };

        for (let i = 0; i < variant?.columns?.length; ++i) {
            const column = variant.columns[i];
            const id = removeKeyFromPath(column.id);
            const def = this.data.definition.columnDefinition?.[id];
            if (!def) {
                logger.error(`Definition for ${id} is missing in columnDefinition`);
                continue;
            }
            if (typeof def.factory === "function") {
                if (!_addExtractedColumn(column, extractedDefs)) {
                    // column not in already loaded defs, try to load it first
                    const loadedDefs = await def.factory(this.getThis(), column.id);
                    _addExtractedColumn(column, loadedDefs);
                    // keep the other definitions for possible other columns in variant
                    extractedDefs.push(...loadedDefs);
                }
            } else {
                mergedColumns.push({
                    ...def,
                    ...column
                });
            }
        }
        this.data.mergedColumns = mergedColumns;
    };

    // Filter out empty filters and join with condition
    joinFilters = (queries: string[], condition = "AND"): string => {
        return queries.filter(i => i).join(` ${condition} `);
    };

    createFilterQueryRecursive = (changedFilters: IChangedFilter[], baseContext: BindingContext, condition: "AND" | "OR" = "AND", parentPrefix?: string, lastFilterBindingContext?: string): IFilterQuery => {
        const rootFilters: IChangedFilter[] = [];
        const collectionFilters: TRecordType<IChangedFilter[]> = {};

        const fnBuildFilterQuery = (filter: IChangedFilter, rootBindingContext: BindingContext, prefix?: string): IFilterQuery => {
            if (filter.info.filter?.buildFilter) {
                const filterQuery: string | IFilterQuery = filter.info.filter.buildFilter(filter, {
                    prefix,
                    rootBindingContext
                }, this.getThis());

                return typeof filterQuery === "string" ? { query: filterQuery } : filterQuery;
            }

            // prevent infinite loop if we already expanded the filter once
            const isFilterAlreadyExpanded = filter.bindingContext.toString() === lastFilterBindingContext;
            // if it's navigation binding context, we use filterNames prop, displayName or keyProperty name for filtering,
            // we also want to support searching against multiple columns from one filter definition (see accountAssignmentDef, LabelsHierarchyDef)
            // but the new multiple filters could be whole new filters, including collections, etc...
            // so in that case, we build new changedFilters array and call createFilterQueryRecursive
            // to support same set of features as we support for "main" filters
            if ((filter.bindingContext.isNavigation() || filter.info.filterName) && !isFilterAlreadyExpanded) {
                // e.g. JournalEntry/AccountAssignment -> JournalEntry/AccountAssignment/Id
                //  or BusinessPartner -> BusinessPartner/Name, etc...
                const defaultFilterName = filter.bindingContext.isEnum() ? `../${filter.bindingContext.getPath()}${BindingContext.ENUM_KEY_PROP}` : filter.info.fieldSettings?.displayName ?? filter.bindingContext.getKeyPropertyName();
                const filterName = filter.info.filterName ?? defaultFilterName;
                const filterNames = Array.isArray(filterName) ? filterName as string[] : [filterName];

                let { value } = filter;
                const hasNullValue = Array.isArray(value) ? (value as any[]).includes(EMPTY_VALUE) : value === EMPTY_VALUE;
                const nonNullValues = Array.isArray(value) ? (value as any[]).filter(i => i !== EMPTY_VALUE) : [];
                const additionalFilters: IChangedFilter[] = [];

                const expandedFilters = filterNames.map(filterName => {
                    // We need to distinguish here if we navigate from the filterBinding context or rootBindingContext,
                    // see LabelsHierarchyDef, where we filter BC is "Name" and we also want to filter by "../Labels/Name"
                    let root = filter.bindingContext;
                    if (filterName.startsWith("/")) {
                        root = baseContext;
                        filterName = filterName.slice(1);
                    }
                    if (filterName.startsWith("../")) {
                        root = root.getParent();
                        filterName = filterName.slice(3);
                    }
                    const bindingContext = filterName ? root.navigate(filterName) : filter.bindingContext;

                    // Both expanded filters should have same basic IFieldInfo (otherwise we won't be able to filter by the same value)
                    // but filterName prop should be deleted to stop the recursive navigation
                    const info = { ...filter.info };
                    delete info.filterName;

                    if (hasNullValue && !bindingContext.getProperty().isNullable()) {
                        // if there is null value and filter has displayName, which is not nullable, we add
                        // filter for the relation itself
                        const nullFilter: IChangedFilter = {
                            value: EMPTY_VALUE,
                            info,
                            bindingContext: filter.info.filter?.nullFilterMeansEmptyCollectionFilter ? bindingContext : filter.bindingContext
                        };
                        if (Array.isArray(value) && nonNullValues.length) {
                            // non null values will be added to expandedFilters in usual way
                            value = nonNullValues;
                            // we create additional null filter
                            additionalFilters.push(nullFilter);
                        } else {
                            return nullFilter;
                        }
                    }

                    return {
                        value,
                        info,
                        bindingContext
                    };
                });

                expandedFilters.push(...additionalFilters);

                // build the nested query and join with "OR" - any condition is met todo: ... change base
                const {
                    query,
                    collectionQueries
                } = this.createFilterQueryRecursive(expandedFilters, rootBindingContext, "OR", prefix, filter.bindingContext.toString());
                return {
                    query: expandedFilters.length > 1 ? `(${query})` : query,
                    collectionQueries
                };
            }

            // otherwise it's single filter -> builds the filter string and returns it
            return {
                query: createFilterString(filter, { prefix, rootBindingContext })
            };
        };

        // Adds filter to collection map
        const fnAddCollectionFilter = (filter: IChangedFilter, path: string) => {
            if (!collectionFilters[path]) {
                collectionFilters[path] = [];
            }
            collectionFilters[path].push(filter);
        };

        // checks if filter belongs to collection and return path for it in that case
        const collectionPath = (bc: BindingContext) => {
            const [firstUniqueSuffix] = getUniqueContextsSuffix(bc, baseContext);

            if (firstUniqueSuffix?.isCollection()) {
                return firstUniqueSuffix.getPath();
            }
            return null;
        };

        for (const filter of changedFilters) {
            const path = collectionPath(filter.bindingContext);
            if (path) {
                fnAddCollectionFilter(filter, path);
            } else {
                const groupByProps = getValueHelpFilterGroupByProps(filter.bindingContext, filter.info);

                // Automatically build filter for composite value help filter made from multiple groupByProps,
                // only if custom buildFilter handling does not exist.
                // Ignore complex values => handle in custom buildFilter if needed.
                if (!filter.info?.filter?.buildFilter && Array.isArray(filter.value) && !isComplexFilterArr(filter.value) && groupByProps?.length > 0) {
                    const groupByChangedFilters: Record<string, IChangedFilter> = {};
                    const filterValues = filter.value as string[];

                    for (const prop of groupByProps) {
                        const bc = filter.bindingContext.getRootParent().navigate(prop);
                        const property = bc.getProperty();
                        const newFilter: IChangedFilter = {
                            bindingContext: bc,
                            value: [],
                            info: {
                                id: prop,
                                bindingContext: bc,
                                type: getFieldTypeFromProperty(property),
                                valueType: getValueTypeFromProperty(property)
                            }
                        };

                        groupByChangedFilters[prop] = newFilter;
                    }

                    // // create new object, to prevent changing the original array
                    filter.value = [...(filter.value as string[])];

                    for (let i = 0; i < filterValues.length; i++) {
                        const filterValue = filterValues[i];
                        const entity = typeof filterValue === "string" ? parseCompositeFilterId(filterValue) : filterValue;
                        // parse the original filter value and put it back to the original filter
                        const origFilterValue = getBoundValue({
                            bindingContext: filter.bindingContext,
                            dataBindingContext: this.data.bindingContext,
                            data: entity
                        });

                        filter.value[i] = origFilterValue;

                        // parse the rest of the composite filter value
                        for (const prop of groupByProps) {
                            const bc = filter.bindingContext.getRootParent().navigate(prop);
                            const compositeFilterValue = getBoundValue({
                                bindingContext: bc,
                                dataBindingContext: this.data.bindingContext,
                                data: entity
                            });

                            if (groupByChangedFilters[prop]?.value) {
                                (groupByChangedFilters[prop].value as string[]).push(compositeFilterValue);
                            }
                        }
                    }

                    for (const changedFilter of Object.values(groupByChangedFilters)) {
                        // only add if there is at least one filter, in case there is some stored filter with wrong data
                        if ((changedFilter.value as string[]).length > 0) {
                            rootFilters.push(changedFilter);

                        }
                    }
                }
                rootFilters.push(filter);
            }
        }

        const allFilters = [];
        const collectionQueries: TRecordType<IFilterQuery> = {};

        for (const filter of rootFilters) {
            const filterQuery = fnBuildFilterQuery(filter, baseContext, parentPrefix);
            allFilters.push(filterQuery.query);

            // pass the nested collectionQueries to the root collectionQueries
            if (filterQuery.collectionQueries && !filter.info.shouldNotFilterCollection) {
                for (const [key, value] of Object.entries(filterQuery.collectionQueries)) {
                    if (collectionQueries[key]) {
                        collectionQueries[key].query = this.joinFilters([collectionQueries[key].query, value.query], condition);
                    } else {
                        collectionQueries[key] = {
                            query: value.query
                        };
                    }
                }
            }
        }

        for (const [collection, filters] of Object.entries(collectionFilters)) {
            const collectionRootBc = baseContext.navigate(collection);
            // sometimes, we don't want to apply the collection filter, only use it on root
            const appliedFilters = filters.filter(filter => !filter.info.filter?.onlyApplyCollectionFilterOnRoot);

            const filterQuery = this.joinFilters(appliedFilters.map(filter => {
                let q = fnBuildFilterQuery(filter, collectionRootBc).query;

                if (q === EMPTY_VALUE) {
                    q = `not(${collection}/any())`;
                }

                return q;

            }), condition);

            if (appliedFilters.length > 0) {
                if (collectionQueries[collection]) {
                    collectionQueries[collection].query = this.joinFilters([filterQuery, collectionQueries[collection].query], condition);
                } else {
                    collectionQueries[collection] = {
                        query: filterQuery
                    };
                }
            }

            // when filtering over collections, we need to append /any filter on the root level
            // e.g startswith(Name,'world')) or Labels/any(x: (startswith(x/Name,'world'))
            // otherwise, the root item could get filtered out
            const prefix = "x";
            const emptyValueFilters: string[] = [];
            const valueFilters: string[] = [];
            let query = "";

            for (const filter of filters) {
                const q = fnBuildFilterQuery(filter, collectionRootBc, prefix).query;

                if (q === EMPTY_VALUE) {
                    emptyValueFilters.push(`not(${collection}/any())`);
                } else {
                    valueFilters.push(q);
                }
            }

            if (valueFilters.length > 0) {
                query = `${collection}/any(${prefix}: ${this.joinFilters(valueFilters, condition)})`;
            }

            if (emptyValueFilters.length > 0) {
                query = this.joinFilters([query, ...emptyValueFilters], condition);
            }

            allFilters.push(query);
        }

        return { collectionQueries, query: this.joinFilters(allFilters, condition) };
    };

    createFilterQuery = (changedFilters: IChangedFilter[]): IFilterQuery => {
        let {
            collectionQueries,
            query
        } = this.createFilterQueryRecursive(changedFilters, this.data.bindingContext, "AND");

        // we need to pass current Company to the filter
        // is there any better generic way to do it?
        let defaultFilter = getInfoValue(this.data.definition, "filter", {
            context: this.context,
            storage: this.getThis(),
            bindingContext: this.data.bindingContext
        });

        if (defaultFilter) {
            if (typeof defaultFilter !== "string") {
                collectionQueries = {
                    ...collectionQueries,
                    ...defaultFilter.collectionQueries
                };
                defaultFilter = defaultFilter.query;
            }

            if (defaultFilter) {
                const operator = getInfoValue(this.data.definition, "filterOperator", {
                    context: this.context,
                    storage: this.getThis(),
                    bindingContext: this.data.bindingContext
                }) ?? LogicOperator.And;

                if (operator !== LogicOperator.NonSoloOr || query) {
                    const _op = operator === LogicOperator.NonSoloOr ? "OR" : operator;
                    query = query ? `(${query}) ${_op} ${defaultFilter}` : defaultFilter;
                }
            }
        }

        const secondaryTabsQuery = query;

        if (this.isDraftView() && this.data.definition.draftDef?.draftFilter) {
            const draftFilter = this.data.definition.draftDef.draftFilter;

            if (query) {
                query += " AND ";
                query += draftFilter;
            } else {
                query = draftFilter;
            }
        }

        return {
            query,
            secondaryTabsValuesQuery: secondaryTabsQuery,
            defaultFilter,
            collectionQueries,
            isInvalid: this.isAnyError()
        };
    };

    getChangedFilters = (): { groups: Record<string, IChangedFilter[]>; changedFields: IChangedFilter[]; } => {
        const _append = (filterGroupKey: string, filters: IFieldInfo[]) => {
            for (const filter of filters) {
                const visible = isVisible({
                    info: filter,
                    storage: this.getThis(),
                    bindingContext: filter.bindingContext
                });

                if (!visible) {
                    continue;
                }

                const value = this.getAdditionalFieldData(filter.bindingContext, "parsedValue") ??
                    this.getValue(filter.bindingContext, { useDirectValue: false });
                if (isDefined(value) && value !== "" && !(Array.isArray(value) && value.length === 0)) {
                    if (!result[filterGroupKey]) {
                        result[filterGroupKey] = [];
                    }

                    result[filterGroupKey].push({
                        info: filter,
                        bindingContext: filter.bindingContext,
                        value
                    });
                }
            }
        };

        const result: Record<string, IChangedFilter[]> = {};

        for (const filterGroupId of this.data.definition.filterBarDef.map(filterGroupDef => filterGroupDef.id)) {
            const visibleFiltersFieldInfos = this.getFilterGroupFieldInfos(filterGroupId);

            _append(filterGroupId, visibleFiltersFieldInfos);
        }

        let changedFields: IChangedFilter[] = [];
        for (const group of Object.values(result)) {
            changedFields = changedFields.concat(group);
        }

        return {
            groups: result,
            changedFields
        };
    };

    storeMultiValue = (e: ISmartFieldChange): void => {
        this.addActiveField(e.bindingContext);
        this.addActiveFieldsFromAffectedFields(e.bindingContext);
        const bc = e.bindingContext.isNavigation() ? e.bindingContext.navigate(e.bindingContext.getKeyPropertyName()) : e.bindingContext;

        const updatedData = setBoundValue({
            bindingContext: bc,
            data: this.data.entity,
            newValue: e.value,
            dataBindingContext: this.data.bindingContext
        });

        this.store({
            entity: updatedData
        });
    };

    /** Returns true if the filter is valid and can be used to build new query */
    handleFilterChange = (args: ISmartFieldChange, storeVariantOnChange = true): boolean => {
        if (!Array.isArray(args.value)) {
            this.handleChange(args);
        } else {
            this.storeMultiValue(args);
        }

        // filters are validated onChange instead onBlur, to prevent unwanted requests with wrong filter values
        let error;

        if (!args.additionalData?.isValueHelper
            // don't validate selects, they should already contain only valid items
            && !isSelectBasedComponent(args.type)) {
            // Basic filters are validated here, valueHelp filters are more complex and value has to be validated
            // in ConditionalFilterDialog - the only point where user can fill custom value
            error = this.validateFieldSync(args.bindingContext);
        }

        if (!error) {
            // store parsedValue so that the changed filters can be built with it
            const parsedValue = args.parsedValue ?? args.value;
            this.setFilterValue(args.bindingContext, parsedValue);

            // when drillDownFilters used, we don't want to store any filters
            // also in draft view as well
            if (!this.isDraftView() && !this.predefinedFilters && storeVariantOnChange) {
                this.setFilterValueIntoVariant(args.bindingContext, parsedValue);
            }
        }

        // also in case there is error, filterQuery is changed, so we should trigger the event
        this.emitter.emit(ModelEvent.FilterChanged);

        return !error;
    };

    /**
     * parsedValue - correctly parsed value used to build filter and read only filter bar items
     * value - current value of the input (which may be invalid)
     * */
    setFilterValue(bc: BindingContext, parsedValue: TValue, value?: TValue): void {
        // todo "parsedValue" may be a historic relict,
        // i think it was used to differentiate between current value of the input (which may be invalid)
        // and parsed correct value, used to build filter and read only filter bar items.
        // nowadays, current value should be stored in WithFormatter, so parsedValue may be redundant,
        // and we could only used value stored in storage.data.entity
        this.setAdditionalFieldData(bc, "parsedValue", parsedValue);

        if (value) {
            this.setValue(bc, value);
        }
    }

    setFilterValueByPath(path: string, parsedValue: TValue, value?: TValue): void {
        this.setFilterValue(this.data.bindingContext.navigate(path), parsedValue, value);
    }

    /**
     * Clear all filters from filter group with 'filter' id
     * If at least one filter was cleaned, triggers ModelEvent.FilterChanged
     *
     * @param {string} filterGroupId id of filter group to clear
     * @param {boolean} keepLocalStorageValues if true, values stored in local storage will be kept intact,
     *  used for drafts which ignores local storage filter values
     *
     * @returns {boolean} return true if at least on filter was cleaned, otherwise false
     */
    clearFilters = (filterGroupId = FilterBarGroup.Filters, keepLocalStorageValues?: boolean): boolean => {
        const { groups } = this.getChangedFilters();
        const filtersGroup = groups[filterGroupId];
        const variant = this.getVariant();
        const savedFilters = { ...variant.filters };
        const savedCompanyFilters = { ...variant.companyFilters };

        // only perform all the updates if there actually is some filter to clean
        if (!filtersGroup || filtersGroup.length === 0) {
            return false;
        }

        for (let i = 0; i < filtersGroup.length; i++) {
            const bindingContext = filtersGroup[i].bindingContext;
            const navigationPath = bindingContext.getNavigationPath();
            const info = this.getInfo(bindingContext);

            if (this.predefinedFilters?.[info.id]) {
                continue;
            }

            this.addActiveField(bindingContext);
            this.clearAdditionalFieldData(bindingContext);
            setBoundValue({
                bindingContext,
                data: this.data.entity,
                newValue: null,
                preventCloning: true,
                dataBindingContext: this.data.bindingContext
            });

            if (!keepLocalStorageValues) {
                // filter should be stored in just one of the variables,
                // but it's easier to just remove it from both
                delete savedFilters[navigationPath];
                delete savedCompanyFilters[navigationPath];
            }
        }

        this.setLocalStorageVariant({
            ...variant,
            filters: savedFilters,
            companyFilters: savedCompanyFilters
        });

        this.applyFilters([]);

        this.emitter.emit(ModelEvent.FilterChanged);
        this.emitter.emit(ModelEvent.FiltersCleared);
        this.refreshFields();

        return true;
    };

    isDraftView = (): boolean => {
        return isDraftView(this.getThis() as TSmartODataTableStorage);
    };

    /** Never access variant value directly, instead use this getter.
     * Distinguishes between regular filters and "isCompanyDependent" filters */
    getFilterValueFromVariant = (bindingContext: BindingContext, info = this.getInfo(bindingContext)): unknown => {
        const variant = this.getVariant();
        const isCompanyDependent = !!info.isCompanyDependent;
        const path = bindingContext.getNavigationPath();

        if (isCompanyDependent) {
            const companyId = this.context.getCompanyId();

            return variant.companyFilters?.[companyId]?.[path];
        } else {
            return variant.filters?.[path];
        }
    };

    /** Never access variant value directly, instead use this setter.
     * Distinguishes between regular filters and "isCompanyDependent" filters */
    setFilterValueIntoVariant = (bindingContext: BindingContext, parsedValue: unknown): void => {
        const variant = this.getVariant();
        const isCompanyDependent = !!this.getInfo(bindingContext)?.isCompanyDependent;
        const path = bindingContext.getNavigationPath();

        if (isCompanyDependent) {
            // store the filter change into the company variant change
            const companyId = this.context.getCompanyId();

            this.setLocalStorageVariant({
                ...variant,
                companyFilters: {
                    ...variant.companyFilters,
                    [companyId]: {
                        ...(variant.companyFilters?.[companyId] ?? {}),
                        [path]: parsedValue
                    }
                }
            });
        } else {
            // store the filter change into the variant
            this.setLocalStorageVariant({
                ...variant,
                filters: {
                    ...variant.filters,
                    [path]: parsedValue
                }
            });
        }
    };

    getLocalStorageVariant = (): ITableVariant => {
        if (!this._cachedVariant && !this.data.definition.preventStoreVariant) {
            this._cachedVariant = LocalSettings.get(this.id)?.tableVariant;
        }

        return this._cachedVariant as ITableVariant;
    };

    setLocalStorageVariant = (variant: ITableVariant): void => {
        this._cachedVariant = variant;
        if (!this.data.definition.preventStoreVariant) {
            LocalSettings.set(this.id, {
                tableVariant: variant
            });
        }
    };

    async updateFiltersAfterDraftModeChange(): Promise<void> {
        const isDraft = this.isDraftView();

        this.clearFilters(FilterBarGroup.Filters, true);

        if (!isDraft) {
            await this.loadStoredFilters();
        }

        this.applyFilters();
        this.refresh();
    }

    async changeVariant(variant: Variant, withoutRefresh?: boolean): Promise<void> {
        this.clearFilters();
        this.data.definition.filterBarDef.forEach(filterGroupDef => {
            if (filterGroupDef.clearOnVariantChange) {
                this.clearFilters(filterGroupDef.id);
            }
        });

        await super.changeVariant(variant);
        await this.updateCurrentTableColumns();

        await this.loadStoredFilters();
        this.applyFilters();

        if (!withoutRefresh) {
            this.refresh();
        }
    }

    getFilterFieldGroup = (id: string): IFilterGroupDef => {
        return this.data.definition?.filterBarDef?.find(group => group.filterDefinition?.[id]);
    };

    applyFilters = (changedFields?: IChangedFilter[]): IFilterQuery => {
        changedFields = changedFields ?? this.getChangedFilters()?.changedFields;
        const filterQuery = this.createFilterQuery(changedFields);

        if (filterQuery?.query !== this.data.filterQuery?.query || filterQuery?.isInvalid !== this.data.filterQuery?.isInvalid) {
            this.store({
                filterQuery: {
                    ...filterQuery
                }
            });
        }

        return filterQuery;
    };

    getMergedColumns = memoizeOne((isDraft: boolean): IFieldDef[] => {
        const propsBlackList = this.data.definition.draftDef?.draftPropsBlacklist;
        let columns = cloneDeep(this.data.mergedColumns);

        columns = columns?.filter(col => {
            return isVisible({
                info: col,
                storage: this.getThis(),
                bindingContext: this.data.bindingContext,
                context: this.context
            });
        });

        if (isDraft && propsBlackList?.length) {
            columns = columns?.reduce((retVal, col) => {
                if (propsBlackList.includes(col.id)) {
                    return retVal;
                }
                col.additionalProperties = col.additionalProperties?.filter(prop => !propsBlackList.includes(prop.id));
                retVal.push(col);
                return retVal;
            }, []);
        }
        return columns;
    }, (isDraft) => [isDraft, this.data.mergedColumns]);
}