import styled from "styled-components/macro";

export const ResizerWrapper = styled.div`
    width: 100%;
    flex: 1 1 auto;
    position: relative;
    overflow: hidden;
`;

export const Scroller = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    overflow: auto;
    position: relative;
`;

interface IPdfWrapper {
    isDraggable?: boolean;
}

export const PdfWrapper = styled.div<IPdfWrapper>`
    outline: none;
    cursor: ${props => props.isDraggable ? "grab" : "default"};
    // center the pdf, without breaking horizontal scrolling when the pdf is wider than the screen (which would happen with justify-content: center; on Scroller)
    margin: 0 auto;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

