import { SwitchType } from "@components/inputs/switch/Switch";
import { IFieldDefFn, IGetValueArgs, TValidatorFn } from "@components/smart/FieldInfo";
import { getCommonFilterDefs, getCommonTableColumnsDefs } from "@components/smart/GeneralFieldDefinition";
import {
    FilterBarGroup,
    getDefaultFilterGroupDef,
    IFilterGroupDef
} from "@components/smart/smartFilterBar/SmartFilterBar.types";
import { ISummaryItem } from "@components/smart/smartSummaryItem/SmartSummaryItem";
import { IColumn, IRow } from "@components/table";
import { createBindingContextFromValidatorPath } from "@odata/Data.utils";
import {
    EntitySetName,
    EntityTypeName,
    INumberRangeDefinitionEntity,
    INumberRangeEntity,
    INumberRangeTypeEntity
} from "@odata/GeneratedEntityTypes";
import { FeatureCode, FiscalYearStatusCode } from "@odata/GeneratedEnums";
import { isCashBasisAccountingCompany } from "@utils/CompanyUtils";
import { isNotDefined } from "@utils/general";
import { compareString } from "@utils/string";
import i18next from "i18next";
import React from "react";
import { ValidationError } from "yup";

import FieldsWrapper from "../../components/inputs/field/FieldsWrapper";
import SimpleTable from "../../components/simpleTable";
import SmartField from "../../components/smart/smartField";
import { DASH_CHARACTER } from "../../constants";
import { IAppContext } from "../../contexts/appContext/AppContext.types";
import { BasicInputSizes, FieldType, GroupedField, LabelStatus, TextAlign, ValidatorType } from "../../enums";
import { Model } from "../../model/Model";
import BindingContext, { IEntity } from "../../odata/BindingContext";
import { booleanFormatter } from "../../types/Boolean";
import { getUtcDayjs } from "../../types/Date";
import { IFormDef } from "../../views/formView/Form";
import { FormStorage } from "../../views/formView/FormStorage";
import { ISplitPageTableDef } from "../../views/table/TableView.utils";
import { activeFormatter } from "../chartOfAccounts/ChartOfAccountsDef";
import { setDefByEntityType } from "../getDefByEntityType";
import { getItemBreadCrumbsText, IDefinition, IGetDefinition } from "../PageUtils";
import {
    ChildrenPath,
    getMaxNumericCount,
    INumberRangeFormCustomData,
    isDocumentNumberRange,
    isIssuedDocumentNumberRange,
    numberOfDigitsValidator,
    NumberRangePreviewPath,
    numberRangeTypePrefixValidator,
    NumRangeWithProgressPath,
    prefixSuffixValidator,
    PreviewsPath,
    VarSymbolPreviewPath
} from "./NumberRange.utils";
import { NumberRangeDefinitionPatternFormatter } from "./NumberRangeFields.utils";
import NumberRangeFormView from "./NumberRangeFormView";
import NumRangeHelpTooltip from "./numRangeHelpTooltip";
import NumRangeWithProgressBar from "./numRangeWithProgressBar";

const isFiscalYearClosed = (args: IGetValueArgs) => {
    const numberRange: INumberRangeEntity = args.storage.getValue(args.bindingContext.getParent());

    return numberRange?.FiscalYear?.StatusCode === FiscalYearStatusCode.Closed;
};

const isDocumentNumberRangeGetter = (args: IGetValueArgs): boolean => {
    return isDocumentNumberRange(args.storage);
};

export const isNestedNumberRange = (args: IGetValueArgs): boolean => {
    const numberRangeDef = args.storage.data.entity as INumberRangeDefinitionEntity;

    return isDocumentNumberRange(args.storage) && numberRangeDef.CarryForward;
};

const isIsDefaultDisabled = (args: IGetValueArgs): boolean => {
    return !args.storage.data.bindingContext.isNew() && ((args.storage as FormStorage).data.origEntity as INumberRangeDefinitionEntity).IsDefault;
};

const initialValueValidator: TValidatorFn = (value, args, testContext) => {
    const fieldBc = createBindingContextFromValidatorPath(testContext.path, args.storage.data.bindingContext, args.storage.data.entity);
    const numberOfDigits = args.storage.getValue(fieldBc.getParent().getParent().navigate("NumberOfDigits"));

    if (!Number.isInteger(value)) {
        return new ValidationError(i18next.t("Common:Validation.Integer"), false, testContext.path);
    } else if ((value as number) < 1) {
        return new ValidationError(i18next.t("Common:Validation.SmallNumber", { min: 1 }), false, testContext.path);
    } else if (numberOfDigits && value.toString().length > numberOfDigits) {
        return new ValidationError(i18next.t("NumberRange:Validation.MoreThanNumberOfDigitsInitial"), false, testContext.path);
    }
    return true;
};

const nextValueValidator: TValidatorFn = (value, args, testContext) => {
    const fieldBc = createBindingContextFromValidatorPath(testContext.path, args.storage.data.bindingContext, args.storage.data.entity);
    const initialValue = args.storage.getValue(fieldBc.getParent().navigate("InitialValue"));
    const numberOfDigits = args.storage.getValue(fieldBc.getParent().getParent().navigate("NumberOfDigits"));

    if (!Number.isInteger(value)) {
        return new ValidationError(i18next.t("Common:Validation.Integer"), false, testContext.path);
    } else if ((value as number) < 1) {
        return new ValidationError(i18next.t("Common:Validation.SmallNumber", { min: 1 }), false, testContext.path);
    } else if ((value as number) < initialValue) {
        return new ValidationError(i18next.t("NumberRange:Validation.HigherThanInitial"), false, testContext.path);
    } else if (numberOfDigits && value.toString().length > numberOfDigits) {
        return new ValidationError(i18next.t("NumberRange:Validation.MoreThanNumberOfDigits"), false, testContext.path);
    }
    return true;
};

export const getDefinitions: IGetDefinition = (context: IAppContext): IDefinition => {

    const isCbaCompany = isCashBasisAccountingCompany(context);
    const carryForwardPrefixItems = isCbaCompany ? [{
        id: "%Y%",
        label: "%Y%"
    }, {
        id: "%YY%",
        label: "%YY%"
    }, {
        id: "%YYYY%",
        label: "%YYYY%"
    }] : [{
        id: "%F%",
        label: "%F%"
    }, {
        id: "%FF%",
        label: "%FF%"
    }, {
        id: "%FFFF%",
        label: "%FFFF%"
    }];

    const filterBarDef: IFilterGroupDef[] = [{
        ...getDefaultFilterGroupDef(FilterBarGroup.Filters),
        defaultFilters: [
            "NumberRangeType",
            "Name",
            "CarryForward",
            "IsActive"
        ],
        filterDefinition: {
            NumberRangeType: {
                fieldSettings: {
                    displayName: "Name"
                },
                label: i18next.t("NumberRange:Type")
            },
            Name: {},
            IsActive: {},
            IsDefault: {},
            CarryForward: {
                label: i18next.t("NumberRange:IsCarried")
            },
            ...getCommonFilterDefs()
        },
        isValueHelp: true,
        allowCustomFilters: true
    }];

    const table: ISplitPageTableDef = {
        filterBarDef,
        id: `${EntityTypeName.NumberRange}Table`,
        filter: args => {
            const filters: string[] = [];
            Object.values(FeatureCode).forEach(featureCode => {
                if (!args.storage.context.isFeatureSwitchEnabled(featureCode as FeatureCode)) {
                    filters.push(`NumberRangeType/Feature/Code ne '${featureCode}'`);
                }
            });
            return filters.join(" AND ");
        },
        hierarchy: "grouped", // for properly rendered table
        groupBy: "NumberRangeTypeCode",
        columns: [
            "NumberRangeTypePrefix",
            "Name",
            "CarryForward",
            "IsActive"
        ],
        additionalProperties: [
            { id: "NumberRanges/Usage/IsUsed" }
        ],
        columnDefinition: {
            NumberRangeTypePrefix: {
                id: "NumberRangeTypePrefix",
                label: i18next.t("NumberRange:Type"),
                formatter: NumberRangeDefinitionPatternFormatter,
                isRequired: true,
                isDisabled: true,
                fieldSettings: {
                    disableSort: true
                },
                additionalProperties: [
                    { id: "/Prefix" },
                    { id: "/Suffix" },
                    { id: "/NumberRangeTypePrefix" },
                    { id: "/NumberOfDigits" },
                    { id: "/NumberRangeTypeCode" },
                    { id: "/CarryForwardPrefix" }
                ]
            },
            Name: {
                id: "Name",
                label: i18next.t("NumberRange:Description")
            },
            IsActive: {
                id: "IsActive",
                textAlign: TextAlign.Center,
                formatter: activeFormatter
            },
            IsDefault: {
                formatter: booleanFormatter
            },
            CarryForward: {
                label: i18next.t("NumberRange:IsCarried"),
                formatter: booleanFormatter
            },
            ...getCommonTableColumnsDefs()
        },
        title: i18next.t("NumberRange:Title")
    };

    const summary: ISummaryItem[] = [];

    const form: IFormDef = {
        id: `${EntityTypeName.NumberRange}Form`,
        translationFiles: getDefinitions.translationFiles,
        isDeletable: true,
        getItemBreadCrumbText: (storage: Model) =>
                getItemBreadCrumbsText(storage, i18next.t("NumberRange:NewRange"),
                        storage.data.entity?.Name),
        summary,
        formControl: NumberRangeFormView,
        additionalProperties: [
            { id: "NumberRangeTypePrefix", useForValidation: true, useForComparison: true },
            { id: "Prefix", useForValidation: true, useForComparison: true },
            { id: "NumberRangeType/Feature/Code" },
            { id: "NumberRangeType/Wildcards/Code" },
            { id: "NumberRangeType/Wildcards/Name" },
            { id: "NumberRangeType/Wildcards/IsFiscalYearDependent" },
            { id: "NumberOfDigits", useForValidation: true, useForComparison: true },
            { id: "Suffix", useForValidation: true, useForComparison: true },
            // force form storage to create field info for CarryForwardPrefix
            { id: "CarryForwardPrefix" },
            {
                id: "NumberRanges", additionalProperties: [
                    { id: "FiscalYear/Number" },
                    { id: "FiscalYear/DateStart" },
                    { id: "FiscalYear/StatusCode" },
                    { id: "FiscalYear/Periods/Number" },
                    { id: "InitialValue" }, { id: "NextValue" },
                    { id: "Usage/IsUsed" }
                ]
            }
        ],
        fieldDefinition: {
            NumberRangeType: {
                type: FieldType.ComboBox,
                width: BasicInputSizes.L,
                columns: [{ id: "Name" }, { id: "Prefix" }],
                label: i18next.t("NumberRange:Type"),
                fieldSettings: {
                    displayName: "Name",
                    keyPath: "Code",
                    additionalProperties: [
                        { id: "DocumentTypeCode" },
                        { id: "Feature" },
                        {
                            id: "Wildcards",
                            additionalProperties: [{
                                id: "Name"
                            }, {
                                id: "IsFiscalYearDependent"
                            }, {
                                id: "Replacement"
                            }]
                        }],
                    localDependentFields: [{
                        from: { id: "DocumentTypeCode" },
                        to: { id: "NumberRangeType/DocumentTypeCode" }
                    }],
                    groups: [
                        {
                            id: "documents",
                            title: i18next.t("NumberRange:NumberRangeTypeGroups.Documents").toUpperCase(),
                            hideDivider: true
                        },
                        {
                            id: "others",
                            title: i18next.t("NumberRange:NumberRangeTypeGroups.Others").toUpperCase(),
                            hideDivider: true
                        }
                    ],
                    transformFetchedItems: (items, args) => {
                        items = items.filter(item => {
                            const featureCode = (item.additionalData as INumberRangeTypeEntity).Feature?.Code;
                            return isNotDefined(featureCode) || args.storage.context.isFeatureSwitchEnabled(featureCode as FeatureCode);
                        });

                        return items
                                .sort((a, b) => {
                                    if (a.additionalData?.DocumentTypeCode && !b.additionalData?.DocumentTypeCode) {
                                        return -1;
                                    }

                                    if (!a.additionalData?.DocumentTypeCode && b.additionalData?.DocumentTypeCode) {
                                        return 1;
                                    }

                                    return compareString(a.label, b.label);
                                })
                                .map(item => {
                                    return {
                                        ...item,
                                        groupId: item.additionalData?.DocumentTypeCode ? "documents" : "others"
                                    };
                                });
                    }
                },
                additionalProperties: [{ id: "Prefix" }, { id: "DocumentTypeCode" }],
                affectedFields: [{ id: "CarryForward" }]
            },
            Name: {
                label: i18next.t("NumberRange:Description"),
                width: BasicInputSizes.L,
            },
            IsActive: {
                type: FieldType.Switch,
                fieldSettings: {
                    type: SwitchType.Icons
                },
                defaultValue: true
            },
            IsDefault: {
                type: FieldType.Switch,
                label: i18next.t("NumberRange:IsDefault"),
                fieldSettings: {
                    type: SwitchType.Icons
                },
                isDisabled: isIsDefaultDisabled,
                defaultValue: false
            },
            CarryForward: {
                type: FieldType.Switch,
                fieldSettings: {
                    type: SwitchType.Icons
                },
                defaultValue: true,
                isVisible: isDocumentNumberRangeGetter,
            },
            CarryForwardPrefix: {
                type: FieldType.ComboBox,
                label: i18next.t("NumberRange:Wildcard"),
                width: BasicInputSizes.S,
                groupedField: GroupedField.MultiStart,
                fieldSettings: {
                    items: carryForwardPrefixItems
                },
                defaultValue: isCbaCompany ? "%YY%" : "%FF%",
                isVisible: isNestedNumberRange,
                customizationData: {
                    useForCustomization: false // part of custom field
                }
            },
            Prefix: {
                label: i18next.t("NumberRange:Prefix"),
                groupedField: args => {
                    return isNestedNumberRange(args) ? null : GroupedField.MultiStart;
                },
                width: BasicInputSizes.XS,
                validator: {
                    type: ValidatorType.Custom,
                    settings: {
                        customValidator: prefixSuffixValidator
                    }
                },
                customizationData: {
                    useForCustomization: false // part of custom field
                }
            },
            NumberOfDigits: {
                label: i18next.t("NumberRange:NumberOfDigits"),
                width: BasicInputSizes.S,
                type: FieldType.NumberInput,
                fieldSettings: {
                    min: 1,
                    max: 10,
                    placeholder: (args) => {
                        return i18next.t("NumberRange:MaxNumSigns", { max: getMaxNumericCount(args.storage) });
                    }
                },
                validator: {
                    type: ValidatorType.Custom,
                    settings: {
                        customValidator: numberOfDigitsValidator
                    }
                },
                customizationData: {
                    useForCustomization: false // part of custom field
                }
            },
            Suffix: {
                label: i18next.t("NumberRange:Suffix"),
                groupedField: GroupedField.MultiEnd,
                width: BasicInputSizes.XS,
                validator: {
                    type: ValidatorType.Custom,
                    settings: {
                        customValidator: prefixSuffixValidator
                    }
                },
                customizationData: {
                    useForCustomization: false // part of custom field
                }
            },
            PadWithZeros: {
                type: FieldType.Switch,
                fieldSettings: {
                    type: SwitchType.YesNo
                },
                defaultValue: true
            },
            NumberRangeTypePrefix: {
                type: FieldType.WriteLine,
                labelStatus: LabelStatus.Hidden,
                width: "40px",
                fieldSettings: {
                    maxLength: 3
                },
                validator: {
                    type: ValidatorType.Custom,
                    settings: {
                        customValidator: numberRangeTypePrefixValidator
                    }
                },
                customizationData: {
                    useForCustomization: false // part of custom field
                }
            },
            [NumRangeWithProgressPath]: {
                label: i18next.t("NumberRange:NumberFormat"),
                labelStatus: LabelStatus.Removed,
                type: FieldType.Custom,
                render: (args: IFieldDefFn) => {
                    return (
                            <NumRangeWithProgressBar storage={args.storage as FormStorage}/>
                    );
                },
                customizationData: {
                    isRequired: true // localcontext contains required fields
                }
            },
            "NumberRanges/FiscalYear/Number": {
                width: "60px",
                label: i18next.t("NumberRange:FiscalYear"),
                labelStatus: LabelStatus.Removed,
                isReadOnly: true,
                customizationData: {
                    useForCustomization: false // part of custom field
                }
            },
            "NumberRanges/InitialValue": {
                useForValidation: true,
                width: BasicInputSizes.M,
                labelStatus: LabelStatus.Removed,
                defaultValue: 1,
                validator: {
                    type: ValidatorType.Custom,
                    settings: {
                        customValidator: initialValueValidator
                    }
                },
                customizationData: {
                    useForCustomization: false // part of custom field
                }
            },
            "NumberRanges/NextValue": {
                useForValidation: true,
                labelStatus: LabelStatus.Removed,
                width: BasicInputSizes.M,
                defaultValue: 1,
                isReadOnly: isFiscalYearClosed,
                validator: {
                    type: ValidatorType.Custom,
                    settings: {
                        customValidator: nextValueValidator
                    }
                }
            },
            [ChildrenPath]: {
                additionalProperties: [
                    // needed for NumberRanges/NextValue validation to be added
                    { id: "NumberRanges/NextValue" }
                ],
                label: i18next.t("NumberRange:ChildrenNumbers"),
                labelStatus: LabelStatus.Removed,
                customizationData: {
                    isRequired: true
                },
                render: (args: IFieldDefFn) => {
                    let children = (args.storage.data.entity as INumberRangeDefinitionEntity).NumberRanges;

                    if (!children) {
                        return null;
                    }


                    children = children.sort((nr1, nr2) => {
                        return getUtcDayjs(nr2.FiscalYear.DateStart).diff(nr1.FiscalYear.DateStart);
                    });

                    const isNested = isNestedNumberRange(args);

                    return (
                            <FieldsWrapper isColumn smallerGap>
                                {children.map((child, index) => {
                                    const childBc = args.storage.data.bindingContext.navigate("NumberRanges").addKey(child);
                                    const keyPropertyName = childBc.getKeyPropertyName();
                                    const numberBc = childBc.navigate("FiscalYear/Number");
                                    const initValueBc = childBc.navigate("InitialValue");
                                    const nextValueBc = childBc.navigate("NextValue");
                                    const isFYClosed = isFiscalYearClosed({
                                        bindingContext: childBc.navigate("NextValue"),
                                        storage: args.storage
                                    });

                                    return (
                                            <FieldsWrapper key={(child as IEntity)[keyPropertyName]}>
                                                {isNested ?
                                                        <SmartField
                                                                fieldDef={{
                                                                    ...args.storage.data.fieldsInfo["NumberRangeDefinitions/NumberRanges/FiscalYear/Number"],
                                                                    labelStatus: index === 0 ? LabelStatus.Visible : LabelStatus.Removed
                                                                }}
                                                                bindingContext={numberBc}
                                                                onChange={args.props.parentProps.onChange}
                                                                onBlur={args.props.parentProps.onBlur}
                                                                storage={args.storage}
                                                                useWidthWhenReadOnly
                                                        /> : null
                                                }
                                                <SmartField
                                                        fieldProps={{
                                                            isSharpLeft: isNested,
                                                            isSharpRight: isNested
                                                        }}
                                                        fieldDef={{
                                                            ...args.storage.data.fieldsInfo["NumberRangeDefinitions/NumberRanges/InitialValue"],
                                                            labelStatus: index === 0 ? LabelStatus.Visible : LabelStatus.Removed
                                                        }}
                                                        bindingContext={initValueBc}
                                                        onChange={args.props.parentProps.onChange}
                                                        onBlur={args.props.parentProps.onBlur}
                                                        storage={args.storage}
                                                        useWidthWhenReadOnly

                                                />
                                                <SmartField
                                                        fieldProps={{
                                                            isSharpLeft: isNested,
                                                            hasPadding: false
                                                        }}
                                                        fieldDef={{
                                                            ...args.storage.data.fieldsInfo["NumberRangeDefinitions/NumberRanges/NextValue"],
                                                            labelStatus: index === 0 ? LabelStatus.Visible : LabelStatus.Removed,
                                                            width: isFYClosed ? "169px" : BasicInputSizes.M
                                                        }}
                                                        bindingContext={nextValueBc}
                                                        onChange={args.props.parentProps.onChange}
                                                        onBlur={args.props.parentProps.onBlur}
                                                        storage={args.storage}
                                                        useWidthWhenReadOnly
                                                />
                                            </FieldsWrapper>
                                    );
                                })}
                            </FieldsWrapper>
                    );
                }
            },
            [PreviewsPath]: {
                label: i18next.t("NumberRange:NumberFormatPreview"),
                labelStatus: LabelStatus.Removed,
                render: (args: IFieldDefFn) => {
                    const entity = args.storage.data.entity as INumberRangeDefinitionEntity;
                    const isDocument = isDocumentNumberRange(args.storage);
                    const isNested = isNestedNumberRange({ storage: args.storage });
                    const isIssued = isDocument && isIssuedDocumentNumberRange(args.storage);

                    const columns: IColumn[] = [];

                    if (isNested) {
                        columns.push({ id: "FiscalYear", label: "" });
                    }

                    columns.push({ id: "numRangePreview", label: i18next.t("NumberRange:NumRangePreview") });

                    if (isDocument && isIssued) {
                        columns.push({ id: "varSymbolPreview", label: i18next.t("NumberRange:VarSymbolPreview") });
                    }

                    const rows: IRow[] = [];

                    if (entity.NumberRanges) {
                        for (const child of entity.NumberRanges) {
                            rows.push(
                                    {
                                        id: child.Id ?? (child as IEntity)[BindingContext.NEW_ENTITY_ID_PROP],
                                        values: {
                                            "FiscalYear": child.FiscalYear?.Number,
                                            "numRangePreview": (child as IEntity)[NumberRangePreviewPath] ?? DASH_CHARACTER,
                                            "varSymbolPreview": (child as IEntity)[VarSymbolPreviewPath] ?? DASH_CHARACTER
                                        }
                                    }
                            );
                        }
                    }


                    return (
                            <SimpleTable columns={columns}
                                         rows={rows}
                                         hasBigFont
                                         showHeaderBorder={false}/>
                    );
                }
            }
        },
        groups: [
            // use multiple groups, to create bigger margins, just like in the ux designs
            {
                id: "main",
                rows: [
                    [{ id: "NumberRangeType" }, { id: "Name" }],
                    [{ id: "CarryForward" }],
                    [{ id: ChildrenPath }],
                    [{ id: "IsDefault" }, { id: "IsActive" }]
                ]
            },
            {
                id: "number",
                tooltip: (storage: FormStorage<INumberRangeDefinitionEntity, INumberRangeFormCustomData>) => {
                    const entity = storage.data.entity;
                    let wildcards = entity?.NumberRangeType?.Wildcards;

                    if (!entity.NumberRangeType) {
                        wildcards = storage.getCustomData().allWildcards;
                    }

                    if (!wildcards || wildcards.length === 0) {
                        return null;
                    }

                    return (
                            <NumRangeHelpTooltip wildcards={wildcards}/>
                    );
                },
                title: i18next.t("NumberRange:Range"),
                rows: [
                    [{ id: NumRangeWithProgressPath }],
                    [{ id: "PadWithZeros" }],
                    [{ id: PreviewsPath }]
                ]
            }
        ]
    };

    return {
        entitySet: EntitySetName.NumberRangeDefinitions,
        table,
        form
    };
};

getDefinitions.translationFiles = ["NumberRange", "Common"];
setDefByEntityType(EntityTypeName.NumberRangeDefinition, getDefinitions);