import { allowCreateValues, IGetValueArgs, isRequired, isVisible, IValidator } from "@components/smart/FieldInfo";
import { createBindingContextFromIndexedCollection, setBoundValue } from "@odata/Data.utils";
import { IFieldInfo } from "@odata/FieldInfo.utils";
import { isDefined, isNotDefined, maxNumberFromMaxStringLength } from "@utils/general";
import { i18n as TI18next } from "i18next";
import * as yup from "yup";
import { ValidationError } from "yup";

import { EMAIL_REGEX } from "../constants";
import { FieldType, ValidatorType } from "../enums";
import { TRecordAny, TValue } from "../global.types";
import BindingContext from "../odata/BindingContext";
import { Model } from "./Model";
import {
    ICustomValidatorSettings,
    INumberValidatorSettings,
    IStringValidatorSettings,
    IValidationError,
    IValidationSchema,
    PHONE_REG,
    TBuildInValidationKey,
    TCustomBuildInValidationMessageDef,
    ValidationMessage
} from "./Validator.types";

// TODO is this is still useful/necessary?
/** Remove massive object cloning from yup internal method to improve performance.
 * !!WARNING!! Since there is no cloning, always create new schema for different validators.
 * Don't reuse same schema object. */
// const cloneBackup = yup.mixed.prototype.clone;

// yup.mixed.prototype.clone = function() {
//     // cloning is needed for .when() conditions to work (used in PrintDialog)
//     // to completely get rid of cloning, we would have to refactor PrintDialog
//     if (
//         // @ts-ignore
//         this.conditions?.length > 0
//         || this.deps?.length > 0
//         || this.tests?.length > 0
//     ) {
//         return cloneBackup.call(this);
//     }
//
//     return this;
// };

function isCustomValidatorRecord(obj: unknown, key: TBuildInValidationKey): obj is TCustomBuildInValidationMessageDef {
    return obj.hasOwnProperty(key);
}

function getValidatorMessage(validator: IValidator, key: TBuildInValidationKey, defaultMessage: string): string {
    const msg = validator?.settings?.message ?? {};
    return isCustomValidatorRecord(msg, key) ? msg[key] : defaultMessage;
}

export function getCorrectValidationArgs(storage: Model, info: IFieldInfo, path?: string): IGetValueArgs {
    // validator object can remain the same, while key of binding context can change
    // meaning info.bindingContext doesn't have to be up to date => create new one, with correct key
    const _correctBc = storage.data.bindingContext.getRootParent().navigate(info.bindingContext.getNavigationPath());

    const args = {
        bindingContext: _correctBc,
        info: {
            ...info,
            bindingContext: _correctBc
        },
        storage,
        context: storage?.context
    };

    const isCollection = args.bindingContext.isAnyPartCollection();
    // can be called without path e.g. with isValidSync
    if (isCollection && !!path) {
        args.bindingContext = createBindingContextFromIndexedCollection(path, storage.data.entity, storage.data.bindingContext);
        args.info = storage.getInfo(args.bindingContext);
        if (!args.info && args.bindingContext.getPath() === args.bindingContext.getParent().getKeyPropertyName()) {
            // selects are validated according to their keyProperty, but getInfo has to be for parent
            args.bindingContext = args.bindingContext.getParent();
            args.info = storage.getInfo(args.bindingContext);
        }
    }

    return args;
}

// Number.MAX_SAFE_INTEGER is too much, in inputs we work with float
// and max value was also causing problems in recalculation of line items https://solitea-cz.atlassian.net/browse/DEV-26234
const MAX_SAFE_NUMBER = Math.pow(10, 12);
const MIN_SAFE_NUMBER = -1 * Math.pow(10, 12);

export class Validator {
    static createText = (error: IValidationError): string => {
        return error.message;
    };

    static getErrorText = (error: IValidationError, i18n: TI18next): string => {
        // translate validation messages from yup
        // translations are stored in Common.toml [Validation]
        const errorText = error && Validator.createText(error);
        const key = errorText.includes(":") ? errorText : `Common:${errorText}`;

        let count: number = error?.params?.count ?? 0;
        if (!count && error?.params && typeof error.params === "object") {
            // find numeric value in error.params and use it as "count" to be able to translate messages with correct plurals
            Object.keys(error.params).forEach((param) => {
                if (typeof error.params[param] === "number") {
                    count = error.params[param];
                }
            });
        }

        return error && (i18n.exists(key) || i18n.exists(`${key}_one`)) ? i18n.t(key, {
            ...error.params,
            count
        }) : errorText;
    };

    static schemaHasPath = (schema: yup.Schema<unknown>, path: string): boolean => {
        try {
            yup.reach(schema, path);
        } catch (e) {
            return false;
        }
        return true;
    };

    static addRequiredValidation = <T extends yup.Schema<unknown>>(schema: T, info: IFieldInfo, storage: Model): T => {
        const validator = info.validator;
        const _getCorrectValidationArgs = (path: string) => getCorrectValidationArgs(storage, info, path);

        // NOTE: If required validation DOES NOT WORK, check if it's called at all. We process null navigations and create
        // an empty object for them, so required validation is called on their children.
        return schema.test("required", getValidatorMessage(validator, "required", ValidationMessage.Required), function(this: yup.TestContext, value: any) {
            // options contains some yup settings combined with parameters passed to schema.validate() call

            const args = _getCorrectValidationArgs(this.path);
            const shouldValidate = args.info?.useForValidation !== false;
            args.isValidateAll = this.options.abortEarly === false;
            if (args.info && isVisible(args) && isRequired(args) && shouldValidate) {
                const isValueSet = !(isNotDefined(value) || value === "" || (Array.isArray(value) && value.length === 0));

                if (!isValueSet) {
                    const parentValue = args.storage.getValue(args.bindingContext.getParent());
                    if (!parentValue) {
                        // if parent value is not set, we should not validate its children, e.g. BusinessPartner might be optional,
                        // so if not set, there is no validation on its children, but when set, we should validate if it
                        // contains all required fields
                        return true;
                    }
                }

                // this handles allow create on select items pointing to navigation not string only
                if (!isValueSet && allowCreateValues(args.info) && args.info.fieldSettings?.displayName) {
                    const bc = args.bindingContext.navigate(args.info.fieldSettings?.displayName);
                    return isDefined(args.storage.getValue(bc));
                }

                return isValueSet;
            }

            return true;
        });
    };

    static createValidationObject = (info: IFieldInfo, storage: Model) => {

        const _getCorrectValidationArgs = (path: string) => getCorrectValidationArgs(storage, info, path);

        if (info?.type === FieldType.CheckboxGroup || info?.type === FieldType.RadioButtonGroup) {
            return null;
        }

        let schema: any;
        const validator = info.validator;
        const type: ValidatorType = validator?.type;
        const property = info.bindingContext?.getProperty();
        const maxPropLength = property?.getMaxLength();
        switch (type) {
            case ValidatorType.String: {
                const stringValidatorSettings = validator?.settings as IStringValidatorSettings;

                schema = yup.string();

                const maxLength = stringValidatorSettings?.max ?? maxPropLength;
                const minLength = stringValidatorSettings?.min;
                const length = stringValidatorSettings?.length;
                const numberString = stringValidatorSettings?.numberString;

                if (numberString) {
                    schema = schema.matches(/^\d+$/, {
                        message: getValidatorMessage(validator, "integer", ValidationMessage.NotANumber),
                        excludeEmptyString: true
                    });
                }

                // length is mutually exclusive with min/max length
                if (length) {
                    schema = schema.length(length, getValidatorMessage(validator, "length", ValidationMessage.Length));
                } else {
                    if (maxLength) {
                        schema = schema.max(maxLength, getValidatorMessage(validator, "max", ValidationMessage.Max));
                    }
                    if (minLength) {
                        schema = schema.min(minLength, getValidatorMessage(validator, "min", ValidationMessage.Min));
                    }
                }

                break;
            }
            case ValidatorType.Date:
                schema = yup.date().typeError(getValidatorMessage(validator, "type", ValidationMessage.NotADate));
                break;
            case ValidatorType.PositiveNumber:
            case ValidatorType.Integer:
            case ValidatorType.Number: {
                const numberValidatorSettings = validator?.settings as INumberValidatorSettings;

                schema = yup.number().typeError(getValidatorMessage(validator, "type", ValidationMessage.NotANumber));

                const min = numberValidatorSettings?.min ?? MIN_SAFE_NUMBER;
                let max = numberValidatorSettings?.max ?? MAX_SAFE_NUMBER;

                if (maxPropLength) {
                    // we take lesser number from max (configuration or maximum safe number in JS) and maxLength from BE field
                    max = Math.min(max, maxNumberFromMaxStringLength(maxPropLength));
                }

                if (type === ValidatorType.Integer) {
                    schema = schema.integer(getValidatorMessage(validator, "integer", ValidationMessage.Integer));
                }

                if (numberValidatorSettings?.excludeMin) {
                    schema = schema.moreThan(min, getValidatorMessage(validator, "moreThan", ValidationMessage.Above));
                } else {
                    schema = schema.min(min, getValidatorMessage(validator, "min", ValidationMessage.SmallNumber));
                }

                if (numberValidatorSettings?.excludeMax) {
                    schema = schema.lessThan(max, getValidatorMessage(validator, "lessThan", ValidationMessage.Below));
                } else {
                    schema = schema.max(max, getValidatorMessage(validator, "max", ValidationMessage.BigNumber));
                }

                if (type === ValidatorType.PositiveNumber) {
                    schema = schema.positive(getValidatorMessage(validator, "max", ValidationMessage.PositiveNumber));
                }
                break;
            }
            case ValidatorType.Boolean:
                schema = yup.boolean();
                break;
            case ValidatorType.Email:
                schema = yup.string()
                    .email(getValidatorMessage(validator, "email", ValidationMessage.WrongEmail))
                    // yup implemented loose email validation in the newer version, so we have to add our own regex
                    .matches(EMAIL_REGEX, {
                        message: getValidatorMessage(validator, "email", ValidationMessage.WrongEmail),
                        excludeEmptyString: true
                    });

                if (maxPropLength) {
                    schema = schema.max(maxPropLength, getValidatorMessage(validator, "max", ValidationMessage.Max));
                }
                break;

            case ValidatorType.Phone:
                schema = yup.string().matches(PHONE_REG, {
                    message: "Validation.WrongNumber",
                    // empty phone number is valid (or caught by isRequired validation)
                    excludeEmptyString: true
                });
                if (maxPropLength) {
                    schema = schema.max(maxPropLength, getValidatorMessage(validator, "max", ValidationMessage.Max));
                }
                break;
            case ValidatorType.Custom:
                const customValidatorSettings = validator?.settings as ICustomValidatorSettings;

                if (customValidatorSettings?.customSchema) {
                    return customValidatorSettings.customSchema({
                        bindingContext: info.bindingContext,
                        info,
                        storage
                    });
                }

                schema = yup.mixed();

                if (maxPropLength) {
                    schema = schema.test("max", function(this: yup.TestContext, value: TValue) {
                        if (typeof value !== "string" || (value as string)?.length <= maxPropLength) {
                            return true;
                        }
                        const error = new ValidationError(getValidatorMessage(validator, "max", ValidationMessage.Max), value, this.path);
                        error.params = { max: maxPropLength };
                        return error;
                    });
                }
                break;
            default:
                schema = yup.mixed();
        }

        if (info?.type === FieldType.EditableText && info?.isConfirmable) {
            schema = schema.test("custom", ValidationMessage.TemporalData, function(this: yup.TestContext, value: any) {
                const args = _getCorrectValidationArgs(this.path);
                const context = this.options?.context as TRecordAny;
                // When we validate temporal data - in handleConfirm - we skip this validation
                return context?.isConfirm || !storage.getTemporalData(args.bindingContext);
            });
        }

        if (type !== ValidatorType.Boolean) {
            schema = Validator.addRequiredValidation(schema, info, storage);
        }

        // keep this after required validation because of priority (empty required value should fail on required
        // validation first, then use more complex custom validations
        const { customValidator } = validator?.settings ?? {};
        if (customValidator) {
            if (Array.isArray(customValidator)) {
                customValidator.forEach((conf, idx) => {
                    schema = schema.test(`custom_${idx}`, conf.message, function(this: yup.TestContext, value: TValue) {
                        return conf.validator(value, _getCorrectValidationArgs(this.path), this);
                    });
                });
            } else {
                schema = schema.test("custom", validator.settings?.message, function(this: yup.TestContext, value: TValue) {
                    return customValidator(value, _getCorrectValidationArgs(this.path), this);
                });
            }
        }

        // we test required by custom test function so basically all fields are marked as nullable to allow null for not required
        schema = schema.nullable();

        return schema;
    };

    static createValidationSchema = (args: IValidationSchema) => {
        let data = {};
        const { storage } = args;
        const localContextCollections: Record<string, boolean> = {};

        if (!storage.data?.bindingContext) {
            // audit trail use TableStorage without binding context
            return null;
        }

        for (const col of args.columnNames) {
            const fieldBindingContext = storage.data.bindingContext.navigate(col);

            // if (fieldBindingContext.getProperty().isReadOnly() ||
            //     (fieldBindingContext.getParent().getProperty() && fieldBindingContext.getParent().getProperty().isReadOnly())) {
            //     continue; // skip read only properties
            // }

            const info = storage.getInfo(fieldBindingContext);
            const valueBc = fieldBindingContext.getNavigationBindingContext();

            if (args.useCollections && info?.isCollectionField) {
                // we are using isCollectionField on local context fields to determine if we should treat their parents as collections
                // .isCollection() check would return false for them
                const parentBc = fieldBindingContext.getParent();
                localContextCollections[parentBc.getPath()] = true;
            }

            const schemaObj = Validator.createValidationObject(info, args.storage);
            if (schemaObj) {
                data = setBoundValue({
                    bindingContext: valueBc,
                    data,
                    newValue: schemaObj,
                    dataBindingContext: args.storage.data.bindingContext
                });
            }
        }

        const _createShape = (obj: any, bc: BindingContext) => {
            for (const name of Object.keys(obj)) {
                const field = obj[name];
                if (!(field instanceof yup.Schema)) {
                    const propBc = bc.navigate(name);
                    let shape = _createShape(field, propBc);

                    // sometimes we have deep definitions like BalanceSheetLayout/LiabilitiesReportSection
                    // which creates correct validation object for LiabilitiesReportSection via 'createValidationObject'
                    // but if we don't have column definition for BalanceSheetLayout, its treated as required object
                    // which doesn't have to be true and we want to check it in metadata
                    const isNullable = propBc.getProperty()?.isNullable();
                    if (!propBc.getProperty() || isNullable) {
                        shape = shape.nullable();
                    }

                    // For TableStorage->Filters we want to ignore collections, because filter values are not stored under
                    // collection keys, e.g. filter for items quantity is stored as InvoiceReceived.Items.Quantity
                    if (args.useCollections && (propBc.isCollection() || localContextCollections[propBc.getPath()])) {
                        obj[name] = yup.array().of(
                            shape // can contain nested objects, has to be called with _createShape as well
                        );
                        if (isNullable) {
                            obj[name] = obj[name].nullable();
                        }
                    } else {
                        obj[name] = shape;
                    }

                    const info = storage.getInfo(propBc);
                    if (info) {
                        obj[name] = Validator.addRequiredValidation(obj[name], info, storage);
                    }
                }
            }

            return yup.object().nullable().noUnknown(false).shape(obj).noUnknown(false);
        };

        return _createShape(data, args.storage.data.bindingContext).noUnknown(false);
    };
}