import { SwitchType } from "@components/inputs/switch/Switch";
import { createBindingContext } from "@odata/BindingContext";
import { updateEntity } from "@odata/Data.utils";
import {
    EntitySetName,
    INotificationSettingEntity,
    INotificationTypeEntity,
    INotificationTypeSettingEntity
} from "@odata/GeneratedEntityTypes";
import { NotificationTypeCode } from "@odata/GeneratedEnums";
import { isBatchResultOk } from "@odata/OData";
import { WithOData, withOData } from "@odata/withOData";
import { isUserCustomer } from "@pages/admin/users/Users.utils";
import { isDefined, isObjectEmpty } from "@utils/general";
import { debounce } from "lodash";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import BusyIndicator from "../../components/busyIndicator";
import Checkbox, { ICheckboxChange } from "../../components/inputs/checkbox";
import FieldsWrapper from "../../components/inputs/field/FieldsWrapper";
import Switch from "../../components/inputs/switch";
import { AppContext, IAppContext } from "../../contexts/appContext/AppContext.types";
import TestIds from "../../testIds";
import { fetchNotificationsSettings, INotificationsState } from "../Notification.utils";
import { WithNotifications, withNotifications } from "../withNotifications";
import {
    GroupHeaderStyled,
    NotificationLabel,
    NotificationRow,
    NotificationsSettingsName,
    NotificationsSettingsWrapper,
    SwitchBannerDisabled
} from "./NotificationsSettings.styles";

interface INotificationTypeGroup {
    Code: string;
    Name: string;
    notificationTypes: TNotificationType[];
}

type TNotificationType = INotificationTypeEntity & { settings: INotificationTypeSettingEntity; };

const DEFAULT_GROUP_CODE: string = null;
const NEW_NOTIF_TYPE_SETTINGS_ID = -1000;

interface IProps extends WithTranslation, WithOData, WithNotifications {
    initialNotificationSettingState: INotificationsState;
    isLoading?: boolean;
    onSave: (savedNotificationSettings: INotificationsState) => void;
}

interface IState extends INotificationsState {
    originalNotificationSettings: INotificationSettingEntity;
}

// could be rewritten to use form to get better and automatic error handling
// but every rendering and data fetching is completely custom...
class NotificationsSettings extends React.PureComponent<IProps, IState> {
    static contextType = AppContext;

    state: IState = {
        notificationSettings: null,
        notificationTypeGroups: null,
        notificationTypes: null,
        originalNotificationSettings: null
    };

    componentDidMount() {
        this.setInitialStateFromProps();
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (this.props.initialNotificationSettingState !== prevProps.initialNotificationSettingState) {
            this.setInitialStateFromProps();
        }
    }

    componentWillUnmount() {
        this.handleSave.flush();
    }

    setInitialStateFromProps = () => {
        const initialSettings = this.props.initialNotificationSettingState;
        if (!isObjectEmpty(initialSettings?.notificationSettings)) {
            this.setState({
                ...initialSettings,
                originalNotificationSettings: initialSettings.notificationSettings
            });
        }
    };

    getNotificationTypeGroupNotificationTypes = (groupCode: string) => {
        const context = this.context as IAppContext;
        const everyonesNotificationTypes = [
            NotificationTypeCode.DataBoxMessageReceivment
        ];
        const customerRelatedNotificationTypes = [
            NotificationTypeCode.DocumentForApprovalReceivement, NotificationTypeCode.NewTicketCreated,
            NotificationTypeCode.NewTicketMessageFromAnAccountantReceivement,
            NotificationTypeCode.InboxDocumentApprovalCanceled, NotificationTypeCode.InboxDocumentApprovalResumed
        ];
        const isCustomer = isUserCustomer(context.getData().userSettings);

        return this.state.notificationTypes
            .filter(nt =>
                nt.NotificationTypeGroupCode === groupCode
            ).filter(nt => {
                const isCustomerRelated = customerRelatedNotificationTypes.includes(nt.Code as NotificationTypeCode);

                return everyonesNotificationTypes.includes(nt.Code as NotificationTypeCode)
                    || isCustomerRelated === isCustomer;
            })
            .map(nt => ({
                ...nt,
                settings: this.state.notificationSettings.TypeSettings.find(nts => nts.NotificationTypeCode === nt.Code)
            }));
    };

    getNotificationGroups = (): INotificationTypeGroup[] => {
        if (!this.state.notificationTypeGroups || !this.state.notificationTypeGroups) {
            return [];
        }

        const notificationTypesGroups: INotificationTypeGroup[] = [
            {
                Code: DEFAULT_GROUP_CODE,
                Name: "",
                notificationTypes: this.getNotificationTypeGroupNotificationTypes(DEFAULT_GROUP_CODE)
            }
        ];

        for (const nTypeGroup of this.state.notificationTypeGroups) {
            notificationTypesGroups.push({
                Code: nTypeGroup.Code,
                Name: nTypeGroup.Name,
                notificationTypes: this.getNotificationTypeGroupNotificationTypes(nTypeGroup.Code)
            });
        }

        return notificationTypesGroups.filter(group => group.notificationTypes?.length > 0);
    };

    handleShowBannerChange = async (checked: boolean): Promise<void> => {
        this.setState({
            notificationSettings: {
                ...this.state.notificationSettings,
                IsBannerDisabled: !checked
            }
        });
        await this.handleSave();
    };

    updateNotificationSettings = async (notificationTypeCode: NotificationTypeCode, options: {
        shouldAllowNotification?: boolean,
        shouldSendMail?: boolean
    } = {}) => {
        const notificationTypeSettings = [...this.state.notificationSettings.TypeSettings];
        const index = this.state.notificationSettings.TypeSettings.findIndex(nts => nts.NotificationTypeCode === notificationTypeCode);
        const isNew = index < 0;
        let newSettings: INotificationTypeSettingEntity;

        if (isNew) {
            newSettings = {
                Id: NEW_NOTIF_TYPE_SETTINGS_ID,
                NotificationTypeCode: notificationTypeCode,
                IsDisabled: isDefined(options.shouldAllowNotification) ? !options.shouldAllowNotification : false,
                IsSendToEmailEnabled: isDefined(options.shouldSendMail) ? options.shouldSendMail : false
            };
            notificationTypeSettings.push(newSettings);
        } else {
            const isDisabled = isDefined(options.shouldAllowNotification) ? !options.shouldAllowNotification : notificationTypeSettings[index].IsDisabled;
            const IsSendToEmailEnabled = !isDisabled && (isDefined(options.shouldSendMail) ? options.shouldSendMail : notificationTypeSettings[index].IsSendToEmailEnabled);

            newSettings = {
                ...notificationTypeSettings[index],
                IsDisabled: isDisabled,
                IsSendToEmailEnabled: IsSendToEmailEnabled
            };
            notificationTypeSettings[index] = newSettings;
        }

        this.setState({
            notificationSettings: {
                ...this.state.notificationSettings,
                TypeSettings: notificationTypeSettings
            }
        });
    };

    updateAllowNotification = async (notificationTypeCode: NotificationTypeCode, shouldAllowNotification: boolean): Promise<void> => {
        await this.updateNotificationSettings(notificationTypeCode, { shouldAllowNotification });
        await this.handleSave();
    };

    updateSendMail = async (notificationTypeCode: NotificationTypeCode, shouldSendMail: boolean): Promise<void> => {
        await this.updateNotificationSettings(notificationTypeCode, { shouldSendMail });
        await this.handleSave();
    };

    handleSave = debounce(async (): Promise<void> => {
        const batch = this.props.oData.batch();
        const isBannerDisabled = this.state.notificationSettings.IsBannerDisabled;

        this.props.notifications.setIsBannerEnabled(!isBannerDisabled);

        updateEntity({
            batch,
            changesOnly: true,
            bindingContext: createBindingContext(EntitySetName.NotificationSettings, this.props.oData.getMetadata()).addKey(this.state.notificationSettings.Id),
            entity: this.state.notificationSettings,
            originalEntity: this.state.originalNotificationSettings
        });

        const batchResponse = batch.getRequests()?.length > 0 ? await batch.execute() : [];

        for (const res of batchResponse) {
            if (!isBatchResultOk(res)) {
                // saving happens right on change, so we have no place to show error and if there
                // is an error, it probably won't be users fault
                break;
            }
        }
        const savedNotificationSettings = await fetchNotificationsSettings(this.context, this.props.oData);
        this.setState({
            originalNotificationSettings: savedNotificationSettings.notificationSettings
        });
        this.props.onSave(savedNotificationSettings);
    }, 2000);

    renderNotificationType = (type: TNotificationType) => {
        const allowNotification = !type.settings?.IsDisabled;
        const sendEmail = type.settings?.IsSendToEmailEnabled ?? false;

        return (
            <NotificationRow key={type.Code} data-testid={TestIds.NotificationSettingsRow}>
                <NotificationsSettingsName>{type.Name}</NotificationsSettingsName>

                <Switch checked={allowNotification}
                    type={SwitchType.Icons}
                    isLight={true}
                    onChange={(checked: boolean) => {
                        this.updateAllowNotification(type.Code, checked);
                    }} />
                <Checkbox checked={sendEmail}
                    isDisabled={!allowNotification}
                    isLight
                    onChange={(args: ICheckboxChange) => {
                        this.updateSendMail(type.Code, args.value);
                    }} />
            </NotificationRow>
        );
    };

    renderNotificationGroup = (group: INotificationTypeGroup) => {
        let notificationGroup: React.ReactNode = group.notificationTypes.map(nt => this.renderNotificationType(nt));

        if (group.Name) {
            notificationGroup = (
                <React.Fragment key={group.Code}>
                    <GroupHeaderStyled title={group.Name}
                        showLine />
                    {notificationGroup}
                </React.Fragment>
            );
        }

        return notificationGroup;
    };

    render() {
        if (!this.props.tReady || this.props.isLoading) {
            return <BusyIndicator />;
        }

        if (isObjectEmpty(this.state.notificationSettings)) {
            // todo: how to handle error? (not loading, but no data)
            return null;
        }

        const notificationGroups = this.getNotificationGroups();

        return (
            <NotificationsSettingsWrapper data-testid={TestIds.NotificationSettings}>
                <NotificationLabel>{this.props.t("Notifications:Settings.Email")}</NotificationLabel>
                {notificationGroups.map(ng => this.renderNotificationGroup(ng))}

                <FieldsWrapper>
                    <SwitchBannerDisabled checked={!this.state.notificationSettings?.IsBannerDisabled}
                        label={this.props.t("Notifications:Settings.ShowBanner")}
                        isLight={true}
                        type={SwitchType.YesNo}
                        onChange={this.handleShowBannerChange} />
                </FieldsWrapper>
            </NotificationsSettingsWrapper>
        );
    }
}

export default withNotifications(withOData(withTranslation(["Common", "Notifications"])(NotificationsSettings)));
