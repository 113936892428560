import { IColumn, ISelectGroup, ISelectItem, TSelectItemId } from "@components/inputs/select/Select.types";
import { IEntity } from "@odata/BindingContext";
import { getIndexedPathFromBindingContext, getUniqueContextsSuffixAsString, setBoundValue } from "@odata/Data.utils";
import { IFieldInfo } from "@odata/FieldInfo.utils";
import { EntitySetName } from "@odata/GeneratedEntityTypes";
import { CurrencyCode } from "@odata/GeneratedEnums";
import { getEnumEntities, getEnumName, isEnum } from "@odata/GeneratedEnums.utils";
import { getCompanyUsedCurrencyCodes } from "@pages/companies/Company.utils";
import { logger } from "@utils/log";
import React from "react";

import { AppContext, AppMode } from "../../../contexts/appContext/AppContext.types";
import { BasicInputSizes, IconSize } from "../../../enums";
import { TableStorage } from "../../../model/TableStorage";
import { ButtonSize } from "../../button/Button.utils";
import ConditionalFilterDialog from "../../conditionalFilterDialog/ConditionalFilterDialog";
import {
    IComplexFilter,
    isComplexFilterArr,
    TFilterValue,
    ValueTypeToCondition
} from "../../conditionalFilterDialog/ConditionalFilterDialog.utils";
import { TGetCustomValueInput } from "../../conditionalFilterDialog/ConditionalFilterRow";
import { ValueHelpFilledIcon, ValueHelpIcon } from "../../icon";
import { MultiSelect } from "../../inputs/select/MultiSelect";
import { ISmartFieldProps } from "../smartField/SmartField";
import { getValueHelperChangeParams, IGetControlDataRetValue } from "../smartField/SmartFieldUtils";
import { createSelectItem } from "../smartValueHelper/ValueHelper.utils";
import { IconButtonStyled, SmartFieldStyled } from "./SmartFilterBar.styles";

interface IProps extends Omit<ISmartFieldProps, "customRenderWrapper"> {
    hasConditionalDialog?: boolean;
}

interface IState {
    isDialogOpen: boolean;
    isBusy?: boolean;
    items?: ISelectItem[];
}

class SmartFilterField extends React.Component<IProps> {
    static contextType = AppContext;

    state: IState = {
        isDialogOpen: false
    };

    get groupHasConditionalDialog(): boolean {
        return this.props.hasConditionalDialog;
    }

    get itemHasConditionalDialog(): boolean {
        const type = this.props.storage.getInfo(this.props.bindingContext).valueType;
        return ValueTypeToCondition[type]?.length > 0;
    }

    get fieldInfo(): IFieldInfo {
        return this.props.storage.getInfo(this.props.bindingContext);
    }

    isEnum = (): boolean => {
        return isEnum(this.fieldInfo);
    };

    isCurrencyEnum = (): boolean => {
        return this.isEnum() && this.getEnumName() === "Currency";
    };

    getEnumName = (): string => {
        return getEnumName(this.fieldInfo, this.props.storage.oData);
    };

    isOptional = (): boolean => {
        return this.props.bindingContext.getProperty()?.isNullable();
    };

    createSelectItems = async (): Promise<ISelectItem[]> => {
        const { bindingContext, storage } = this.props;

        // could be null in case we create enum items manually, e.g. for document status filter
        const isEnum = this.isEnum();
        const fieldInfo = this.fieldInfo;

        const opts = {
            bindingContext, fieldInfo,
            storage: storage as TableStorage,
            isEnum: true
        };

        let items: ISelectItem[] = [];

        if (fieldInfo.fieldSettings?.conditionalDialogItemsFactory) {
            items = (await fieldInfo.fieldSettings.conditionalDialogItemsFactory({
                storage, bindingContext, info: fieldInfo
            })) ?? [];
        } else if (isEnum) {
            let rows: IEntity[];
            if (fieldInfo.additionalProperties?.length || (fieldInfo.fieldSettings?.displayName && fieldInfo.fieldSettings?.displayName !== "Name")) {
                const displayName = fieldInfo.fieldSettings?.displayName ?? "Name";
                const columns = ["Code", displayName, ...fieldInfo.additionalProperties?.map(prop => prop.id)];
                const query = storage.oData.getEntitySetWrapper(bindingContext.getEntitySet().getName() as EntitySetName).query()
                        .select(...columns);

                const data = await query.fetchData<IEntity[]>();
                rows = data.value;
            } else {
                // todo: getEnumEntities should return enum with all props (add Description to entity toml)
                rows = getEnumEntities(this.getEnumName());
            }
            items = rows.map((item: IEntity) => createSelectItem(item, opts, bindingContext));
        }

        if (isEnum && this.isOptional()) {
            const emptyItem = createSelectItem(null, opts);
            items = [emptyItem, ...items];
        }

        return items;
    };

    getCustomValueInput = (): TGetCustomValueInput => {
        if (!this.isEnum()) {
            return null;
        }
        const { bindingContext } = this.props;

        let { items } = this.state;
        let isTabular = false;
        let columns: IColumn[];
        let groups: ISelectGroup[];

        if (this.isCurrencyEnum()) {
            const isOnCompanyLevel = this.context.getAppMode() === AppMode.Company;
            const usedCurrencies = isOnCompanyLevel && getCompanyUsedCurrencyCodes(this.context);
            items = items.reduce((ret, item) => {
                if (!isOnCompanyLevel || usedCurrencies.includes(item.id as CurrencyCode)) {
                    ret.push({
                        ...item,
                        tabularData: [item.id, item.label],
                        label: item.id,
                        description: item.label
                    });
                }
                return ret;
            }, []);
            isTabular = true;
            columns = [{ id: "Code" }, { id: "Name" }];
        }

        // generally enum with local context has to predefine its items, because there is no other way how to obtain them
        if (bindingContext.isLocal()) {
            groups = this.fieldInfo.fieldSettings.groups;
            items = items?.length > 0 ? items : this.fieldInfo.fieldSettings.initialItems;

            if (!items) {
                logger.warn(`SmartFilterField: local enum filter ${this.props.bindingContext.toString()} is missing initialItems definition`);
            }
        }

        // we don't need to pollute ConditionalFilterDialog with binding context,
        // instead we can create the SmartMultiSelect here
        return (row: IComplexFilter, handleChange: (value: IComplexFilter) => void) => {
            return (
                <MultiSelect
                    value={row.value as unknown as TSelectItemId[]}
                    isTabular={isTabular}
                    items={items}
                    groups={groups}
                    width={BasicInputSizes.L}
                    columns={columns}
                    onChange={(event) => {
                        handleChange({
                            ...row,
                            value: event.value as TFilterValue
                        });
                    }}/>
            );
        };
    };

    handleIconClick = async (e: React.MouseEvent): Promise<void> => {
        e.preventDefault();

        this.setState({ isBusy: true });

        const items = await this.createSelectItems();

        this.setState({
            isDialogOpen: true,
            isBusy: false,
            items
        });
    };

    handleCancel = (): void => {
        this.setState({
            isDialogOpen: false
        });
    };

    handleConfirm = (values: IComplexFilter[]): void => {
        const { storage, bindingContext } = this.props;
        const info = storage.getInfo(bindingContext);
        this.props.onChange(getValueHelperChangeParams(bindingContext, info.type, {
            value: values,
            triggerAdditionalTasks: true
        }));

        this.setState({
            isDialogOpen: false
        });
    };


    getValidatorFn = () => {
        const { storage } = this.props;
        let { bindingContext } = this.props;
        const data = storage.data;
        let path: string;

        const fieldInfo = storage.getInfo(bindingContext);

        if (fieldInfo.fieldSettings?.displayName) {
            bindingContext = bindingContext.navigate(fieldInfo.fieldSettings?.displayName);
        }

        if (bindingContext.isAnyPartCollection()) {
            path = getIndexedPathFromBindingContext(bindingContext, data.entity, data.bindingContext);
        } else {
            path = getUniqueContextsSuffixAsString(bindingContext, data.bindingContext, ".");
        }

        return (value: TFilterValue) => {
            const entity = {};
            // store data in simulated entity to support validation
            setBoundValue({
                bindingContext,
                data: entity,
                newValue: value,
                dataBindingContext: data.bindingContext,
                preventCloning: true
            });

            storage._validationSchema.validateSyncAt(path, entity);
        };
    };

    renderSmartFieldWrapper = (field: React.ReactNode, fieldInfo: IFieldInfo, data: IGetControlDataRetValue): React.ReactNode => {
        const { storage, fieldProps } = this.props;
        const { additionalFieldData } = data.storageData;
        const type = fieldInfo.valueType;
        const _isEnum = isEnum(fieldInfo);

        const hasConditionalDialog = this.groupHasConditionalDialog && this.itemHasConditionalDialog;
        const conditionalValues: IComplexFilter[] = [];
        if (isComplexFilterArr(additionalFieldData?.parsedValue)) {
            conditionalValues.push(...additionalFieldData.parsedValue);
        }

        const Icon = conditionalValues.length ? ValueHelpFilledIcon : ValueHelpIcon;

        return (<>
            {field}

            {hasConditionalDialog &&
                <IconButtonStyled title={storage.t("Components:ValueHelper.OpenConditionalFilter")}
                                  onClick={this.handleIconClick}
                                  hotspotId={`${fieldInfo?.id}-openConditionalDialog`}
                                  isDisabled={fieldProps.isDisabled || data.props.isDisabled || this.state.isBusy}
                                  size={ButtonSize.Default}
                                  isDecorative
                                  isLight>
                    <Icon width={IconSize.M}/>
                </IconButtonStyled>}

            {this.state.isDialogOpen &&
                <ConditionalFilterDialog name={fieldInfo.label}
                                         onCancel={this.handleCancel}
                                         onConfirm={this.handleConfirm}
                                         values={conditionalValues}
                                         type={type}
                                         isEnum={_isEnum}
                                         validator={this.getValidatorFn()}
                                         getCustomValueInput={this.getCustomValueInput()}
                />}
        </>);
    };

    render() {
        return (<SmartFieldStyled groupHasConditionalDialog={this.groupHasConditionalDialog}
                                  itemHasConditionalDialog={this.itemHasConditionalDialog}
                                  {...this.props}
                                  fieldProps={{
                                      width: BasicInputSizes.M, // by default all filter input should have 180px to match grid of value help inputs
                                      ...this.props.fieldProps
                                  }}
                                  customRenderWrapper={this.renderSmartFieldWrapper}/>);
    }
}

export default SmartFilterField;