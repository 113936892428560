import { IColumn, IRow } from "@components/table";
import { WorkBook } from "xlsx";

import { MimeType } from "../enums";
import { TRecordAny } from "../global.types";
import DateType from "../types/Date";
import NumberType from "../types/Number";

export enum ExportType {
    CSV = "CSV",
    XLSX = "XLSX"
}

enum SheetJsDataType {
    String = "s",
    Number = "n",
    Date = "d",
    Boolean = "b",
    Error = "e",
    Stub = "z"
}

export interface ITableExport {
    tableId: string;
    rows: IRow[];
    columns: IColumn[];
    type?: ExportType;
}

export interface IExport {
    columns: IColumn[];
    rows: TRecordAny[];
    tableName: string;
    type?: ExportType;
}

const wrapSeparators = (str: string) => {
    // separators need to be wrapped in quotes for excel to recognize them as separators
    return str.replace(/([.\-/])/g, "\"$1\"");
};

export default class ExcelExport {
    static UTF8_BOM = "\uFEFF";
    static DEFAULT_FILE_NAME = "export";
    static META_COL_ROW_ID = "@isMetaColRow@";

    static getXLSX = async () => {
        const { utils, write } = await import("xlsx");
        return { utils, write };
    };

    static async toWorkbook(args: IExport) {
        const XLSX = await ExcelExport.getXLSX();
        const workBook = XLSX.utils.book_new();
        // first add column labels
        const columnRow = [args.columns.map(column => column.label)];
        const rows = args.rows.map(row => args.columns.map(column => row[column.id]));
        const metaColRow: string[][] = [];

        // @ts-ignore
        if (args.rows?.[0]?.[ExcelExport.META_COL_ROW_ID]) {
            metaColRow.push(rows.shift());
        }

        // use user settings date format for xml, standard date format for csv
        const dateFormat = args.type === ExportType.CSV ? "YYYY-MM-DD" : wrapSeparators(DateType.defaultDateFormat);

        const workSheet = XLSX.utils.aoa_to_sheet(
            [...metaColRow, ...columnRow, ...rows]
            , {
                cellDates: true,
                dateNF: dateFormat
            }
        );

        XLSX.utils.book_append_sheet(workBook, workSheet, "Data");


        return workBook;
    }

    static async workbookToCsv(workBook: WorkBook, withoutBom?: boolean) {
        const XLSX = await ExcelExport.getXLSX();
        // for CSV we need to format numbers to correct localized representation (e.g. 0,1 for cz-cs instead of 0.1)
        // the library sadly doesn't provide this
        const workSheet = workBook.Sheets[workBook.SheetNames[0]];
        // !ref access full range of the workSheet
        const range = XLSX.utils.decode_range(workSheet["!ref"]);

        for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cell = workSheet[XLSX.utils.encode_cell({ r: R, c: C })];

                // cell.t = type
                // cell.v = value
                // cell.w = formatted value

                switch (cell?.t) {
                    case SheetJsDataType.Number:
                        // cell.w is the formatted representation of the cell
                        cell.w = NumberType.format(cell.v, { useGrouping: false });
                        break;
                    default:
                        break;
                }
            }
        }

        let result = XLSX.utils.sheet_to_csv(workBook.Sheets[workBook.SheetNames[0]], {
            FS: ";" // Field Separator
        });

        if (!withoutBom) {
            result = ExcelExport.UTF8_BOM + result;
        }

        return result;
    }

    static async workbookToXlsx(workBook: WorkBook) {
        const XLSX = await ExcelExport.getXLSX();
        return XLSX.write(workBook, { bookType: "xlsx", type: "array" });
    }

    static async tableToWorkbook(args: ITableExport) {
        const cleanRows = args.rows.map(row => row.values);

        return await ExcelExport.toWorkbook({
            ...args,
            tableName: args.tableId,
            rows: cleanRows
        });
    }

    static async tableToCsv(args: ITableExport, withoutBom?: boolean) {
        const workBook = await ExcelExport.tableToWorkbook({
            ...args,
            type: ExportType.CSV
        });

        return await ExcelExport.workbookToCsv(workBook, withoutBom);
    }

    static async exportTableToCsv(args: ITableExport) {
        const csv = await ExcelExport.tableToCsv(args);
        return new File([csv], `${args.tableId || ExcelExport.DEFAULT_FILE_NAME}.csv`, { type: MimeType.CSV });
    }

    static async exportTableToXlsx(args: ITableExport) {
        const workBook = await ExcelExport.tableToWorkbook({
            ...args,
            type: ExportType.XLSX
        });
        const arrayBuffer = await ExcelExport.workbookToXlsx(workBook);
        return new File([arrayBuffer], `${args.tableId || ExcelExport.DEFAULT_FILE_NAME}.xlsx`, { type: MimeType.XLSX });
    }

    static async export(args: IExport) {
        let extension, mimeType, convertFn;

        switch (args.type) {
            case ExportType.CSV:
                extension = "csv";
                mimeType = MimeType.CSV;
                convertFn = ExcelExport.workbookToCsv;
                break;
            case ExportType.XLSX:
            default:
                extension = "xlsx";
                mimeType = MimeType.XLSX;
                convertFn = ExcelExport.workbookToXlsx;
                break;
        }

        const workBook = await ExcelExport.toWorkbook(args);
        const data = await convertFn(workBook);

        return new File([data], `${args.tableName || ExcelExport.DEFAULT_FILE_NAME}.${extension}`, { type: mimeType });
    }
}