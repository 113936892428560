import { AlertAction } from "@components/alert/Alert";
import { WithBusyIndicator } from "@components/busyIndicator/withBusyIndicator";
import { IconButton } from "@components/button";
import { ISelectionChangeArgs, ISelectItem } from "@components/inputs/select/Select.types";
import { IReadOnlyListItem } from "@components/readOnlyList/ReadOnlyListItem";
import { getRow, TCustomRowAction, updateRowsArray } from "@components/smart/smartTable/SmartTable.utils";
import { IActionRendererArgs, IRow, IRowAction, TId } from "@components/table";
import { IRowProps } from "@components/table/Rows";
import { TEntityKey } from "@evala/odata-metadata/src";
import { getEntitySetByDocumentType } from "@odata/EntityTypes";
import { EntitySetName, IPaymentDocumentItemEntity } from "@odata/GeneratedEntityTypes";
import { BankTransactionTypeCode, CompanyPermissionCode } from "@odata/GeneratedEnums";
import { roundToDecimalPlaces } from "@utils/general";
import i18next from "i18next";
import React, { ReactElement } from "react";

import { AddIcon, ChainedIcon, DarkIcon, IProps as IIconProps, UnchainedIcon } from "../../components/icon";
import IconSelect from "../../components/inputs/select/IconSelect";
import ReadOnlyList from "../../components/readOnlyList/ReadOnlyList";
import { IProps as ISmartFilterBarProps } from "../../components/smart/smartFilterBar/SmartFilterBar";
import { IProps as ISmartTableProps, SmartTable } from "../../components/smart/smartTable/SmartTable";
import { NEW_ITEM_DETAIL } from "../../constants";
import { WithPermissionContext } from "../../contexts/permissionContext/withPermissionContext";
import { ActionState, IconSize, RowAction, Status, ToolbarItemType } from "../../enums";
import { TRecordAny } from "../../global.types";
import { IAfterSaveEventArgs, ModelEvent } from "../../model/Model";
import BindingContext, { createBindingContext } from "../../odata/BindingContext";
import memoizeOne from "../../utils/memoizeOne";
import { FormStorage } from "../../views/formView/FormStorage";
import { TableActionOrder, TableButtonsAction, TTableToolbarItem } from "../../views/table/TableToolbar.utils";
import TableView, { IHandleCustomActionArgs, ITableViewBaseProps } from "../../views/table/TableView";
import { getConfirmationActionText } from "../../views/table/TableView.render.utils";
import { SecondaryTableViewTitle, TableWrapper } from "../../views/table/TableView.styles";
import View from "../../views/View";
import { PAIR_ACTION_ID } from "../asset/PairingWithAssetTableView";
import { hasPermissionForDocument } from "../documents/Document.utils";
import { amountPaidFormatter } from "./bankTransactions/BankTransactionDocumentPairDef";
import { IBankCustomData, isPairingRowReadOnly } from "./bankTransactions/BankTransactions.utils";
import { getPairIconDef } from "./Payments.utils";

export interface IPairTableViewBaseProps extends ITableViewBaseProps<IBankCustomData>, WithPermissionContext, WithBusyIndicator {
    saveDraftFn?: () => Promise<void>;
    skipAmountPair?: boolean;
    isSingleSelect?: boolean;
    transactionType?: BankTransactionTypeCode;
}

export interface IPaymentDocumentItemEntityExpanded extends IPaymentDocumentItemEntity {
    ExchangeGain?: number;
    Description?: string;
}

class PairTableViewBase<P extends IPairTableViewBaseProps = IPairTableViewBaseProps> extends TableView<P> {
    needConfirmDialog: boolean;
    origPairedDocuments: Record<TEntityKey, IPaymentDocumentItemEntityExpanded>;
    _createdRowBindingContext: BindingContext;

    _isTableReady = false;

    constructor(props: P) {
        super(props);

        this.handleCustomAction = this.handleCustomAction.bind(this);
        this.presetDefaultFilters = this.presetDefaultFilters.bind(this);

        this.setOriginItems();
        this.handleCustomAction(PAIR_ACTION_ID, { update: false });

        this.props.storage.emitter.on(ModelEvent.FiltersCleared, this.presetDefaultFilters);
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        this.props.storage.emitter.off(ModelEvent.FiltersCleared, this.presetDefaultFilters);
    }

    get isToolbarDisabled(): boolean {
        return !this._isTableReady;
    }

    presetDefaultFilters(): void {
        /* redefined on children */
    }

    getCustomFilterBarProps(): Partial<ISmartFilterBarProps> {
        return {
            onFilterCustomizationChanged: this.presetDefaultFilters
        };
    }

    async handleTableLoad(): Promise<void> {
        if (this._createdRowBindingContext) {
            const bc = this._createdRowBindingContext;
            this._createdRowBindingContext = null;
            this.handleRowPairClick(bc)
                    .then(() => this.handleCustomAction(PAIR_ACTION_ID, { update: false }));
        }
        this._isTableReady = true;

        super.handleTableLoad();
    }

    handleFormAfterSave({ isNew, bindingContext }: IAfterSaveEventArgs): void {
        if (isNew) {
            this._createdRowBindingContext = this.props.storage.data.bindingContext.addKey(bindingContext.getKey());
            this.getInitialActiveRows.reset();
        }
    }

    getAmountPaidFormatterFn = () => {
        return amountPaidFormatter;
    };

    isDraftView = (): boolean => {
        return false;
    };

    setOriginItems(): void {
        throw Error(`setOriginItems must be specified in ${this.constructor.name}`);
    }

    getPairedDocuments(): Record<TEntityKey, IPaymentDocumentItemEntityExpanded> {
        return this.props.storage.getCustomData().pairedDocuments || {};
    }

    reset = (): void => {
        this.props.storage.setCustomData({ pairedDocuments: { ...this.origPairedDocuments } });
    };

    getToolbarButtons(): TableButtonsAction[] {
        return [];
    }

    handleAddChange = (e: ISelectionChangeArgs): void => {
        let type = e.value;
        const props: TRecordAny = {};

        if (`${type}`.startsWith("ProformaInvoices")) {
            props.IsDDOP = type === "ProformaInvoicesReceivedTax" || type === "ProformaInvoicesIssuedTax";
            if (props.IsDDOP) {
                type = (type as string).replace("Tax", "");
            }
        }

        const bc = createBindingContext(type as string, this.props.rootStorage.oData.metadata).addKey(NEW_ITEM_DETAIL, true);

        this.props.onRowSelect?.(bc, null, null, props);
    };

    get addMenuConfiguration() {
        return [
            { id: "InvoicesReceived", permission: CompanyPermissionCode.InvoicesReceived },
            { id: "InvoicesIssued", permission: CompanyPermissionCode.InvoicesIssued },
            { id: "ProformaInvoicesReceived", permission: CompanyPermissionCode.InvoicesReceived },
            { id: "ProformaInvoicesIssued", permission: CompanyPermissionCode.InvoicesIssued },
            { id: "ProformaInvoicesReceivedTax", permission: CompanyPermissionCode.InvoicesReceived },
            { id: "ProformaInvoicesIssuedTax", permission: CompanyPermissionCode.InvoicesIssued },
            { id: "OtherLiabilities", permission: CompanyPermissionCode.Reports },
            { id: "OtherReceivables", permission: CompanyPermissionCode.InvoicesReceived },
            { id: "CorrectiveInvoicesReceived", permission: CompanyPermissionCode.InvoicesReceived },
            { id: "CorrectiveInvoicesIssued", permission: CompanyPermissionCode.InvoicesIssued }
        ];
    }

    getAddSelectDef = (): TTableToolbarItem => {
        return {
            id: "addSelect",
            itemType: ToolbarItemType.Custom,
            order: TableActionOrder.Add,
            render: () => {
                const conf = this.addMenuConfiguration;

                const items: ISelectItem[] = conf.map(cfgItem => ({
                    id: cfgItem.id,
                    label: this.props.rootStorage.t(`Document:Pair.${cfgItem.id}`),
                    iconName: `${cfgItem.id}Plain`,
                    isDisabled: !this.props.permissionContext.companyPermissions.has(cfgItem.permission)
                }));

                const isAddingNew = !!this.props.storage.data.addingRow;
                const title = this.props.rootStorage.t("Document:Pair.NewDocument");
                const sharedProps = {
                    headerText: title,
                    icon: <AddIcon/>,
                    iconButtonProps: {
                        isLight: false,
                        isTransparent: true
                    },
                    title,
                    isActive: isAddingNew,
                    isDisabled: this.isTableAction() || this.isToolbarDisabled,
                    onChange: this.handleAddChange
                };

                return (
                    <IconSelect items={items}
                                hotspotId={"PairTableViewBaseAddSelect"}
                                key={"AddSelectDef"}
                                {...sharedProps}
                                showSearchBoxInMenu={false}/>
                );
            }
        };
    };

    handlePairClick = (): void => {
        this.handleCustomAction(PAIR_ACTION_ID);
    };

    getPairIconDef = (): TTableToolbarItem => {
        return getPairIconDef(this.getTableAction(), this.handlePairClick, !!this.props.storage.data.addingRow || this.isToolbarDisabled);
    };

    getRootStorage = (): FormStorage<unknown, IBankCustomData> => {
        return this.props.rootStorage;
    };

    handleRowSelect = (bindingContext: BindingContext): void => {
        const correctBc = bindingContext.createNewBindingContext(this.getRowEntitySet(bindingContext)).addKey(bindingContext.getKey());
        this.props.onRowSelect?.(correctBc);
    };

    getSelectedRows = memoizeOne(() => {
        const oldBc = this.props.storage.data.rowBindingContext;
        const bc = oldBc?.createNewBindingContext(this.props.storage.data.bindingContext.getPath()).addKey(oldBc.getKey());

        return bc ? [bc] : [];
    }, () => [this.props.storage.data.rowBindingContext]);

    canConfirmToolbarAction = (): boolean => {
        const isDeleting = this.props.rootStorage.data.entity.Items?.length > 0;
        const hasActive = this.props.storage.tableAPI?.getState().activeRows.size > 0;
        return isDeleting || hasActive || Object.keys(this.getPairedDocuments()).length > 0;
    };

    getConfirmText = (): string | ReactElement => {
        const tableAction = this.getTableAction();

        if (tableAction === PAIR_ACTION_ID) {
            const setItems = Object.values(this.getPairedDocuments()).filter(item => item.Amount !== 0);
            const activeRowActionCount = Object.keys(setItems).length;
            return getConfirmationActionText(this.props.storage.t(`Document:Pair.ToPair`), activeRowActionCount);
        }

        return super.getConfirmText(tableAction);
    };

    getRowEntitySet = (bc: BindingContext): EntitySetName => {
        const row = getRow(this.props.storage.tableAPI.getState().rows, bc);
        return getEntitySetByDocumentType(row.customData.entity.DocumentType.Code);
    };

    handleCustomAction(action: string, { update = true, value }: IHandleCustomActionArgs = {}): void {
        this.setTableAction(action, true, false);
        if (update) {
            this.forceUpdate();
        }
    }

    handleToolbarCancel = async (): Promise<void> => {
        const tableAction = this.getTableAction();
        if (tableAction === PAIR_ACTION_ID) {
            this.reset();
        }

        super.handleToolbarCancel();
    };

    savePairChanges = async (storage: FormStorage, pairedDocs: Record<TEntityKey, IPaymentDocumentItemEntityExpanded>): Promise<void> => {
        throw Error(`savePairChanges must be specified in ${this.constructor.name}`);
    };

    get successSubtitle(): string {
        return "";
    }

    getTransactionType = (): BankTransactionTypeCode => {
        throw Error(`getTransactionType must be specified in ${this.constructor.name}`);
    };


    async handleToolbarConfirm(): Promise<void> {
        const tableAction = this.getTableAction();

        if (tableAction === PAIR_ACTION_ID) {
            const pairedDocs = this.props.storage.getCustomData().pairedDocuments || {};

            await this.savePairChanges(this.props.rootStorage, pairedDocs);
            this.props.saveDraftFn?.();
            this.props.rootStorage.setDirty(this.props.rootStorage.data.bindingContext);
            await this.setTableAction(null);

            // update origPairedDocuments with the new "original" state
            this.origPairedDocuments = { ...pairedDocs };
            this.getInitialActiveRows.reset();

            this.props.storage.data.alert = {
                status: Status.Success,
                title: i18next.t("Common:Validation.SuccessTitle"),
                subTitle: this.successSubtitle,
                useFade: true,
                action: AlertAction.None
            };

        } else {
            await super.handleToolbarConfirm();
        }
        await this.props.storage.tableAPI?.reloadTable();
    }

    get customToolbarItems(): TTableToolbarItem[] {
        return [
            this.getAddSelectDef(),
            this.getPairIconDef()
        ];
    }

    formatNumberCurrency = (val: number, currency: string): number => {
        return roundToDecimalPlaces(2, val);
    };

    handleRowPairClick = async (rowId: TId): Promise<void> => {
        throw Error(`handleRowPairClick must be specified in ${this.constructor.name}`);
    };

    canRowBePaired = (rowId: TId) => {
        return true;
    };

    getCustomRowAction = memoizeOne((): TCustomRowAction => {
        return {
            actionType: RowAction.Custom,
            isSingleSelect: this.props.isSingleSelect,
            showIconsOnHover: (rowId: TId, row: IRowProps) => {
                return this.props.isSingleSelect && Object.keys(this.getPairedDocuments()).length > 0;
            },
            onClick: (rowId: TId) => this.handleRowPairClick(rowId),
            render: (args: IActionRendererArgs) => {
                if (args.isMainToggle) {
                    return null;
                }
                const storage = this.props.storage;
                const key = (args.rowId as BindingContext)?.getKey?.();
                const pairedDoc = this.getPairedDocuments()[key];
                const isReadOnly = isPairingRowReadOnly(pairedDoc, storage, this.props.rootStorage);
                if (isReadOnly) {
                    return (
                            <DarkIcon><ChainedIcon width={IconSize.S} height={IconSize.S}/></DarkIcon>
                    );
                }

                const Icon = args.actionState === ActionState.Active ? ChainedIcon : UnchainedIcon;
                const title = args.actionState === ActionState.Active ? storage.t("Banks:Transactions.UnPairingTitle") : storage.t("Banks:Transactions.PairingTitle");

                return (
                        <IconButton title={title}
                                onClick={args.onClick}
                                isDisabled={args.isDisabled || !this.canRowBePaired(args.rowId)}
                                isDecorative>
                        <Icon width={IconSize.S} height={IconSize.S}/>
                    </IconButton>
                );
            }
        };
    });

    getRowIcon = (id: TId, row: IRowProps, rowAction: IRowAction): React.ComponentType<IIconProps> => {
        if (rowAction) {
            return null;
        }

        const isActive = this.getPairedDocuments()[(id as BindingContext)?.getKey?.()];
        return isActive ? ChainedIcon : null;
    };

    getTransactionExchangeRate = (): number => {
        return this.props.rootStorage.data.entity.ExchangeRatePerUnit;
    };

    getTransactionCurrency = (): string => {
        return this.props.rootStorage.data.entity?.TransactionCurrency?.Code ?? this.props.rootStorage.data.entity?.TransactionCurrencyCode;
    };

    isRowWithoutAction(rowId: TId, action: RowAction, row: IRow): boolean {
        if (action === RowAction.Remove) {
            const typeCode = row.customData?.entity?.DocumentType?.Code;
            return !hasPermissionForDocument(typeCode, this.props.permissionContext.companyPermissions);
        }
        return super.isRowWithoutAction(rowId, action, row);
    }

    getInitialActiveRows = memoizeOne((): BindingContext[] => {
        const docs = this.origPairedDocuments;
        const { bindingContext } = this.props.storage.data;
        const initialActiveRows = (Object.keys(docs) ?? []).map(key => bindingContext.addKey(key));
        if (this._createdRowBindingContext) {
            initialActiveRows.push(this._createdRowBindingContext);
        }
        return initialActiveRows;
    });

    getTableSharedProps(): ISmartTableProps & { key: string } {
        const props = super.getTableSharedProps();
        props.keepActiveRowsOnFilterChange = true;
        props.initialActiveRows = this.getInitialActiveRows();
        return props;
    }

    getTitle = (): string => {
        return "";
    };

    getHeaderData = (): IReadOnlyListItem[] => {
        return [] as IReadOnlyListItem[];
    };

    get secondaryTitle(): string {
        return this.props.storage.data.definition.title;
    }

    renderAmountDialog = (): ReactElement => {
        return null;
    };

    rowsFactory = (rows: IRow[]): IRow[] => {
        const { storage } = this.props;
        return updateRowsArray(rows, (row: IRow) => {
            return {
                ...row,
                values: {
                    ...row.values,
                    [BindingContext.localContext("AmountPaid")]: this.getAmountPaidFormatterFn()(null, {
                        storage,
                        context: storage.context,
                        item: {
                            ...row.values,
                            Id: (row.id as BindingContext)?.getKey?.(),
                            Currency: {
                                Code: row?.customData?.entity?.CurrencyCode
                            }
                        }
                    })
                }
            };
        });
    };

    render() {
        const headerData = this.getHeaderData();

        return (
            <>
                <View hotspotContextId={"pairTableViewBase"}>
                    {this.props.busyIndicator}
                    {headerData &&
                        <ReadOnlyList title={this.getTitle()}
                                      data={headerData}
                        />
                    }
                    <SecondaryTableViewTitle>{this.secondaryTitle}</SecondaryTableViewTitle>
                    {this.renderFilterBar()}
                    {this.renderAlert()}
                    {this.renderToolbar()}
                    <TableWrapper>
                        <SmartTable
                            rowsFactory={this.rowsFactory}
                            {...this.getTableSharedProps()}
                        />
                    </TableWrapper>
                </View>
                {!!this.props.storage.getCustomData().amountDialogRow && this.renderAmountDialog()}
            </>
        );
    }
}

export { PairTableViewBase as PairTableViewBaseForExtend };


