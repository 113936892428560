import { DevelLocalSettings } from "@utils/LocalSettings";

import { REST_API_URL } from "../constants";
import { HTTPStatusCode } from "../enums";
import forPaymentSvg from "../icons/dashboard/forPayment.svg"; // origName: menu+icons/svg/dashboard/menu icons all_DASHBOARD_k úhradě.svg
import pdfSvg from "../icons/documents/pdf.svg"; // origName: doc+icons/svg/doc icons_pdf.svg
import fileSimpleSvg from "../icons/fileSimple.svg"; // origName: standard+icons/svg+(české+názvy)/icons_Soubor jednoduchý.svg
import oldImportClockSvg from "../icons/oldImportClock.svg"; // origName: standard+icons/svg+(české+názvy)/icons_Starý majetek.svg
import switchSvg from "../icons/switch.svg"; // origName: standard+icons/svg+(české+názvy)/icons_Switch.svg
import { IMenuDefGroup } from "../menu-def";
import { ROUTE_BACKGROUNDJOBS_HISTORY, ROUTE_DEVTOOLS } from "../routes";
import accountAnalysis from "../svg/menu/accountAnalysis.svg"; // origName: menu+icons/svg/menu icons all_REPORTY_analyza uctu.svg
import coaTemplates from "../svg/menu/coaTemplates.svg"; // origName: menu+icons/svg/menu icons all_SPOLECNA NASTAVENI_sablony uct rozvrhu.svg
import correctiveInvoicesIssued from "../svg/menu/correctiveInvoicesIssued.svg"; // origName: menu+icons/svg/menu icons all_DOKLADY_opravne doklady vydane.svg
import internalDocument from "../svg/menu/internalDocuments.svg"; // origName: menu+icons/svg/menu icons all_DOKLADY_interni doklady.svg
import openBalance from "../svg/menu/openBalance.svg"; // origName: menu+icons/svg/menu icons all_REPORTY_stavy pohledavek.svg
import otherLiability from "../svg/menu/otherLiability.svg"; // origName: menu+icons/svg/menu icons all_DOKLADY_ostatni zavazky.svg
import underConstructionSvg from "../svg/menu/underConstruction.svg"; // origName: unknown

const backendTestModeTimeUrl = `${REST_API_URL}/TestAdmin/GetSystemNow`;

export const DevtoolsMenuDefinition: IMenuDefGroup = {
    title: "DevTools",
    key: "devtools",
    items: [
        {
            key: "ODataBrowser",
            title: "OData Browser",
            iconPath: accountAnalysis,
            url: `${ROUTE_DEVTOOLS}/ODataBrowser`
        },
        {
            key: "TenantCloner",
            title: "Demo Account Cloner",
            iconPath: correctiveInvoicesIssued,
            url: `${ROUTE_DEVTOOLS}/TenantCloner`
        },
        {
            key: "InterfaceGenerator",
            title: "Entity interfaces generator",
            iconPath: coaTemplates,
            url: `${ROUTE_DEVTOOLS}/InterfaceGenerator`
        },
        {
            key: "ViewIdMapGenerator",
            title: "ViewId Map Generator",
            iconPath: openBalance,
            url: `${ROUTE_DEVTOOLS}/ViewIdMapGenerator`
        },
        {
            key: "HashesGenerator",
            title: "Definition test hashes generator",
            url: `${ROUTE_DEVTOOLS}/HashesGenerator`,
            iconPath: internalDocument
        },
        {
            key: "BackgroundJobsHistory",
            title: "BackgroundJobs history",
            url: ROUTE_BACKGROUNDJOBS_HISTORY,
            iconPath: underConstructionSvg
        },
        {
            key: "Email",
            title: "Emails",
            url: `${ROUTE_DEVTOOLS}/Email`,
            iconPath: otherLiability
        },
        {
            key: "FeatureSwitch",
            title: "Feature switching",
            url: `${ROUTE_DEVTOOLS}/FeatureSwitch`,
            iconPath: switchSvg
        },
        {
            key: "ScheduledTasks",
            title: "Scheduled tasks",
            url: `${ROUTE_DEVTOOLS}/ScheduledTasks`,
            iconPath: underConstructionSvg
        },
        {
            key: "FiscalYearClose",
            title: "Close fiscal year",
            url: `${ROUTE_DEVTOOLS}/FiscalYearClose`,
            iconPath: underConstructionSvg
        },
        {
            key: "TimeTravel",
            title: "Time travel",
            url: `${ROUTE_DEVTOOLS}/TimeTravel`,
            iconPath: oldImportClockSvg
        },
        {
            key: "FileIntegrationMock",
            title: "FileIntegrationMock",
            url: `${ROUTE_DEVTOOLS}/FileIntegrationMock`,
            iconPath: fileSimpleSvg
        },
        {
            key: "GoPayMock",
            title: "GoPayMock",
            url: `${ROUTE_DEVTOOLS}/GoPayMock`,
            iconPath: forPaymentSvg
        },
        {
            key: "PdfViewer",
            title: "PdfViewer",
            url: `${ROUTE_DEVTOOLS}/PdfViewer`,
            iconPath: pdfSvg
        }
    ]
};

export const isBackendInTestMode = async (): Promise<boolean> => {
    const res = await fetch(backendTestModeTimeUrl);

    if (res.status === HTTPStatusCode.NotFound) {
        return false;
    }

    return true;
};

export const getBackendTestModeTime = async (): Promise<string> => {
    const res = await fetch(backendTestModeTimeUrl);

    if (res.status === HTTPStatusCode.NotFound) {
        return null;
    }

    const time = (await res.text()).replaceAll("\"", "");

    return time;
};

export const setFeTimeTravelDate = (date: Date | string): void => {
    if (!date) {
        DevelLocalSettings.remove("timeTravel", "timeTravelDate");
        return;
    }

    DevelLocalSettings.set("timeTravel", {
        timeTravelDate: typeof date === "string" ? date : date.toISOString()
    });
};

export const isInDevtools = (): boolean => {
    return window?.location?.pathname?.toLowerCase?.()?.startsWith(ROUTE_DEVTOOLS.toLowerCase());
};