import React from "react";

import { ToolbarItemType } from "../../enums";
import { TRecordAny } from "../../global.types";

export const MENU_ICON_SIZE = 38;

export interface IToolbarItem {
    id: string;

    isBusy?: boolean;
    order?: number;
    label?: string;
    iconName?: string;
    itemType: ToolbarItemType;
    isDisabled?: boolean;
    isLight?: boolean;
    isActive?: boolean;
    itemProps?: TRecordAny;

    // for ToolbarItemType.Custom
    render?: () => React.ReactElement;
    // how many elements will the custom item render into dom next to each other
    // it is needed for the updateSticky method, so that it can pair rendered element with correct definition
    // by default 1
    elementsCount?: number;
}

// todo remove every use of React.ReactElement and always use ToolbarItemType.Custom instead
// to prevent possible errors
// because one component can render multiple elements into the toolbar
// => updateSticky won't be able to pair rendered elements onto their children/definition counterpart
// ==> we either have to always render one element for one custom item, or custom def with elementsCount has to be used
/** Custom items can be pass as rendered element.
 * Pass each button as standalone element so that Toolbar can properly render and use collapsing for each of them.*/
export type TToolbarItem = IToolbarItem | React.ReactElement;