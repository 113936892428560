import { addNewLineItem } from "@components/smart/smartFastEntryList";
import { ISmartFieldChange } from "@components/smart/smartField/SmartField";
import {
    EntitySetName,
    IPrTaxDiscountTypeEntity,
    IPrTaxpayerDeclarationDiscountEntity,
    IPrTaxpayerDeclarationEntity,
    PrTaxpayerDeclarationEntity
} from "@odata/GeneratedEntityTypes";
import { PrTaxDiscountTypeCode } from "@odata/GeneratedEnums";
import {
    getPathsForDeductionType,
    showChildrenPath
} from "@pages/payroll/taxpayerDeclaration/TaxpayerDeclaration.utils";
import { cloneDeep } from "lodash";
import React, { ReactElement } from "react";
import { ButtonGroup } from "react-bootstrap";

import Switch, { SwitchType } from "../../../components/inputs/switch/Switch";
import { withPermissionContext } from "../../../contexts/permissionContext/withPermissionContext";
import { FieldType } from "../../../enums";
import { IFormStorageDefaultCustomData } from "../../../views/formView/FormStorage";
import { FormViewForExtend, IFormViewProps } from "../../../views/formView/FormView";

export interface ITaxPayerDeclarationCustomData extends IFormStorageDefaultCustomData {
    etag?: string;
    discountTypes?: Record<string, IPrTaxDiscountTypeEntity>;
}

interface IProps extends IFormViewProps<IPrTaxpayerDeclarationEntity, ITaxPayerDeclarationCustomData> {
}

class TaxpayerDeclarationFormView extends FormViewForExtend<IPrTaxpayerDeclarationEntity, IProps> {
    loadPrTaxDiscountTypes = async (): Promise<void> => {
        const res = await this.props.storage.oData.getEntitySetWrapper(EntitySetName.PrTaxDiscountTypes)
                .query()
                .fetchData<IPrTaxDiscountTypeEntity[]>();

        const discountTypes: Record<string, IPrTaxDiscountTypeEntity> = {};

        res.value?.forEach(discountType => {
            discountTypes[discountType.Code] = discountType;
        });

        this.props.storage.setCustomData({
            discountTypes
        });

        this.props.storage.refresh();
    };

    getAdditionalLoadPromise = () => {
        return [
            this.loadPrTaxDiscountTypes()
        ];
    };

    async onAfterLoad() {
        const storage = this.props.storage;
        
        storage.setCustomData({
            etag: null
        });

        if (!storage.data.bindingContext.isNew()) {
            this.fillLocalContextFieldsFromDiscounts();
        }

        const hasChildren = !!this.entity.Children?.length;
        storage.setValueByPath(showChildrenPath, hasChildren);

        return super.onAfterLoad();
    }

    handleChange = (e: ISmartFieldChange): void => {
        this.props.storage.handleChange(e);

        if (e.bindingContext.getPath() === showChildrenPath) {
            if (!e.value) {
                this.entity.Children = [];
            } else {
                addNewLineItem(this.props.storage, PrTaxpayerDeclarationEntity.Children);
            }
        }

        if (e.type === FieldType.Switch) {
            this.props.storage.refresh();
        } else {
            this.props.storage.refreshFields();
        }
    };

    handleCheck = async (checked: boolean): Promise<void> => {
        this.entity.IsChecked = checked;

        this.props.storage.setBusy(true);
        const isSaved = await this.save();
        if (!isSaved) {
            this.entity.IsChecked = !checked;
        }
    };

    renderButtons = (): ReactElement => {
        return <ButtonGroup>
            <Switch
                    label={this.props.storage.t("TaxpayerDeclaration:Form.Checked")}
                    checked={this.entity.IsChecked}
                    onChange={this.handleCheck}
                    type={SwitchType.Icons}
            />
        </ButtonGroup>;
    };

    fillLocalContextFieldsFromDiscounts = (): void => {
        this.entity.Discounts?.forEach(discount => {
            const {
                switchPath,
                dateValidFrom,
                dateValidTo
            } = getPathsForDeductionType(discount.DiscountTypeCode as PrTaxDiscountTypeCode);

            this.props.storage.setValueByPath(switchPath, true);
            this.props.storage.setValueByPath(dateValidFrom, discount.DateValidFrom);
            this.props.storage.setValueByPath(dateValidTo, discount.DateValidTo);
        });
    }

    createDiscountsFromLocalContext = (): IPrTaxpayerDeclarationDiscountEntity[] => {
        const discounts: IPrTaxpayerDeclarationDiscountEntity[] = [];
        Object.values(PrTaxDiscountTypeCode).forEach(type => {
            const { switchPath, dateValidFrom, dateValidTo } = getPathsForDeductionType(type);

            if (this.props.storage.getValueByPath(switchPath)) {
                const discount = {
                    DiscountTypeCode: type,
                    DateValidFrom: this.props.storage.getValueByPath(dateValidFrom),
                    DateValidTo: this.props.storage.getValueByPath(dateValidTo)
                } as IPrTaxpayerDeclarationDiscountEntity;

                discounts.push(discount);
            }
        });
        return discounts;
    };

    onBeforeSave(data?: IPrTaxpayerDeclarationEntity): IPrTaxpayerDeclarationEntity {
        const storage = this.props.storage;
        storage.clearEmptyLineItems(PrTaxpayerDeclarationEntity.Children, true);
        const entity = cloneDeep(this.entity);
        entity.Discounts = this.createDiscountsFromLocalContext();

        return entity;
    }
}

export default withPermissionContext(TaxpayerDeclarationFormView);