import { CheckboxLabel } from "@components/inputs/checkbox/Checkbox.styles";
import { StyledWriteLine } from "@components/inputs/writeLine/WriteLine.style";
import { transparentize } from "polished";
import styled, { css } from "styled-components/macro";

import Alert from "../components/alert";
import { Button, ButtonGroup, IconButton } from "../components/button";
import Clickable from "../components/clickable";
import Checkbox from "../components/inputs/checkbox";
import { TooltipIconInfo } from "../components/tooltipIcon";
import {
    getDisabledStyle,
    getFocusBorderElement,
    LinkStyles,
    multiline_ellipsis,
    T_HEADER_bold,
    T_HEADER_small
} from "../global.style";
import waveLogging from "../static/images/waveLogging.svg"; // origName: ostatní/PODVOJ+login+a+registrace/ilustrace_animace/prodloužená vlnka.svg
import TenantSelector from "./components/TenantSelector";
import { LOGIN_PAGE_BOX_MIN_MARGIN, MinimumAnimationWindow } from "./Login.utils";

const LOGIN_PAGE_BOX_MAX_WIDTH = 575;
const LOGIN_PAGE_BOX_MIN_HEIGHT = 893;
const LOGIN_PAGE_BOX_LEFT_MARGIN = 95;

const LOGIN_BOX_MEDIA_HEIGHT_THRESHOLD = LOGIN_PAGE_BOX_MIN_HEIGHT + 2 * LOGIN_PAGE_BOX_MIN_MARGIN;
const LOGIN_BOX_MEDIA_WIDTH_THRESHOLD = LOGIN_PAGE_BOX_MAX_WIDTH + 2 * LOGIN_PAGE_BOX_MIN_MARGIN;

const BG_WIDTH = 3840; // width of background and animation
// bakcground SVG has basic size 300x84px
const BG_HEIGHT = 84 * BG_WIDTH / 300;
// wave has 198px at the end when SVG image has height of 1076px
const WAVE_FOLLOWING_HEIGHT_RATIO = 198 / 1076;
const WAVE_FOLLOWING_HEIGHT = BG_HEIGHT * WAVE_FOLLOWING_HEIGHT_RATIO;

const BASE_FRAME_SIDE_PADDING = 68;
const LoginFieldMargin = 32;

// todo: remove these...
const THIRD_MEDIA_THRESHOLD = 836;
const FOURTH_MEDIA_THRESHOLD = 768;

const LoginFont = css`
    font-family: AvertaPE, Arial, sans-serif;
`;

const textCSS = css`
    ${LoginFont};
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5;
`;

export const getScrollingBodyMedia = () => {
    return css`
        @media only screen and (max-height: ${FOURTH_MEDIA_THRESHOLD}px) {
            height: 400px;
        }

        @media only screen and (max-height: ${THIRD_MEDIA_THRESHOLD}px) {
            height: 450px;
        }
    `;
};

export const LoginPageWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    background-size: ${BG_WIDTH}px;
    background-image: url(${waveLogging});
    background-position: left 50%;
    background-repeat: no-repeat;

    &::after, &::before {
        // continue of the wave on huge screens and bottom part of the wave not contained in the SVG
        content: "";
        position: absolute;
        width: 100%;
        background-color: #DCB7D3;
    }

    &::after {
        left: ${BG_WIDTH}px;
        // bottom animation corner minus 1px to prevent rounding glitches
        bottom: calc(50vh - ${BG_HEIGHT / 2}px - 1px);
        // add the one px added above            ^^^, so it's aligned with the wave 
        height: ${WAVE_FOLLOWING_HEIGHT + 1}px;
    }

    &::before {
        // bottom of the wave on very high screens
        left: 0;
        bottom: 0;
        // space between bottom window corner and bottom of the SVG wave
        height: calc(50vh - ${BG_HEIGHT / 2}px);
    }

    @media only screen and (max-height: ${LOGIN_BOX_MEDIA_HEIGHT_THRESHOLD}px) {
        background-position: -${LOGIN_PAGE_BOX_LEFT_MARGIN}px 50%;

        &::after {
            left: ${BG_WIDTH - LOGIN_PAGE_BOX_LEFT_MARGIN}px;
        }
    }
`;

export const LoginPageStyled = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden auto;
`;

const WithoutRoundedCorners = css`
    margin: 0;
    border-radius: 0;
    min-height: 100%;
`;

// LoginFrame has to be form, not div
//   1. it is semantically correct
//   2. it allows submitting form by pressing Enter when the button is set to type="submit"
export const LoginFrame = styled.form.attrs({ autoComplete: "off" })<{
    _minHeight?: string
}>`
    ${LoginFont};
    position: relative;
    min-height: ${LOGIN_PAGE_BOX_MIN_HEIGHT}px;
    max-width: ${LOGIN_PAGE_BOX_MAX_WIDTH}px;
    width: 100%;

    margin: ${LOGIN_PAGE_BOX_MIN_MARGIN}px auto ${LOGIN_PAGE_BOX_MIN_MARGIN}px ${LOGIN_PAGE_BOX_LEFT_MARGIN}px;
    padding: 0 ${BASE_FRAME_SIDE_PADDING}px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;

    border-radius: 30px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
    background-color: #fff;
    overflow: hidden;

    z-index: 1;

    & ${StyledWriteLine} {
        width: 100%;
    }

    // by default, it's rendered as box on the left side of the screen, centered vertically
    // when width is so small, that animation is not visible, the box is centered, wave is visible on behind
    // if width or height squeezes under minimum, just inner white box is displayed
    @media only screen and (max-width: ${LOGIN_BOX_MEDIA_WIDTH_THRESHOLD}px) {
        // without corners because of width (overrides height condition also 
        // -> if it don't fit to the width, it can't be rendered as box at all)
        ${WithoutRoundedCorners};
        max-width: 100%;
        padding: 0 15%;
    }
    @media only screen and (min-height: ${LOGIN_BOX_MEDIA_HEIGHT_THRESHOLD + 1}px) {
        &.fit-height {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            margin-top: 0;
            margin-bottom: 0;

            @media only screen and (max-width: ${MinimumAnimationWindow.width}px) {
                // between full width and threshold for displaying animation, it's centered
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    @media only screen and (max-height: ${LOGIN_BOX_MEDIA_HEIGHT_THRESHOLD}px) {
        ${WithoutRoundedCorners};
    }
    @media only screen and (min-width: ${LOGIN_BOX_MEDIA_WIDTH_THRESHOLD + 1}px) and (max-width: ${MinimumAnimationWindow.width}px) {
        // between full width and threshold for displaying animation, it's centered
        margin-right: auto;
        margin-left: auto;
    }
`;

export const FramePadding = styled.div`
    width: 50px;
    flex: 1 1 0;
    max-height: 96px;
    min-height: 74px;
`;

export const ErrorMessage = styled.div`
    ${LoginFont};
    font-size: 18px;
    font-weight: 600;
    line-height: 1.33;
    color: ${props => props.theme.C_SEM_text_bad};
    background-color: ${props => transparentize(0.95, props.theme.C_SEM_el_bad)};
    padding: 15px ${BASE_FRAME_SIDE_PADDING}px;
    margin: 0 -${BASE_FRAME_SIDE_PADDING}px;

    span {
        max-height: 47px;
        ${multiline_ellipsis("100%", 2)};
    }

    & + ${FramePadding} {
        // error message handle some spacing, so the framePadding should be minimized
        max-height: 74px;
    }
`;

export const LoginFrameBody = styled.div`
    // DEV-25621 makes busy indicator NOT over whole box... position: relative;
    min-height: 0;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
`;

export const AnimationStyled = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: none;

    @media only screen and (min-height: ${MinimumAnimationWindow.height}px) and (min-width: ${MinimumAnimationWindow.width}px) {
        display: block;
    }
`;

export const AnimWrapper = styled.div`
    height: ${BG_HEIGHT}px;
    width: 10000px; // some space for the SVG, so it don't shrink and adjust just by height
    position: absolute;
    bottom: calc(50vh - ${BG_HEIGHT / 2}px);

    @media only screen and (max-height: ${LOGIN_BOX_MEDIA_HEIGHT_THRESHOLD}px) {
        left: -${LOGIN_PAGE_BOX_LEFT_MARGIN}px;
    }

    & > * {
        height: 100%;
    }

    & svg {
        height: 100%;
        width: auto !important;
    }
`;

export const StaticBikerImage = styled.img`
    position: absolute;
    width: 1000px;
    height: auto;
    left: 625px;
    bottom: 115px;
`;

export const BikerSignImage = styled.img`
    position: absolute;
    width: 300px;
    height: 800px;
    left: 1600px;
    bottom: 150px;
`;

export const LoginTitle = styled.div`
    ${LoginFont};
    font-size: 50px;
    font-weight: 800;
    line-height: 1.24;
    letter-spacing: 1.25px;
    position: relative;
    margin-bottom: 28px;
`;

export const BasicLoginText = styled.div`
    ${textCSS};
`;

export const BasicLoginTextBlock = styled.div`
    ${textCSS};
    margin: 0 0 28px;
    display: block;
`;

export const LoginFormStyled = styled.div<{ bigMargin?: boolean; tinyMargin?: boolean }>`
    margin-top: ${props => props.bigMargin ? 80 : props.tinyMargin ? 30 : 52}px;
`;

export const NewTenantButtonStyled = styled(Button)`
    left: -20px;
`;

export const LabelRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin-bottom: 30px; // spacing between label and input
`;

export const LoginFieldStyled = styled.div`
    margin-bottom: ${LoginFieldMargin}px;
    ${T_HEADER_small};
    position: relative;
`;

export const Row = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 31px;

    @media only screen and (max-width: ${0.8 * LOGIN_BOX_MEDIA_WIDTH_THRESHOLD}px) {
        flex-direction: column;
        align-items: stretch;
        gap: 0; // there is bottom margin of the field instead
    }
`;

export const StyledLabel = styled.label`
    display: block;
    text-transform: uppercase;
    ${T_HEADER_bold};
`;

export const TooltipIconInfoStyled = styled(TooltipIconInfo)`
    margin-left: 5px;
    margin-bottom: 3px;
`;

export const CheckboxStyled = styled(Checkbox)`
    margin-bottom: 12px;
    margin-top: 38px;

    & + & {
        margin-top: 0;
    }

    ${CheckboxLabel} {
        white-space: normal;
    }
`;

export const LoginAlert = styled(Alert)`
    margin-top: -40px;
    max-width: 100%;
`;

export const Tenants = styled.div`
    padding-right: 5px;
    height: 100%;
`;

export const BackArrowStyled = styled(IconButton)`
    position: absolute;
    top: -28px;
    transform: translate(0, -100%);
`;

// todo: may be make common inner scroll component for login page without specifing height
export const TenantsBody = styled.div`
    display: flex;
    height: 550px;
    flex-direction: column;
    margin-top: 78px;

    ${getScrollingBodyMedia()}
`;

export const TenantScrollbarWrapper = styled.div`
    margin-bottom: 40px;
    max-height: calc(100% - 60px);
`;
export const TenantButtonWrapper = styled.div`
    margin-left: 20px;
`;

export const SingleSignOnWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const SingleSignOnAddEmailWrapper = styled.div`
    display: block;
    border: 1px solid black;
    border-radius: 3px;
    padding: 10px;
`;

export const SingleSignOnImg = styled.img`
    width: 40px;
    cursor: pointer;
    margin-right: 10px;
`;

export const GoogleIconWrapper = styled.div`
    margin-right: 20px;
    cursor: pointer;
`;

export const FacebookIconWrapper = styled.div`
    cursor: pointer;
`;

export const LoginButtonGroupStyled = styled(ButtonGroup) <{ smallMargin?: boolean }>`
    margin: 50px 0 ${props => props.smallMargin ? "0" : "26px"} 0;
`;

export const BottomText = styled.div`
`;

const LoginBottomTextStyles = css`
    opacity: 0.5;
    font-size: 14px;
    font-style: italic;
`;

export const LoginSmallBottomText = styled.span`
    ${LoginBottomTextStyles};
`;

export const LoginSmallBottomClickable = styled(Clickable)`
    ${LinkStyles};
    ${LoginBottomTextStyles};
    margin-left: 5px;
    ${props => !props.isDisabled && css`color: ${props.theme.C_ACT_main};`};
    text-decoration: none;
`;

export const LostPass = styled(LoginSmallBottomClickable)`
    position: absolute;
    bottom: -9px;
    right: 0;
    transform: translate(0, 100%);
`;

export const TenantSelectorStyled = styled.div<{ isDisabled: boolean }>`
    position: relative;
    height: 100px;
    border-bottom: 1px solid ${props => props.theme.C_ACT_thick_line};

    &:first-of-type {
        border-top: 1px solid ${props => props.theme.C_ACT_thick_line};
    }

    display: flex;
    align-items: center;

    width: 430px;
    cursor: pointer;

    padding-left: 15px;

    &:hover {
        background-color: ${props => props.theme.C_ACT_hover};
    }

    ${props => getDisabledStyle(props.isDisabled)}
    ${props => getFocusBorderElement({ offset: -5, keyboardOnly: true })};
`;

export const TenantSelectorDemo = styled(TenantSelector)`
    border-bottom: 1px solid ${props => props.theme.C_ACT_thick_line};
`;

export const TenantBottomLine = styled.div`
    border-bottom: 1px solid ${props => props.theme.C_ACT_thick_line};
`;

export const TenantTitleWrapper = styled.div`
    height: 71px;
    overflow: hidden;
    display: flex;
    align-items: center;
`;

export const TenantTitle = styled.span`
    font-size: 20px;
    margin-left: 20px;
    font-weight: bold;


    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`;

export const DeviceWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledDeviceSelector = styled.div`
    display: flex;
    align-items: center;
    margin: 13px 0;
    width: 100%;
`;

export const DeviceBody = styled.div<{
    isCurrent: boolean;
}>`
    opacity: ${props => props.isCurrent ? 1 : 0.5};
    display: flex;
    align-items: center;
    width: 100%;
`;

export const DeviceSeparator = styled.div`
    background-color: #a7a6a7;
    height: 2px;
    margin: 23px 0;
`;

export const DeviceName = styled.div`
    font-size: 18px;
    margin-left: 30px;
    width: 100%;
`;


export const Message = styled.div`
    max-width: 300px;
`;
