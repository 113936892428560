import { AlertPosition } from "@components/alert/Alert";
import { WithAlert, withAlert } from "@components/alert/withAlert";
import { WithConfirmationDialog, withConfirmationDialog } from "@components/dialog/withConfirmationDialog";
import {
    fileNameToMime,
    getFileNameExtension,
    getUniqueFileName,
    splitFileName
} from "@components/fileUploader/File.utils";
import {
    getFileNameFromSrc,
    getFileViewer,
    IFileViewerProps,
    isFileOpenable
} from "@components/fileViewers/FileViewers.utils";
import { HotspotViewIds } from "@components/hotspots/HotspotViewIds";
import { ISelectItem } from "@components/inputs/select/Select.types";
import { DocumentEntity, IFileMetadataEntity } from "@odata/GeneratedEntityTypes";
import { getCleanDraftKey } from "@pages/PageUtils";
import { removeWhiteSpace } from "@utils/string";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import BusyIndicator from "../../components/busyIndicator";
import SmartField, {
    ISmartFieldChange,
    ISmartFieldTempDataActionArgs
} from "../../components/smart/smartField/SmartField";
import SmartFileUploader from "../../components/smart/smartFileUploader/SmartFileUploader";
import { FILES_API_URL } from "../../constants";
import { ModelEvent } from "../../model/Model";
import BindingContext, { areBindingContextsDifferent, IEntity } from "../../odata/BindingContext";
import { getAlertFromError } from "../formView/Form.utils";
import { FormStorage } from "../formView/FormStorage";
import { FileViewStyled } from "./FileView.styles";
import { getFileNameWriteLineDef, getFiles } from "./FileViewUtils";

interface IProps extends WithConfirmationDialog, WithTranslation, WithAlert {
    formStorage: FormStorage;
    isVisible?: boolean;
    isBusy?: boolean;
    onNewFiles?: () => void;
    onFilesUploaded?: (isFirstUpload: boolean) => void;
    onFilesRemoved?: (isLastFile: boolean) => void;
    onDragEnter?: (event: DragEvent) => void;
    onDragLeave?: (event: DragEvent) => void;
    /** Can be used in case current entity uses FileView which has global file drop area.
     *otherFileDropArea is list of elements, that handles file drop for themselves, outside of the FileView.
     * When user hovers files over them, in case this FileDropArea is global,
     * we should disable hover state in this FileDropArea, so that use knows which of the drop areas will be used. */
    otherFileDropAreas?: HTMLElement[];

    ref?: React.RefObject<React.ReactElement>;
}

interface IState {
    selectedFile: IFileMetadataEntity;
}

class FileView extends React.PureComponent<IProps, IState> {
    state: IState = {
        selectedFile: null
    };

    formBcBackup: BindingContext = null;
    hasFormBcChanged = true;
    filesProperty: string;

    componentDidMount() {
        if (this.props.isVisible) {
            this.init();
            this.formBcBackup = this.props.formStorage?.data?.bindingContext;
        }
    }

    getAttachmentsBc = (): BindingContext => {
        return this.props.formStorage.data.bindingContext.navigate(DocumentEntity.Attachments);
    };

    getFileNameBc = (): BindingContext => {
        const attachment = this.props.formStorage.data.entity?.Attachments?.find((attachment: IEntity) => attachment[this.filesProperty].Id === this.state.selectedFile.Id);

        if (!attachment) {
            return null;
        }

        return this.getAttachmentsBc()
            .addKey(attachment.Id)
            .navigate(`${this.filesProperty}/Name`);
    };

    init = () => {
        this.hasFormBcChanged = false;
        this.initFiles();
    };

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        const { formStorage } = this.props;
        if (areBindingContextsDifferent(this.formBcBackup, formStorage?.data?.bindingContext)) {
            this.hasFormBcChanged = true;
        }

        // we want to automatically pre-open file, if only one is present
        // but only the first time FileView is opened
        // this should reset everytime selected row changes
        const isLoaded = !!(formStorage?.loaded && !formStorage?.isBusy());
        if (isLoaded && this.props.isVisible && this.hasFormBcChanged) {
            this.init();
        }

        this.formBcBackup = formStorage?.data?.bindingContext;
    }

    getCurrentForm = () => {
        return this.props.formStorage?.data?.definition;
    };

    isFormReadOnly = () => {
        return this.props.formStorage?.isReadOnly;
    };

    handleFileChange = (file: IFileMetadataEntity, preventDownload?: boolean) => {
        this.setState({
            selectedFile: file
        });
    };

    handleNewFiles = () => {
        this.props.onNewFiles?.();
        if (this.state.selectedFile) {
            this.setState({
                selectedFile: null
            });
        }
    };

    handleFilesUploaded = (files: IFileMetadataEntity[], isFirstUpload: boolean) => {
        const isSingle = files.length === 1 && getFiles(this.props.formStorage).length === 1;
        this.props.onFilesUploaded?.(isFirstUpload);
        this.props.formStorage.emitter.emit(ModelEvent.FilesUploaded, files);

        if (isSingle) {
            const file = files[0];
            this.filesProperty = this.getCurrentForm().files.property;
            if (isFileOpenable(file.Name)) {
                this.handleFileChange(file, true);
            }
        }
    };

    handleFilesRemoved = (files: IFileMetadataEntity[], isLastRemoved: boolean) => {
        this.props.onFilesRemoved?.(isLastRemoved);
    };

    handleReturnFromFile = () => {
        this.props.formStorage.cancelFields([this.getFileNameBc()]);
        this.setState({
            selectedFile: null
        });
    };

    initFiles = () => {
        const filesDef = this.getCurrentForm()?.files;
        if (!filesDef) {
            return;
        }

        const files = getFiles(this.props.formStorage);
        this.filesProperty = filesDef.property;
        const selectedFile = files?.length === 1 && (this.filesProperty ? files[0][this.filesProperty] : files[0]);

        if (isFileOpenable(selectedFile.Name)) {
            this.handleFileChange(selectedFile, true);
        } else {
            this.setState({
                selectedFile: null
            });
        }
    };

    handleFileRemove = async (src: string) => {
        const isConfirmed = await this.props.confirmationDialog.open({
            content: this.props.formStorage.t("Common:Confirmations.ConfirmDelete")
        });

        if (!isConfirmed) {
            return;
        }

        const fileId = parseInt(getFileNameFromSrc(src));
        const attachmentsBc = this.getAttachmentsBc();
        const attachments: IEntity[] = this.props.formStorage.getValue(attachmentsBc);

        const attachmentId = attachments.find((attachment: IEntity) => attachment[this.filesProperty].Id === fileId).Id;
        const isDraft = !!this.props.formStorage.data.entity[this.props.formStorage.data.definition.draftDef.draftProperty]?.Id;
        let path = attachmentsBc.toString();

        if (isDraft) {
            path = `${this.props.formStorage.data.definition.draftDef.draftEntitySet}(${getCleanDraftKey(this.props.formStorage.data.bindingContext.getKey())})/${DocumentEntity.Attachments}`;
        }

        const queryable = this.props.formStorage.oData.fromPath(path);

        try {
            await queryable.delete(attachmentId);

            this.props.formStorage.setValueToEntityAndOrigEntity(attachmentsBc, attachments.filter(attachment => attachment.Id !== attachmentId));

            const isLastRemoved = getFiles(this.props.formStorage).length === 0;

            this.props.onFilesRemoved?.(isLastRemoved);

            this.setState({
                selectedFile: null
            });
        } catch (e) {
            this.props.setAlert({
                ...getAlertFromError(e),
                isSmall: false
            });
        }
    };

    handleFileNameChange = (e: ISmartFieldChange) => {
        const originalName = this.props.formStorage.getValue(this.getFileNameBc());
        const extension = getFileNameExtension(originalName);

        if (extension) {
            e.value = `${e.value || ""}.${extension}`;
        }

        this.props.formStorage.handleTemporalChange(e);
        this.props.formStorage.refreshFields();
    };

    handleFileNameCancel = (args: ISmartFieldTempDataActionArgs) => {
        this.props.formStorage.handleCancel(args)
            .then(() => this.props.formStorage.refreshFields());
    };

    /** Can be triggered by pressing enter or clicking on confirm button.*/
    handleFileNameConfirm = async (args: ISmartFieldTempDataActionArgs) => {
        const fileNameBc = this.getFileNameBc();
        const fileName = this.props.formStorage.getValue(fileNameBc);
        let { prefix } = splitFileName(fileName);

        prefix = removeWhiteSpace(prefix);

        // if the WriteLine is confirmed when empty,
        // restore the original file name
        if (!prefix) {
            await this.props.formStorage.handleCancel(args);
            this.props.formStorage.refreshFields();
            return;
        }

        const fileEntitySet = fileNameBc.getParent().getEntitySet();
        const queryable = this.props.formStorage.oData.fromPath(fileEntitySet.getName());

        const attachments: IEntity[] = getFiles(this.props.formStorage);
        const uniqueName = getUniqueFileName(fileName, attachments.filter(attachment => attachment[this.filesProperty].Id !== this.state.selectedFile.Id).map(attachment => attachment[this.filesProperty].Name));

        await this.props.formStorage.handleConfirm(args);

        if (uniqueName !== fileName) {
            this.props.formStorage.setValue(fileNameBc, uniqueName);
        }

        this.props.formStorage.refreshFields();

        queryable.update(this.state.selectedFile.Id, {
            Name: uniqueName
        });

    };

    renderFileNameChange = () => {
        const fileNameBc = this.getFileNameBc();

        // form bc has changed, but selectedFile is not yet correctly updated
        if (!fileNameBc) {
            return null;
        }

        return (
            <SmartField storage={this.props.formStorage}
                        onTemporalChange={this.handleFileNameChange}
                        bindingContext={fileNameBc}
                        fieldDef={getFileNameWriteLineDef(this.props.formStorage.t)}
                        onConfirm={this.handleFileNameConfirm}
                        onCancel={this.handleFileNameCancel}
                        style={{
                            // negative margin to shift the field into the padding area of the parent
                            marginTop: "-27px",
                            // prevent from overlapping with SplitPage control buttons
                            marginRight: "130px",
                            top: "38px",
                            left: "38px",
                            fontSize: "14px"
                        }}
            />
        );
    };

    renderFileViewer = () => {
        const fileNameBc = this.getFileNameBc();

        // form bc has changed, but selectedFile is not yet correctly updated
        if (!fileNameBc) {
            return null;
        }

        return (
            this.getFileViewer()
        );
    };

    handleFileViewerOnCustomFileAction = (actionId: string) => {
        this.props.formStorage.emitter.emit(ModelEvent.CustomFileAction,
            {
                file: this.state.selectedFile,
                action: actionId
            }
        );
    };

    getFileViewer = () => {
        const form = this.getCurrentForm();
        const mime = fileNameToMime(this.state.selectedFile.Name);
        const src = `${FILES_API_URL}/${this.state.selectedFile.Id}`;
        const isReadOnly = this.isFormReadOnly();

        const customFileActions = isReadOnly ? [] : form?.getCustomAttachmentFileActions?.(this.state.selectedFile, this.props.formStorage);

        const props: IFileViewerProps = {
            src,
            isReadOnly,
            fileName: this.state.selectedFile.Name,
            onGoToList: this.handleReturnFromFile,
            isRemoveDisabled: !!this.props.formStorage.getBackendDisabledFieldMetadata(this.getAttachmentsBc())?.cannotDelete,
            onFileRemove: this.handleFileRemove,
            customFileActions: customFileActions ?? null,
            onCustomFileAction: this.handleFileViewerOnCustomFileAction
        };

        return getFileViewer(mime, props);
    };

    handleDragEnter = (event: DragEvent) => {
        this.props.onDragEnter?.(event);
    };

    handleDragLeave = (event: DragEvent) => {
        this.props.onDragLeave?.(event);
    };

    handleCustomFileAction = (file: IFileMetadataEntity, actionId: string, attachId: number) => {
        this.props.formStorage.emitter.emit(ModelEvent.CustomFileAction, { file: file, action: actionId, attachId });
    };

    getCustomFileActionsItems = (file: IFileMetadataEntity, storage: FormStorage): ISelectItem[] => {
        if (this.isFormReadOnly()) {
            return [];
        }
        const form = this.getCurrentForm();

        return form?.getCustomAttachmentFileActions?.(file, storage)?.map(item => {
            return {
                id: item.id,
                label: item.label,
                iconName: item.iconName,
                isDisabled: item.isDisabled || item.isBusy
            } as ISelectItem;
        });

    };

    render() {
        const form = this.getCurrentForm();
        const isBusy = this.props.isBusy || !this.props.formStorage?.loaded;
        return (<>
            {this.props.alert}
            {/*file name writeline has to be outside of scroll bar*/}
            {/*because it's in the padding area of the parent*/}
            {this.state.selectedFile &&
                this.renderFileNameChange()
            }
            <FileViewStyled hotspotContextId={HotspotViewIds.FileView}
                            nonScrollableContent={isBusy && <BusyIndicator/>}>
                {this.state.selectedFile &&
                    this.renderFileViewer()
                }
                <SmartFileUploader style={{ marginTop: "17px" }}
                                   isReadOnly={this.isFormReadOnly()}
                                   isHidden={!!this.state.selectedFile || !this.props.isVisible}
                                   storage={this.props.formStorage}
                                   collection={form?.files?.collection}
                                   fileProperty={form?.files?.property}
                                   customFileActions={this.getCustomFileActionsItems}
                                   onCustomFileAction={this.handleCustomFileAction}
                                   onFileClick={this.handleFileChange}
                                   onNewFiles={this.handleNewFiles}
                                   onFilesUploaded={this.handleFilesUploaded}
                                   onFilesRemoved={this.handleFilesRemoved}
                                   onDragEnter={this.handleDragEnter}
                                   onDragLeave={this.handleDragLeave}
                                   otherFileDropAreas={this.props.otherFileDropAreas}/>
            </FileViewStyled>
        </>);
    }
}

export default withTranslation(["Document", "Components"], { withRef: true })(withAlert({ position: AlertPosition.CenteredBottom })(withConfirmationDialog(FileView)));