import { getDrillDownVariant } from "@components/drillDown/DrillDown.utils";
import {
    COLUMN_INDEX_REGEX,
    getCleanColumnId,
    IReportColumnDef,
    IReportHierarchy,
    IReportWarning,
    ISmartReportTableAPI
} from "@components/smart/smartTable";
import { IRow, ISort } from "@components/table";
import {
    getDefaultSystemVariant,
    ITableVariant,
    IVariantColumn,
    IVariants,
    Variant
} from "@components/variantSelector/VariantOdata";
import { setNestedValue } from "@odata/Data.utils";
import { VariantAccessTypeCode, VariantTypeCode } from "@odata/GeneratedEnums";

import { ModelEvent } from "../../model/Model";
import { ITableStorageData, ITableStorageDefaultCustomData, TableStorage } from "../../model/TableStorage";
import BindingContext from "../../odata/BindingContext";
import memoizeOne from "../../utils/memoizeOne";
import {
    convertVariantToReportHierarchy,
    getColumnFromColumnAlias,
    getColumnFullAlias,
    IReportDateRange,
    IReportSettings,
    IReportTableDefinition,
    ReportConfigGroup
} from "./Report.utils";

export interface IReportStorageDefaultCustomData extends ITableStorageDefaultCustomData {
    warnings?: IReportWarning[];
    namedDateRanges?: IReportDateRange[];
}

interface IReportStorageData<C extends IReportStorageDefaultCustomData = IReportStorageDefaultCustomData> extends ITableStorageData<C> {
    definition?: IReportTableDefinition;
}

export class ReportStorage<C extends IReportStorageDefaultCustomData = IReportStorageDefaultCustomData> extends TableStorage<ISmartReportTableAPI, C> {
    data: IReportStorageData<C> = {};
    private _reportHierarchy: IReportHierarchy = null;
    private _supportedColumns: IReportColumnDef[] = [];
    private _additionalFilters: IReportColumnDef[] = [];
    private _settings: IReportSettings = {};
    // used to build unique column values for value helpers
    private _unfilteredRows: IRow[];

    getVariant = (): ITableVariant => {
        const currentVariant = this.data.variants?.currentVariant;
        const variantDef = this.data.definition.defaultReportVariants?.[currentVariant.id];

        if (this.predefinedFilters) {
            const sort = (this.tableAPI as unknown as ISmartReportTableAPI)?.getState().sort;
            return {
                columns: this.convertReportHierarchyToVariantColumns(this.reportHierarchy, sort ?? variantDef?.Sort ?? this.data.definition.initialSortBy),
                filters: this.predefinedFilters
            };
        }

        const storedVariant = this.getStoredVariant(true);
        const isVariantNonEmpty = storedVariant?.columns?.length > 0;

        if (isVariantNonEmpty) {
            return storedVariant;
        }

        if (currentVariant && variantDef) {
            return {
                columns: this.convertReportHierarchyToVariantColumns(variantDef.ReportHierarchy, variantDef.Sort ?? this.data.definition.initialSortBy),
                filters: {}
            };
        }

        return {
            columns: this.convertReportHierarchyToVariantColumns(this.data.definition.defaultReportHierarchy, this.data.definition.initialSortBy),
            filters: {}
        };
    };

    convertReportHierarchyToVariantColumns = memoizeOne((reportHierarchy: IReportHierarchy, sort?: ISort[]): IVariantColumn[] => {
        const variantColumns: IVariantColumn[] = [];
        const allColIds = [];

        if (!sort) {
            sort = this.tableAPI?.getSort() ?? this.data.definition.initialSortBy;
        }

        for (const groupType of [ReportConfigGroup.Groups, ReportConfigGroup.Columns, ReportConfigGroup.Aggregations]) {
            for (const colDef of reportHierarchy[groupType as unknown as keyof Omit<IReportHierarchy, "Aggregate">]) {
                // sort is stored with full column alias (including agg fn)
                const fullColAlias = getColumnFullAlias(colDef);
                const sortIndex = sort?.findIndex(s => {
                    let cleanId = getCleanColumnId(s.id.toString());

                    if (this.reportHierarchy && reportHierarchy && this.reportHierarchy.Aggregate !== reportHierarchy.Aggregate) {
                        cleanId = getColumnFromColumnAlias(cleanId).ColumnAlias;
                    }

                    return cleanId === fullColAlias;
                });
                const colSort = sort?.[sortIndex];
                let repeatedColumnsCount = 0;

                for (let i = 0; i < sortIndex; i++) {
                    if (COLUMN_INDEX_REGEX.test(sort?.[i].id.toString())) {
                        repeatedColumnsCount += 1;
                    }
                }

                const variantColumn: IVariantColumn = {
                    id: colDef.ColumnAlias,
                    group: reportHierarchy.Aggregate ? groupType : null,
                    aggregationFunction: colDef.AggregationFunction,
                    ...this.getVariantColumnSortProps(fullColAlias, colSort, sortIndex - repeatedColumnsCount)
                };

                variantColumns.push(variantColumn);
                allColIds.push(fullColAlias);
            }
        }

        return variantColumns;
    }, () => [this.tableAPI?.getSort, this.data.definition.initialSortBy]);

    setLocalStorageVariantFromCurrentReportHierarchy = (): void => {
        const oldVariant = this.getVariant();
        const variantColumns = this.convertReportHierarchyToVariantColumns(this.reportHierarchy);

        this.setLocalStorageVariant({
            ...oldVariant,
            columns: variantColumns
        });
    };

    get reportHierarchy(): IReportHierarchy {
        return this._reportHierarchy;
    }

    set reportHierarchy(reportHierarchy: IReportHierarchy) {
        this._reportHierarchy = reportHierarchy;
    }

    get supportedColumns(): IReportColumnDef[] {
        return this._supportedColumns;
    }

    set supportedColumns(supportedColumns: IReportColumnDef[]) {
        this._supportedColumns = supportedColumns;
    }

    get additionalFilters(): IReportColumnDef[] {
        return this._additionalFilters;
    }

    set additionalFilters(additionalFilters: IReportColumnDef[]) {
        this._additionalFilters = additionalFilters;
    }

    get settings(): IReportSettings {
        return this._settings;
    }

    set settings(settings: IReportSettings) {
        this._settings = settings;
    }

    get unfilteredRows(): IRow[] {
        return this._unfilteredRows;
    }

    set unfilteredRows(unfilteredRows: IRow[]) {
        this._unfilteredRows = unfilteredRows;
    }

    clearFilterByPath = (fullPath: string): void => {
        const navigationPath = fullPath.split("/").slice(1).join("/");

        if (this.predefinedFilters?.[fullPath]) {
            return;
        }

        const variant = this.getVariant();
        const savedFilters = { ...variant.filters };

        setNestedValue("", navigationPath, this.data.entity);
        this.clearAdditionalFieldDataByPath(fullPath);
        delete savedFilters[navigationPath];
    };

    updateCurrentTableColumns = async (sort?: ISort[]): Promise<void> => {
        const drillDownVariantQuery = getDrillDownVariant();
        const drillDownVariantDef = this.data.definition.defaultReportVariants?.[drillDownVariantQuery];
        let reportHierarchy: IReportHierarchy;

        if (drillDownVariantDef) {
            reportHierarchy = drillDownVariantDef.ReportHierarchy;
        } else if (this.predefinedFilters) {
            // when drilldown, show default variant
            reportHierarchy = this.data.variants.defaultVariant?.table?.columns ? convertVariantToReportHierarchy(this.data.variants.defaultVariant.table) : this.reportHierarchy ?? this.data.definition.defaultReportHierarchy;

        } else {
            reportHierarchy = convertVariantToReportHierarchy(this.getVariant());
        }

        if (drillDownVariantDef || this.predefinedFilters) {
            // when drilldown used, right now, sort is only stored in the state of the table
            // maybe we could store it in storage, like this.reportHierarchy?
            // but forcing new sort into the table state like this should work also
            if (sort) {
                (this.tableAPI as unknown as ISmartReportTableAPI).setState({
                    sort
                }, (this.tableAPI as unknown as ISmartReportTableAPI).reloadTable);
            }
        }

        this.reportHierarchy = reportHierarchy;
        // we need to refresh rows when report hierarchy is changed
        this.unfilteredRows = null;
    };

    async changeVariant(variant: Variant): Promise<void> {
        this.data.additionalFieldData = {};
        this.data.entity = {};
        this.settings = {};

        // skip refresh (withoutRefresh true), to prevent SmartReportTable from firing multiple requests
        // caused by the fact that settings is changed here and initialized
        // AFTER ModelEvent.VariantChanged is handled in ReportView
        // => we want the SmartReportTable to re-render after it has been already re-initialized, not before
        await super.changeVariant(variant, true);
        this.emitter.emit(ModelEvent.VariantChanged);
    }

    async loadVariants(variantType: VariantTypeCode, bindingContext: BindingContext): Promise<IVariants> {
        const variants = await super.loadVariants(variantType, bindingContext);

        // filter out system variants that are not in the current definition
        // e.g. in DocumentJournal entry, we want to hide some system variants when the current company is not tax payer (vat registered)
        if (this.data.definition.defaultReportVariants) {
            for (const key of Object.keys(variants.allVariants)) {
                if (variants.allVariants[key].accessType === VariantAccessTypeCode.SystemVariant && !this.data.definition.defaultReportVariants[key]) {
                    delete variants.allVariants[key];
                }
            }

            // if the original current/default variant is now not present in allVariants, change it to the default one
            if (variants.currentVariant && !variants.allVariants[variants.currentVariant.id]) {
                variants.currentVariant = getDefaultSystemVariant(Object.values(variants.allVariants));
            }

            if (variants.defaultVariant && !variants.allVariants[variants.defaultVariant.id]) {
                variants.defaultVariant = getDefaultSystemVariant(Object.values(variants.allVariants));
            }
        }

        return variants;
    }
}