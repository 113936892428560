import { updateRowsArray } from "@components/smart/smartTable/SmartTable.utils";
import { IRow, TId } from "@components/table";
import {
    AccountEntity,
    EntitySetName,
    IAccountEntity,
    IChartOfAccountsEntity,
    IMandatoryChartOfAccountsEntity
} from "@odata/GeneratedEntityTypes";
import { AccountCategoryCode, AccountTypeCode, TaxApplicabilityCode } from "@odata/GeneratedEnums";
import { encodeOdataObject, OData } from "@odata/OData";
import { parseResponse } from "@odata/ODataParser";
import { logger } from "@utils/log";

import { ACCOUNT_USAGE_ACROSS_CHARTS_API_URL, ACCOUNT_USAGE_API_URL, REST_API_URL } from "../../constants";
import { RowAction } from "../../enums";
import { TRecordAny } from "../../global.types";
import { Model } from "../../model/Model";
import BindingContext, { IEntity, TEntityKey } from "../../odata/BindingContext";
import customFetch from "../../utils/customFetch";
import { FormStorage, IFormStorageDefaultCustomData } from "../../views/formView/FormStorage";

export const CATEGORY_SELECT_PATH = BindingContext.localContext("Category");
export const IS_INVERTIBLE_PATH = BindingContext.localContext("IsInvertible");

export enum AccountPeriod {
    Actual = "actual",
    Future = "future",
    Past = "past"
}

export enum CategoryItems {
    BalanceAssets = "BS-A",
    BalanceLiability = "BS-L",
    BalanceLiabilityAssets = "BS-AL",
    IncomeStatementActive = "IS-I",
    IncomeStatementActiveTaxable = "IS-I-T",
    IncomeStatementExpense = "IS-E",
    IncomeStatementExpenseTaxable = "IS-E-T",
    FinancialStatement = "FS"
}

export enum CategoryType {
    Balance = "BS",
    Statement = "IS",
    Result = "FS"
}

export interface IChartOfAccountsFormCustomData extends IFormStorageDefaultCustomData {
    chartOfAccounts?: IChartOfAccountsEntity[];
    journalData?: IAccountUsage;
    isParentInvertible?: boolean;
    descendantsHaveJournalEntries?: boolean;
    isPosted?: boolean;
    isMandatory?: boolean;
}

function isOfCategory(storage: FormStorage<IAccountEntity>, category: CategoryType): boolean {
    const val = storage.getValueByPath(AccountEntity.Category, { useDirectValue: false });
    return category === val;
}

export function isStatement(storage: FormStorage<IAccountEntity>): boolean {
    return isOfCategory(storage, CategoryType.Statement);
}

export function isBalance(storage: FormStorage<IAccountEntity>): boolean {
    return isOfCategory(storage, CategoryType.Balance);
}

const fetchClonerRequest = async (urlSuffix: string, name: string, parameters: IEntity = {}): Promise<any> => {
    const method = "POST",
        headers = { "Content-Type": "application/json" },
        body = JSON.stringify({ Name: name, ...parameters });

    const response = await customFetch(`${REST_API_URL}/Cloner/${urlSuffix}`, { method, headers, body });
    const data = await parseResponse(response);
    if (response.ok) {
        return data;
    } else {
        // throws oDataError
        throw data;
    }
};


export const createChartOfAccountsFromTemplate = async (companyId: string, templateId: string, name: string, fiscalYearData: IEntity, odata: OData): Promise<IEntity> => {
    const encodedData = encodeOdataObject(fiscalYearData, odata.getMetadata(), "FiscalYears");

    // clone charts of accounts for the newly created company
    return fetchClonerRequest(`CreateChartOfAccountsFromTemplate/${templateId}`, name, { FiscalYear: encodedData });
};

export const createChartOfAccountsFromChartOfAccounts = async (chartOfAccountsId: string, name: string, fiscalYearData: IEntity, odata: OData): Promise<IEntity> => {
    const encodedData = encodeOdataObject(fiscalYearData, odata.getMetadata(), "FiscalYears");

    // clone charts of accounts for the newly created company
    return fetchClonerRequest(`CreateChartFromChartOfAccounts/${chartOfAccountsId}`, name, { FiscalYear: encodedData });
};

export const cloneChartOfAccountsTemplate = async (templateId: string, name: string, CompanyId?: number): Promise<IEntity> => {
    return fetchClonerRequest(`CloneChartOfAccountsTemplate/${templateId}`, name, { CompanyId });
};

export const createTemplateFromChartOfAccounts = async (chartOfAccountsId: string, name: string): Promise<IEntity> => {
    return fetchClonerRequest(`CreateTemplateFromChartOfAccounts/${chartOfAccountsId}`, name);
};

export const rewriteChartOfAccountsWithTemplate = async (chartOfAccountId: string, name: string, templateId: string): Promise<any> => {
    return fetchClonerRequest(`RewriteChartOfAccountsWithTemplate/${chartOfAccountId}/${templateId}`, name);
};

export const createMinimalChartOfAccountsTemplate = async (accountingCode: string, name: string, CompanyId?: number): Promise<any> => {
    return fetchClonerRequest(`CreateMinimalChartOfAccountsTemplate/${accountingCode}`, name, { CompanyId });
};

export const getMandatoryAccounts = async (storage: Model): Promise<IAccountEntity[]> => {
    const mandatoryAccounts = await storage.oData.getEntitySetWrapper(EntitySetName.MandatoryChartsOfAccounts).query().expand("Accounts").fetchData<IMandatoryChartOfAccountsEntity[]>();

    return mandatoryAccounts?.value[0]?.Accounts;
};

export const isMandatoryAccount = (storage: Model, account: IEntity): boolean => {
    const accounts = storage.context.getData().custom.mandatoryAccounts;
    const number = account.Number;

    return !!accounts?.find((acc: TRecordAny) => acc.Number === number);
};

export const accountsIsRowWithoutAction = (rowId: TId, action: RowAction, row: IRow, unRemovableAccounts: number[], storage: Model): boolean => {
    return !!(action === RowAction.Remove
        && (
            unRemovableAccounts?.find(accountId => accountId === (rowId as BindingContext).getKey())
            || isMandatoryAccount(storage, row.values)
        ));
};

export const accountsRowsFactory = (rows: IRow[], storage: Model, unRemovableAccounts: number[], tableAction: RowAction): IRow[] => {
    if (tableAction !== RowAction.Remove) {
        return rows;
    }

    return updateRowsArray(rows, (row: IRow) => {
        if (!accountsIsRowWithoutAction(row.id, RowAction.Remove, row, unRemovableAccounts, storage)) {
            return row;
        }

        const isMandatory = isMandatoryAccount(storage, row.values);
        const tooltip = storage.t(`ChartsOfAccounts:Table.${isMandatory ? "CannotRemoveMandatoryAccount" : "CannotRemoveAccountWithJournalEntry"}`);

        return {
            ...row,
            isDisabled: true,
            tooltip: tooltip
        };
    });
};

export const getMandatoryAccountsPromise = (storage: Model): Promise<IAccountEntity[]> => {
    if (!storage.context.getData().custom.mandatoryAccounts) {
        const promise = getMandatoryAccounts(storage);
        promise.then((mandatoryAccounts) => {
            storage.setCustomData({ mandatoryAccounts });
            storage.context.setCustomData({
                mandatoryAccounts: mandatoryAccounts
            });
        });

        return promise;
    }

    return null;
};

// todo remove if BE can return correct data for every row in validatedependententitiesdelete
export const loadUnRemovableAccounts = async (storage: Model, parentEntityId: TEntityKey): Promise<number[]> => {
    try {
        const promises: Promise<any>[] = [];

        promises.push(getMandatoryAccountsPromise(storage));

        // todo: returns 404 when called from COATemplates - is it applicable for templates? parentEntityId seems
        //  to be a template here, do we maybe need different API for template Id?
        promises.push(
            customFetch(`${REST_API_URL}/Accounting/GetChartOfAccountsUsage/${parentEntityId}`)
        );

        const results = await Promise.all(promises);
        const journalEntriesInfo = await (results.length === 2 ? results[1] : results[0]).json();

        return (Array.isArray(journalEntriesInfo) ? journalEntriesInfo : [])
            .filter((journalEntryInfo: any) => journalEntryInfo.HasJournalEntries || journalEntryInfo.DescendantsHaveJournalEntries || journalEntryInfo.HasJournalEntriesInDifferentFiscalYear)
            .map((journalEntryInfo: any) => journalEntryInfo.AccountId);
    } catch (e) {
        logger.error("ChartOfAccountsUsage request failed", e);
        return [];
    }
};

export interface IAccountUsage {
    AccountId: number;
    HasJournalEntries: boolean;
    DescendantsHaveJournalEntries: boolean;
    HasJournalEntriesInDifferentFiscalYear: boolean;
    HasCompanyBankAccount: boolean;
    HasCashBox: boolean;
    AccountNumber: number;
    CurrentCurrencyCode: string;
    AccountedEntityCurrencyCode: string;
    InitialBalanceCurrencyCode: string;
    AvailableAccountedEntityCurrencyCodes: string[];
}

export async function getAccountUsageAcrossCharts(accountNumber: string): Promise<IAccountUsage> {
    const url = `${ACCOUNT_USAGE_ACROSS_CHARTS_API_URL}/${accountNumber}`;
    const response = await customFetch(url);

    if (response.ok) {
        return await response.json();
    }
    return null;
}

export async function getAccountUsage(CoAId: TEntityKey, AccountId: TEntityKey): Promise<IAccountUsage> {
    const url = `${ACCOUNT_USAGE_API_URL}/${CoAId}/${AccountId}`;
    const response = await customFetch(url);

    if (response.ok) {
        return await response.json();
    }
    return null;
}

export const isAccountUsedCheck = (usage: IAccountUsage): boolean => {
    return usage?.HasJournalEntries || usage?.HasJournalEntriesInDifferentFiscalYear || usage?.DescendantsHaveJournalEntries;
};

export async function getIsAccountUsed(accountNumber: string): Promise<boolean> {
    try {
        const usage = await getAccountUsageAcrossCharts(accountNumber);
        return isAccountUsedCheck(usage);
    } catch (e) {
        // fall silently
    }

    // in case of error, we assume account is not used, user will
    // be allowed to modify the fields and BE will handle the constraint
    return false;
}

export const composeCategory = (entity: IAccountEntity): CategoryItems => {
    const category = entity.Category?.Code;
    const type = entity.Type?.Code;
    const isTaxable = entity.TaxApplicabilityCode === TaxApplicabilityCode.TaxApplicable;
    const isInvertible = entity.IsInvertible;

    if (!category) {
        return null;
    }

    if (category === AccountCategoryCode.FinancialStatement) {
        return CategoryItems.FinancialStatement;
    }

    let categoryId = `${category}-${type}${isTaxable ? "-T" : ""}`;

    if (isInvertible) {
        categoryId = CategoryItems.BalanceLiabilityAssets;
    }

    return categoryId as CategoryItems;
};

export const decomposeCategory = (categoryId: CategoryItems) => {
    const [category, type, isTaxApplicable] = categoryId.split("-");

    return {
        categoryCode: category as AccountCategoryCode,
        typeCode: type as AccountTypeCode,
        isTaxApplicable: isTaxApplicable === "T"
    };
};
