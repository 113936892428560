import styled from "styled-components/macro";

import { T_PLAIN_small, T_TITLE_2, T_TITLE_3_normal } from "../../../global.style";
import welcome from "../../../static/images/welcome.svg"; // origName: ostatní/PODVOJ+dashboard/Vítejte+v+Evale/Ilustracia Vítejte.svg
import { getDashboardBoxShadow } from "../../dashboard/Dashboard.styles";
import DashboardTileBackground from "../../dashboard/DashboardTileBackground";

const WelcomeTileTopPadding = 44;
export const WelcomeAvatarSize = 82;
const AvatarBorderWidth = 3;

export const StyledWelcomeTile = styled(DashboardTileBackground)`
    position: relative;
    margin-top: ${WelcomeTileTopPadding}px;
    height: calc(100% - ${WelcomeTileTopPadding}px);
    background: ${props => props.theme.C_BG_menu} url(${welcome}) bottom left no-repeat;
    background-size: auto 100%;

    align-items: center;
    justify-content: flex-start;
`;

export const WelcomeAvatar = styled.span`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${WelcomeAvatarSize}px;
    height: ${WelcomeAvatarSize}px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${T_TITLE_2};

    &, &::before, &::after {
        border-radius: 100%;
    }

    &::before, &::after {
        display: block;
        content: " ";
        position: absolute;
        z-index: -1;
        background: ${props => props.theme.C_BG_menu};
    }

    &::after {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &::before {
        top: -${AvatarBorderWidth}px;
        left: -${AvatarBorderWidth}px;
        bottom: -${AvatarBorderWidth}px;
        right: -${AvatarBorderWidth}px;
        background: ${props => props.theme.C_MAIN_brand_gradient};
        ${props => getDashboardBoxShadow(props)};
    }
`;

export const Heading = styled.h3`
    ${T_TITLE_2};
    margin: 44px auto 20px;
`;

export const UserName = styled.span`
    ${T_TITLE_3_normal};
    margin-bottom: 4px;
`;

export const Role = styled.span`
    ${T_PLAIN_small};
    opacity: 0.5;
`;
