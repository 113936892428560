import {
    CompanyPermissionEntity,
    EntitySetName,
    ICompanyPermissionEntity,
    ICompanyRolePermissionEntity
} from "@odata/GeneratedEntityTypes";
import { FeatureCode } from "@odata/GeneratedEnums";
import React, { PureComponent } from "react";

import BindingContext from "../../../odata/BindingContext";
import TestIds from "../../../testIds";
import memoizeOne from "../../../utils/memoizeOne";
import { FormStorage } from "../../../views/formView/FormStorage";
import { PermissionsGrid } from "../generalRoles/GeneralRoles.styles";
import { PermissionSwitch } from "../generalRoles/PermissionsTable";

interface IProps {
    storage: FormStorage;
    roleId: number;
}

class CompanyPermissionsTable extends PureComponent<IProps> {
    componentDidMount = async (): Promise<void> => {
        const storage = this.props.storage as FormStorage;

        if (storage.loaded && storage.data.bindingContext.isNew() && !this.props.storage.data.entity.CompanyRolePermissions) {
            await this.init();
        }
    };

    // componentDidMount can be called during storage.init call
    // and CompanyRolePermissions would get deleted from the storage later
    // => we need to wait for the storage to be loaded
    componentDidUpdate = async (): Promise<void> => {
        const storage = this.props.storage as FormStorage;

        if (storage.loaded && storage.data.bindingContext.isNew() && !this.props.storage.data.entity.CompanyRolePermissions) {
            await this.init();
        }
    };

    init = async (): Promise<void> => {
        const permissions = await this.fetchPermissions();
        // const response = await customFetch(COMPANY_ROLE_ALLOWED_PERMISSION_URL);
        // const permissions = (await response.json()) as TRecordType<ICompanyPermissionEntity[]>;
        // const userPermissions = permissions["Code = User, Name = User role"];

        this.props.storage.data.entity.CompanyRolePermissions = permissions?.map((perm: ICompanyPermissionEntity) => ({
            Id: BindingContext.NEW_ENTITY_ID_PROP,
            CompanyPermissionCode: perm.Code,
            CompanyPermission: perm
        }));
        this.forceUpdate();
    };

    fetchPermissions = memoizeOne(async (): Promise<ICompanyPermissionEntity[]> => {
        const result = await this.props.storage.oData.getEntitySetWrapper(EntitySetName.CompanyPermissions)
            .query()
                .select(CompanyPermissionEntity.Code, CompanyPermissionEntity.Name, CompanyPermissionEntity.Description, CompanyPermissionEntity.FeatureCode)
            .fetchData<ICompanyPermissionEntity[]>();

        return result.value;
    });

    handleChange = (code: string, checked: boolean): void => {
        this.props.storage.handleFirstChange();

        const permissions = this.props.storage.data.entity.CompanyRolePermissions;
        const permission = permissions.find((role: ICompanyRolePermissionEntity) => role.CompanyPermission.Code === code);
        permission.IsEnabled = checked;
        if (permission.OwnOnly === true && checked === false) {
            permission.OwnOnly = false;
        }
        this.forceUpdate();
    };

    onOwnOnlyChange = (code: string, ownOnly: boolean): void => {
        this.props.storage.handleFirstChange();

        const permissions = this.props.storage.data.entity.CompanyRolePermissions;
        const permission = permissions.find((role: ICompanyRolePermissionEntity) => role.CompanyPermission.Code === code);
        permission.OwnOnly = ownOnly;
        this.forceUpdate();
    };

    render() {
        const permissions = this.props.storage.data.entity.CompanyRolePermissions?.filter((p: ICompanyRolePermissionEntity) => {
            const featureCode = p.CompanyPermission.FeatureCode as FeatureCode;
            return !featureCode || this.props.storage.context.isFeatureSwitchEnabled(featureCode);
        }) ?? [];

        return <PermissionsGrid data-testid={TestIds.PermissionsGrid}>
            {permissions.map((role: ICompanyRolePermissionEntity) => {
                return <PermissionSwitch
                    key={role.CompanyPermission.Code}
                    permission={role.CompanyPermission}
                    checked={role.IsEnabled}
                    ownOnly={role.OwnOnly}
                    storage={this.props.storage}
                    onOwnOnlyChange={this.onOwnOnlyChange}
                    isReadOnly={this.props.storage.isReadOnly}
                    onChange={this.handleChange}/>;
            })}
        </PermissionsGrid>;
    }
}

export default CompanyPermissionsTable;