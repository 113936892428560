import { darken, ellipsis, setLightness } from "polished";
import styled, { css } from "styled-components/macro";

import { getDisabledStyle, getFocusBorderElement, LinkStyles } from "../../global.style";

interface IClickableText {
    $hasLinkStyle: boolean;
    $disabled?: boolean;
    $isWithoutStyle?: boolean;
    $useEllipsis?: boolean;
    $renderAsFlex?: boolean;
}

export const ClickableText = styled.a.attrs(props => ({
    tabIndex: props.tabIndex ?? 0
}))<IClickableText>`
    position: relative;
    cursor: pointer;
    text-decoration: none;
    width: fit-content;

    ${props => props.$renderAsFlex && css`
        display: flex;
    `};

    ${props => props.$useEllipsis && css`
        ${ellipsis()};
    `};

    ${props => !props.$isWithoutStyle && css<IClickableText>`
        outline: none;
        ${props.$disabled ? getDisabledStyle(true) : props.$hasLinkStyle ? LinkStyles : css`
        color: ${props.theme.C_BG_filterbar};

        &:hover {
            color: ${props => setLightness(0.7, props.theme.C_ICON_primary_dark)};
        }

        &:active {
            color: ${props => darken(0.1, props.theme.C_BG_filterbar)};
        }

        ${getFocusBorderElement({ offsetX: 2, offsetY: 0, borderRadius: "3px", keyboardOnly: true })};
    `};
    `}
`;
