import { ISelectionChangeArgs, ISelectItem, SelectGroups } from "@components/inputs/select/Select.types";
import { VariantAccessTypeCode, VariantTypeCode } from "@odata/GeneratedEnums";
import { compareString } from "@utils/string";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { IconSize } from "../../enums";
import { ModelType } from "../../model/Model";
import { StorageModel } from "../../model/StorageModel";
import TestIds from "../../testIds";
import { ConfirmationDialog } from "../dialog/ConfirmationDialog";
import { getDrillDownVariant } from "../drillDown/DrillDown.utils";
import { HOTSPOT_ID_ATTR } from "../hotspots/Hotspots.utils";
import { CaretIcon } from "../icon";
import VariantDialog, { VariantDialogType } from "./VariantDialog";
import { IVariantContext, IVariants, loadVariants, Variant } from "./VariantOdata";
import { IconSelectStyled, Label, StyledVariantSelector } from "./VariantSelector.styles";

const SortedAccessTypes = [
    VariantAccessTypeCode.SystemVariant,
    VariantAccessTypeCode.GlobalVariant,
    VariantAccessTypeCode.UserVariant
];

interface IProps {
    className?: string;
    storage: StorageModel;
    isDisabled?: boolean;
    hotspotId?: string;
}

export enum VariantDialogState {
    Edit = "edit",
    Clone = "clone",
    Warning = "warning",
    Closed = "closed"
}

interface IState {
    config?: IVariants;
    variantDialogState: VariantDialogState;
}

class VariantSelector extends React.Component<IProps & WithTranslation, IState> {
    _variantToChange: Variant;

    state: IState = {
        variantDialogState: VariantDialogState.Closed
    };

    createSelectItemsFromStoredVariants = (variants: Record<string, Variant>): ISelectItem[] => {
        return Object.values(variants || {})
            .sort((v1, v2) => {
                if (v1.accessType !== v2.accessType) {
                    return SortedAccessTypes.indexOf(v1.accessType) - SortedAccessTypes.indexOf(v2.accessType);
                }
                return compareString(v1.name, v2.name);
            }).map(variant => ({
                id: variant.id,
                label: variant.name,
                description: this.props.storage.data.variants?.defaultVariant?.id === variant.id ? `(${this.props.t("Components:VariantSelector.Default").toLowerCase()})` : null
            }));
    };

    isVariantDialogState(dialogState: unknown): dialogState is VariantDialogState {
        return [VariantDialogState.Clone, VariantDialogState.Edit].includes(dialogState as VariantDialogState);
    }

    handleVariantChange = async (e: ISelectionChangeArgs) => {
        if (this.isVariantDialogState(e.value)) {
            let config: IVariants = null;
            if (e.value === VariantDialogState.Edit) {
                // preload all variants for all combinations (accounting, vat status)
                const { oData, data: { bindingContext }, id, type } = this.props.storage;
                const opts: IVariantContext = {
                    viewId: id,
                    variantType: type === ModelType.Form ? VariantTypeCode.FormVariant : VariantTypeCode.TableVariant
                };
                config = await loadVariants(oData, opts, bindingContext);
            }
            this.setState({
                config,
                variantDialogState: e.value
            });
        } else {
            if (e.triggerAdditionalTasks) {
                const isLocalVariant = this.props.storage.getLocalStorageVariant();
                if (isLocalVariant) {
                    // In case the current variant is stored only in localstorage using customization, we ask user
                    // if he would like to override it by selecting stored variant in DB
                    this._variantToChange = this.props.storage.data.variants.allVariants[e.value as string];
                    this.setState({
                        variantDialogState: VariantDialogState.Warning
                    });
                } else {
                    const variant = this.props.storage.data.variants.allVariants[e.value as string];
                    await this.props.storage.changeVariant(variant);
                    this.forceUpdate();
                }
            }
        }
    };

    closeDialog = () => {
        this.setState({
            variantDialogState: VariantDialogState.Closed
        });
    };

    handleConfirmationDialogConfirm = async () => {
        await this.props.storage.changeVariant(this._variantToChange);
        this.handleConfirmationDialogClose();
    };

    handleConfirmationDialogClose = () => {
        this._variantToChange = null;
        this.setState({
            variantDialogState: VariantDialogState.Closed
        });
    };

    render() {
        if (!this.props.storage) {
            return null;
        }

        const drilldownVariant = getDrillDownVariant();
        const isCustom = !drilldownVariant && !!this.props.storage?.getLocalStorageVariant();
        const text = isCustom ? `(${this.props.t("Components:VariantSelector.Custom")})` :
                this.props.storage?.data.variants.allVariants[this.props.storage.data.variants.currentVariant?.id]?.name;
        return (
                <>
                    {this.state.variantDialogState === VariantDialogState.Warning &&
                            <ConfirmationDialog onConfirm={this.handleConfirmationDialogConfirm}
                                                onClose={this.handleConfirmationDialogClose}>
                                {this.props.t("Components:VariantSelector.ChangedUnsavedVariant")}
                                <br/><br/>
                                {this.props.t("Common:General.ContinueAnyway")}
                    </ConfirmationDialog>
                }
                <StyledVariantSelector
                    {...{ [HOTSPOT_ID_ATTR]: this.props.hotspotId }}
                    data-testid={TestIds.VariantSelector}
                    className={this.props.className}>
                    <Label _isCustom={isCustom} data-testid={TestIds.VariantLabel} _isDisabled={this.props.isDisabled}>
                        {text}
                    </Label>
                    <IconSelectStyled
                        hotspotId={"VariantSelectorMenu"}
                        items={this.createSelectItemsFromStoredVariants(this.props.storage?.data.variants?.allVariants)}
                        additionalItems={[{
                            id: VariantDialogState.Clone,
                            label: this.props.t("Components:VariantSelector.Clone"),
                            iconName: "Add",
                            groupId: SelectGroups.Action
                        }, {
                            id: VariantDialogState.Edit,
                            label: this.props.t("Components:VariantSelector.Edit"),
                            iconName: "Edit",
                            groupId: SelectGroups.Action
                        }]}
                        value={isCustom ? undefined : this.props.storage?.data.variants.currentVariant?.id}
                        icon={<CaretIcon width={IconSize.M}/>}
                        onChange={this.handleVariantChange}
                        isDisabled={this.props.isDisabled}
                        useAutoSelection={false}/>
                </StyledVariantSelector>
                {this.isVariantDialogState(this.state.variantDialogState) &&
                    <VariantDialog
                        onClose={this.closeDialog}
                        storage={this.props.storage}
                        variants={this.state.config?.allVariants ?? this.props.storage.data.variants?.allVariants}
                        activeVariantId={this.props.storage.data.variants?.currentVariant?.id}
                        defaultVariants={this.state.config?.defaultVariants}
                        type={this.state.variantDialogState as unknown as VariantDialogType}/>
                }
            </>
        );
    }
}

export default withTranslation(["Components", "Common"])(VariantSelector);