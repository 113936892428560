import styled from "styled-components/macro";

import { T_TITLE_1, T_TITLE_3 } from "../../../global.style";
import purchaseTileMountains from "../../../static/images/purchaseTileMountains.svg"; // origName: ostatní/PODVOJ+dashboard/Koupit+Evalu/SVG/tile Koupit Evalu.svg

const BorderRadius = 30;
const BorderWidth = 3;

export const StyledPurchaseTile = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.C_ACT_hover_without_opacity} url(${purchaseTileMountains}) bottom left no-repeat;
    background-size: auto 330px;
    background-position: -25px -30px;
    width: calc(100% - ${2 * BorderWidth}px);
    height: calc(100% - ${2 * BorderWidth}px);
    border-radius: ${BorderRadius}px;
    cursor: pointer;

    &::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: -${BorderWidth}px;
        left: -${BorderWidth}px;
        bottom: -${BorderWidth}px;
        right: -${BorderWidth}px;
        /* Adjust border-radius for the pseudo-element */
        border-radius: calc(${BorderRadius}px + ${BorderWidth}px);
        background: ${props => props.theme.C_MAIN_brand_gradient};
    }

    gap: 14px;
`;

export const DaysLeftText = styled.div`
    white-space: pre-wrap;
    text-align: center;
    ${T_TITLE_3};
`;

export const BuyEvalaTitle = styled.h3`
    ${T_TITLE_1};
    margin: 0;
    position: relative;
`;