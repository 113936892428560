import { formatDateToDateString } from "@components/inputs/date/utils";
import { isODataError } from "@odata/Data.types";
import {
    DocumentAccountAssignmentEntity,
    DocumentAccountAssignmentSelectionEntity,
    DocumentEntity,
    ElectronicSubmissionEntity,
    EntitySetName,
    EntityTypeName,
    IDocumentEntity,
    IElectronicSubmissionEntity,
    IElectronicSubmissionsLockParameters,
    InternalDocumentEntity,
    OdataActionName,
    ODataActionPath
} from "@odata/GeneratedEntityTypes";
import { OData, ODataQueryBuilder } from "@odata/OData";
import { parseResponse } from "@odata/ODataParser";
import memoizeOne from "@utils/memoizeOne";
import dayjs, { Dayjs } from "dayjs";
import { saveAs } from "file-saver";

import { REST_API_URL } from "../../constants";
import { IAppContext } from "../../contexts/appContext/AppContext.types";
import {
    ElectronicSubmissionSubmissionTypeCode,
    ElectronicSubmissionTypeCode,
    VatStatementFrequencyCode,
    VatStatusCode
} from "../../odata/GeneratedEnums";
import { getEnumNameSpaceName } from "../../odata/GeneratedEnums.utils";
import { getUtcDate, getUtcDateBy, getUtcDayjs } from "../../types/Date";
import { getCompanyVatStatusesSorted } from "../../utils/CompanyUtils";
import customFetch, { getDefaultPostParams } from "../../utils/customFetch";
import FileStorage from "../../utils/FileStorage";
import { createVatStatementPeriod, getDayjsUnit, VatStatementPeriod } from "../companies/Company.utils";
import { getMostCurrentFiscalYear, getOldestActiveFY, getSortedFYs } from "../fiscalYear/FiscalYear.utils";
import { IDateRangeParam } from "../reports/Report.utils";

const ELECTRONIC_SUBMISSION_REST_API = `${REST_API_URL}/ElectronicSubmissions`;

export const REGULAR_SUBMISSION_THRESHOLD_DATE = 25;

export enum ElectronicSubmissionDateRangeType {
    Regular = "Regular",
    Corrective = "Corrective"
}

export enum SubmissionStatus {
    Locked = "Locked",
    Unlocked = "Unlocked",
    // white background
    // - submission can exist at that time, but user is not supposed to be able to create it yet
    Empty = "Empty",
    // without background
    // - submission of that type can't exist at that time
    NonExisting = "NonExisting"
}

export interface ISubmissionInfo {
    status: SubmissionStatus;
    // key for ISubmissionOverviewData.submissions object to get the submission entity
    submissionId: string;
    frequency: VatStatementFrequencyCode;
    isSelected?: boolean;
    isCorrective?: boolean;
    // quarterly VatVies that will be split into monthly when unlocked
    isSplittable?: boolean;
}

// array instead of just one value, because there can be multiple (corrective) submissions for the same period
// the last one in the array is the current one
export type TSubmissionsMap = Map<string, IElectronicSubmissionEntity[]>;

export interface ISubmissionOverviewRow {
    month: number;
    [ElectronicSubmissionTypeCode.VatStatement]?: ISubmissionInfo;
    [ElectronicSubmissionTypeCode.VatControlStatement]?: ISubmissionInfo;
    [ElectronicSubmissionTypeCode.VatVIESStatement]?: ISubmissionInfo;
}

// export interface ISubmissionOverviewData {
//     rows: ISubmissionOverviewRow[];
//
// }

export const SubmissionTypeColumnOrder = {
    [ElectronicSubmissionTypeCode.VatStatement]: 1,
    [ElectronicSubmissionTypeCode.VatControlStatement]: 2,
    [ElectronicSubmissionTypeCode.VatVIESStatement]: 3

};

export const ElectronicSubmissionNamespaces = [
    "Common", "Audit", "ElectronicSubmission", "Document", getEnumNameSpaceName(EntityTypeName.VatStatementStatus), getEnumNameSpaceName(EntityTypeName.ElectronicSubmissionSubmissionType)
];

export function isSubmissionLocked(submission: IElectronicSubmissionEntity): boolean {
    // no IsLocked property anymore, any existing submission is considered locked
    return !!submission?.Id;
}

interface IGetSubmissionFileUrl {
    context: IAppContext;
    type: ElectronicSubmissionTypeCode;
    submission: IElectronicSubmissionEntity;
}

export function getSubmissionEpoFileUrl(args: IGetSubmissionFileUrl): string {
    const ident = args.submission?.Id;
    return `${REST_API_URL}/VatStatementEpo/${ident}?CompanyId=${args.context.getCompany().Id}`;
}

export async function downloadSubmissionXmlFile(args: IGetSubmissionFileUrl, fileName?: string): Promise<void> {
    const file = await FileStorage.get(args.submission.FileMetadata.Id, { fileName: fileName ? `${fileName}.xml` : null });

    if (file) {
        saveAs(file);
    }
}

export async function getLastSubmissionByType(oData: OData, type?: ElectronicSubmissionTypeCode): Promise<IElectronicSubmissionEntity> {
    try {
        const filters = [];

        if (type) {
            filters.push(`ElectronicSubmissionTypeCode eq '${ElectronicSubmissionTypeCode.VatStatement}'`);
        }

        const query = oData.getEntitySetWrapper(EntitySetName.ElectronicSubmissions).query()
            .filter(filters.join(" AND "))
            .orderBy("DatePeriodStart", false);

        const res = await query.fetchData<IElectronicSubmissionEntity[]>();

        return res?.value?.[0];
    } catch (e) {
        return null;
    }
}

interface IGetElectronicSubmissionArgs {
    oData: OData;
    year?: number;
    type?: ElectronicSubmissionTypeCode;
}

export async function getElectronicSubmissions({
                                                   oData,
                                                   year,
                                                   type
                                               }: IGetElectronicSubmissionArgs): Promise<IElectronicSubmissionEntity[]> {
    let query = oData.getEntitySetWrapper(EntitySetName.ElectronicSubmissions)
        .query()
        .expand(ElectronicSubmissionEntity.FileMetadata)
        // we can have multiple (amended) submissions for the same period,
        // => order them by submission date so that the last one is used in the submissions map as the current one
        .orderBy(ElectronicSubmissionEntity.DateSubmission);

    query = expandElectronicSubmissionEntityForVat(query);

    let filter = "";

    if (year) {
        filter = `${ElectronicSubmissionEntity.DatePeriodStart} qe '${year}-01-01' AND ${ElectronicSubmissionEntity.DatePeriodStart} le '${year}-12-31'`;
    }

    if (type) {
        if (filter) {
            filter += " AND ";
        }

        filter += `${ElectronicSubmissionEntity.ElectronicSubmissionTypeCode} eq '${type}'`;
    }

    if (filter) {
        query.filter(filter);
    }

    query.orderBy(ElectronicSubmissionEntity.DatePeriodStart);

    const res = await query.fetchData<IElectronicSubmissionEntity[]>();

    return res?.value;
}

export interface IPossibleSubmissionRange {
    DateStart: Date;
    DateEnd: Date;
    Type: ElectronicSubmissionDateRangeType[];
    CanBeLocked: boolean;
}

export type TPossibleSubmissions = Partial<Record<ElectronicSubmissionTypeCode, IPossibleSubmissionRange[]>>;

export const fetchPossibleSubmissions = async (year: number): Promise<TPossibleSubmissions> => {
    const url = `${ELECTRONIC_SUBMISSION_REST_API}/GetUnlockedSubmissions/${year}`;
    const res = await customFetch(url);
    const possibleSubmissions: TPossibleSubmissions = {};

    if (res.ok) {
        const value = await res.json() as TPossibleSubmissions;
        // parse dates for every possible submission
        for (const key of Object.keys(value)) {
            possibleSubmissions[key as ElectronicSubmissionTypeCode] = value[key as ElectronicSubmissionTypeCode]
                .map(s => ({
                    ...s,
                    DateStart: getUtcDate(s.DateStart),
                    DateEnd: getUtcDate(s.DateEnd)
                }));
        }
    }

    return possibleSubmissions;
};

interface IFetchSubmissionData {
    context: IAppContext;
    oData: OData;
    year?: number;
}

export const fetchSubmissionData = async (args: IFetchSubmissionData): Promise<{
    firstVatPeriodDate: Dayjs;
    lastVatPeriodDate: Dayjs;
    submissions: TSubmissionsMap;
    submissionsRows: IElectronicSubmissionEntity[];
    possibleSubmissions: TPossibleSubmissions;
    year: number;
}> => {
    const rows = await getElectronicSubmissions({ oData: args.oData });
    let year = args.year;

    // if year not set, call twice, first without possibleSubmission, to get the year,
    // then with possibleSubmission to get the correct locked submissions
    if (!year) {
        const { lastVatPeriodDate } = getInitSubmissionsData({
            submissions: rows,
            context: args.context
        });

        year = lastVatPeriodDate.year();

        const fiscalYears = getSortedFYs(args.context);
        const fiscalYear = fiscalYears.find(fy => fy.DateStart.getFullYear() === year);

        if (!fiscalYear) {
            // if there is no fiscal year for the year, get closest one to today
            year = getMostCurrentFiscalYear(args.context)?.DateStart.getFullYear();
        }
    }

    const possibleSubmissions = await fetchPossibleSubmissions(year);

    const { firstVatPeriodDate, lastVatPeriodDate, submissions } = getInitSubmissionsData({
        submissions: rows,
        possibleSubmissions,
        context: args.context
    });

    return {
        firstVatPeriodDate,
        lastVatPeriodDate,
        submissions,
        submissionsRows: rows,
        possibleSubmissions,
        year
    };
};

export function getSubmissionIdBy(type: ElectronicSubmissionTypeCode, dateStart: Date): string {
    return `${type}-${dateStart.getFullYear()}-${dateStart.getMonth()}`;
}

export function getSubmissionId(entity: IElectronicSubmissionEntity): string {
    const dateStart = getUtcDate(entity.DatePeriodStart);

    return getSubmissionIdBy(entity.ElectronicSubmissionTypeCode as ElectronicSubmissionTypeCode, dateStart);
}

export function parseSubmissionId(submissionId: string): {
    type: ElectronicSubmissionTypeCode,
    year: number,
    month: number
} {
    const [type, year, month] = submissionId.split("-");

    return {
        type: type as ElectronicSubmissionTypeCode,
        year: parseInt(year),
        month: parseInt(month)
    };
}

export function getVatStatementFrequencyFromSubmission(submission: IElectronicSubmissionEntity): VatStatementFrequencyCode {
    // third parameter is true to get the decimal value
    // round up to ensure correct months diff
    const periodLength = getUtcDayjs(submission.DatePeriodEnd).add(1, "day").diff(submission.DatePeriodStart, "months", true);

    return Math.round(periodLength) === 3 ? VatStatementFrequencyCode.Quarterly : VatStatementFrequencyCode.Monthly;
}

export function getVatStatementFrequencyFromDateRange(dateRange: IDateRangeParam): VatStatementFrequencyCode {
    const periodLength = getUtcDayjs(dateRange.DateEnd).add(1, "day").diff(dateRange.DateStart, "months", true);

    return Math.round(periodLength) === 3 ? VatStatementFrequencyCode.Quarterly : VatStatementFrequencyCode.Monthly;
}

interface IGetVatStatementFrequencyForSubmissionTypeArgs {
    type: ElectronicSubmissionTypeCode;
    month: number;
    submission: IElectronicSubmissionEntity;
    possibleSubmissions: TPossibleSubmissions;
}

export function getVatStatementFrequencyForSubmissionType(args: IGetVatStatementFrequencyForSubmissionTypeArgs): VatStatementFrequencyCode {
    const { type, month, submission, possibleSubmissions } = args;

    if (submission) {
        return getVatStatementFrequencyFromSubmission(submission);
    }

    const possibleSubmission = getPossibleSubmission(possibleSubmissions, type, month);

    if (!possibleSubmission) {
        return null;
    }

    return possibleSubmission.DateEnd.getMonth() - possibleSubmission.DateStart.getMonth() === 0 ? VatStatementFrequencyCode.Monthly : VatStatementFrequencyCode.Quarterly;
}


function expandElectronicSubmissionEntityForVat(query: ODataQueryBuilder): ODataQueryBuilder {
    return query
        .expand(ElectronicSubmissionEntity.VatClearingDocument, (q) => q.select(InternalDocumentEntity.Id, InternalDocumentEntity.NumberOurs))
        .expand(ElectronicSubmissionEntity.VatStatementDocument, (q) => {
            q.select(DocumentEntity.Id, DocumentEntity.NumberOurs, DocumentEntity.Amount, DocumentEntity.CurrencyCode, DocumentEntity.DocumentTypeCode, DocumentEntity.DateCreated);
            q.expand(DocumentEntity.AccountAssignmentSelection, (qq) => {
                qq.expand(DocumentAccountAssignmentSelectionEntity.AccountAssignment, (qqq) => {
                    qqq.expand(DocumentAccountAssignmentEntity.ChartOfAccounts);
                    qqq.expand(DocumentAccountAssignmentEntity.CreditAccount);
                    qqq.expand(DocumentAccountAssignmentEntity.DebitAccount);
                });
            });
        });
}

export async function unlockSubmission(oData: OData, submission: IElectronicSubmissionEntity): Promise<IElectronicSubmissionEntity> {
    const wrapper = oData.getEntitySetWrapper(EntitySetName.ElectronicSubmissions);

    const action = ODataActionPath[OdataActionName.ElectronicSubmissionUnlock];
    const query = expandElectronicSubmissionEntityForVat(wrapper.query(submission.Id, action));

    const result = await query.fetchData<IElectronicSubmissionEntity>();
    const updatedSubmission = result.value;
    // if BE adds removing the submission entity, it won't return the updated entity most likely
    // -> return the original one with the updated IsLocked flag, so we know which entity was unlocked and
    // for which we can process the handlers
    return updatedSubmission?.Id ? updatedSubmission : { ...submission, Id: null };
}

export function getVatStatementPeriod(forDate: Date, frequency: VatStatementFrequencyCode): VatStatementPeriod {
    const unit = getDayjsUnit(frequency);
    const periodDate = getUtcDayjs(forDate);

    return createVatStatementPeriod(periodDate, unit);
}

interface ICreateLockedVatStatementSubmissionArgs {
    context: IAppContext;
    oData: OData;
    date: Date;
    vatSubmissionType: ElectronicSubmissionTypeCode;
    vatSubmissionSubmissionType: ElectronicSubmissionSubmissionTypeCode;
    freq: VatStatementFrequencyCode;
    dateCorrectionDiscovered?: Date;
    correctionReason?: string;
    referenceNumber?: string;
}

export async function createLockedVatStatementSubmission(args: ICreateLockedVatStatementSubmissionArgs): Promise<IElectronicSubmissionEntity> {
    const period = getVatStatementPeriod(args.date, args.freq);
    const data: IElectronicSubmissionsLockParameters = {
        DatePeriodStart: formatDateToDateString(period.from),
        DatePeriodEnd: formatDateToDateString(period.to),
        ElectronicSubmissionTypeCode: args.vatSubmissionType,
        ElectronicSubmissionSubmissionTypeCode: args.vatSubmissionSubmissionType,
        Company: { Id: args.context.getCompanyId() }
    };
    const wrapper = args.oData.getEntitySetWrapper(EntitySetName.ElectronicSubmissions);
    const query = expandElectronicSubmissionEntityForVat(wrapper.query(null, ODataActionPath[OdataActionName.ElectronicSubmissionsLock]));


    if (args.dateCorrectionDiscovered) {
        data.DateCorrectionDiscovered = formatDateToDateString(args.dateCorrectionDiscovered);
        data.CorrectionReason = args.correctionReason;
        data.ReferenceNumberOfNotice = args.referenceNumber;
    }

    const res = await query.fetchData<IElectronicSubmissionEntity>(null, null, data);
    return res.value;
}

export async function createVatSubmissionDocuments(submission: IElectronicSubmissionEntity, amount: number, dateAccountingTransaction?: Date, accountNumber?: string): Promise<IDocumentEntity[]> {
    const url = `${ELECTRONIC_SUBMISSION_REST_API}/CreateVatDocuments/${submission.Id}`;
    const params: {
        CustomVatLiability: number;
        DateAccountingTransaction?: string;
        AccountNumber?: string;
    } = {
        CustomVatLiability: amount
    };

    if (dateAccountingTransaction) {
        params.DateAccountingTransaction = formatDateToDateString(dateAccountingTransaction);
    }

    if (accountNumber) {
        params.AccountNumber = accountNumber;
    }

    const res = await customFetch(url, {
        ...getDefaultPostParams(),
        body: JSON.stringify(params)
    });
    const body = await parseResponse<IDocumentEntity[]>(res);
    if (isODataError(body)) {
        throw body;
    }
    return body;
}

export async function removeVatSubmissionDocuments(submission: IElectronicSubmissionEntity): Promise<Response> {
    const url = `${ELECTRONIC_SUBMISSION_REST_API}/RemoveVatDocuments/${submission.Id}`;
    const res = await customFetch(url, {
        ...getDefaultPostParams(),
        body: JSON.stringify({})
    });

    return res;
}


export const getNextPeriodStart = (prevPeriodEndDate: Date, context: IAppContext) => {
    const preferred = getUtcDayjs(prevPeriodEndDate).add(1, "day");
    const preferredDate = preferred.toDate();
    const FYs = getSortedFYs(context);
    const lastFY = FYs[FYs.length - 1];

    if (preferred.isAfter(lastFY.DateEnd)) {
        return null;
    }

    const vatStatuses = getCompanyVatStatusesSorted(context.getCompany());
    const nextVatRegisteredStatus = vatStatuses.find(status => status.DateValidTo > preferredDate && status.VatStatusCode === VatStatusCode.VATRegistered);

    return nextVatRegisteredStatus ? dayjs.max(preferred, getUtcDayjs(nextVatRegisteredStatus.DateValidFrom)).startOf("month") : null;
};

export const getClosestVisiblePeriod = (submission: IElectronicSubmissionEntity, context: IAppContext) => {
    const previousPeriodEndDate = getUtcDayjs(submission.DatePeriodStart).subtract(1, "day");
    // find next period according to previous date as it could be the submission itself or next start of VatRegistered period
    return getNextPeriodStart(previousPeriodEndDate.toDate(), context);
};


interface ICreateSubmissionDataFromSubmissionsArgs {
    submissions: IElectronicSubmissionEntity[];
    possibleSubmissions?: TPossibleSubmissions;
    context: IAppContext;
    // year?: number;
}

export const getInitSubmissionsData = (args: ICreateSubmissionDataFromSubmissionsArgs): {
    firstVatPeriodDate: Dayjs;
    lastVatPeriodDate: Dayjs;
    submissions: TSubmissionsMap;
} => {
    const { submissions, context } = args;
    const possibleSubmissions = args.possibleSubmissions ?? {
        [ElectronicSubmissionTypeCode.VatStatement]: [],
        [ElectronicSubmissionTypeCode.VatControlStatement]: [],
        [ElectronicSubmissionTypeCode.VatVIESStatement]: []
    };

    const submissionsMap = new Map<string, IElectronicSubmissionEntity[]>();

    let firstVatPeriodDate: Dayjs;
    // last vat period after the last locked submission,
    // locked submissions means all the different vat submission columns are locked in the period
    let lastVatPeriodDate: Dayjs;
    // arbitrary submission type that is locked, latest in time
    let lastLockedVatPeriod: IElectronicSubmissionEntity;
    // VatSubmission that is locked and all related VatControlStatement and VatVIESStatement are locked for the same period
    let lastLockedAllVatTypesPeriod: IElectronicSubmissionEntity;
    let firstUnlockedVatPeriod: IElectronicSubmissionEntity;

    submissions.forEach(submission => {
        if (!firstVatPeriodDate || firstVatPeriodDate.isAfter(submission.DatePeriodStart)) {
            firstVatPeriodDate = getUtcDayjs(submission.DatePeriodStart);
        }

        if (isSubmissionLocked(submission)) {
            if (!lastLockedVatPeriod || getUtcDayjs(lastLockedVatPeriod.DatePeriodStart).isBefore(submission.DatePeriodStart)) {
                lastLockedVatPeriod = submission;
            }
        } else {
            if (!firstUnlockedVatPeriod || getUtcDayjs(firstUnlockedVatPeriod.DatePeriodStart).isAfter(submission.DatePeriodStart)) {
                firstUnlockedVatPeriod = submission;
            }
        }

        // to determine if all types of VAT statements are locked,
        // so that we know whether we can move lastVatPeriodDate to the next period or not,
        // we need to check if all other VAT statements types related to the VatStatement are locked
        if (submission.ElectronicSubmissionTypeCode === ElectronicSubmissionTypeCode.VatStatement && isSubmissionLocked(submission)) {
            const periodStart = getUtcDayjs(submission.DatePeriodStart);
            const periodEnd = getUtcDayjs(submission.DatePeriodEnd);
            const fnGetRelatedSubmissions = (relatedType: ElectronicSubmissionTypeCode) => {
                return submissions.filter(s => s.ElectronicSubmissionTypeCode === relatedType
                    && periodStart.isSameOrBefore(s.DatePeriodStart) && periodEnd.isSameOrAfter(s.DatePeriodEnd))
                    .sort((a, b) => getUtcDayjs(a.DatePeriodStart).diff(getUtcDayjs(b.DatePeriodStart)));
            };
            const fnGetRelatedPossible = (relatedType: ElectronicSubmissionTypeCode) => {
                return possibleSubmissions[relatedType]?.filter(s => {
                    return s.Type.includes(ElectronicSubmissionDateRangeType.Regular) && periodStart.isSameOrBefore(s.DateStart) && periodEnd.isSameOrAfter(s.DateEnd);
                }) ?? [];
            };
            const relatedTypes = [ElectronicSubmissionTypeCode.VatControlStatement, ElectronicSubmissionTypeCode.VatVIESStatement];
            let allRelatedLocked = false;

            for (const type of relatedTypes) {
                const relatedSubmissions = fnGetRelatedSubmissions(type);

                if (relatedSubmissions.length > 0) {
                    // either all related submissions in that period are locked
                    // different types can have different frequency, validate not only that all of them are locked,
                    // but also that they cover the same period
                    if (relatedSubmissions.every(relatedVatControlStatements => isSubmissionLocked(relatedVatControlStatements))
                        && periodStart.isSame(relatedSubmissions[0].DatePeriodStart) && periodEnd.isSame(relatedSubmissions.slice(-1)[0].DatePeriodEnd)) {
                        allRelatedLocked = true;
                        break;
                    }
                } else {
                    // or there are no related possible submission at all for that period
                    if (relatedSubmissions.length === 0 && args.possibleSubmissions && fnGetRelatedPossible(type).length === 0) {
                        allRelatedLocked = true;
                        break;
                    }
                }
            }

            if (allRelatedLocked) {
                lastLockedAllVatTypesPeriod = submission;
            }
        }
        submissionsMap.set(getSubmissionId(submission), [...(submissionsMap.get(getSubmissionId(submission)) || []), submission]);
    });


    if (!firstVatPeriodDate) {
        const FY = getOldestActiveFY(context);
        const fyDateStart = getUtcDayjs(FY?.DateStart);
        const dateFromLastPeriod = fyDateStart.subtract(1, "day");

        // take first VAT period that takes places after oldest active FY
        firstVatPeriodDate = getNextPeriodStart(dateFromLastPeriod.toDate(), context) || fyDateStart;
    }

    if (firstUnlockedVatPeriod) {
        lastVatPeriodDate = getClosestVisiblePeriod(firstUnlockedVatPeriod, context);
    } else if (lastLockedVatPeriod) {
        if (lastLockedAllVatTypesPeriod && getUtcDayjs(lastLockedAllVatTypesPeriod.DatePeriodEnd).isSameOrAfter(lastLockedVatPeriod.DatePeriodEnd)) {
            lastVatPeriodDate = getNextPeriodStart(lastLockedVatPeriod.DatePeriodEnd, context);
        } else {
            lastVatPeriodDate = getUtcDayjs(lastLockedVatPeriod.DatePeriodStart);
        }

        // in case user has some locked periods, but is no longer VAT registered, there is no next period
        // => show last locked period...
        if (!lastVatPeriodDate) {
            lastVatPeriodDate = dayjs(lastLockedVatPeriod.DatePeriodStart);
        }
    } else {
        lastVatPeriodDate = firstVatPeriodDate;
    }

    return {
        firstVatPeriodDate,
        lastVatPeriodDate,
        submissions: submissionsMap
    };
};

export const getPossibleSubmission = (possibleSubmissions: TPossibleSubmissions, type: ElectronicSubmissionTypeCode, month: number): IPossibleSubmissionRange => {
    return possibleSubmissions[type]?.find(s => s.DateStart.getMonth() <= month && s.DateEnd.getMonth() >= month);
};

interface IGetSubmissionInfoArgs {
    type: ElectronicSubmissionTypeCode;
    submissions: TSubmissionsMap;
    possibleSubmissions: TPossibleSubmissions;
    date: Date;
    firstVatPeriodDate: Dayjs,
    lastVatPeriodDate: Dayjs
    previousInfoRows: ISubmissionOverviewRow[];
    context: IAppContext;
}

export const getSubmissionInfo = (args: IGetSubmissionInfoArgs): ISubmissionInfo => {
    const {
        type,
        date,
        submissions,
        possibleSubmissions,
        context,
        firstVatPeriodDate,
        lastVatPeriodDate,
        previousInfoRows
    } = args;

    const monthIndex = date.getMonth();
    const submissionId = getSubmissionIdBy(type, date);
    const submission = submissions.get(submissionId)?.slice(-1)[0];
    const frequency = getVatStatementFrequencyForSubmissionType({
        submission,
        possibleSubmissions,
        month: monthIndex,
        type
    });
    let status: SubmissionStatus;
    const possibleSubmission = getPossibleSubmission(possibleSubmissions, type, monthIndex);
    const isSplittable = type === ElectronicSubmissionTypeCode.VatVIESStatement && frequency === VatStatementFrequencyCode.Quarterly && possibleSubmission && getVatStatementFrequencyFromDateRange({
        DateStart: possibleSubmission?.DateStart,
        DateEnd: possibleSubmission?.DateEnd
    }) === VatStatementFrequencyCode.Monthly;
    const isCorrective = !!possibleSubmission?.Type?.includes(ElectronicSubmissionDateRangeType.Corrective);


    if (frequency === VatStatementFrequencyCode.Quarterly && monthIndex % 3 !== 0) {
        status = SubmissionStatus.NonExisting;
    } else {
        if (submission) {
            status = isSubmissionLocked(submission) ? SubmissionStatus.Locked : SubmissionStatus.Unlocked;
        } else {
            if (!possibleSubmission) {
                status = SubmissionStatus.NonExisting;
            } else {
                let isPossibleSubmissionPartOfQuarterlySubmission = false;

                // backend returns possible corrective submission with Monthly frequency,
                // even if it is part of existing quarterly submission
                // => in that case, we need to set the existing submission as corrective,
                // and return current info as NonExisting to prevent breaking Quarterly grid into monthly
                if (frequency === VatStatementFrequencyCode.Monthly) {
                    for (let i = monthIndex - (monthIndex % 3); i < monthIndex; i++) {
                        const previousSubmission = previousInfoRows[i][type];

                        if (previousSubmission.frequency === VatStatementFrequencyCode.Quarterly) {
                            isPossibleSubmissionPartOfQuarterlySubmission = true;
                            if (args.type === ElectronicSubmissionTypeCode.VatVIESStatement) {
                                previousSubmission.isSplittable = true;
                            } else {
                                previousSubmission.isCorrective = true;
                            }
                            status = SubmissionStatus.NonExisting;
                        }
                    }
                }

                if (!isPossibleSubmissionPartOfQuarterlySubmission) {
                    status = possibleSubmission.CanBeLocked ? SubmissionStatus.Unlocked : SubmissionStatus.Empty;
                }
            }
        }
    }

    return {
        submissionId,
        frequency,
        status,
        isCorrective,
        isSplittable
    };
};

export interface IGetSubmissionInfoRowsForYearArgs {
    submissions: TSubmissionsMap;
    possibleSubmissions: TPossibleSubmissions;
    context: IAppContext;
    year: number;
    firstVatPeriodDate: Dayjs;
    lastVatPeriodDate: Dayjs;
}

export const getSubmissionInfoRowsForYear = memoizeOne((args: IGetSubmissionInfoRowsForYearArgs): ISubmissionOverviewRow[] => {
    const getSubmissionsInfoSharedArgs = {
        submissions: args.submissions,
        possibleSubmissions: args.possibleSubmissions,
        context: args.context,
        firstVatPeriodDate: args.firstVatPeriodDate,
        lastVatPeriodDate: args.lastVatPeriodDate
    };
    const rows: ISubmissionOverviewRow[] = [];
    const possibleColumns = Object.keys(args.possibleSubmissions) as ElectronicSubmissionTypeCode[];

    for (let i = 0; i < 12; i++) {
        const date = getUtcDateBy(args.year, i, 1);

        const row: ISubmissionOverviewRow = {
            month: i
        };

        for (const column of possibleColumns) {
            row[column] = getSubmissionInfo({
                ...getSubmissionsInfoSharedArgs, date, type: column,
                previousInfoRows: rows
            });
        }

        rows.push(row);
    }

    return rows;
});
