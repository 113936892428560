import { IToolbarItem } from "@components/toolbar/Toolbar.types";

import { RequireKeys } from "../../global.types";

export enum TableButtonsAction {
    Add = "Add",
    CSVExport = "CSVExport",
    ExpandCollapseAll = "ExpandCollapseAll",
    FullscreenOpen = "FullscreenOpen",
    FullscreenClose = "FullscreenClose",
    Lock = "Lock",
    MassEdit = "MassEdit",
    PdfExport = "PdfExport",
    PdfExportReportFull = "PdfExportReportFull",
    PdfExportReportShort = "PdfExportReportShort",
    Remove = "Remove",
    Sorting = "Sorting",
    Settings = "Settings",
    ToggleDraftView = "ToggleDraftView",
    XLSXExport = "XLSXExport",
}

// order spec
// https://solitea-cz.atlassian.net/wiki/spaces/IRIS/pages/2981068806/Po+ad+ikon+v+toolbaru+nad+tabulkami
export enum TableActionOrder {
    Add = 10,
    Remove = 20,
    // every special action should be added after Remove
    ExpandCollapseAll = 30,
    Fullscreen = 40,
    Lock = 50,
    MassEdit = 60,
    Sort = 70,
    Export = 80,
    Settings = 90,
    ToggleDraftView = 100
}

export type TTableToolbarItem = RequireKeys<IToolbarItem, "order">;

export type TableButtonsActionType = TableButtonsAction | string;
export const exportButtonActionGroup = [
    TableButtonsAction.XLSXExport,
    TableButtonsAction.CSVExport,
    TableButtonsAction.PdfExport,
    TableButtonsAction.PdfExportReportShort,
    TableButtonsAction.PdfExportReportFull
];

export interface ICustomButtonDefArgs {
    isActive: boolean;
    isDisabled: boolean;
}