import styled, { css } from "styled-components/macro";

import { T_TITLE_1, T_TITLE_2_2 } from "../../global.style";
import remains2 from "../../static/images/remainingTrial/remains_2.svg"; // origName: PODVOJ+welcome+screen/ilustrace/předplatné+-+západ+slunce/SVG/zalozeni-tenanta_illustration_Sunset_5th phase.svg
import remains5 from "../../static/images/remainingTrial/remains_5.svg"; // origName: PODVOJ+welcome+screen/ilustrace/předplatné+-+západ+slunce/SVG/zalozeni-tenanta_illustration_Sunset_4th phase.svg
import remains8 from "../../static/images/remainingTrial/remains_8.svg"; // origName: PODVOJ+welcome+screen/ilustrace/předplatné+-+západ+slunce/SVG/zalozeni-tenanta_illustration_Sunset_3rd phase.svg
import remains11 from "../../static/images/remainingTrial/remains_11.svg"; // origName: PODVOJ+welcome+screen/ilustrace/předplatné+-+západ+slunce/SVG/zalozeni-tenanta_illustration_Sunset_2nd phase.svg
import remains14 from "../../static/images/remainingTrial/remains_14.svg"; // origName: PODVOJ+welcome+screen/ilustrace/předplatné+-+západ+slunce/SVG/zalozeni-tenanta_illustration_Sunset_1st phase.svg
import { StyledButtonGroup } from "../button";

const getRemainingDaysImage = (remains: number): string => {
    switch (remains) {
        case 2:
            return remains2;
        case 5:
            return remains5;
        case 8:
            return remains8;
        case 11:
            return remains11;
        case 14:
            return remains14;
        default:
            return remains14;
    }
};

export const StyledDialog = styled.div<{ $backgroundColor: string, $color: string, $remains: number }>`
    position: relative;
    width: 750px;
    height: 750px;
    max-width: 80%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 19px 270px;

    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.15);
    ${props => css`
        background: ${props.$backgroundColor} url(${getRemainingDaysImage(props.$remains)}) bottom center no-repeat;
        background-size: 100% auto;
        color: ${props.$color};
    `};

    ${StyledButtonGroup} {
        position: absolute;
        bottom: 12px;
        right: 19px;
    }
`;

export const Subtitle = styled.h3`
    ${T_TITLE_2_2};
    margin-bottom: 20px;
    text-align: center;
`;

export const Title = styled.h2<{ $isBig?: boolean }>`
    ${T_TITLE_1};
    font-size: ${props => props.$isBig ? 62 : 42}px;
    text-align: center;
`;