import { getValue } from "@utils/general";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { AppContext } from "../../contexts/appContext/AppContext.types";
import { addCompanyIdToUrl } from "../../contexts/appContext/AppContext.utils";
import underConstructionSvg from "../../svg/menu/underConstruction.svg"; // origName: unknown
import TestIds from "../../testIds";
import Badge from "../badge";
import { SvgImage } from "../svgImage/SvgImage";
import Tooltip from "../tooltip";
import { ItemBody, ItemFooter, NavListItem, StyledLink } from "./NavDashboard.styles";
import { IMenuItem } from "./NavMenu";

interface IProps extends IMenuItem {
}

class NavDashboardItem extends React.PureComponent<IProps & WithTranslation> {
    static contextType = AppContext;

    render() {
        const title = this.props.t(getValue(this.props.title));

        return (
            <Tooltip content={this.props.tooltip}>
                {(ref) => {
                    return (
                        <NavListItem
                            ref={ref}
                            $isDisabled={this.props.isDisabled}
                            key={this.props.key}
                            data-testid={TestIds.DashboardItem}>
                            <StyledLink to={addCompanyIdToUrl(this.props.url, this.context)}
                                        tabIndex={this.props.isDisabled ? -1 : 0}
                                        $isDisabled={this.props.isDisabled}>
                                <ItemBody>
                                    <Badge offset={12} count={this.props.count} max={999}/>
                                    <SvgImage src={this.props.iconPath ?? underConstructionSvg}/>
                                </ItemBody>
                                <ItemFooter>
                                    {title}
                                </ItemFooter>
                            </StyledLink>
                        </NavListItem>
                    );
                }}
            </Tooltip>
        );
    }
}

export default withTranslation("Common")(NavDashboardItem);