import { IconButton } from "@components/button";
import { fetchItemsByInfo } from "@components/smart/smartSelect/SmartSelectAPI";
import { getRow, TCustomRowAction } from "@components/smart/smartTable/SmartTable.utils";
import { IActionRendererArgs, IRow, IRowAction, TId } from "@components/table";
import { IRowProps } from "@components/table/Rows";
import { TEntityKey } from "@evala/odata-metadata/src";
import { BankTransactionEntity } from "@odata/GeneratedEntityTypes";
import { generalBankAccountFormatter } from "@utils/BankUtils";
import i18next from "i18next";
import React from "react";

import { AddIcon, ChainedIcon, IProps as IIconProps, UnchainedIcon } from "../../../components/icon";
import ReadOnlyList, { formatHeaderValue } from "../../../components/readOnlyList/ReadOnlyList";
import { SmartTable } from "../../../components/smart/smartTable";
import { NEW_ITEM_DETAIL } from "../../../constants";
import { AppContext } from "../../../contexts/appContext/AppContext.types";
import { ActionState, IconSize, RowAction, Status, ToolbarItemType } from "../../../enums";
import { TRecordAny } from "../../../global.types";
import { IAfterSaveEventArgs, ModelEvent } from "../../../model/Model";
import BindingContext, { createBindingContext } from "../../../odata/BindingContext";
import { formatCurrency } from "../../../types/Currency";
import DateType from "../../../types/Date";
import memoizeOne from "../../../utils/memoizeOne";
import { FormStorage } from "../../../views/formView/FormStorage";
import {
    TableActionOrder,
    TableButtonsAction,
    TableButtonsActionType,
    TTableToolbarItem
} from "../../../views/table/TableToolbar.utils";
import TableView, { ITableViewBaseProps } from "../../../views/table/TableView";
import { SecondaryTableViewTitle, TableWrapper } from "../../../views/table/TableView.styles";
import View from "../../../views/View";
import { PAIR_ACTION_ID } from "../../asset/PairingWithAssetTableView";
import { refreshExchangeRate } from "../../documents/Document.utils";
import { getPairIconDef } from "../Payments.utils";
import { createDefaultStatementPairFilter, IBankCustomData, setNumberOurs } from "./BankTransactions.utils";
import { BankTransactionLocksFilterPath } from "./BankTransactionStatementPairDef";


interface IProps extends ITableViewBaseProps<IBankCustomData> {
}

class BankTransactionStatementPairTableView extends TableView<IProps> {
    static contextType = AppContext;

    constructor(props: IProps) {
        super(props);

        this.presetDefaultFilters = this.presetDefaultFilters.bind(this);

        const { storage } = props;

        this.getRootStorage().setCustomData({ pairedStorage: storage });
        this.handleCustomAction(PAIR_ACTION_ID, { update: false });

        this.setPairDocumentsFromEntity();

        storage.emitter.on(ModelEvent.FiltersCleared, this.presetDefaultFilters);
        this.presetDefaultFilters();
    }

    presetDefaultFilters(): void {
        const { storage } = this.props;
        createDefaultStatementPairFilter(storage);

        // by default, don't show locked transactions
        storage.setValueByPath(BankTransactionLocksFilterPath, false);
        storage.setFilterValueByPath(BankTransactionLocksFilterPath, false);

        storage.applyFilters();
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        this.props.storage.emitter.off(ModelEvent.FiltersCleared, this.presetDefaultFilters);
    }

    _createdRowBindingContext: BindingContext;

    async handleTableLoad(): Promise<void> {
        if (this._createdRowBindingContext) {
            this._pair(this._createdRowBindingContext, true);
            this.handleCustomAction(PAIR_ACTION_ID, { update: false });
            this._createdRowBindingContext = null;
        }

        super.handleTableLoad();
    }

    handleFormAfterSave({ isNew, bindingContext }: IAfterSaveEventArgs): void {
        if (isNew) {
            this._createdRowBindingContext = this.props.storage.data.bindingContext.addKey(bindingContext.getKey());
            this.getInitialActiveRows.reset();
        }
    }

    getRootStorage = () => {
        return this.props.rootStorage as FormStorage<unknown, IBankCustomData>;
    };

    setPairDocumentsFromEntity = () => {
        if (this.props.rootStorage.data.entity.BankStatement) {
            const statement = this.props.rootStorage.data.entity.BankStatement;
            this.props.storage.setCustomData({ pairedDocuments: { [statement.Id]: statement.Id } });
        } else {
            this.props.storage.setCustomData({ pairedDocuments: {} });
        }
    };

    formatCurrency = (val: number) => {
        const currency = this.props.rootStorage.data.entity?.TransactionCurrency?.Code;
        return formatCurrency(val, currency);
    };

    getInitialActiveRows = memoizeOne((): BindingContext[] => {
        const key = Object.keys(this.getPairedDocuments());
        const pairAction = this.getTableAction();
        const initialActiveRows: BindingContext[] = [];
        if (key?.[0] && pairAction !== TableButtonsAction.Remove) {
            const bc = this.props.storage.data.bindingContext.addKey(key[0]);
            initialActiveRows.push(bc);
        } else if (this._createdRowBindingContext) {
            initialActiveRows.push(this._createdRowBindingContext);
        }
        return initialActiveRows;
    });

    getTableSharedProps = () => {
        const props = super.getTableSharedProps();

        props.keepActiveRowsOnFilterChange = true;
        props.initialActiveRows = this.getInitialActiveRows();
        return props;
    };

    handlePairClick = (): void => {
        this.handleCustomAction(PAIR_ACTION_ID);
    };

    getPairIconDef = (): TTableToolbarItem => {
        return getPairIconDef(this.getTableAction(), this.handlePairClick, !!this.props.storage.data.addingRow);
    };

    handleToolbarCancel(): void {
        if (this.isTableAction(PAIR_ACTION_ID)) {
            this.setPairDocumentsFromEntity();
        }
        super.handleToolbarCancel();
        this.forceUpdate();
    }

    async handleToolbarConfirm(): Promise<void> {
        if (this.isTableAction(PAIR_ACTION_ID)) {
            await this.saveChanges();
        } else {
            await super.handleToolbarConfirm();
        }
    }

    getAddIconDef = (): TTableToolbarItem => {
        return {
            id: "addIcon",
            itemType: ToolbarItemType.Custom,
            order: TableActionOrder.Add,
            render: () => {
                const isAddingNew = !!this.props.storage.data.addingRow;
                return (
                    <IconButton title={this.props.storage.t("Banks:Statements.NewFormTitle")}
                                isTransparent onClick={this.handleAddClick}
                                key={"customAddIcon"}
                                isActive={isAddingNew}
                                isDisabled={this.isTableAction()}>
                        <AddIcon/>
                    </IconButton>
                );
            }
        };
    };

    getPairedDocuments(): Record<TEntityKey, any> {
        return this.props.storage.getCustomData().pairedDocuments || {};
    }

    getCustomRowAction = memoizeOne((): TCustomRowAction => {
        const tableAction = this.getTableAction();

        return {
            actionType: tableAction === PAIR_ACTION_ID ? RowAction.Custom : this.getRowActionType(),
            isSingleSelect: true,
            render: (args: IActionRendererArgs) => {
                const Icon = args.actionState === ActionState.Active ? ChainedIcon : UnchainedIcon;
                return (
                        <IconButton title=""
                                    key={"StatementPairingIcon"}
                                    onClick={(...event: any) => {
                                        const bindingContext = args.rowId as BindingContext;
                                        this.onPair(bindingContext);
                                        args.onClick(event);
                                    }}
                                    isDisabled={args.actionState === ActionState.None || args.isDisabled || args.isLocked}
                                    isDecorative>
                            <Icon width={IconSize.S} height={IconSize.S}/>
                        </IconButton>
                );
            }
        };
    });

    getRowIcon = (id: TId, row: IRowProps, rowAction: IRowAction): React.ComponentType<IIconProps> => {
        if (rowAction) {
            return null;
        }

        const isActive = this.getPairedDocuments()?.[(id as BindingContext).getKey()];
        return isActive ? ChainedIcon : null;
    };

    getToolbarButtons(): TableButtonsActionType[] {
        return [];
    }

    get customToolbarItems(): TTableToolbarItem[] {
        return [
            this.getAddIconDef(),
            this.getPairIconDef()
        ];
    }

    canConfirmToolbarAction = (): boolean => {
        if (this.getTableAction() === PAIR_ACTION_ID) {
            const pairedDocs = this.props.storage.getCustomData().pairedDocuments;
            return pairedDocs && Object.keys(pairedDocs)?.length > 0;
        }
        return this.props.storage.tableAPI?.getState().changedRows.size > 0;
    };

    handleAddClick = () => {
        const bc = createBindingContext("BankStatements", this.props.rootStorage.oData.metadata).addKey(NEW_ITEM_DETAIL, true);
        this.props.onRowSelect?.(bc, null, null, {
            hideTransactions: true
        });
    };

    isRowWithoutAction(rowId: TId, action: RowAction, row: IRow): boolean {
        if (action === RowAction.Remove) {
            // todo: do we have remove action here??
            return row.customData?.entity?.TransactionsCount > 0;
        } else if (action === RowAction.Custom && this.getTableAction() === PAIR_ACTION_ID) {
            const { entity } = row.customData ?? {};
            return !entity.BankAccount.IsActive;
        }
        return super.isRowWithoutAction(rowId, action, row);
    }

    getHeaderData = () => {
        const acc = generalBankAccountFormatter(null, {
            item: this.props.rootStorage.data.entity.BankStatement?.BankAccount
        });

        const accName = this.props.rootStorage.data.entity.BankStatement?.BankAccount?.Name;

        return [{
            label: this.props.rootStorage.t("Banks:Pairing.DateOfPay"),
            value: formatHeaderValue(DateType.format(this.props.rootStorage.data.entity.DateBankTransaction))
        }, {
            label: this.props.rootStorage.t("Banks:Pairing.Amount"),
            value: formatHeaderValue(this.formatCurrency(this.props.rootStorage.data.entity.TransactionAmount))
        }, {
            label: this.props.rootStorage.t("Banks:Pairing.SymbolVariable"),
            value: formatHeaderValue(this.props.rootStorage.data.entity.SymbolVariable)
        }, {
            label: this.props.rootStorage.t("Banks:Pairing.FromAccount"),
            value: formatHeaderValue(acc),
            description: accName ? ` | ${accName}` : ""
        }, {
            label: this.props.rootStorage.t("Banks:Pairing.ToAccount"),
            value: formatHeaderValue(generalBankAccountFormatter(null, {
                item: this.props.rootStorage.data.entity.BankAccount
            }))
        }, {
            label: this.props.rootStorage.t("Banks:Pairing.Note"),
            value: formatHeaderValue(this.props.rootStorage.data.entity.RemittanceInformation)
        }];
    };

    saveChanges = async () => {
        const pairedDocs = this.props.storage.getCustomData().pairedDocuments || {};
        const val: TRecordAny = Object.values(pairedDocs)[0];

        const bc = this.props.rootStorage.data.bindingContext.navigate(BankTransactionEntity.BankStatement);
        const info = this.props.rootStorage.getInfo(bc);
        if (info && val) {
            // case that new item was just created and items are still loading (in case user clicks way to fast :)
            let items = info.fieldSettings?.items;

            if (!items || items.length === 0) {
                items = await fetchItemsByInfo(this.props.rootStorage, info);
            }

            const item = items?.find(i => i.id === val.Id);
            if (item) {
                const newCurrency = item.additionalData.BankAccount?.TransactionCurrencyCode;
                this.props.rootStorage.data.entity.TransactionCurrency = { Code: newCurrency };
                refreshExchangeRate(this.props.rootStorage, newCurrency, BankTransactionEntity.DateBankTransaction);

                val.BankAccount = item.additionalData.BankAccount;
                setNumberOurs(item.additionalData?.NumberOurs, item.additionalData?._metadata?.Transactions?.count, this.props.rootStorage);
            }
        }

        this.props.rootStorage.clearAndSetValueByPath("BankStatement", val);
        this.getInitialActiveRows.reset();
        this.props.storage.tableAPI?.reloadTable();

        this.props.storage.setTableAlert({
            status: Status.Success,
            title: i18next.t("Common:Validation.SuccessTitle"),
            subTitle: i18next.t("Banks:Pairing.Saved")
        }, true);

        // turn off table action
        this.handleToolbarCancel();
        this.props.storage.refresh();
    };

    _pair = (bc: BindingContext, alwaysPair?: boolean) => {
        const row = getRow(this.props.storage.tableAPI.getState().rows, bc);

        let pairedDoc = this.getPairedDocuments();
        const key = bc.getKey();
        if (pairedDoc[key] && !alwaysPair) {
            pairedDoc = {};
        } else {
            pairedDoc = {
                [key]: {
                    Id: key,
                    BankAccount: row.customData.entity.BankAccount
                }
            };
        }

        this.props.storage.setCustomData({ pairedDocuments: pairedDoc });
    };

    onPair = (bc: BindingContext) => {
        this._pair(bc);
        this.forceUpdate();
    };

    getTitle = () => {
        const amount = this.props.rootStorage.data.entity.TransactionAmount;
        if (!amount) {
            return this.props.rootStorage.t("Banks:Transactions.Payment");
        }

        return amount < 0 ? this.props.rootStorage.t("Banks:Pairing.OutPayment") : this.props.rootStorage.t("Banks:Pairing.InPayment");
    };

    getCustomQuerySettings = memoizeOne(() => ({
        Transactions: {
            count: true,
            top: 0
        }
    }));

    render() {
        const sharedTableProps = this.getTableSharedProps();

        return (
            <>
                <View hotspotContextId={"BankTransStatementPairTableView"}>
                    <ReadOnlyList title={this.getTitle()}
                                  data={this.getHeaderData()}/>
                    <SecondaryTableViewTitle>{this.props.rootStorage.t("Banks:Pairing.Statement")}</SecondaryTableViewTitle>
                    {this.renderFilterBar()}
                    {this.renderAlert()}
                    {this.renderToolbar()}
                    <TableWrapper>
                        <SmartTable
                            {...sharedTableProps}
                            key={sharedTableProps?.key}
                            querySettings={this.getCustomQuerySettings()}
                        />
                    </TableWrapper>
                </View>
            </>
        );
    }
}

export default BankTransactionStatementPairTableView;