import Tooltip from "@components/tooltip";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { BackgroundJobActionType } from "../../contexts/backgroundJobsContext/BackgroundJobsContext.utils";
import { IconSize } from "../../enums";
import TestIds from "../../testIds";
import Avatar from "../avatar";
import { CloseIcon } from "../icon";
import { IProps as IProgressBarProps } from "../progressBar";
import ProgressBar from "../progressBar/ProgressBar";
import {
    BackgroundJobActionOverlay,
    BackgroundJobHeaderText,
    BackgroundJobHeaderWrapper,
    BackgroundJobSeparator,
    BackgroundJobTitle,
    ButtonBackgroundJobAction,
    IconButtonBackgroundJobClose,
    StyledBackgroundJob
} from "./BackgroundJobsPopup.styles";

export interface IBackgroundJobProps {
    id: number;
    title: string;
    progress: IProgressBarProps;
    avatarSrc: string;
    headerText: string;
    hideCloseButton?: boolean;
    /** On close button click */
    onClose?: (id: number) => void;
    /** Triggers hide transition */
    isClosing?: boolean;
    /** Called on transition end */
    onCloseFinish?: (id: number) => void;

    action?: BackgroundJobActionType;
    onActionClick?: (backgroundJobId: number, actionId: BackgroundJobActionType) => void;
}

export const BackgroundJob: React.FunctionComponent<IBackgroundJobProps> = React.memo((props) => {
    const { id, onClose, action, onActionClick, isClosing, onCloseFinish } = props;
    const { t } = useTranslation(["Common", "Components"]);
    // const [isClosing, setIsClosing] = useState(false);
    const fnOnClose = useCallback(() => {
        onClose(id);
    }, [id, onClose]);
    const fnOnTransitionEnd = useCallback(() => {
        if (isClosing) {
            onCloseFinish(id);
        }
    }, [id, onCloseFinish, isClosing]);
    const fnOnActionClick = useCallback(() => {
        onActionClick(id, action);
    }, [onActionClick, id, action]);

    return (
        <>
            <StyledBackgroundJob key={id}
                                 isClosing={props.isClosing}
                                 hasAction={!!props.action}
                                 onTransitionEnd={fnOnTransitionEnd}
                                 tabIndex={0}
                                 data-testid={TestIds.BackgroundJobsItem}>
                {!!props.action &&
                    <BackgroundJobActionOverlay/>
                }
                <BackgroundJobHeaderWrapper>
                    {props.avatarSrc &&
                        <Avatar src={props.avatarSrc} size={"14px"}/>
                    }
                    <BackgroundJobHeaderText>
                        {props.headerText}
                    </BackgroundJobHeaderText>
                </BackgroundJobHeaderWrapper>
                <Tooltip content={props.title} onlyShowWhenChildrenOverflowing>
                    {(ref) => (
                        <BackgroundJobTitle data-testid={TestIds.Title} ref={ref}>
                            {props.title}
                        </BackgroundJobTitle>
                    )}
                </Tooltip>

                {!props.hideCloseButton &&
                    <IconButtonBackgroundJobClose title={t("Common:General.Close")}
                                                  isDecorative
                                                  onClick={fnOnClose}>
                        <CloseIcon
                            width={IconSize.S}
                            height={IconSize.S}/>
                    </IconButtonBackgroundJobClose>
                }
                <ProgressBar {...props.progress} isDescriptionInverseValue/>
                {props.action &&
                    <ButtonBackgroundJobAction
                        onClick={fnOnActionClick}>
                        {t(`Components:BackgroundJobs.${props.action}`)}
                    </ButtonBackgroundJobAction>
                }
            </StyledBackgroundJob>
            <BackgroundJobSeparator/>
        </>
    );
});

