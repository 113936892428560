import { memoize } from "lodash";

import { parseEntityKey } from "./Metadata.utils";

export interface IType {
    getName(): string;

    getNamespace(): string;

    getFullName(): string;
}

class Type implements IType {
    name: string;
    namespace: string;
    _isCollection: boolean;

    constructor(name: string, namespace: string, isCollection?: boolean) {
        this.name = name;
        this.namespace = namespace;
        this._isCollection = isCollection;
    }

    getName(): string {
        return this.name;
    }

    getNamespace(): string {
        return this.namespace;
    }

    getFullName(): string {
        return this.namespace + "." + this.name;
    }

    isCollection(): boolean {
        return this._isCollection;
    }

    toString(): string {
        const str = this.namespace + "." + this.name;
        return this._isCollection ? `Collection(${str})` : str;
    }

    getExplicitName(): string {
        return `${this.getName()}${this.isCollection() ? "s" : ""}`;
    }
}

export interface IReferentialConstraint {
    property: string;
    referencedProperty: string;
}

export interface IPropertyInit {
    key?: boolean;
    navigation?: boolean;
    nullable?: boolean;
    maxLength?: string;
    precision?: string;
    scale?: string;
    defaultValue?: string;
    readOnly?: boolean;
    subordinate?: boolean;
    includable?: boolean;
    dependent?: boolean;
    independent?: boolean;
    immutable?: boolean;
    internal?: boolean;
    displayFormat?: string;
    currency?: string;
    referentialConstraint?: IReferentialConstraint;
    codeFor?: string;
}


class Property {
    name: string;
    type: Type;
    entityType: Type;

    private readonly _isNavigation: boolean;
    private readonly _isReadOnly: boolean;
    private readonly _isSubordinate: boolean;
    private readonly _isIncludable: boolean;
    private readonly _isDependent: boolean;
    private readonly _isIndependent: boolean;
    private readonly _isImmutable: boolean;
    private readonly _isInternal: boolean;
    private readonly _isKey: boolean;
    private readonly _isNullable: boolean;

    private readonly _maxLength: number;
    private readonly _precision: number;
    private readonly _scale: number;

    private readonly _defaultValue: string | boolean;
    private readonly _displayFormat: string;
    private readonly _currency: string;
    private readonly _referentialConstraint: IReferentialConstraint;
    private readonly _codeFor: string;

    constructor(name: string, type: Type, parameters: IPropertyInit, entityType: Type) {
        this.type = type;
        this.name = name;
        this.entityType = entityType;
        this._isNavigation = !!parameters.navigation;
        this._isKey = !!parameters.key;
        this._isNullable = !!parameters.nullable;
        this._maxLength = (parameters.maxLength && parseInt(parameters.maxLength)) || null;
        this._precision = (parameters.precision && parseInt(parameters.precision)) || null;
        this._scale = (parameters.scale && parseInt(parameters.scale)) || null;
        this._defaultValue = parameters.defaultValue || null;
        this._isReadOnly = !!parameters.readOnly;
        this._isSubordinate = !!parameters.subordinate;
        this._isIncludable = !!parameters.includable;
        this._isDependent = !!parameters.dependent;
        this._isIndependent = !!parameters.independent;
        this._isImmutable = !!parameters.immutable;
        this._isInternal = !!parameters.internal;
        this._displayFormat = parameters.displayFormat;
        this._currency = parameters.currency;
        this._referentialConstraint = parameters.referentialConstraint;
        this._codeFor = parameters.codeFor;
    }

    getType(): Type {
        return this.type;
    }

    getName(): string {
        return this.name;
    }

    getEntityType(): Type {
        return this.entityType;
    }

    isKey(): boolean {
        return this._isKey;
    }

    isNavigation(): boolean {
        return this._isNavigation;
    }

    isNullable(): boolean {
        return this._isNullable;
    }

    getMaxLength(): number {
        return this._maxLength;
    }

    getPrecision(): number {
        return this._precision;
    }

    getScale(): number {
        return this._scale;
    }

    getDefaultValue(): string | boolean {
        return this._defaultValue;
    }

    isReadOnly(): boolean {
        return this._isReadOnly;
    }

    /** Specifies whether the property has its own entity set (false) or has to be accessed via parent entity set (true) */
    isSubordinate(): boolean {
        return this._isSubordinate;
    }

    /** This navigation entity can only be included together with parent in the first POST request.
     * Consequent patches has to be done on the EntitySet of the navigation entity itself.*/
    isIncludable(): boolean {
        return this._isIncludable;
    }

    /** This navigation can only be updated via the parent entity,
     * even though there can exist separate (readonly) EntitySet just for this entity.
     * */
    isDependent(): boolean {
        return this._isDependent;
    }

    /** This property can be used on navigation collections of independent entities (with own entity set).
     * It specifies that when we create request to remove item from the collection,
     * we shouldn't call DELETE on the item itself, but rather just delete the reference by using $ref.
     * Example: PostingRule has collection of CompanyBankAccounts. CompanyBankAccounts has its own entity set.
     * When user remove item from the collection, we don't want to remove the CompanyBankAccount itself,
     * we just want to remove the reference to it from PostingRule.
     * */
    isIndependent(): boolean {
        return this._isIndependent;
    }

    isImmutable(): boolean {
        return this._isImmutable;
    }

    isInternal(): boolean {
        return this._isInternal;
    }

    getDisplayFormat(): string {
        return this._displayFormat;
    }

    getCurrency(): string {
        return this._currency;
    }

    getReferentialConstraint(): IReferentialConstraint {
        return this._referentialConstraint;
    }

    getCodeFor(): string {
        return this._codeFor;
    }
}

class EntityType implements IType {
    private readonly name: string;
    private readonly namespace: string;
    private readonly properties: Record<string, Property>;
    /** Holds all properties of EntityType merged with properties of its BaseType
     * Created once on the first getProperties call. */
    private allProperties: Record<string, Property>;
    private baseType: EntityType;

    constructor(name: string, namespace: string, properties: Record<string, Property>) {
        this.name = name;
        this.namespace = namespace;
        this.properties = properties;
    }

    getName(): string {
        return this.name;
    }

    getNamespace(): string {
        return this.namespace;
    }

    getProperties(): Record<string, Property> {
        if (!this.allProperties) {
            this.allProperties = { ...this.baseType?.getProperties(), ...this.properties };
        }

        return this.allProperties;
    }

    getProperty(name: string): Property {
        return this.getProperties()[name];
    }

    setBaseType(baseType: EntityType): void {
        this.baseType = baseType;
    }

    getBaseType(): EntityType {
        return this.baseType;
    }

    hasParentWithBaseType(type: string): boolean {
        let baseType = this.getBaseType();
        while (baseType?.getBaseType() && baseType?.getName() !== type) {
            baseType = baseType.getBaseType();
        }
        return baseType?.getName() === type;
    }

    getFullName(): string {
        return this.namespace ? `${this.namespace}.${this.name}` : this.name;
    }

    toString(): string {
        return this.name;
    }

    getKeys(): Property[] {
        return Object.values(this.getProperties()).filter(p => p.isKey());
    }

    getNavigationProperties(): Property[] {
        return Object.values(this.getProperties()).filter(p => p.isNavigation());
    }

    /**
     * Returns properties meant for UI
     */
    getUiProperties(): Property[] {
        return Object.values(this.getProperties()).filter(p => !p.isInternal());
    }
}


class NavigationBinding {
    path?: string;
    target?: string;

    constructor(path: string, target: string) {
        this.path = path;
        this.target = target;
    }

    getPath(): string {
        return this.path;
    }

    getTarget(): string {
        return this.target;
    }
}

class EntitySet {
    _name: string;
    _type: Type;
    navigationBindings: Record<string, NavigationBinding>;

    constructor(name: string, type: Type, navigationBindings: Record<string, NavigationBinding>) {
        this._name = name;
        this._type = type;
        this.navigationBindings = navigationBindings;
    }

    getName(): string {
        return this._name;
    }

    getType(): Type {
        return this._type;
    }

    toString(): string {
        return this._name;
    }
}

export interface IODataActionParameter {
    name: string;
    type: Type;
    nullable: boolean;
    optional: boolean;
}

export class ODataAction {
    _name: string;
    _isBound: boolean;
    _parameters: IODataActionParameter[];
    _bindingParameter: IODataActionParameter;
    _returnType: Type;

    constructor(name: string, parameters: IODataActionParameter[], returnType?: Type, isBound?: boolean) {
        this._name = name;
        this._returnType = returnType;
        this._isBound = isBound;
        this._parameters = parameters.filter(parameter => parameter.name !== "bindingParameter");
        this._bindingParameter = parameters.find(parameter => parameter.name === "bindingParameter");
    }

    getName(): string {
        return this._name;
    }

    getFullName(): string {
        return `${this.getBindingParameter().type.getExplicitName()}${this.getName()}`;
    }

    getBindingParameter(): IODataActionParameter {
        return this._bindingParameter;
    }

    getParameters(): IODataActionParameter[] {
        return this._parameters;
    }

    getReturnType(): Type {
        return this._returnType;
    }
}

export type TEntityKey = string | number;

interface IEntitySetNavigation {
    entitySet: EntitySet;
    entityKey: TEntityKey;
    navigation: string;
}

class Metadata {
    entities: Record<string, EntityType>;
    entitySets: Record<string, EntitySet>;
    actions: Record<string, ODataAction>;
    url: string;

    constructor(url: string, entities: Record<string, EntityType>, entitySets: Record<string, EntitySet>, actions: Record<string, ODataAction>) {
        this.url = url;
        this.entities = entities;
        this.entitySets = entitySets;
        this.actions = actions;
    }

    getUrl(): string {
        return this.url;
    }

    getEntitySet = (path: string): EntitySet => {
        const { entitySet, navigation } = this.getLastValidEntitySet(path);

        if (navigation) {
            throw new Error(`Metadata: Wrong entity path: ${path}`);
        }

        return entitySet;
    };

    getLastValidEntitySet = (path: string): IEntitySetNavigation => {
        const pathParts = path.split("/");
        const firstPart = parseEntityKey(pathParts[0]);
        const length = pathParts.length;
        let currentEntitySet: EntitySet = this.entitySets[firstPart.path];
        let lastEntitySetKey: TEntityKey = firstPart.key;
        let lastEntitySet: EntitySet = currentEntitySet;
        let lastEntitySetIndex = 0;
        let currentEntityType: EntityType = this.entities[currentEntitySet ? currentEntitySet.getType().getFullName() : firstPart.path];

        for (let i = 1; i < length; i++) {
            if (!currentEntityType) {
                throw new Error(`Metadata: Wrong entity path: ${path}`);
            }

            const part = parseEntityKey(pathParts[i]);
            currentEntityType = this.entities[currentEntityType.getProperty(part.path).getType().getFullName()];

            if (currentEntitySet) {
                const navigation = currentEntitySet.navigationBindings[part.path];

                if (!navigation) {
                    currentEntitySet = this.getEntitySetForEntityType(currentEntityType);
                } else {
                    currentEntitySet = this.entitySets[navigation.getTarget()];
                }
            } else if (currentEntityType) {
                currentEntitySet = this.getEntitySetForEntityType(currentEntityType);
            }

            if (currentEntitySet) {
                lastEntitySet = currentEntitySet;
                lastEntitySetIndex = i;
                lastEntitySetKey = part.key;
            }
        }

        return {
            entitySet: lastEntitySet,
            entityKey: lastEntitySetKey,
            navigation: pathParts.slice(lastEntitySetIndex + (lastEntitySet ? 1 : 0)).join("/")
        };
    };

    /** Tries to find entity set for given entity type. If more than one exists, throws an error */
    getEntitySetForEntityType = memoize((entityType: EntityType): EntitySet => {
        if (!entityType) {
            return null;
        }

        const entityName = entityType.getFullName();

        const entitySets = Object.values(this.entitySets).filter((entitySet) => {
            return entitySet.getType().getFullName() === entityName;
        });

        if (entitySets.length > 1) {
            throw new Error(`Metadata: Multiple entity sets found for: ${entityName}`);
        }

        return entitySets[0];
    });

    /**
     * Returns an entity type for provided navigation path. The path happens upon EntitySet and it's navigationBindings and is translated to target type.
     *
     * @param {string} path Path segments
     */
    getTypeForPath(path: string): EntityType {
        if (this.entities[path]) {
            return this.entities[path];
        }

        let entityType, navigatedEntityType;

        const { entitySet, navigation } = this.getLastValidEntitySet(path);

        if (entitySet) {
            entityType = this.entities[entitySet.getType().getFullName()];
        }


        // collections doesn't have to have its own entity set, but their entity type should still be returned
        if (navigation) {
            const navigationParts = navigation.split("/").map(nav => parseEntityKey(nav).path);

            if (!entityType) {
                entityType = this.entities[navigationParts.shift()];
            }

            for (const navigPart of navigationParts) {
                if (!entityType.getProperty(navigPart)) {
                    throw new Error(`Metadata: Wrong entity path: ${path}`);
                }

                navigatedEntityType = this.entities[entityType.getProperty(navigPart).getType().getFullName()];

                if (navigatedEntityType) {
                    entityType = navigatedEntityType;
                }
            }
        }

        return entityType;
    }
}

export { Type, Property, EntityType, NavigationBinding, EntitySet, Metadata };