import { IFieldDefFn } from "@components/smart/FieldInfo";
import { EntitySetName, EntityTypeName, IDeferredPlanEntity } from "@odata/GeneratedEntityTypes";
import { DeferredPlanPostingTypeCode, PeriodDayCode } from "@odata/GeneratedEnums";
import { IFormatOptions } from "@odata/OData.utils";
import { setDefForTesting } from "@pages/getDefByEntityType";
import { IDefinition, IGetDefinition } from "@pages/PageUtils";
import { getCompanyCurrency } from "@utils/CompanyUtils";
import i18next from "i18next";
import React from "react";

import CollapsibleSection, {
    CollapsibleSectionTitleLook
} from "../../../../components/collapsibleSection/CollapsibleSection";
import { IAppContext } from "../../../../contexts/appContext/AppContext.types";
import { CacheStrategy, FieldType, GroupedField, LabelStatus } from "../../../../enums";
import { TValue } from "../../../../global.types";
import BindingContext from "../../../../odata/BindingContext";
import Currency from "../../../../types/Currency";
import { IFormDef } from "../../../../views/formView/Form";
import { getConfirmationActionText } from "../../../../views/table/TableView.render.utils";
import { TimePlanTable } from "./TimePlanTable";
import { getDeferredItems, getTimeResAccountFieldDef } from "./TimeResolution.utils";

const planAffectedFields = [{ id: BindingContext.localContext("plan") }];

export const getDefinition: IGetDefinition = (context: IAppContext, receivable: boolean): IDefinition => {
    const entitySet = EntitySetName.DeferredPlans;

    const form: IFormDef = {
        id: `${EntityTypeName.DeferredPlan}Form`,
        translationFiles: getDefinition.translationFiles,
        fieldDefinition: {
            Account: getTimeResAccountFieldDef(receivable),
            [BindingContext.localContext("fullAmount")]: {
                label: i18next.t("TimeResolution:Form.FullAmount"),
                isReadOnly: true,
                formatter: (val: TValue, args: IFormatOptions) => {
                    return Currency.format(val, { currency: getCompanyCurrency(context) });
                },
                useForComparison: false
            },
            DateStart: {
                affectedFields: planAffectedFields,
                groupedField: GroupedField.MultiStart
            },
            DateEnd: {
                affectedFields: planAffectedFields,
                groupedField: GroupedField.MultiEnd
            },
            PostingType: {
                type: FieldType.SegmentedButton,
                labelStatus: LabelStatus.Removed,
                defaultValue: DeferredPlanPostingTypeCode.Yearly,
                affectedFields: planAffectedFields,
                fieldSettings: {
                    items: [
                        {
                            id: DeferredPlanPostingTypeCode.Yearly,
                            label: i18next.t("TimeResolution:Form.Yearly")
                        },
                        {
                            id: DeferredPlanPostingTypeCode.Monthly,
                            label: i18next.t("TimeResolution:Form.Monthly")
                        }
                    ]
                }
            },
            PeriodDay: {
                type: FieldType.ComboBox,
                defaultValue: PeriodDayCode.LastDay,
                affectedFields: planAffectedFields,
                fieldSettings: {
                    displayName: "Name",
                    entitySet: EntitySetName.PeriodDays
                },
                cacheStrategy: CacheStrategy.EndOfTime
            },
            Description: {
                isReadOnly: true,
                defaultValue: i18next.t("TimeResolution:Form.Description")
            },
            [BindingContext.localContext("plan")]: {
                type: FieldType.Custom,
                render: (args: IFieldDefFn) => {
                    const entity = args.storage.data.entity as IDeferredPlanEntity;
                    const items = getDeferredItems(entity, args.storage.context.getCompany());

                    return (
                        <CollapsibleSection
                            defaultIsOpen={false}
                            title={getConfirmationActionText(args.storage.t("TimeResolution:TimePlanTable.Title"), items.length)}
                            titleLook={CollapsibleSectionTitleLook.Basic}>
                            <TimePlanTable items={items} currency={entity?.OriginalDocument?.Currency?.Code}/>
                        </CollapsibleSection>
                    );
                },
                useForComparison: false
            }
        },
        groups: [
            {
                id: "top",
                rows: [[
                    { id: BindingContext.localContext("fullAmount") },
                    { id: "DateStart" }, { id: "DateEnd" }
                ]]
            },
            {
                id: "accounting",
                title: i18next.t("TimeResolution:Form.AccountingGroup"),
                rows: [
                    [{ id: "PostingType" }]

                ]
            },
            {
                id: "accounting",
                title: "",
                rows: [
                    [
                        { id: "PeriodDay" },
                        { id: "Account" }
                    ]
                ],
                isSmallBottomMargin: true
            },
            {
                id: "bottom",
                rows: [
                    [{ id: "Description" }],
                    [{ id: BindingContext.localContext("plan") }]
                ]
            }
        ]
    };

    return {
        entitySet,
        form
    };
};

getDefinition.translationFiles = ["TimeResolution", "Documents", "Common"];
setDefForTesting("TimeResolution", getDefinition);