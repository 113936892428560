import { composeRefHandlers, getElementOuterHeight, isObjectEmpty } from "@utils/general";
import Emittery from "emittery";
import { debounce } from "lodash";
import React, { CSSProperties } from "react";
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableProvidedDragHandleProps,
    DraggableRubric,
    DraggableStateSnapshot,
    DragStart,
    DragUpdate,
    Droppable,
    DroppableProvided,
    DroppableStateSnapshot,
    DropResult
} from "react-beautiful-dnd";
import AutoSizer from "react-virtualized-auto-sizer";
import { ListChildComponentProps, VariableSizeList } from "react-window";

import { WithDomManipulator, withDomManipulator } from "../../contexts/domManipulator/withDomManipulator";
import TestIds from "../../testIds";
import memoizeOne from "../../utils/memoizeOne";
import { getDragedElement } from "../configurationList";
import CustomResizeObserver from "../customResizeObserver";
import { FIELD_VER_MARGIN_SMALL } from "../inputs/field/Field.styles";
import {
    EmptyChildHeight,
    EmptyChildLineNumberTopOffset,
    EmptyItem,
    ItemIndex,
    ItemWrapper,
    LineNumbersWrapper,
    OrderBackground,
    StyledSortableList,
    VirtualizedSortableListWrapper
} from "./SortableList.styles";
import SortableListPlaceholder from "./SortableListPlaceholder";

export interface ISortableListChild {
    id: string;
    render: (dragHandleProps: DraggableProvidedDragHandleProps) => React.ReactElement;
    // renders orange line next to the index number
    showIndicator?: () => boolean;
    // render empty space with fixed height and index number,
    // removes dragging
    isEmptyItem?: boolean;
    emptyItemText?: string;
}

export const VIRTUALIZED_LIST_CLASSNAME = "VirtualizedList";


/** Provides DnD and virtualization for list components. Used by FastEntryList. */
export interface IProps {
    id: string;
    onReorder: (sourceIndex: number, destinationIndex: number, listId: string) => void;
    showLineNumbers?: boolean;
    // use together with "showLineNumbers" to specify correct position for current items
    lineNumberTopOffset?: string;
    // how many items to render before virtualization starts
    virtualizationThreshold?: number;
    useVirtualization?: boolean;
    virtualizedListRef?: React.RefObject<VariableSizeList>;
    recalculate?: unknown;
    // use together with virtualization
    // either use number for absolute height
    // or string, that can use any valid css size
    // and will be combined together with AutoSizer
    height?: number | string;
    useDnD?: boolean;
    onResize?: () => void;
    children?: ISortableListChild[];
    rowGap?: string;
    className?: string;
    isInAuditTrail?: boolean;
}

// SortableList item (e.g. FastEntry) can wrap => we cannot always use fixed height
// ==> dynamically use height
interface IState {
    // for virtualization,
    // all items has to have the same height => only one value is enough
    // but for standard rendering combined with DnD and line numbers,
    // each item can have different height => store all of them but only use one when virtualization is used
    itemsHeight: Record<string, number>;
}

interface IItemData {
    items: ISortableListChild[];
    showLineNumbers?: boolean;
    lineNumberTopOffset?: string;
    useVirtualization: boolean;
}

class Item extends React.PureComponent<{
    data: IItemData,
    style?: CSSProperties;
    index?: number;
    provided?: DraggableProvided;
    snapshot?: DraggableStateSnapshot;
    rowGap?: string;
    rubric?: DraggableRubric;
}> {
    render() {
        const { index, data, style, provided, rubric } = this.props;
        const i = index ?? rubric.source.index;
        const item = data.items[i];

        return (
            <ItemWrapper key={item.id}
                         rowGap={this.props.rowGap}
                         {...(!item.isEmptyItem ? provided?.draggableProps : {})}
                         useVirtualization={data.useVirtualization}
                         showLineNumbers={data.showLineNumbers}
                         showIndicator={item.showIndicator?.()}
                         isEmptyItem={item.isEmptyItem}
                         style={{
                             ...provided?.draggableProps.style,
                             ...style
                         }}
                         ref={provided?.innerRef}
                         data-testid={TestIds.SortableListItem}>

                {!item.isEmptyItem && item.render(provided?.dragHandleProps)}
                {item.isEmptyItem &&
                    <EmptyItem isDraggable={!!provided?.draggableProps}>{item.emptyItemText}</EmptyItem>}
                {/*todo how to render ItemIndex for virtualized item outside of the drag item*/}
                {/*so that it is not dragged together*/}
                {data.useVirtualization && data.showLineNumbers &&
                    <ItemIndex $top={!item.isEmptyItem ? data.lineNumberTopOffset : EmptyChildLineNumberTopOffset}
                               isAbsolute>
                        {!item.isEmptyItem ? i + 1 : null}
                    </ItemIndex>
                }
            </ItemWrapper>
        );
    }
}

interface IRenderItem extends Omit<ListChildComponentProps, "data"> {
    data: IItemData;
    rowGap?: string;
}

class SimpleItem extends React.PureComponent<IRenderItem> {
    render() {
        const { data, style, index, rowGap } = this.props;

        return (
            <Item data={data}
                  rowGap={rowGap}
                  index={index}
                  style={style}
            />
        );
    }
}

class DraggableItem extends React.PureComponent<IRenderItem> {
    render() {
        const { index, data, style, rowGap } = this.props;
        const item = data.items?.[index];

        return (
            <Draggable draggableId={`${item.id}`}
                       index={index}
                       key={item.id}
                       isDragDisabled={item.isEmptyItem}
                       disableInteractiveElementBlocking // enable d'n'd drag events even with html input inside
            >
                {(provided: DraggableProvided, snapshot: DraggableStateSnapshot, rubric: DraggableRubric) => {
                    return (
                        <Item data={data}
                              rowGap={rowGap}
                              style={style}
                              provided={provided}
                              snapshot={snapshot}
                              rubric={rubric}
                        />
                    );
                }}
            </Draggable>
        );
    }
}

export enum SortableListEvent {
    PlaceholderChange = "PlaceholderChange"
}

class SortableList extends React.PureComponent<IProps & WithDomManipulator, IState> {
    static defaultProps: Partial<IProps> = {
        useDnD: true
    };

    state: IState = {
        itemsHeight: {}
    };

    emitter = new Emittery();
    measurementRef = React.createRef<HTMLDivElement>();
    virtualizedListRef = React.createRef<VariableSizeList>();
    listElementRef = React.createRef<HTMLDivElement>();
    scrollRef = React.createRef<HTMLDivElement>();

    // we want to ignore first resize that happens during rendering
    ignoreResize = true;

    getItemData = memoizeOne(() => {
        return {
            items: this.children,
            useVirtualization: this.props.useVirtualization,
            showLineNumbers: this.props.showLineNumbers,
            lineNumberTopOffset: this.props.lineNumberTopOffset
        };
    }, () => [this.children]);

    get children(): ISortableListChild[] {
        return (Array.isArray(this.props.children) ? this.props.children : [this.props.children]) as ISortableListChild[];
    }

    get shouldUpdateItemHeight() {
        return (this.props.useVirtualization || this.props.useDnD || this.props.showLineNumbers) && this.props.children?.length > 0;
    }

    componentDidMount() {
        if (this.shouldUpdateItemHeight) {
            this.updateItemHeightAfterFirstRender();
        }
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (this.shouldUpdateItemHeight && isObjectEmpty(this.state.itemsHeight)) {
            this.updateItemHeightAfterFirstRender(!prevProps.useVirtualization && this.props.useVirtualization);
        }

        if ((prevProps.useVirtualization || prevProps.useDnD || prevProps.showLineNumbers)
            && (!this.props.useVirtualization && !this.props.useDnD && !this.props.showLineNumbers)) {
            this.setState({
                itemsHeight: {}
            });
        }
        if (prevProps.recalculate !== this.props.recalculate && !isObjectEmpty(this.state.itemsHeight)) {
            this.updateItemHeightAfterResize();
        }

        if (this.props.useVirtualization && this.state.itemsHeight["0"] && prevState.itemsHeight["0"] !== this.state.itemsHeight["0"]) {
            this.virtualizedListRef.current?.resetAfterIndex(0, true);
        } else if (this.props.children.length !== prevProps.children.length) {
            const prevFirstEmptyItem = prevProps.children.findIndex(child => child.isEmptyItem);
            const firstEmptyItem = this.props.children.findIndex(child => child.isEmptyItem);

            if (firstEmptyItem > 0) {
                // if "empty" item exist in the list, it means not all the sizes are same, because "empty" item has very small height.
                // => we need to call resetAfterIndex, otherwise, react-virtualized won't refresh the sizes
                // "empty" item can be obtained by removing existing item on a draft form, or check FastEntry stories
                this.virtualizedListRef.current?.resetAfterIndex(Math.min(prevFirstEmptyItem, firstEmptyItem) - 1, true);
            }
        }
    }

    getRowGap = () => {
        return this.props.rowGap ?? FIELD_VER_MARGIN_SMALL;
    };

    getAllItemsHeight = (): Record<string, number> => {
        const itemsHeight: Record<string, number> = {};
        const elements = Array.from(this.listElementRef.current?.children ?? []) as HTMLDivElement[];


        for (let i = 0; i < this.props.children.length; i++) {
            const item = this.props.children[i];

            itemsHeight[item.id] = elements[i] ? getElementOuterHeight(elements[i]) : 0;
        }
        return itemsHeight;
    };

    updateItemHeightAfterFirstRender = (scrollDown?: boolean) => {
        this.props.domManipulatorOrchestrator.registerCallback(
            () => {
                if (this.props.useVirtualization) {
                    return {
                        "0": getElementOuterHeight(this.measurementRef.current.children[0] as HTMLDivElement) + +parseInt(this.getRowGap())
                    };
                } else {
                    return this.getAllItemsHeight();
                }

            },
            (itemsHeight: Record<string, number>) => {
                this.setState({
                    itemsHeight
                }, () => {
                    if (scrollDown) {
                        this.scrollToItem(this.props.children.length - 1);
                    }
                });
            },
            [this.props.useVirtualization ? this.measurementRef : this.listElementRef]
        );
    };

    updateItemHeightAfterResize = () => {
        if (this.props.useVirtualization) {
            if (!this.listElementRef.current) {
                return;
            }

            const itemWrapper = this.listElementRef.current.children[0] as HTMLDivElement;
            const itemWrapperStyles = getComputedStyle(itemWrapper);
            const innerItem = itemWrapper.children[0] as HTMLDivElement;
            const newItemHeight = getElementOuterHeight(innerItem) + parseInt(itemWrapperStyles.marginTop) + parseInt(itemWrapperStyles.marginBottom);
            const oldItemHeight = Object.values(this.state.itemsHeight)[0];

            if (newItemHeight !== oldItemHeight) {
                this.setState({
                    itemsHeight: {
                        "0": newItemHeight + +parseInt(this.getRowGap())
                    }
                });
            }
        } else {
            this.setState({
                itemsHeight: this.getAllItemsHeight()
            });
        }
    };

    handleDragStart = (event: DragStart) => {
        const draggedElement = getDragedElement(event.draggableId);

        if (!draggedElement) {
            return;
        }

        this.emitter.emit(SortableListEvent.PlaceholderChange, event.source.index);
    };

    handleDragUpdate = (event: DragUpdate) => {
        this.emitter.emit(SortableListEvent.PlaceholderChange, null);

        if (!event.destination) {
            return;
        }

        this.emitter.emit(SortableListEvent.PlaceholderChange, event.destination.index);
    };

    handleDragEnd = (result: DropResult) => {
        this.emitter.emit(SortableListEvent.PlaceholderChange, null);

        if (!result.destination) {
            return; // dropped outside?
        }

        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;

        if (this.props.children[destinationIndex].isEmptyItem) {
            // ignore drag events placed if ended on/behind empty item
            return;
        }

        this.props.onReorder(sourceIndex, destinationIndex, this.props.id);
    };

    renderClone = (provided: DraggableProvided, snapshot: DraggableStateSnapshot, rubric: DraggableRubric) => {
        return (
            <Item data={this.getItemData()}
                  provided={provided}
                  snapshot={snapshot}
                  rubric={rubric}
            />
        );
    };

    scrollToItem = (index: number) => {
        // const scrollTop = (index + 1) * Object.values(this.state.itemsHeight)[0];
        //
        // setTimeout(() => {
        //     // this will cause handleScroll to be called as well
        //     this.scrollRef.current.scrollTop = scrollTop;
        // });
        this.virtualizedListRef.current.scrollToItem(index, "smart");
    };

    handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const scrollTop = (event.target as HTMLDivElement).scrollTop;

        // since we use custom scrollbar,
        // we have to let VariableSizeList know about the scroll event
        this.virtualizedListRef.current.scrollTo(scrollTop);
    };

    handleResize = debounce(() => {
        if (this.shouldUpdateItemHeight && !this.ignoreResize) {
            this.updateItemHeightAfterResize();
        }

        this.ignoreResize = false;

        this.props.onResize?.();
    });

    getItemSize = (index: number) => {
        if (this.children[index].isEmptyItem) {
            return EmptyChildHeight;
        }

        return Object.values(this.state.itemsHeight)[0];
    };

    renderVirtualizedList = (droppableProvided: DroppableProvided = null, droppableSnapshot: DroppableStateSnapshot = null, width: number | string, height: number) => {
        const children = this.children;

        return (
            <>
                {/*when ScrollBar is used, DnD only works for items that are initially visible,*/}
                {/*but not for the rest of them -_-. dunno why */}
                {/*<ScrollBar style={{ width: `100%`, height: `${height}px` }}*/}
                {/*           scrollableNodeProps={{*/}
                {/*               onScroll: this.handleScroll,*/}
                {/*               ref: this.scrollRef*/}
                {/*           }}>*/}
                <VariableSizeList
                    width={width}
                    height={height}
                    itemCount={children.length}
                    itemSize={this.getItemSize}
                    itemData={this.getItemData()}
                    outerRef={droppableProvided?.innerRef}
                    innerRef={this.listElementRef}
                    className={VIRTUALIZED_LIST_CLASSNAME}
                    overscanCount={1}
                    ref={composeRefHandlers(this.virtualizedListRef, this.props.virtualizedListRef)}
                    // we are using custom scrollbar => disable overflow
                    // style={{
                    //     overflow: "visible"
                    // }}
                >
                    {droppableProvided ? DraggableItem : SimpleItem}
                </VariableSizeList>
                {/*</ScrollBar>*/}
                <SortableListPlaceholder emitter={this.emitter}
                                         items={this.props.children}
                                         itemsHeight={this.state.itemsHeight}
                                         snapshot={droppableSnapshot}
                                         portalTo={this.listElementRef}
                                         addLineNumbersPadding={this.props.showLineNumbers}/>
            </>
        );
    };

    renderVirtualized = (droppableProvided: DroppableProvided = null, droppableSnapshot: DroppableStateSnapshot = null) => {
        const useAutoSizer = typeof this.props.height === "string";
        let renderedContent: React.ReactElement;

        if (!useAutoSizer) {
            renderedContent = this.renderVirtualizedList(droppableProvided, droppableSnapshot, "100%", this.props.height as number);
        } else {
            renderedContent = (
                <AutoSizer onResize={this.handleResize}
                    // use default values to prevent beautiful-dnd warning on first render
                           defaultWidth={300}
                           defaultHeight={150}>
                    {({ width, height }: { width: number; height: number; }) => {
                        return this.renderVirtualizedList(droppableProvided, droppableSnapshot, width, height);
                    }}
                </AutoSizer>
            );
        }

        return (
            // wrap to make sure auto sizer will work in flex
            <VirtualizedSortableListWrapper
                    $rowGap={this.props.rowGap}
                showLineNumbers={this.props.showLineNumbers}
                $height={useAutoSizer ? this.props.height as string : null}>
                {!useAutoSizer && <CustomResizeObserver onResize={this.handleResize}/>}
                {renderedContent}
            </VirtualizedSortableListWrapper>
        );
    };

    renderStandard = (droppableProvided?: DroppableProvided, droppableSnapshot?: DroppableStateSnapshot) => {
        const children = this.children;
        let renderedContent = (
            <StyledSortableList className={this.props.className}
                                {...droppableProvided?.droppableProps}
                                ref={composeRefHandlers(droppableProvided?.innerRef, this.listElementRef)}
                                data-testid={TestIds.SortableList}
            >
                {children.map((child, index) => {
                    const RenderItem = this.props.useDnD ? DraggableItem : SimpleItem;

                    return (
                        <RenderItem key={child.id}
                                    index={index}
                                    rowGap={this.props.rowGap}
                                    data={this.getItemData()}
                                    style={null}/>
                    );
                })}
                {droppableProvided?.placeholder}
                <SortableListPlaceholder emitter={this.emitter}
                                         items={this.props.children}
                                         itemsHeight={this.state.itemsHeight}
                                         snapshot={droppableSnapshot}/>
                <CustomResizeObserver onResize={this.handleResize}/>
            </StyledSortableList>
        );

        if (this.props.showLineNumbers) {
            renderedContent = (
                <LineNumbersWrapper>
                    <OrderBackground>
                        {children.map((child, i) => {
                            const height = this.state.itemsHeight[child.id];

                            return (
                                <ItemIndex key={child.id}
                                           $height={`${height}px`}
                                           $top={!child.isEmptyItem ? this.props.lineNumberTopOffset : EmptyChildLineNumberTopOffset}>
                                    {!child.isEmptyItem && !this.props.isInAuditTrail ? i + 1 : null} {/* audit has its own line numbers, different color and order */}
                                </ItemIndex>
                            );
                        })}
                    </OrderBackground>
                    {renderedContent}
                </LineNumbersWrapper>
            );
        }

        return renderedContent;
    };

    renderWithDnd = () => {
        return (
            <DragDropContext
                onDragStart={this.handleDragStart}
                onDragUpdate={this.handleDragUpdate}
                onDragEnd={this.handleDragEnd}>
                <Droppable droppableId={`SortableList-${this.props.id}`}
                           direction={"vertical"}
                           type={"SortableList"}
                           mode={this.props.useVirtualization ? "virtual" : "standard"}
                           renderClone={this.renderClone}
                >
                    {(provided, snapshot) => {
                        return this.props.useVirtualization ? this.renderVirtualized(provided, snapshot) : this.renderStandard(provided, snapshot);
                    }}
                </Droppable>
            </DragDropContext>
        );
    };

    renderWithoutDnd = () => {
        return this.props.useVirtualization ? this.renderVirtualized() : this.renderStandard();
    };

    renderItemForMeasurement = () => {
        const children = this.children;

        if (!children || this.children.length === 0) {
            return null;
        }

        return (
            <div style={{ visibility: "hidden" }} ref={this.measurementRef}>
                <Item data={this.getItemData()}
                      index={0}/>
            </div>

        );
    };

    render() {
        if (this.shouldUpdateItemHeight && this.props.useVirtualization && isObjectEmpty(this.state.itemsHeight)) {
            return this.renderItemForMeasurement();
        }

        return this.props.useDnD ? this.renderWithDnd() : this.renderWithoutDnd();
    }
}

export default withDomManipulator(SortableList);