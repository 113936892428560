import { CurrencyCode } from "@odata/GeneratedEnums";
import { roundToDecimalPlaces } from "@utils/general";
import { fixMinusSign } from "@utils/string";
import i18next from "i18next";

import { CurrencyUnit } from "../enums";
import { TValue } from "../global.types";
import NumberType from "./Number";

interface ICurrencyTypeOptions {
    currency?: string;
    scale?: CurrencyUnit;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
}

class CurrencyType {
    static parse(value: string, currency: string): number {
        return NumberType.parse(value.replace(this.getCurrencyUnit(currency), ""));
    }

    static format(value: TValue, {
        currency,
        scale,
        minimumFractionDigits,
        maximumFractionDigits
    }: ICurrencyTypeOptions): string {
        if (value === "" || isNaN(value as number)) {
            return value as string;
        } else if (currency) {
            switch (scale) {
                case CurrencyUnit.Thousands:
                    return NumberType.format(
                            roundToDecimalPlaces(2, parseFloat(value as string) / 1000).toString())
                        + ` ${CurrencyType.getThousandsUnit(currency)} ${CurrencyType.getCurrencyUnit(currency)}`;
                case CurrencyUnit.Millions:
                    return NumberType.format(
                            roundToDecimalPlaces(2, parseFloat(value as string) / 1000000).toString())
                        + ` ${CurrencyType.getMillionsUnit(currency)} ${CurrencyType.getCurrencyUnit(currency)}`;
                case CurrencyUnit.Units:
                default:
                    return fixMinusSign(
                        new Intl.NumberFormat(i18next.language, {
                            style: "currency",
                            currency: currency,
                            // we want USD to be formatted as "$" without any other prefix
                            // because US dollar is more important than all the other dollars
                            currencyDisplay: currency === CurrencyCode.USDollar ? "narrowSymbol" : "symbol",
                            minimumFractionDigits,
                            maximumFractionDigits
                        }).format(value as number));
            }
        } else {
            return NumberType.format(value as number);
        }
    }

    static isValid(value: number): boolean {
        return NumberType.isValid(value);
    }

    static getThousandsUnit(currency: string): string {
        return CurrencyType.getCompactUnit(1000, currency);
    }

    static getMillionsUnit(currency: string): string {
        return CurrencyType.getCompactUnit(1000000, currency);
    }

    /**
     * Returns unit for given order in current locale (e.g. scale is 1000 => returns 'tis.' for CZ and 'K' for en)
     */
    static getCompactUnit(scale: number, currency: string): string {
        return new Intl.NumberFormat(i18next.language, {
            style: "currency",
            currency: currency,
            notation: "compact"
        }).formatToParts(scale).find((part) => part.type === "compact")?.value ?? "";
    }

    static getCurrencyUnit(currency: string): string {
        return new Intl.NumberFormat(i18next.language, {
            style: "currency",
            currency: currency,
            currencyDisplay: currency === CurrencyCode.USDollar ? "narrowSymbol" : "symbol",
        }).formatToParts(1).find((part) => part.type === "currency").value;
    }
}


export function getCurrencyScale(value: number, currency: CurrencyCode): string {
    let scale = 1;
    while (Math.abs(value) >= 1000) {
        value /= 1000;
        scale *= 1000;
    }
    const unit = CurrencyType.getCompactUnit(scale, currency);
    return `${NumberType.format(value)} ${unit}`;
}

/** Ignore decimal numbers for whole numbers, but show decimals for numbers with fraction */
export const formatCurrencyVariableDecimals = (value: TValue, currency: string): string => {
    return CurrencyType.format(value, {
        currency,
        minimumFractionDigits: Number.isInteger(parseFloat(value as string)) ? 0 : 2
    });
};

/** Simplified currency formatter always ignores fraction parts */
export function formatSimpleCurrency(value: TValue, currency: string): string {
    return CurrencyType.format(value, { currency, maximumFractionDigits: 0 });
}

/** Basic currency formatter - fills zeros */
export function formatCurrency(value: TValue, currency: string): string {
    // todo: we may want to set minimumFractionDigits according to currency minorUnit
    return CurrencyType.format(value, { currency, minimumFractionDigits: 2 });
}

export default CurrencyType;